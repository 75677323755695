import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/production/kiki/private/reportWorkRecord/'

export default {
  // 报工记录-分页
  getRecordsListPage(data) {
    return request.get(baseUrl + 'getReportWorkRecordListPage', data)
  },
  // 报工记录-不分页
  getRecordsList(data) {
    return request.get(baseUrl + 'getReportWorkRecordList', data)
  },
  // 获取入站列表
  getArriveList(data) {
    return request.get(baseUrl + 'getArrivalRecordList', data)
  },
  // 获取出站列表
  getOutboundList(data) {
    return request.get(baseUrl + 'getOutboundRecordList', data)
  },
  // 分批次获取拆单、减产工序
  getReduceAndDivisionList(data) {
    return request.get(baseUrl + 'getReduceAndDivisionList', data)
  },
  //投产记录 分页查询
  getProductionRecordListPage(data) {
    return request.get('api/web/v1/production/kiki/private/materialsReceiveRecord/getMaterialsReceiveRecordListPage', data)
  },
}
