import permission from './permission'
import { selectScrollEnd } from './selectScrollEnd'

const directives = {
  permission,
  selectScrollEnd
}

export default {
  install(Vue) {
    Object.keys(directives).forEach(key => {
      Vue.directive(key, directives[key])
    })
  }
}
