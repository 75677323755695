var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-builder-wrap" },
    [
      _c("CFormBuilder", {
        attrs: {
          disabled: _vm.disabled,
          "col-span": _vm.colSpan,
          this: _vm.that
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }