import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/basicData/private/station/'

export default {
  // 获取工位-不分页
  getStationList(data) {
    return request.get(baseUrl + 'getStationList', data)
  },
  // 获取工位-分页
  getStationListPage(data) {
    return request.get(baseUrl + 'getStationListPage', data)
  },
  // 新增工位
  addStation(data) {
    return request.post(baseUrl + 'addStation', data)
  },
  // 修改工位
  modifyStation(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 复制工位
  copyStation(data) {
    return request.post(baseUrl + 'addStation', data)
  },
  // 删除工位
  delStation(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  // 工位详情
  getStationDetail(id) {
    return request.get(baseUrl + 'getStationDetail', { id })
  },
  getStationListByProcedureId(procedureId, areaId) {
    return request.get(baseUrl + 'getStationListByProcedureId', { procedureId, areaId })
  },
  getStationListByUserId(userId) {
    return request.get(baseUrl + 'getStationListByUserId', { userId })
  },
  createCode(params) {
    return request.get(baseUrl + 'getMaxStationCode', params)
  },
  getProcedureListByStationId(stationId) {
    return request.get('api/web/v1/basicData/private/station/getProcedureListByStationId', { stationId })
  },
  setProcedure(data) {
    return request.put(baseUrl + 'setProcedureListForStation', data)
  }
}
