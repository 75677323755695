<template>
  <div>
    <el-form label-width="80px" :model="pageInfo" size="small" class="kr-form">
      <el-row>
        <el-col :span="15">
          <el-form-item label="模板名称">
            <el-select
              v-model="pageInfo.title"
              @change="selectTitle"
            >
              <el-option v-for="val in getTemplateList" :key="val.name" :label="val.name" :value="val.name" />
            </el-select>
            <!-- <el-input v-model="pageInfo.title" class="full-w"></el-input> -->
          </el-form-item>
        </el-col>
        <el-col :span="6" style="margin-left: 20px;">
          <el-button size="mini" type="text" @click="addTemplate">另存为</el-button>
          <el-button size="mini" type="text" @click="delTemplate">删除</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="模板宽度">
            <el-input-number
              v-model="pageInfo.width"
              controls-position="right"
              :min="200"
              :max="750"
              class="min-input"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="模板高度">
            <el-input-number
              v-model="pageInfo.height"
              controls-position="right"
              :min="200"
              :max="750"
              class="min-input"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <el-row>
        <el-form-item label="纸张宽度">
          <el-input-number
            v-model="pageInfo.pageWidth"
            controls-position="right"
            :min="0"
            class="min-input"
          ></el-input-number>
          <span class="unit-text">(mm)</span>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="纸张高度">
          <el-input-number
            v-model="pageInfo.pageHeight"
            controls-position="right"
            :min="0"
            class="min-input"
          ></el-input-number>
          <span class="unit-text">(mm)</span>
        </el-form-item>
      </el-row> -->
    </el-form>
  </div>
</template>

<script>
import vptd from '../../mixins/vptd'
import api from '@/api/print'

export default {
  mixins: [vptd],
  props: {
    getTemplateList: {
      type: Array,
      default: []
    }
  },
  computed: {
    pageInfo() {
      return this.$vptd.state.page
    }
  },
  methods: {
    addTemplate() {
      this.$emit('addTemplate')
    },
    delTemplate() {
      this.$emit('delTemplate')
    },
    selectTitle() {
      this.$emit('selectTitle', this.pageInfo.title)
    }
  }
}
</script>
<style lang="scss" scoped>
.kr-form {
  ::v-deep.el-form-item--mini.el-form-item {
    margin-bottom: 10px;
  }
  ::v-deep .el-form-item--small.el-form-item {
    margin-bottom: 10px;
  }
}
</style>

