import request from '@/utils/request'
import requestForAsync from '@/utils/requestForAsync'
const baseUrl = '/api/web/v1/systemConfig/private/updateLogRecord/'
export default {
  getLogList(postData) {
    return requestForAsync.get(baseUrl + 'getUpdateLogRecordList', postData)
  },
  addLog(postData) {
    return requestForAsync.post(baseUrl + 'addUpdateLogRecord', postData)
  },
  modifyLog(data) {
    return requestForAsync.put(baseUrl + `updateOne`, data)
  },
  delLog(id) {
    return requestForAsync.del(baseUrl + `deleteOne?id=${id}`)
  }
}
