<template>
  <div class="second-bar-container" :style="{
    width: (opened || siderIconHovered) && list.length ? '145px' : 0,
    position: siderIconHovered ? 'fixed' : 'relative',
    left: siderIconHovered ? '50px' : 0,
    top: 0,
    transition: opened ? 'width .5s' : 'initial'
  }">
    <div class="current-parent" style="width: 145px">{{ parentTitle }}</div>
    <ul>
      <!-- <li
          title="调整测试"
          @click="openSecond2()"
        >跳转测试</li> -->

      <template v-for="item in list">
        <li
          v-if="!item.hidden && hasAuth(item)"
          :key="item.name"
          :title="item.meta.title"
          @click="openSecond(item)"
        >
          <div class="li-content" v-if="isShowSecondBar(item)">
            <!-- <span class="circle" /> -->
            <!-- <img src="@/assets/home/shape.png" style="width: 10px;height: 10px;margin-right: 8px;"/> -->
            <span style="width: 15px;display: inline-block;" />
            <span class="ellipsis">{{ item.meta.title }}</span>
          </div>
          <ul>
            <template v-for="child in item.children">
              <li
                v-if="!child.hidden && hasAuth(child)"
                :key="child.name"
                :title="child.meta.title"
                @click="openSecond(child)"
              >
                <div class="li-contents" :class="routerName === child.meta.activeMenu ? 'active' : 'inactive'">
                  <span style="width: 18px;display: inline-block;" />
                  <span class="ellipsis">{{ child.meta.title }}</span>
                </div>
              </li>
            </template>
          </ul>
          <img v-if="showVideo" src="http://mubyte.cn/wp-content/uploads/2021/10/2021102207331539.png"
            style="position: absolute;bottom: 60px;width: 90%;left: 5%" @click="viewVideo">
          <img v-if="showVideo" src="http://mubyte.cn/wp-content/uploads/2021/11/2021110409345441.png" style="position: absolute;bottom: 20px;width: 90%;left: 5%">
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
/** write by luomingzhi */
export default {
  name: 'SecondBar',
  props: {
    list: {
      type: Array,
      default: () => ([])
    },
    parentTitle: {
      type: String,
      default: ''
    },
    siderIconHovered: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    opened() {
      return this.$store.state.app.sidebar.opened
    },
    routerName() {
      return this.$route.meta.activeMenu
    },
    roles() {
      return this.$store.state.user.roles
    }
  },
  data() {
    return {
      showVideo: false
    }
  },
  mounted() {
    this.showVideo = sessionStorage.getItem('videoIndex') && sessionStorage.getItem('tipImgSrc')
    this.$nextTick(() => {
      if (this.showVideo) this.viewVideo()
    })
  },
  onShow() {
    // console.log(this.list)
  },
  methods: {
    isShowSecondBar(item){
      let arr = [
        "plansInfo","File","salesManage","allEquipment","userInfo","baseSettingInfo",
        "Fun","BarcodeManagement","MouldManagement"
      ]
      if(arr.indexOf(item.name)>-1){
        return false
      }else{
        return true
      }
    },
    viewVideo: function() {
      const box = document.querySelector("body")
      let newDiv = document.createElement('div')
      newDiv.className = 'mark'
      const index = sessionStorage.getItem('videoIndex')
      const videoUrl = [
        'https://oss.mubyte.cn/%E5%AE%98%E7%BD%91%E6%95%99%E5%AD%A6%E8%A7%86%E9%A2%91/%E8%B4%A8%E6%A3%80%E5%91%98%E6%95%99%E5%AD%A6%E8%A7%86%E9%A2%91.mp4',
        'https://oss.mubyte.cn/%E5%AE%98%E7%BD%91%E6%95%99%E5%AD%A6%E8%A7%86%E9%A2%91/%E4%BB%93%E7%AE%A1%E5%91%98%E6%95%99%E5%AD%A6%E8%A7%86%E9%A2%91.mp4',
        'https://oss.mubyte.cn/%E5%AE%98%E7%BD%91%E6%95%99%E5%AD%A6%E8%A7%86%E9%A2%91/%E5%B7%A5%E8%89%BA%E5%91%98%E6%95%99%E5%AD%A6%E8%A7%86%E9%A2%91.mp4',
        'https://oss.mubyte.cn/%E5%AE%98%E7%BD%91%E6%95%99%E5%AD%A6%E8%A7%86%E9%A2%91/%E6%93%8D%E4%BD%9C%E5%B7%A5%E6%95%99%E5%AD%A6%E8%A7%86%E9%A2%91.mp4',
        'https://oss.mubyte.cn/%E5%AE%98%E7%BD%91%E6%95%99%E5%AD%A6%E8%A7%86%E9%A2%91/%E8%AE%A1%E5%88%92%E5%91%98%E6%95%99%E5%AD%A6%E8%A7%86%E9%A2%91.mp4',
        'https://oss.mubyte.cn/%E5%AE%98%E7%BD%91%E6%95%99%E5%AD%A6%E8%A7%86%E9%A2%91/%E7%AE%A1%E7%90%86%E5%91%98%E6%95%99%E5%AD%A6%E8%A7%86%E9%A2%91.mp4',
        'https://oss.mubyte.cn/%E5%AE%98%E7%BD%91%E6%95%99%E5%AD%A6%E8%A7%86%E9%A2%91/%E8%AE%BE%E5%A4%87%E5%91%98%E6%95%99%E5%AD%A6%E8%A7%86%E9%A2%91.mp4'
      ]
      let iframeDiv = document.createElement('div')
      iframeDiv.className = 'iframe'
      let video = document.createElement('video')
      video.src = videoUrl[index]
      video.autoplay = true
      video.controls = true
      video.muted = 'muted'
      video.style = 'width: 100%;height: 100%'
      iframeDiv.appendChild(video)
      let newSpan = document.createElement('span')
      newSpan.innerHTML = 'X'
      iframeDiv.appendChild(newSpan)

      newSpan.onclick = function() {
        newDiv.style.transition = 'all .5s'
        newDiv.style.opacity = '0'
        setTimeout(() => {
          box.removeChild(newDiv)
        }, 500)
        // box.removeChild(newSpan);
        // box.removeChild(newImg);
      }
      newDiv.appendChild(iframeDiv)
      box.appendChild(newDiv)
      // setTimeout(() => {
      //   document.querySelector('video').play()
      // }, 500)
    },
    hasAuth(menu) {
      //新增加的判断是为了处理首页新增路由  但是admin redis存的路由没有自动刷新  需要手动处理权限
      //这里需要根据当前登录用户是不是admin超级管理员  来判断是否需要对首页的几个菜单去做权限控制
      if(this.$store.state.user.userInfo.flag === 1){
        return this.roles.indexOf(menu.name) !== -1 || menu.name.startsWith("Home") || menu.name.startsWith("Fun")
      }else{
        return this.roles.indexOf(menu.name) !== -1 || menu.name.startsWith("Fun")
      }
    },
    openSecond(menu) {
      // console.log("跳转的菜单信息",menu)
      if (menu.children && menu.children.length && !menu.meta.canLink) return
      this.$router.push({ name: menu.name })
    },
    openSecond2() {
      this.$router.push({ path: `/dataease` })

      // this.$router.push({ path: `/project-management/file/project-info/dataease` })

      // this.$router.push({ name: `TaskNodes` })

      // const routeUrl = this.$router.resolve({ path: `/dataease` })
      // window.open(routeUrl.href, '_blank')
    }
  }
}
</script>
<style>
.mark {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  opacity: 1;
  z-index: 9999999;
  animation: show 0.5s;
  /*修改pic的宽高,可调整图片大小*/
}

.mark .iframe {
  position: absolute;
  height: 70%;
  width: 70%;
  z-index: 99999999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #000;
  border-radius: 10px;
}
.mark span {
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  right: 20px;
  top: 20px;
  border-radius: 100%;
  background: #fff;
  text-align: center;
  color: #2D2F31;
  cursor: pointer;
  box-shadow: 0 0 5px 5px #e6e4e4;
  z-index: 999999999999;
  display: none;
}
.mark .iframe:hover span {
  display: inline-block;
}

@keyframes show {
  to {
    opacity: 1;
  }
  from {
    opacity: 0;
  }
}
</style>
<style scoped lang="scss">
.second-bar-container {
  width: 0;
  overflow: hidden;
  position: relative;
  background-color: #E7EDF4;
  height: 100%;
  ul, li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  div.active {
    background: #E0E9F1;
    .ellipsis {
      color: #607FFF;
    }
  }
  .li-contents:hover {
    background: #E0E9F1;
     .ellipsis {
      color: #6B8AFF;
    }
  }
  .current-parent {
    font-weight: 600;
    padding: 12px 0 12px 28px;
    width: 100%;
    font-size: 16px;
    color: #41456B;
    line-height: 28px;
    text-align: left;
    font-style: normal;
  }
  >ul {
    padding: 5px 0;
    height: calc(100% - 60px);
    overflow-y: auto;
    .li-content {
      display: flex;
      align-items: center;
      padding: 10px 15px;
    }
    .li-contents {
      display: flex;
      align-items: center;
      padding: 10px 15px;
    }
    li {
      // padding: 10px 0;
      // cursor: pointer;
      color: #9FA1B7;
      .circle {
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background: $primary-color;
        margin-right: 8px;
      }
    }
    >li {
      // cursor: pointer;
      span {
        font-size: 14px;
        width: 100%;
        //word-break: keep-all;
      }
      >ul {
        >li {
          cursor: pointer;
          span {
            color: #41456B;
            font-size: 14px;
            //word-break: keep-all;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
