import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/production/private/planNodeTemp/'
const baseUrl1 = 'api/web/v1/production/private/'

export default {
    // 获取任务节点列表
    getTaskNodesListPage(data) {
        return request.get(baseUrl + 'listPage', data)
    },
    //   新建任务节点
    addTaskNodes(data) {
        return request.post(baseUrl + 'create', data)
    },
      // 复制任务节点
  copyTaskNodes(data) {
    return request.post(baseUrl + 'create', data)
  },
    // 修改任务节点
    modifyTaskNodes(data) {
        return request.put(baseUrl + 'update', data)
    },
    //   删除
    delTaskNodes(data) {
        return request.del(baseUrl + 'delete', data)
    },
    // 设为默认
    setTaskNodesDefault(data){
        return request.put(baseUrl + 'updateDefault', data)
    },
    //  获取计划时间
    getPlanNodeTimeList(data){
        return request.get(baseUrl + 'getPlanNodeTimeList',data)
    },
    // 获取计划类型
    getPlanType(data){
        return request.get(baseUrl1 + 'planType/list',data)
    }
}
