var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "gantt-chart",
      on: {
        "&wheel": function($event) {
          return _vm.wheelHandle($event)
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "gantt-container",
          style: {
            height: "calc(100% - " + _vm.scrollXBarHeight + "px)",
            width: "calc(100% - " + _vm.scrollYBarWidth + "px)"
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hideHeader,
                  expression: "!hideHeader"
                }
              ],
              staticClass: "gantt-header",
              style: { width: "calc(100% + " + _vm.scrollYBarWidth + "px)" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "gantt-header-title",
                  style: {
                    "line-height": _vm.titleHeight + "px",
                    height: _vm.titleHeight + "px",
                    width: _vm.titleWidth + "px"
                  }
                },
                [_vm._t("title", [_vm._v("welcome v-gantt-chart")])],
                2
              ),
              _c(
                "div",
                { ref: "headerTimeline", staticClass: "gantt-header-timeline" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "gantt-timeline-wrapper",
                      style: {
                        width: _vm.totalWidth + _vm.scrollYBarWidth + "px"
                      }
                    },
                    [
                      _c("timeline", {
                        attrs: {
                          start: _vm.start,
                          end: _vm.end,
                          "cell-width": _vm.cellWidth,
                          "title-height": _vm.titleHeight,
                          scale: _vm.scale,
                          "start-time-of-render-area": _vm.dayjs(
                            _vm.startTimeOfRenderArea
                          ),
                          "end-time-of-render-area": _vm.dayjs(
                            _vm.endTimeOfRenderArea
                          ),
                          "get-positon-offset": _vm.getPositonOffset,
                          ganttType: _vm.ganttType
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "gantt-body",
              style: { height: "calc(100% - " + _vm.actualHeaderHeight + "px)" }
            },
            [
              _c("div", { staticClass: "gantt-table" }, [
                _c(
                  "div",
                  {
                    ref: "marklineArea",
                    staticClass: "gantt-markline-area",
                    style: { marginLeft: _vm.titleWidth + "px" }
                  },
                  [
                    _vm.showCurrentTime
                      ? _c("CurrentTime", {
                          attrs: { "get-positon-offset": _vm.getPositonOffset }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    ref: "leftbarWrapper",
                    staticClass: "gantt-leftbar-wrapper",
                    style: {
                      width: _vm.titleWidth + "px",
                      height: "calc(100% + " + _vm.scrollXBarHeight + ")"
                    }
                  },
                  [
                    _vm.ganttType === "tableType"
                      ? _c("LeftBar", {
                          style: { height: _vm.totalHeight + "px" },
                          attrs: {
                            datas: _vm.datas,
                            "data-key": _vm.dataKey,
                            "scroll-top": _vm.scrollTop,
                            "height-of-blocks-wrapper":
                              _vm.heightOfBlocksWrapper,
                            "cell-height": _vm.cellHeight,
                            preload: _vm.preload
                          },
                          on: { selectId: _vm.selectId },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var data = ref.data
                                  return [_vm._t("left", null, { data: data })]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _c("LeftTreeBar", {
                          ref: "leftBar",
                          style: { height: _vm.totalHeight + "px" },
                          attrs: {
                            datas: _vm.datasB,
                            "data-key": _vm.dataKey,
                            "scroll-top": _vm.scrollTop,
                            "height-of-blocks-wrapper":
                              _vm.heightOfBlocksWrapper,
                            "cell-height": _vm.cellHeight,
                            preload: _vm.preload
                          },
                          on: {
                            selectId: _vm.selectId,
                            nodeOpen: _vm.nodeOpen,
                            nodeClose: _vm.nodeClose,
                            adjustNode: _vm.adjustNode,
                            releaseNode: _vm.releaseNode,
                            viewLog: _vm.viewLog,
                            onUrge: _vm.onUrge
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var data = ref.data
                                  return [_vm._t("left", null, { data: data })]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                  ],
                  1
                ),
                _c(
                  "div",
                  { ref: "blocksWrapper", staticClass: "gantt-blocks-wrapper" },
                  [
                    _c("blocks", {
                      style: {
                        width: _vm.totalWidth + "px",
                        height: _vm.totalHeight + "px"
                      },
                      attrs: {
                        "scroll-top": _vm.scrollTop,
                        "scroll-left": _vm.scrollLeft,
                        "height-of-blocks-wrapper": _vm.heightOfBlocksWrapper,
                        "width-of-blocks-wrapper": _vm.widthOfBlocksWrapper,
                        "array-keys": _vm.arrayKeys,
                        "item-key": _vm.itemKey,
                        "data-key": _vm.dataKey,
                        datas: _vm.datas,
                        "cell-width": _vm.cellWidth,
                        "cell-height": _vm.cellHeight,
                        scale: _vm.scale,
                        "get-positon-offset": _vm.getPositonOffset,
                        "get-width-about2-times": _vm.getWidthAbout2Times,
                        "custom-generate-blocks": _vm.customGenerateBlocks,
                        "start-time-of-render-area": _vm.startTimeOfRenderArea,
                        "end-time-of-render-area": _vm.endTimeOfRenderArea,
                        preload: _vm.preload,
                        start: _vm.start,
                        end: _vm.end,
                        "select-ids": _vm.selectIds
                      },
                      scopedSlots: _vm._u(
                        [
                          !_vm.customGenerateBlocks
                            ? {
                                key: "default",
                                fn: function(ref) {
                                  var data = ref.data
                                  var item = ref.item
                                  var getPositonOffset = ref.getPositonOffset
                                  var getWidthAbout2Times =
                                    ref.getWidthAbout2Times
                                  var selectIds = ref.selectIds
                                  return [
                                    _vm._t("block", null, {
                                      data: data,
                                      item: item,
                                      getPositonOffset: getPositonOffset,
                                      getWidthAbout2Times: getWidthAbout2Times,
                                      selectIds: selectIds
                                    })
                                  ]
                                }
                              }
                            : {
                                key: "default",
                                fn: function(ref) {
                                  var data = ref.data
                                  var getPositonOffset = ref.getPositonOffset
                                  var getWidthAbout2Times =
                                    ref.getWidthAbout2Times
                                  var isInRenderingTimeRange =
                                    ref.isInRenderingTimeRange
                                  var isAcrossRenderingTimeRange =
                                    ref.isAcrossRenderingTimeRange
                                  return [
                                    _vm._t("block", null, {
                                      data: data,
                                      getPositonOffset: getPositonOffset,
                                      getWidthAbout2Times: getWidthAbout2Times,
                                      isInRenderingTimeRange: isInRenderingTimeRange,
                                      isAcrossRenderingTimeRange: isAcrossRenderingTimeRange,
                                      startTimeOfRenderArea:
                                        _vm.startTimeOfRenderArea,
                                      endTimeOfRenderArea:
                                        _vm.endTimeOfRenderArea
                                    })
                                  ]
                                }
                              }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ])
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          ref: "scrollYBar",
          staticClass: "gantt-scroll-y",
          style: {
            width: _vm.scrollYBarWidth + "px",
            height: "calc(100% - " + _vm.actualHeaderHeight + "px",
            marginTop: _vm.actualHeaderHeight + "px"
          },
          on: {
            "&scroll": function($event) {
              return _vm.syncScrollY($event)
            }
          }
        },
        [_c("div", { style: { height: _vm.totalHeight + "px" } })]
      ),
      _c(
        "div",
        {
          ref: "scrollXBar",
          staticClass: "gantt-scroll-x",
          style: {
            height: _vm.scrollXBarHeight + "px",
            width: "calc(100% - " + _vm.titleWidth + "px )",
            marginLeft: _vm.titleWidth + "px"
          },
          on: {
            "&scroll": function($event) {
              return _vm.syncScrollX($event)
            }
          }
        },
        [_c("div", { style: { width: _vm.totalWidth + "px" } })]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }