import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/invoices/private/facilityConfigurationParameterTemplate/'
const repairUrl = 'api/web/v1/field/facility/invoices/private/facilityConfigurationTemplate/'

export default {
  // 获取配置参数列表（不分页）
  getParameterList(data) {
    return request.get(baseUrl + 'getFacilityConfigurationParameterTemplateList', data)
  },
  // 获取配置参数列表（分页）
  getParameterListPage(data) {
    return request.get(baseUrl + 'getFacilityConfigurationParameterTemplateListPage', data)
  },
  // 新建配置参数
  addParameter(data) {
    return request.post(baseUrl + 'addFacilityConfigurationParameterTemplate', data)
  },
  // 修改配置参数
  modifyParameter(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 复制配置参数
  copyParameter(data) {
    return request.post(baseUrl + 'addFacilityConfigurationParameterTemplate', data)
  },
  // 删除配置参数
  delParameter(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 获取配置参数详情
  getParameterDetail(data) {
    return request.get(baseUrl + 'getFacilityConfigurationParameterTemplateDetail', data)
  },
  // 批量删除配置参数
  delBatchParameter(data) {
    return request.del(baseUrl + 'deleteBatch', data)
  },

  // 获取配置模板列表（不分页）
  getTemplatesList(data) {
    return request.get(repairUrl + 'getFacilityConfigurationTemplateList', data)
  },
  // 获取配置模板列表（分页）
  getTemplatesListPage(data) {
    return request.get(repairUrl + 'getFacilityConfigurationTemplateListPage', data)
  },
  // 新建配置模板
  addTemplates(data) {
    return request.post(repairUrl + 'addFacilityConfigurationTemplate', data)
  },
  // 修改配置模板
  modifyTemplates(data) {
    return request.put(repairUrl + 'updateOne', data)
  },
  // 删除配置模板
  delTemplates(data) {
    return request.del(repairUrl + 'deleteOne', data)
  },
  // 获取配置模板详情
  getTemplatesDetail(data) {
    return request.get(repairUrl + 'getFacilityConfigurationTemplateDetail', data)
  },
  // 批量删除配置模板
  delBatchTemplates(data) {
    return request.del(repairUrl + 'deleteBatch', data)
  }
}
