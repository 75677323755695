import request from '@/utils/requestForAsync'
const baseUrl1 = 'api/web/v1/basicData/materials/private/materialsCompleteCalculate/'
const baseUrl = 'api/web/v1/basicData/production/private/salesOrder/'

export default {
  // 获取列表
  getSalesOrderList(data) {
    return request.get(baseUrl + 'listSalesOrderDetails', data)
  },
  // 客户选项
  getCustomer() {
    return request.get('api/web/v1/basicData/private/customer/listAllCustoms')
  },
  // 负责人选项
  getResponsible() {
    return request.get('api/web/v1/authority/private/user/listAllUsers')
  },
  // 订单编号
  getCode() {
    return request.get(baseUrl + 'getSalesOrderCode')
  },
  // 查看详情
  viewDetail(data) {
    return request.get(baseUrl + '/getSalesOrderDetail', data)
  },
  // 新建
  addSalesOrder(data) {
    return request.post(baseUrl + 'addSalesOrder', data)
  },
  // 修改
  updateSalesOrder(data) {
    return request.put(baseUrl + 'updateSalesOrder', data)
  },
  // 删除
  delete(data) {
    return request.put(baseUrl + 'deleteSalesOrder', data)
  },
  // 完结
  finish(data) {
    return request.put(baseUrl + 'finishSalesOrder', data)
  },
  // 修改
  edit(data) {
    return request.post(baseUrl + 'updateSalesOrder', data)
  },
  // 作废
  cancellation(data) {
    return request.put(baseUrl + 'discardSalesOrder', data)
  },
  // 重启
  restart(data) {
    return request.put(baseUrl + 'restartSalesOrder', data)
  },
  // 销售订单活跃下拉列表
  getActiveSalesOrderList() {
    return request.get(baseUrl + 'activeSalesOrderList')
  },
  // 货品清单库存查询
  getMaterialsVariableStock(data) {
    return request.get('api/web/v1/warehouse/kiki/private/warehouseMaterialsBatKiki/getMaterialsVariableStock', data)
  },
  // 销售需求列表
  getSalesDemandList(data) {
    return request.get('api/web/v1/salesschedule/private/list', data)
  },
  // 销售需求列表库存锁定
  lockStock(data) {
    return request.post('api/web/v1/salesschedule/private/lock', data)
  },
  // 销售需求列表批量下推
  simplePub(data) {
    return request.post('api/web/v1/salesschedule/private/produce/create', data)
  },
  // 销售需求列表合并下推
  batchPub(data) {
    return request.post('api/web/v1/salesschedule/private/produce/merge', data)
  },
  // 下推获取code
  getCodePub(data) {
    return request.get('api/web/v1/production/produceorder/private/code', data)
  }
}
