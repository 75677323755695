<template>
  <MDialog v-model="modalShow" width="1000px" :append-to-body="true" title="选择物料" @onOk="submitForm" @fullScreen="fullScreenChange">
    <Split :offset.sync="split.offset" :height="splitHeight">
      <template slot="left">
        <MaterialsClass :view="true" @setMaterialsClass="setMaterialsClass" @nodeClick="setMaterialsClassId" />
      </template>
      <template slot="right">
        <div class="top">
          <SearchForm ref="searchForm" :form-list-extend="searchFormList" :form-data.sync="searchFormData" @search="searchTable" />
        </div>
        <div class="materials-info">
          <FunctionHeader
            v-model="functionHeader.searchVal"
            :search-title="isContainer === 1 ? '物料名称/编码/规格/批次号/序列号/容器编号/容器名称' : '物料名称/编码/规格/批次号/序列号'"
            class="materials-head"
            :tags="functionHeader.tags"
            :columns-setting="false"
            @search="$refs.mTable.setTableData(true)"
          >
         
            <div slot="all">
              <span style="margin: 0 20 0 0px; display: inline-block">
                已选中
                <span style="color: #000">{{ getSelectionDataLength }}</span>
                项
              </span>
              <el-button type="text" @click="resetData">清空</el-button>
            </div>
          </FunctionHeader>
          <MTable
            ref="mTable"
            :highlight-current-row="single"
            :columns="columns"
            :height="height"
            :set-data-method="getTableData"
            :columns-setting="false"
            @selection-change="selectionChange"
            @current-change="selectionChange"
            @row-click="handleRowClick"
          >
            <div slot="index" slot-scope="{ row, $index }">
              {{ $index + 1 }}
            </div>
            <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
              <div :style="{ background: row.isEnabled ? '#24CAD7' : '#FFCC78' }" />
              <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
            </div>
            <div slot="warehouseBatchNumber" slot-scope="{ row }">
              <span >{{ row.warehouseBatchNumber || '-' }}</span>
              <!-- <template v-else>
                <div v-if="+row.isBatchManager === 1" style="display: flex;align-items: center;justify-content: flex-start;">
                  <el-input v-model="row.warehouseBatchNumber" />
                </div>
                <span v-else>-</span>
              </template> -->
            </div>
          </MTable>
        </div>
      </template>
    </Split>
  </MDialog>
</template>

<script>
import MaterialsClass from '@/components/MaterialsClass/MaterialsClass'
import Split from '@/components/Split/Split'
import SearchForm from '@/components/SearchForm/SearchForm'
import FunctionHeader from '@/components/FunctionHeader'
import { columnsInOut, columnsInAll, columns, ivColumns, sColumns, columnsIn, mColumns } from './columns'
import { status } from '@/config/options.config'
import { getUUid } from '@/utils'
import api from '@/api/information/materials'
import inventoryApi from '@/api/warehouse/inventory'
import { Encrypt } from '@/utils/sercet'
import maApi from '@/api/information/materials-attribute'
import warehouseApi from '@/api/information/warehouse/model'

export default {
  name: 'SelectMaterialsInventory',
  components: { MaterialsClass, Split, SearchForm, FunctionHeader },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    conditions: {
      type: Array,
      default: () => []
    },
    selectMaterialInventorys: {
      type: Array,
      default: () => []
    },
    from: {
      type: String,
      default: 'materials' // inventory
    },
    warehouseId: {
      type: Number,
      default: undefined
    },
    operateType: {
      type: Number,
      default: undefined
    },
    consumerId: {
      type: Number,
      default: undefined
    },
    isContainer: {
      type: Number,
      default: undefined
    }
    // isOutBand: {
    //   type: Number,
    //   default: undefined
    // }
  },
  data() {
    return {
      modalShow: false,
      split: {
        offset: 20
      },
      columns,
      functionHeader: {
        searchVal: '',
        tags: []
      },
      materialsSearchFormList: [
        {
          colSpan: 6,
          key: 'materialsTypeId',
          tagName: 'el-select',
          defaultValue: 1,
          props: {
            placeholder: '请选择物料类型'
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: []
            }
          ]
        }
      ],
      materialsInventorySearchFormList: [
        {
          colSpan: 6,
          key: 'warehouseId',
          tagName: 'el-select',
          props: {
            placeholder: '请选择仓库'
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: []
            }
          ]
        },
        {
          colSpan: 6,
          key: 'warehouseLocationId',
          tagName: 'el-select',
          props: {
            placeholder: '请选择库位'
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: []
            }
          ]
        }
      ],
      searchFormList: [],
      searchFormData: {},
      searchFormatData: [],
      formOptions: {
        materialsClassList: []
      },
      selectionData: [],
      defaultSearch: {
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }
          ])
        )
      },
      fullScreen: false,
      warehouseIds: '',
      isContainers: undefined
    }
  },
  computed: {
    height() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 83 - 57 : this.$store.state.app.contentHeight - 289 - 160 - 10
    },
    splitHeight() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 10 : this.$store.state.app.contentHeight - 289 - 45
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionData.filter(item => !item.selectable)
      return selectionDataNoDisabled.length
    },
    warehouseCurId() {
      return this.searchFormData.warehouseId
    }
  },
  watch: {
    visible(val) {
      this.modalShow = val
      if (+this.operateType === 1) this.columns = columnsIn
      if (this.single) this.columns = sColumns
      if (+this.operateType === 3) {
        this.columns = mColumns
        this.getWarehouseList()
      }
      if (val) {
        this.echoData()
      }
    },
    modalShow(val) {
      if (!val) {
        this.searchFormData = {}
        this.searchFormatData = []
        this.functionHeader.searchVal = ''
        this.$emit('update:visible', false)
      }
    },
    from() {
      this.init()
    },
    warehouseCurId: {
      async handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.$refs.searchForm.setForm({ warehouseLocationId: '' })
          const res = await warehouseApi.getAllNodesByWarehouseId(newVal)
          this.searchFormList[1].children[0].options = (res || []).filter(item => item.type === 2)
        }
      },
      deep: true
    },
    isContainer(val) {
      this.isContainers = val
      if (+this.isContainers === 1) this.columns = columnsInAll
      if (+this.isContainers === 0) this.columns = columnsInOut
    }
  },
  mounted() {
    this.isContainers = this.isContainer
    this.modalShow = this.visible
    if (+this.operateType === 1) this.columns = columnsIn
    if (this.single) this.columns = sColumns
    if (+this.operateType === 3) this.columns = mColumns
    this.init()
    if (this.modalShow) {
      this.echoData()
    }
  },
  methods: {
    resetData() {
      this.selectionData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionData = this.selectionData.filter(item => item.selectable)
      // this.selectionData = []
      // this.$refs.mTable.tableRefs().clearSelection()
    },
    echoData() {
      this.$nextTick(() => {
        // 数据回显
        this.materialsClassId = ''
        this.selectionData = this._.cloneDeep(this.selectMaterialInventorys)
        // console.log('ceshihuixian=>', this.selectionData)
        if (this.single) {
          this.$refs.mTable.tableRefs().setCurrentRow(this.selectionData[0])
        } else {
          this.selectionData.forEach(item => {
            this.$refs.mTable.tableRefs().toggleRowSelection(item, true)
          })
        }
      })
    },
    updateTable() {
      this.$refs.mTable.setTableData()
    },
    // 新增
    add() {
      this.$emit('addMaterial')
    },
    // 选中行复选框也会选中
    handleRowClick(row) {
      if (!row.selectable) {
        this.$refs.mTable.tableRefs().toggleRowSelection(row)
      }
    },
    fullScreenChange(val) {
      this.fullScreen = val
    },
    init() {
      this.searchFormatData = []
      if (+this.operateType === 3) {
        this.searchFormList = this.materialsInventorySearchFormList
      } else {
        this.searchFormList = this.materialsSearchFormList
        this.getMaterialsTypeList()
      }
      this.searchFormData = {}
      this.columns = +this.operateType === 1 ? columnsIn : columns
    },
    // 获取物料类型
    async getMaterialsTypeList() {
      const res = await maApi.getMaterialsTypeList(this.defaultSearch)
      if (res) {
        this.searchFormList[0].children[0].options = res
      }
    },
    // 获取仓库
    async getWarehouseList() {
      if (!this.consumerId) {
        const res = await warehouseApi.getRepositoryModelList()
        if (res) {
          this.searchFormList[0].children[0].options = res.filter(item => item.type === 0)
        }
      }
    },
    selectionChange(data) {
      if (this.single) {
        this.selectionData = []
        this.selectionData.push(data[data.length - 1])
        return false
      }
      if (Array.isArray(data)) {
        this.selectionData = data
      } else if (!data.selectable) {
        this.selectionData = []
        this.selectionData.push(data)
      }
    },
    setMaterialsClassId(id) {
      this.materialsClassId = id
      // this.selectionData = []
      this.$refs.mTable.setTableData(true)
    },
    // 获取物料类型
    setMaterialsClass(res) {
      this.formOptions.materialsClassList = res
    },
    // 查询物料
    searchTable(data) {
      this.searchFormatData = data
      this.$refs.mTable.setTableData()
    },
    // 获取物料数据
    async getTableData(condition, callback) {
      const { page, search, order } = condition

      if (+this.operateType === 3 && this.consumerId) {
        const r = await warehouseApi.getWarehouseByCustomerId({
          id: this.consumerId
        })
        if (!r.length) {
          this.$message.info('该客户下未绑定仓库，无法选择物料')
          callback()
          return;
        }
        this.searchFormList[0].children[0].options = r.filter(item => item.type === 0)
        this.warehouseIds = this.searchFormList[0].children[0].options.map(item => item.id).join('^')
      }
      console.log('ceshi=>', this.isContainers)
      let res
      const params = {
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchKeyword: this.functionHeader.searchVal,
        materialsClassId: this.materialsClassId,
        searchVal: Encrypt(JSON.stringify([...search, ...this.conditions])),
        warehouseId: this.warehouseId || '',
        warehouseIds: this.warehouseIds || '',
        isContainer: this.isContainers,
        ...this.searchFormData

      }

      if (this.operateType === 1) {
        res = await inventoryApi.getMaterialsListPage(params)
      } else {
        res = await inventoryApi.getWarehouseMaterialsDetailsIsContainerListPage(params)
      }

      if (res) {
        const data = res.records
        if (this.selectMaterialInventorys) {
          if (this.operateType === 1) {
            this.selectMaterialInventorys.forEach(item => {
              data.forEach(t => {
                if (item.selectable && t.materialsId === item.materialsId) {
                  t.selectable = true
                }
              })
            })
          } else {
            this.selectMaterialInventorys.forEach(item => {
              data.forEach(t => {
                if (item.selectable && t.key === item.key) {
                  t.selectable = true
                }
              })
            })
          }
        }
        callback({
          total: res.total,
          content: data
        })
      }
      callback()
    },
    submitForm(callback) {
      if (this.selectionData.length === 0) {
        this.$message.info('请至少选择一条物料')
        callback()
        return
      }
      this.$emit('submitForm', this.selectionData)
      // callback()
    }
  }
}
</script>

<style lang="scss" scoped>
.materials-info {
  padding: 10px;
}
.materials-head {
  padding: 0 0 10px 0;
}
</style>
