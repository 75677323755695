import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/warehouse/record/private/outInWarehouseType/'

export default {
  // 获取出入库类型-不分页
  getOutInWarehouseTypeList(data) {
    return request.get(baseUrl + 'getOutInWarehouseTypeList', data)
  },
  // 获取出入库类型-分页
  getOutInWarehouseTypeListPage(data) {
    return request.get(baseUrl + 'getOutInWarehouseTypeListPage', data)
  },
  // 新增出入库类型
  addOutInWarehouseType(data) {
    return request.post(baseUrl + 'addOutInWarehouseType', data)
  },
  // 修改出入库类型
  modifyOutInWarehouseType(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除出入库类型
  delOutInWarehouseType(data) {
    return request.del(baseUrl + 'deleteOne', data)
  }
}
