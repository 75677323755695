var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "materials-class" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isOpen,
            expression: "isOpen"
          }
        ],
        staticStyle: { height: "100%" }
      },
      [
        _c("div", { staticClass: "top" }, [
          _c("div", [_vm._v(" BOM类别 ")]),
          _c("div", [
            !_vm.view
              ? _c(
                  "span",
                  {
                    staticClass: "add-materials-class",
                    on: {
                      click: function($event) {
                        return _vm.add()
                      }
                    }
                  },
                  [_c("i", { staticClass: "el-icon-circle-plus" })]
                )
              : _vm._e()
          ])
        ]),
        _c(
          "div",
          { staticClass: "tree" },
          [
            _c("Tree", {
              ref: "zTrees",
              attrs: { view: _vm.view, nodes: _vm.nodes },
              on: { command: _vm.command, nodeClick: _vm.nodeClick }
            })
          ],
          1
        ),
        _c(
          "MDialog",
          {
            attrs: { title: _vm.currentType },
            on: { onOk: _vm.submitForm },
            model: {
              value: _vm.visible,
              callback: function($$v) {
                _vm.visible = $$v
              },
              expression: "visible"
            }
          },
          [
            _c("MFormBuilder", {
              ref: "formBuild",
              attrs: { "form-data": _vm.formData, "form-list": _vm.formList }
            })
          ],
          1
        ),
        _c("div", { staticClass: "fixed" }, [
          _c(
            "span",
            {
              staticStyle: { cursor: "pointer" },
              attrs: { title: "点击收起" },
              on: {
                click: function($event) {
                  return _vm.changeWidthStatus(1)
                }
              }
            },
            [_c("i", { staticClass: "el-icon-d-arrow-left" })]
          )
        ])
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isOpen,
            expression: "!isOpen"
          }
        ],
        staticClass: "fixed"
      },
      [
        _c(
          "div",
          {
            staticStyle: { cursor: "pointer" },
            attrs: { title: "点击展开" },
            on: {
              click: function($event) {
                return _vm.changeWidthStatus(0)
              }
            }
          },
          [_c("i", { staticClass: "el-icon-d-arrow-right" })]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }