import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/invoices/private/facilityMappingParameters/'

export default {
  // 获取技术参数-不分页
  getFacilityParametersList(data) {
    return request.get(baseUrl + 'getFacilityMappingParametersList', data)
  },
  // 获取技术参数-分页
  getFacilityParametersListPage(data) {
    return request.get(baseUrl + 'getFacilityMappingParametersListPage', data)
  },
  // 新增技术参数
  addFacilityParameters(data) {
    return request.post(baseUrl + 'addFacilityMappingParameters', data)
  },
  // 修改技术参数
  modifyFacilityParameters(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除技术参数
  delFacilityParameters(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 获取技术参数模板-分页
  getFacilityTechnicalParameterTemplatePage(data) {
    return request.get('api/web/v1/field/facility/invoices/private/facilityTechnicalParameterTemplate/getFacilityTechnicalParameterTemplateListPage', data)
  },
  // 获取技术参数模板-详情
  getFacilityTechnicalParameterTemplateDetail(data) {
    return request.get('api/web/v1/field/facility/invoices/private/facilityTechnicalParameterTemplate/getFacilityTechnicalParameterTemplateDetail', data)
  }
}
