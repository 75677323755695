import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/equipment/sparePart/private/sparePartProcurement/'
const baseUrl2 = 'api/web/v1/equipment/sparePart/private/sparePartProcurementDetails/'
export default {
  // 获取备件申请-分页
  getSparePartProcurementListPage(data) {
    return request.get(baseUrl + 'getSparePartProcurementListPage', data)
  },
  // 新增备件申请
  addSparePartProcurement(data) {
    return request.post(baseUrl + 'addSparePartProcurement', data)
  },
  // 修改备件申请
  modifySparePartProcurement(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 修改并提交备件申请
  updateCommiSparePartProcurement(data) {
    return request.put(baseUrl + 'updateCommit', data)
  },
  // 批量删除备件申请
  delBatchApply(data) {
    return request.post(baseUrl + 'deleteBatch', data)
  },
  // 删除备件申请
  delSparePart(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 保存备件申请
  submitSparePartProcurement(data) {
    return request.get(baseUrl2 + 'commit', data)
  },
  // 保存并提交备件
  saveAndCommitSparePartProcurement(data) {
    return request.post(baseUrl2 + 'saveAndCommit', data)
  },
  // 详情
  getSparePartProcurementDetail(data) {
    return request.get(baseUrl + `getSparePartProcurementDetail?id=${data}`)
  }
}
