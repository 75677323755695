<template>
  <div class="searchs">
    <!-- 顶部折叠搜索 -->
    <el-row>
      <el-col :md="18" :gutter="10">
        <el-row :gutter="10" type="flex" justify="start" style="flex-flow:row wrap">
          <el-col :md="5">
            <el-select v-model="searchFormData.status" placeholder="生产进度">
              <el-option
                v-for="item in productionStatus"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="7">
            <SelectMultipleMaterialsNew ref="multipleMaterial" @typeChange="searchData" />
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.isRepair" placeholder="工单属性">
              <el-option
                v-for="item in procedureClass"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <!-- <el-select v-model="searchFormData.produceOrderId" placeholder="所属生产订单/#多选" filterable multiple>
                  <el-option
                    v-for="item in orderNumbers"
                    :key="item.id"
                    :label="item.produceOrderNumber"
                    :value="item.id">
                  </el-option>
                </el-select> -->
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.currentProcedureIds" placeholder="当前所处工序/#多选" filterable multiple>
              <el-option
                v-for="item in currentProcedures"
                :key="item.id"
                :label="item.name"
                :value="(item.id).toString()"
              />
            </el-select>
          </el-col>
        </el-row>
        <el-row v-if="!show" type="flex" justify="start" style="flex-flow:row wrap" :gutter="10">
          <el-col :md="5">
            <el-select v-model="searchFormData.source" placeholder="工单来源">
              <el-option
                v-for="item in taskSource"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="7">
            <el-select v-model="searchFormData.processFlowId" placeholder="工艺">
              <el-option
                v-for="item in processFlowList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                @click.native="selectFlower(item)"
              />
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.processFlowVersionId" placeholder="工艺版本">
              <el-option
                v-for="item in versionList"
                :key="item.id"
                :label="item.version"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.areaId" placeholder="产线">
              <el-option
                v-for="item in areaList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="5">
            <el-select v-model="searchFormData.priority" placeholder="计划优先级/#多选" filterable multiple>
              <el-option
                v-for="(item, index) in priorityStatus"
                :key="index"
                :label="item.name"
                :value="index"
              />
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col :md="6" class="all-btns">
        <el-button size="small" type="primary" @click="searchData">查询</el-button>
        <el-button size="small" @click="resetData">重置</el-button>
        <div class="showMore" :style="{background: show ? '' : '#F8F8F8'}" @click="changeMore">
          <span>高级筛选</span>
          <i v-if="show" class="el-icon-caret-bottom icons" />
          <i v-else class="el-icon-caret-top" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { taskSource, productionStatus, procedureClass } from '@/config/options.config'
import SelectMultipleMaterialsNew from '@/components/SelectMultipleMaterialsNew/SelectMultipleMaterialsNew'
import { getUUid } from '@/utils'

import dayjs from 'dayjs'
export default {
  components: { SelectMultipleMaterialsNew },
  props: {
    formOption: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      procedureClass,
      taskSource,
      productionStatus,
      show: true,
      processFlowList: [],
      areaList: [],
      versionList: [],
      orderNumbers: [],
      currentProcedures: [],
      searchFormData: {
        source: '',
        status: '',
        produceOrderNumber: undefined,
        processFlowVersionId: '',
        currentProcedureIds: '',
        processFlowId: '',
        areaId: '',
        produceOrderId: []
      },
      selectKeyword: '0',
      searchDatas: []
    }
  },
  computed: {
    priorityStatus() {
      return this.$store.state.user.priorityList
    }
  },
  mounted() {
    this.processFlowList = this.formOption.flowerOptions
    this.orderNumbers = this.formOption.orderOptions
    this.currentProcedures = this.formOption.operatorOptions
    this.areaList = this.formOption.areaOptions
  },
  methods: {
    selectFlower(row) {
      this.versionList = row.versionList
      this.searchFormData.processFlowVersionId = ''
    },
    changeMore() {
      this.show = !this.show
    },
    // 顶部查询
    searchData() {
      const obj = {}
      const searchDatas = []
      let currentProcedureIds = []
      Object.keys(this.searchFormData).forEach(item => {
        if (Array.isArray(this.searchFormData[item])) {
          if (this.searchFormData[item].length > 0) {
            if (item === 'produceOrderId' || item === 'priority') {
              searchDatas.push({
                id: getUUid(),
                fieldName: item,
                fieldType: 'number',
                fieldValue: this.searchFormData[item].join('^'),
                operator: 'in'
              })
            }
            if (item === 'currentProcedureIds') {
              currentProcedureIds = this.searchFormData[item].join('^')
            }
          }
        } else if (this.searchFormData[item] || this.searchFormData[item] === 0) {
          searchDatas.push({
            id: getUUid(),
            fieldName: item,
            fieldType: typeof this.searchFormData[item],
            fieldValue: item === 'currentProcedure' ? 'procedureId\":' + this.searchFormData[item] + ',' : this.searchFormData[item],
            operator: typeof this.searchFormData[item] === 'number' ? 'eq' : 'like'
          })
        }
      })
      // console.log('搜索数据', searchDatas)
      const keyword = this.$refs.multipleMaterial.keyword.code
      this.$emit('searchData', searchDatas, this.selectKeyword, keyword, currentProcedureIds)
    },
    // 顶部重置
    resetData() {
      this.searchFormData = {
        source: '',
        status: '',
        produceOrderNumber: undefined,
        processFlowVersionId: '',
        currentProcedure: '',
        processFlowId: '',
        areaId: ''
      }
      this.$refs.multipleMaterial.keyword = ''
      this.$emit('resetData')
    }
  }
}
</script>

<style scoped lang="scss">
 .searchs{
    .input-with-select{
      width:100%;
      .input-select{
        width:105px;
      }
   }
    ::v-deep{
      .el-col-md-6{
        margin-bottom:10px;
      }
      .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner{
        width:100%;
      }
      .el-date-editor.el-input, .el-date-editor.el-input__inner{
        width:100%;
      }
      .el-select{
        width:100%;
      }
   }
  .showMore{
    width: 88px;
    height: 40px;
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #607FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    .icons{
     color: #DDE3EB;
    }
    &:hover{
      background:#F8F8F8;
      i{ color: #607FFF;}
    }
  }
  .all-btns{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
  }
}
</style>
