import requestForAsync from '@/utils/requestForAsync'

const baseUrl = 'api/web/v1/systemConfig/private/printTemplate/'

export default {
  // 分页
  getPrintTemplateListPage(postData) {
    return requestForAsync.get(baseUrl + 'getPrintTemplateListPage', postData)
  },
  // 不分页
  getPrintTemplateList(postData) {
    return requestForAsync.get(baseUrl + 'getPrintTemplateList', postData)
  },
  // 删除
  delPrintTemplate(id) {
    return requestForAsync.del(baseUrl + `deleteOne?id=${id}`)
  },
  // 修改
  fixPrintTemplate(data) {
    return requestForAsync.put(baseUrl + `updateOne`, data)
  },
  // 新增
  addPrintTemplate(data) {
    return requestForAsync.post(baseUrl + `addPrintTemplate`, data)
  }
}
