import request from '@/utils/request'
import requestForAsync from '@/utils/requestForAsync'

export default {
  // 取得用户列表(不分页)
  getUserList(data) {
    return request.get('/api/web/v1/authority/private/user/getUserListPage', data)
  },
  // 新建用户
  createUser(postData) {
    return request.post('/api/web/v1/authority/private/user/addUser', postData)
  },
  getNewUserName() {
    return request.get(`/api/web/v1/authority/private/user/getNewUserName`, {})
  },
  // 删除用户
  deleteUser(id) {
    return request.del(`/api/web/v1/authority/private/user/deleteOne?id=${id}`)
  },
  // 修改用户
  modifyUser(data) {
    return request.put('/api/web/v1/authority/private/user/updateOne', data)
  },
  getUserDetail(data) {
    return requestForAsync.get('/api/web/v1/authority/private/user/getUserDetail', data)
  },
  // 重置密码
  resetPassWord(id) {
    return request.put(`/api/web/v1/authority/private/user/resetPassword?id=${id}`)
  },
  // 修改个人信息
  modifyUserInfo(data) {
    return requestForAsync.put('/api/web/v1/authority/private/user/updateOwn', data)
  },
  // 导出用户列表
  exportUserIist() {
    return request.get('/api/web/v1/authority/private/user/export', {})
  },
  // 启用
  enableUser(id) {
    return request.get(`/api/web/v1/authority/private/user/enable?id=${id}`)
  },
  // 禁用
  disableUser(id) {
    return request.get(`/api/web/v1/authority/private/user/disable?id=${id}`)
  }
}
