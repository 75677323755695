<template>
  <Popover
    popper-class="custom-popover"
    placement="bottom"
    width="100"
    style="min-width: 100px"
    trigger="contextmenu"
  >
    <a style="text-align: center;width: 100%;color: #607fff" @click="jumpDetailNewTab">在新的窗口打开</a>
    <el-button slot="reference" type="text" @click="jumpDetail()">{{ text }}</el-button>
  </Popover>
</template>

<script>
import Popover from '@/components/EleComponents/popover/popover'
export default {
  name: 'NewPageOpen',
  components: { Popover },
  props: {
    path: {
      required: true,
      type: Object,
      default: () => ({})
    },
    text: {
      type: String,
      default: ''
    }
  },
  methods: {
    jumpDetail() {
      console.log('path:',this.path)
      this.$router.push(this.path)
    },
    jumpDetailNewTab() {
      this.moreTabSetSessionStorage()
      const routeUrl = this.$router.resolve(this.path)
      window.open(routeUrl.href, '_blank')
    },
    moreTabSetSessionStorage() {
      if (!sessionStorage.length) {
        localStorage.setItem('getSessionStorage', Date.now())
      }
      window.addEventListener('storage', (event) => {
        if (event.key === 'getSessionStorage') {
          localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage))
          localStorage.removeItem('sessionStorage')
        } else {
          if (event.key === 'sessionStorage' && !sessionStorage.length) {
            const data = JSON.parse(event.newValue).value
            for (const key in data) {
              sessionStorage.setItem(key, data[key])
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
.custom-popover {
  min-width: 125px !important;
}
</style>
