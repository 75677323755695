import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/warehouse/kiki/private/wasteWarehouse/'

export default {
  // 获取供应商-不分页
  getWastList(data) {
    return request.get(baseUrl + 'getWasteWarehouseList', data)
  },
  // 获取供应商-分页
  getWastListPage(data) {
    return request.get(baseUrl + 'getWasteWarehouseListPage', data)
  },
  // 新增供应商
  addWast(data) {
    return request.post(baseUrl + 'addWasteWarehouse', data)
  },
  copyWast(data) {
    return request.post(baseUrl + 'addWasteWarehouse', data)
  },
  // 修改供应商
  modifyWast(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除供应商
  delWast(data) {
    return request.del(baseUrl + 'deleteOne', data)
  }
}
