import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/private/numberSet/'

export default {
  // 获取编号设置-不分页
  getNumberSetList(data) {
    return request.get(baseUrl + 'getNumberSetList', data)
  },
  // 获取编号设置-分页
  getNumberSetListPage(data) {
    return request.get(baseUrl + 'getNumberSetListPage', data)
  },
  // 修改编号设置
  modifyNumberSet(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除编号设置
  delNumberSet(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  getNumberSetByDetial(id) {
    return request.get(baseUrl + 'getNumberSetDetail', { id })
  },
  // 获取修改自定义设置-编号
  getFieldSetList() {
    return request.get('api/web/v1/field/private/fieldSet/getFieldSetNumberSetList')
  }
}
