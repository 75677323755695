import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/equipment/check/private/cycleCheckPlan/'
const settingUrl = 'api/web/v1/equipment/setting/private/'

export default {
  addCheckItemAndBindEquipmentClass(data) {
    return request.post(settingUrl + 'checkItemEquipmentClassMapping/addEquipmentClassCheckItem', data)
  },
  // 获取点巡检项-不分页
  getCheckItemEquipmentClassListPage(data) {
    return request.get(settingUrl + 'checkItemEquipmentClassMapping/getCheckItemEquipmentClassMappingListPage', data)
  },
  // 获取设备类列表
  getEquipmentClassList() {
    return request.get(`/api/web/v1/basicData/private/equipmentClass/getEquipmentClassList`)
  },
  // 获取巡检计划-不分页
  getInspectionPlanList(data) {
    return request.get(baseUrl + 'getCycleCheckPlanList', data)
  },
  // 获取巡检计划-分页
  getInspectionPlanListPage(data) {
    return request.get(baseUrl + 'getCycleCheckPlanListPage', data)
  },
  // 新增巡检计划
  addInspectionPlan(data) {
    return request.post(baseUrl + 'addCycleCheckPlan', data)
  },
  // 新增巡检计划
  copyInspectionPlan(data) {
    return request.post(baseUrl + 'addCycleCheckPlan', data)
  },
  // 修改巡检计划
  modifyInspectionPlan(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除巡检计划
  delInspectionPlan(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  getInspectionPlanDetail(data) {
    return request.get(baseUrl + 'getCycleCheckPlanDetail', data)
  },
  // 获取巡检类型
  getCheckTypeList() {
    return request.get(baseUrl + 'getCheckTypeList')
  },
  // 获取单号
  getPlanCode() {
    return request.get(baseUrl + 'getMaxCycleCheckNumber')
  }
}
