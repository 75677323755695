import request from '@/utils/request'

export default {
  getAllFlightList(params) {
    return request.get('/api/web/v1/authority/private/frequency/getFrequencyList', params)
  },
  // 获取班次列表（不分页）
  getFlightList(params) {
    return request.get('/api/web/v1/authority/private/frequency/getFrequencyListPage', params)
  },
  // 新建班次
  createFlight(postData) {
    return request.post('/api/web/v1/authority/private/frequency/addFrequency', postData)
  },
  // 删除班次
  deleteFlight(id) {
    return request.del(`/api/web/v1/authority/private/frequency/deleteOne?id=${id}`)
  },
  // 修改班次
  modifyFlight(data) {
    return request.put('/api/web/v1/authority/private/frequency/updateOne', data)
  },
  // 导出班次列表
  exportFlightList() {
    return request.get('/api/web/v1/authority/private/frequency/export', {})
  },
  // 启用班次
  enableFlight(id) {
    return request.get(`/api/web/v1/authority/private/frequency/enable?id=${id}`)
  },
  // 禁用班次
  disableFlight(id) {
    return request.get(`/api/web/v1/authority/private/frequency/disable?id=${id}`)
  }
}
