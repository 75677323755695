var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MDialog",
    {
      attrs: {
        value: _vm.show,
        title: _vm.title,
        "show-footer": false,
        "append-to-body": _vm.appends
      },
      on: {
        close: function($event) {
          _vm.visible = false
        }
      }
    },
    [
      _vm._t("default"),
      _c("MTable", {
        ref: "mTable",
        attrs: {
          columns: _vm.columns,
          height: _vm.height,
          data: _vm.tableData,
          "show-page": false,
          "columns-setting": false
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var row = ref.row
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "isEnabled",
            fn: function(ref) {
              var row = ref.row
              return _c("div", { staticClass: "is-enabled" }, [
                _c("div", {
                  style: { background: row.isEnabled ? "#24CAD7" : "#FFCC78" }
                }),
                _c("div", [_vm._v(_vm._s(row.isEnabled ? "启用" : "停用"))])
              ])
            }
          },
          {
            key: "needUpload",
            fn: function(ref) {
              var row = ref.row
              return _c("div", { staticClass: "is-enabled" }, [
                _c("div", {
                  style: { background: row.needUpload ? "#24CAD7" : "#FFCC78" }
                }),
                _c("div", [_vm._v(_vm._s(row.needUpload ? "是" : "否"))])
              ])
            }
          },
          {
            key: "sketchMap",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                _vm._l(_vm.formatArr(row.sketchMap), function(item) {
                  return _c("el-image", {
                    key: item.id,
                    staticClass: "table-row-img",
                    staticStyle: { "padding-left": "10px" },
                    attrs: { src: item.url, "preview-src-list": [item.url] }
                  })
                }),
                1
              )
            }
          },
          {
            key: "sparePartDrawing",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  row.sparePartDrawing
                    ? _c("el-image", {
                        staticClass: "table-row-img",
                        staticStyle: { "padding-left": "10px" },
                        attrs: {
                          src: row.sparePartDrawing,
                          "preview-src-list": [row.sparePartDrawing]
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }
          },
          {
            key: "maintenanceResult",
            fn: function(ref) {
              var row = ref.row
              return _c("div", { staticClass: "is-enabled" }, [
                _c("div", {
                  style: {
                    background: row.result === 1 ? "#24CAD7" : "#FFCC78"
                  }
                }),
                _c("div", [
                  _vm._v(_vm._s(row.result === 1 ? "已保养" : "未保养"))
                ])
              ])
            }
          },
          {
            key: "equipmentNames",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [_vm._v(_vm._s(row.equipment.name))])
            }
          },
          {
            key: "parameterType",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm._v(_vm._s(_vm._f("parameterTypeName")(row.parameterType)))
              ])
            }
          },
          {
            key: "limitType",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm._v(_vm._s(_vm._f("limitTypeName")(row.limitType)))
              ])
            }
          },
          {
            key: "procedureType",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm._v(_vm._s(_vm._f("procedureType")(row.type)))
              ])
            }
          },
          {
            key: "spareStatus",
            fn: function(ref) {
              var row = ref.row
              return _c("div", { style: { color: _vm.colors[row.status] } }, [
                _vm._v(_vm._s(_vm._f("spareStatusName")(row.status)))
              ])
            }
          },
          {
            key: "action",
            fn: function(ref) {
              var row = ref.row
              var $index = ref.$index
              return _c(
                "div",
                {},
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.scans(row)
                        }
                      }
                    },
                    [_vm._v("查看上传工序参数")]
                  ),
                  row.updateReportFlag === 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.cancelBao(row, $index)
                            }
                          }
                        },
                        [_vm._v("撤回")]
                      )
                    : _vm._e()
                ],
                1
              )
            }
          },
          {
            key: "moldInstances",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  row.moldInstances
                    ? _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: row.moldInstances,
                            placement: "top"
                          }
                        },
                        [
                          _c("el-button", { attrs: { type: "text" } }, [
                            _vm._v(
                              _vm._s(
                                row.moldInstances &&
                                  row.moldInstances.split("、")[0] + "..."
                              )
                            )
                          ])
                        ],
                        1
                      )
                    : _c("div", [_vm._v("-")])
                ],
                1
              )
            }
          },
          {
            key: "actions",
            fn: function(ref) {
              var row = ref.row
              var $index = ref.$index
              return _c(
                "div",
                {},
                [
                  row.cancelFlag === 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.cancelArrivals(row, $index)
                            }
                          }
                        },
                        [_vm._v("撤回")]
                      )
                    : _vm._e()
                ],
                1
              )
            }
          }
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }