<template>
  <div>
    <el-button
      v-if="!readOnly"
      style="margin-bottom: 10px;"
      size="small"
      type="primary"
      @click="handleStep"
    >添加工步</el-button>
    <MTable
      ref="planTable"
      :can-row-sortable="!readOnly"
      :show-page="false"
      :height="300"
      :columns="columns"
      :data="baseList"
      only-key="name"
      @onSorted="onSorted"
    >
      <div slot="index" slot-scope="{$index }">{{ $index+1 }}</div>
      <div slot="description" slot-scope="{ row }">
        <el-tooltip :content="extractBeforeHttps(row.description)" placement="top">
          <div>
                <div>{{ extractBeforeHttps(row.description)  }}</div>
            <img v-if="extractAfterHttps(row.description)" style="width: 40px; height: 40px;" :src="extractAfterHttps(row.description)" alt="" srcset="">
              </div>
        </el-tooltip>
      </div>
      <div slot="remark" slot-scope="{ row }">
        <el-tooltip :content="extractBeforeHttps(row.remark)" placement="top">
          <div>
                <div>{{ extractBeforeHttps(row.remark)  }}</div>
            <img v-if="extractAfterHttps(row.remark)" style="width: 40px; height: 40px;" :src="extractAfterHttps(row.remark)" alt="" srcset="">
              </div>
        </el-tooltip>
      </div>
      <el-tooltip slot="params" slot-scope="{ row }" :content="getParams(row.params )" placement="top">
        <div>{{ getParams(row.params ) }}</div>
      </el-tooltip>
      <div slot="action" slot-scope="{ $index,row }">
        <el-button type="text" :disabled="readOnly" @click="baseList.splice($index,1)">删除
        </el-button>
        <el-button type="text" :disabled="readOnly" @click="onEdit(row,$index)">编辑
        </el-button>
      </div>
    </MTable>

    <MDialog v-model="visible" width="80%" title="新增工步" @onOk="onSubmit" @onCancel="cancel()" :customCancel="true">
      <HandleWorkStep ref="HandleWorkStep" :base-data="stepInfo" :edit-idx="editIdx" />
    </MDialog>
  </div>
</template>

<script>
import HandleWorkStep from './HandleWorkStep.vue'
export default {
  name: 'Index',
  components: { HandleWorkStep },
  props: {
    baseList: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const columns =
    [
          {
            prop: 'index',
            slotName: 'index',
            label: '序号',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'name',
            label: '操作步骤',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'description',
            label: '操作内容',
            slotName: 'description',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'remark',
            slotName: 'remark',
            label: '检验标准',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'params',
            slotName: 'params',
            label: '工步参数',
            sortable: false,
            hiddenSearch: true
          },
          {
            slotName: 'action',
            label: '操作',
            hiddenSearch: true,
            sortable: false
          }
    ]
    return {
      stepInfo: {},
      columns: Object.freeze(columns),
      visible: false,
      editIdx: -1
    }
  },
  mounted() {
  },
  methods: {
    extractBeforeHttps(url) {
      if(!url){
        return url
      }
    var httpsIndex = url.indexOf('https');
    if (httpsIndex !== -1) {
        return url.substring(0, httpsIndex);
    } else {
        return url; // 如果没有找到 https，则返回 null 或者可以根据情况返回原始字符串
    }
},
     extractAfterHttps(url) {
      if(!url){
        return url
      }
    var httpsIndex = url.indexOf('https');
    if (httpsIndex !== -1) {
        return url.substring(httpsIndex);
    } else {
        return ''; // 如果没有找到 https，则返回 null 或者可以根据情况返回原始字符串
    }
},
    getParams(params) {
      return Array.isArray(params) ? params.map(item => item.name).join(',') : ''
    },
    cancel(){
          const { params } = this.stepInfo
          const newParams = params ? params.map((item, idx) => {
            const { candidateOptions, defaultValues, name, repeats, timeFormat, timeSys, type,requiredWrite } = item
            return ({
              candidateValues: Array.isArray(candidateOptions) ? candidateOptions.map(item => item.val).join(',') : candidateOptions,
              defaultValues: Array.isArray(defaultValues) ? defaultValues.join(',') : defaultValues,
              name, repeats, timeFormat, timeSys, type,requiredWrite
            })
          }) : ''
          this.stepInfo.params = newParams
          if (this.editIdx !== -1) {
            this.$set(this.baseList, this.editIdx, this.stepInfo)
          } else {
            this.baseList.push(this.stepInfo)
          }
          this.editIdx = -1
      this.visible = false
    },
    onSubmit(callback) {
      console.log(callback)
      console.log('stepInfo:',this.stepInfo)
      callback()
      this.$refs.HandleWorkStep.$refs.ruleForm1.validate((valid) => {
        if (valid) {
          const { params } = this.stepInfo
          const newParams = params ? params.map((item, idx) => {
            const { candidateOptions, defaultValues, name, repeats, timeFormat, timeSys, type,requiredWrite } = item
            return ({
              candidateValues: Array.isArray(candidateOptions) ? candidateOptions.map(item => item.val).join(',') : candidateOptions,
              defaultValues: Array.isArray(defaultValues) ? defaultValues.join(',') : defaultValues,
              name, repeats, timeFormat, timeSys, type,requiredWrite
            })
          }) : ''
          this.stepInfo.params = newParams
          if (this.editIdx !== -1) {
            this.$set(this.baseList, this.editIdx, this.stepInfo)
          } else {
            this.baseList.push(this.stepInfo)
          }
          this.editIdx = -1
          this.visible = false
        }
      })
    },
    onEdit(row, idx) {
      const { name, description, remark } = row
      let { params } = row
      if (params == null) {
        params = []
      }
      this.stepInfo = {
        name,
        description,
        remark,
        params
      }
      this.editIdx = idx
      this.visible = true
    },
    handleStep(row) {
      this.stepInfo = {
        name: '',
        description: '',
        remark: '',
        params: []
      }
      this.visible = true
    },
    onSorted(val, i, j) {
      // console.log(this.baseList)
    }
  }
}

</script>
<style lang='scss' scoped>
</style>
