var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gantt-leftbar" },
    [
      _c("div", {
        staticClass: "gantt-leftbar-item gantt-block-top-space",
        style: { height: _vm.topSpace + "px" }
      }),
      _c("el-tree", {
        ref: "tree",
        attrs: {
          data: _vm.showDatas,
          props: _vm.defaultProps,
          "node-key": "id",
          "empty-text": "",
          "default-expanded-keys": _vm.expandedKeys
        },
        on: {
          "node-collapse": _vm.nodeCollapse,
          "node-expand": _vm.nodeExpand
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var node = ref.node
              var data = ref.data
              return _c("div", { staticClass: "custom-tree-node" }, [
                _c("div", [_vm._v(_vm._s(node.label))]),
                _c("div", [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      data.isTaskNode && !data.status && !data.type
                        ? _c("div", { staticStyle: { color: "#FA6400" } }, [
                            _vm._v("未下达")
                          ])
                        : _c("div", [
                            _vm._v(_vm._s(data.processPercent) + "%")
                          ]),
                      _c(
                        "div",
                        {
                          staticStyle: { position: "absolute", right: "10px" }
                        },
                        [
                          _c("el-tooltip", { attrs: { placement: "bottom" } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "0 10px",
                                  cursor: "pointer"
                                },
                                attrs: { slot: "content" },
                                slot: "content"
                              },
                              [
                                (data.isTaskNode && data.status) || data.type
                                  ? _c(
                                      "div",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.viewNodeDetail(data)
                                          }
                                        }
                                      },
                                      [_vm._v("详情")]
                                    )
                                  : _vm._e(),
                                data.status != 3 && !data.type
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "padding-top": "10px" },
                                        on: {
                                          click: function($event) {
                                            return _vm.adjustNode(data)
                                          }
                                        }
                                      },
                                      [_vm._v("调整")]
                                    )
                                  : _vm._e(),
                                data.isTaskNode && !data.status && !data.type
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "padding-top": "10px" },
                                        on: {
                                          click: function($event) {
                                            return _vm.releaseNode(data)
                                          }
                                        }
                                      },
                                      [_vm._v(" 下达 ")]
                                    )
                                  : _vm._e(),
                                (data.status === 1 || data.status === 2) &&
                                !data.type
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "padding-top": "10px" },
                                        on: {
                                          click: function($event) {
                                            return _vm.onUrge(data)
                                          }
                                        }
                                      },
                                      [_vm._v(" 催办 ")]
                                    )
                                  : _vm._e(),
                                data.status && !data.type
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "padding-top": "10px" },
                                        on: {
                                          click: function($event) {
                                            return _vm.viewLog(data)
                                          }
                                        }
                                      },
                                      [_vm._v(" 日志 ")]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            data.type != 2
                              ? _c("img", {
                                  staticClass: "setting",
                                  attrs: {
                                    src: require("@/assets/table_filter_images/setting.png"),
                                    alt: "设置"
                                  }
                                })
                              : _vm._e()
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ])
              ])
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }