<template>
  <MDialog v-model="modalShow" title="修改" @onOk="submitForm">
    <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList">
      <div slot="processFlowClassName">
        <CURDSelect :default-value="formData.planTypeId" api-name="PlanType" @change="selectName" />
      </div>
      <div slot="startTime">
        <el-date-picker
          v-show="showStartTime"
          v-model="planStartTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          style="width: 100%"
          placeholder="开始时间"
          :disabled="isChooseStartAttr"
          @change="chooseStartTime"
        />
        <div style="display: flex" v-show="!showStartTime">
          <el-select
            v-model="startPlanId"
            style="width: 100%"
            placeholder="请选择"
            @change="changeStartTimePlan"
            clearable
            filterable
          >
            <el-option
              v-for="item in planNodeTimeList"
              :key="item.id"
              :label="item.fullName"
              :value="item.id"
            />
          </el-select>
          <el-input-number
            style="margin-left: 10px"
            v-model="startTimeBasis"
            controls-position="right"
            class="min-input"
          />天
        </div>
      </div>
      <div slot="finishTime">
        <el-date-picker
          v-show="showFinishTime"
          v-model="planFinishTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          style="width: 100%"
          defaultTime="23:59:59"
          placeholder="完成时间"
          :disabled="isChooseFinishAttr"
          @change="chooseFinishTime"
        />
        <div style="display: flex" v-show="!showFinishTime">
          <el-select
            v-model="finishPlanId"
            style="width: 100%"
            placeholder="请选择"
            @change="changeFinishTimePlan"
            clearable
            filterable
          >
            <el-option
              v-for="item in planNodeTimeList"
              :key="item.id"
              :label="item.fullName"
              :value="item.id"
            />
          </el-select>
          <el-input-number
            style="margin-left: 10px"
            v-model="finishTimeBasis"
            controls-position="right"
            class="min-input"
          />天
        </div>
      </div>
    </MFormBuilder>
  </MDialog>
</template>
  
  <script>
import papi from "@/api/projectManagement/project";
import {
  formList,
  getFunctionList,
} from "@/views/project/MRP/projectManagement/task-nodes/form-list";
import CURDSelect from "@/components/CURDSelect/CURDSelect";
export default {
  name: "TaskNodeDialog",
  components: { CURDSelect },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    formModifyData: {
      type: Object,
      default: () => ({}),
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    nodeIndex: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      modalShow: false,
      formOptions: {
        materialsClassList: [],
      },
      selectionData: [],
      fullScreen: false,

      formList,
      formData: {
        planTypeId: "",
      },
      formOptions: {},
      showFinishTime: true,
      showStartTime: true,
      planStartTime: "",
      planFinishTime: "",
      planNodeTimeList: [],
      startTimeRelationPlanId: "",
      finishTimeRelationPlanId: "",
      startTimeBasis: 0,
      finishTimeBasis: 0,
      startPlanId: "",
      finishPlanId: "",
      startTimeRelationTimeType: "",
      finishTimeRelationTimeType: "",
      planStartDateTime: "",
      planFinishDateTime: "",
      isChooseFinishAttr: true,
      isChooseStartAttr: true,
      planNodeId: "",
      startTimeRelationPlanName: "",
      finishTimeRelationPlanName: "",
      principalLst: [],
    };
  },
  computed: {
    height() {
      return this.fullScreen
        ? this.$store.state.app.contentHeight - 160 - 83 - 57
        : this.$store.state.app.contentHeight - 289 - 160 - 10;
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionData.filter((item) => !item.selectable);
      return selectionDataNoDisabled.length;
    },
  },
  watch: {
    visible(val) {
      this.modalShow = val;
      if (val) {
        this.getSelfNodeTimeTypeList();
        this.echoData(this.formModifyData);
      }
    },
    modalShow(val) {
      if (!val) {
        this.$emit("update:visible", false);
      }
    },
  },
  activated() {
    getFunctionList({
      startTimeAttributeChange: this.startTimeAttributeChange,
      finishTimeAttributeChange: this.finishTimeAttributeChange,
    });
  },
  beforeMount() {
    getFunctionList({
      startTimeAttributeChange: this.startTimeAttributeChange,
      finishTimeAttributeChange: this.finishTimeAttributeChange,
    });
  },
  mounted() {
    this.getBusinessUnitList();
    this.getUserList();
    this.modalShow = this.visible;
    if (this.modalShow) {
      this.getSelfNodeTimeTypeList();
      this.echoData(this.formModifyData);
    }
  },
  methods: {
    async getBusinessUnitList() {
      const res = await papi.getBusinessUnitList();
      if (res) {
        this.formList[2].children[0].options = res;
      }
    },
    // 获取负责人
    async getUserList() {
      const res = await papi.getResponsible();
      if (res) {
        this.formList[3].children[0].options = res;
        this.principalLst = res;
      }
    },
    //   async getPlanNodeTimeList(){
    //   const res = await api.getPlanNodeTimeList()
    //   if (res) {
    //     this.planNodeTimeList = res
    //   }
    // },
    async getSelfNodeTimeTypeList() {
      let arr = this._.cloneDeep(this.tableData);
      arr = this.flattenArray(arr);
      // 先平铺
      arr.forEach((item, index) => {
        item.planId = index;
        item.timeType = 0;
      });
      let arr2 = this._.cloneDeep(arr);
      arr2.forEach((item) => {
        item.timeType = 1;
      });
      arr.push(...arr2);
      let data = arr.map((item, index) => {
        let fullName = item.planName + "-";
        if (item.timeType === 0) {
          fullName += "开始时间";
        } else {
          fullName += "完成时间";
        }
        return {
          id: item.planId + "-" + item.timeType,
          planId: item.planId,
          timeType: item.timeType,
          fullName,
          planName: item.planName,
          startTimeRelationPlanId: item.startTimeRelationPlanId,
          finishTimeRelationPlanId: item.finishTimeRelationPlanId,
          // dateTime: item.timeType === 0 ? item.oldStartTime : item.oldFinishTime,
          dateTime: item.timeType === 0 ? item.startTime : item.finishTime,
        };
      });
      const groupedById = data.reduce((acc, obj) => {
        const key = obj.planId;
        acc[key] = [...(acc[key] || []), obj];
        return acc;
      }, {});

      const sortedKeys = Object.keys(groupedById).sort((a, b) => a - b);
      const arr3 = sortedKeys.reduce((acc, key) => acc.concat(groupedById[key]), []);
      this.planNodeTimeList = arr3.filter(
        (item) =>
          item.planId != this.nodeIndex &&
          item.startTimeRelationPlanId !== this.nodeIndex &&
          item.finishTimeRelationPlanId !== this.nodeIndex
      );
    },
    flattenArray(arr) {
      let result = [];
      function flatten(arr) {
        arr.forEach((item) => {
          result.push(item);
          if (item.children && item.children.length > 0) {
            flatten(item.children);
          }
        });
      }
      flatten(arr);
      return result;
    },
    startTimeAttributeChange(val) {
      if (val || val === 0) {
        this.isChooseStartAttr = false;
        this.showStartTime = val === 2 ? false : true;
      } else {
        this.isChooseStartAttr = true;
        this.showStartTime = true;
      }
      this.$refs.formBuild.setForm({
        startTime: "",
      });
      this.planStartDateTime = '' 
      this.planStartTime = ''
    },
    finishTimeAttributeChange(val) {
      if (val || val === 0) {
        this.isChooseFinishAttr = false;
        this.showFinishTime = val === 2 ? false : true;
      } else {
        this.isChooseFinishAttr = true;
        this.showFinishTime = true;
      }
      this.$refs.formBuild.setForm({
        finishTime: "",
      });
      this.planFinishDateTime = ''
      this.planFinishTime = ''
    },
    selectName(item) {
      this.$refs.formBuild.setForm({
        planTypeId: item.id,
        planTypeName: item.name,
      });
    },
    async chooseStartTime() {
      await this.$refs.formBuild.setForm({
        startTime: this.planStartTime,
      })
      this.$refs.formBuild.formRefs().validateField('startTime')
    },
    async chooseFinishTime() {
      await this.$refs.formBuild.setForm({
        finishTime: this.planFinishTime
      })
      this.$refs.formBuild.formRefs().validateField('finishTime')
    },
    changeStartTimePlan(val) {
      const currentRow = this.planNodeTimeList.find((item) => item.id === val);
      if (currentRow) {
        this.startTimeRelationPlanId = currentRow.planId;
        this.startTimeRelationTimeType = currentRow.timeType;
        this.planStartDateTime = currentRow.dateTime;
        this.startTimeRelationPlanName = currentRow.planName;
      } else {
        this.startTimeRelationPlanId = "";
        this.startTimeRelationTimeType = "";
        this.planStartDateTime = "";
        this.startTimeRelationPlanName = "";
      }
      this.$refs.formBuild.setForm({
        startTime: this.planStartDateTime ? this.planStartDateTime : this.startTimeRelationPlanId,
      });
    },
    changeFinishTimePlan(val) {
      const currentRow = this.planNodeTimeList.find((item) => item.id === val);
      if (currentRow) {
        this.finishTimeRelationPlanId = currentRow.planId;
        this.finishTimeRelationTimeType = currentRow.timeType;
        this.planFinishDateTime = currentRow.dateTime;
        this.finishTimeRelationPlanName = currentRow.planName;
      } else {
        this.finishTimeRelationPlanId = "";
        this.finishTimeRelationTimeType = "";
        this.planFinishDateTime = "";
        this.finishTimeRelationPlanName = "";
      }
      this.$refs.formBuild.setForm({
        finishTime: this.planFinishDateTime
          ? this.planFinishDateTime
          : this.finishTimeRelationPlanId,
      });
    },

    resetData() {
      this.selectionData.forEach((item) => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false);
        }
      });
      this.selectionData = this.selectionData.filter((item) => item.selectable);
    },

    echoData(row) {
      this.formData = this._.cloneDeep(row);
      this.formData.principalId = this.formData.principalId
        ? this.formData.principalId.split(",").map((item) => +item)
        : this.formData.principalId;
      this.formData.principalDepartId = this.formData.principalDepartId
        ? this.formData.principalDepartId.split(",").map((item) => +item)
        : this.formData.principalDepartId;
      this.planNodeId = row.id;
      this.planStartTime = row.startTime;
      this.planFinishTime = row.finishTime;
      this.startTimeBasis = row.startTimeBasis;
      this.finishTimeBasis = row.finishTimeBasis;
      this.startPlanId =
        row.startTimeRelationPlanId || row.startTimeRelationPlanId === 0
          ? row.startTimeRelationPlanId + "-" + row.startTimeRelationTimeType
          : "";
      this.finishPlanId =
        row.finishTimeRelationPlanId || row.finishTimeRelationPlanId === 0
          ? row.finishTimeRelationPlanId + "-" + row.finishTimeRelationTimeType
          : "";
      this.planStartDateTime = this.planNodeTimeList.find((item) => item.id === this.startPlanId)
        ? this.planNodeTimeList.find((item) => item.id === this.startPlanId).dateTime
        : "";
      this.startTimeRelationPlanId = row.startTimeRelationPlanId;
      this.startTimeRelationTimeType = row.startTimeRelationTimeType;
      this.finishTimeRelationPlanId = row.finishTimeRelationPlanId;
      this.finishTimeRelationTimeType = row.finishTimeRelationTimeType;
      this.planFinishDateTime = this.planNodeTimeList.find((item) => item.id === this.finishPlanId)
        ? this.planNodeTimeList.find((item) => item.id === this.finishPlanId).dateTime
        : "";
      this.showStartTime = this.formData.startTimeAttribute === 2 ? false : true;
      this.showFinishTime = this.formData.finishTimeAttribute === 2 ? false : true;
      this.isChooseFinishAttr =
        this.formData.finishTimeAttribute || this.formData.finishTimeAttribute === 0 ? false : true;
      this.isChooseStartAttr =
        this.formData.startTimeAttribute || this.formData.startTimeAttribute === 0 ? false : true;
      this.startTimeRelationPlanName = this.planNodeTimeList.find(
        (item) => item.id === this.startPlanId
      )
        ? this.planNodeTimeList.find((item) => item.id === this.startPlanId).planName
        : "";
      this.finishTimeRelationPlanName = this.planNodeTimeList.find(
        (item) => item.id === this.finishPlanId
      )
        ? this.planNodeTimeList.find((item) => item.id === this.finishPlanId).planName
        : "";

      this.formData.startTime = row.startTime
        ? row.startTime
        : this.planStartDateTime
        ? this.planStartDateTime
        : this.startTimeRelationPlanId;
      this.formData.finishTime = row.finishTime
        ? row.finishTime
        : this.planFinishDateTime
        ? this.planFinishDateTime
        : this.finishTimeRelationPlanId;
      // this.finishPlanId = row.finishTimeRelationPlanId? row.finishTimeRelationPlanId+'-'+row.finishTimeRelationTimeType:'')
    },
    createSetData(raw) {
      return {
        ...raw,
        planName: raw.planName,
        planTypeId: raw.planTypeId,
        startTimeAttribute: raw.startTimeAttribute,
        finishTimeAttribute: raw.finishTimeAttribute,
        startTime: raw.startTimeAttribute === 2 ? this.planStartDateTime : this.planStartTime,
        finishTime: raw.finishTimeAttribute === 2 ? this.planFinishDateTime : this.planFinishTime,
        principalId: raw.principalId ? raw.principalId.join(",") : "",
        principalName: raw.principalId ? this.getName(raw.principalId) : "",
        remark: raw.remark,
        startTimeRelationPlanId: raw.startTimeAttribute === 2 ? this.startTimeRelationPlanId : "",
        startTimeRelationTimeType:
          raw.startTimeAttribute === 2 ? this.startTimeRelationTimeType : "",
        startTimeBasis: raw.startTimeAttribute === 2 ? this.startTimeBasis : "",
        finishTimeRelationPlanId:
          raw.finishTimeAttribute === 2 ? this.finishTimeRelationPlanId : "",
        finishTimeRelationTimeType:
          raw.finishTimeAttribute === 2 ? this.finishTimeRelationTimeType : "",
        finishTimeBasis: raw.finishTimeAttribute === 2 ? this.finishTimeBasis : "",
        principalDepartId: raw.principalDepartId ? raw.principalDepartId.join(",") : "",
        principalDepartName: raw.principalDepartId ? this.getDeName(raw.principalDepartId) : "",
        startTimeRelationPlanName:
          raw.startTimeAttribute === 2 ? this.startTimeRelationPlanName : "",
        finishTimeRelationPlanName:
          raw.finishTimeAttribute === 2 ? this.finishTimeRelationPlanName : "",
      };
    },
    getName(raw) {
      let resultArray = [];
      this.principalLst.map((item) => {
        if (raw.includes(item.id)) {
          resultArray.push(item.userFullName);
        }
      });
      return resultArray.join(",");
    },
    getDeName(raw) {
      let resultArray = [];
      this.formList[2].children[0].options.map((item) => {
        if (raw.includes(item.id)) {
          resultArray.push(item.name);
        }
      });
      return resultArray.join(",");
    },
    createResetData(raw) {
      const obj = {
        id: raw.id,
      };
      return Object.assign(this.createSetData(raw), obj);
    },

    submitForm(callback) {
      const { form } = this.$refs.formBuild;
      this.$refs.formBuild.formRefs().validate(async (valid) => {
        if (valid) {
          callback(true);
          this.$emit("submitForm", this.createResetData(form));
        } else {
          callback();
        }
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.materials-info {
  padding: 10px;
}
.materials-head {
  padding: 0 0 10px 0;
}
.module-content > div {
  height: 33px;
}
</style>
  