<template>
  <div class="function-header-wrap">
    <div class="function-left">
      <slot />
      <GeneralTag :tags="tags" />
      <slot name="center" />
      <GeneralTag :tags="exportAndImportTags" />
      <slot name="all" />
    </div>
    <div
      v-if="!hiddenSearch"
      class="input-wrapper"
      :style="{ right: columnsSetting ? '84px' : 0 }"
    >
      <div
        v-if="!hiddenTitleSearch"
        style="display: flex; justify-content: space-between; width: 290px"
      >
        <el-input
          v-model="searchVal"
          :placeholder="searchTitle"
          :title="searchTitle"
          clearable
          @change="inputChange"
          @keyup.enter.native="search()"
        />
        <el-button
          icon="el-icon-search"
          type="primary"
          style="background: #607fff"
          size="small"
          @click="search()"
        >
          搜索
        </el-button>
      </div>
      <div v-if="hiddenMulSearch" class="search-all" @click="selectSearch">
        <span>筛选</span>
        <div
          class="all-ri"
          :style="{
            background: !isShowBkg ? '#607FFF' : '',
            borderRadius: !isShowBkg ? '0' : '0 4px 4px 0'
          }"
        >
          <el-badge
            :value="searchIndexs[tabRadio]"
            class="item"
            :hidden="searchIndexs[tabRadio] === 0 || !isShowBkg"
          >
            <img
              v-if="isShowBkg"
              src="@/assets/table_filter_images/search-1.png"
              alt=""
              class="img"
            >
            <img
              v-if="!isShowBkg"
              src="@/assets/table_filter_images/search-2.png"
              alt=""
              class="img"
            >
          </el-badge>
        </div>
      </div>
    </div>
    <!-- <div v-if="!hiddenSearch" class="input-wrapper" :style="{right: columnsSetting ? '33px' : 0}">
      <el-input v-model="searchVal" :placeholder="searchTitle" :title="searchTitle" clearable @change="inputChange" @keyup.enter.native="search()" />
      <el-button
        icon="el-icon-search"
        type="primary"
        style="background: #607FFF"
        size="small"
        @click="search()"
      >
        搜索
      </el-button>
    </div> -->
    <slot name="right" />
    <BatchImport
      :visible.sync="showImport"
      :import-name="importName"
      :processId="processId"
      :processVersionId="processVersionId"
      :cover-name="['quality'].includes(importName)?'是否覆盖':'重复数据是否覆盖'"
      :show-cover="
        ['materials', 'supplier', 'customer', 'procedure','quality'].includes(importName)
      "
    />
  </div>
</template>
<script>
import tagRender from '@/components/MCustom/FormBuilder/generalTagRender'
import exportApi from '@/api/exportAndImport/export'
import dayjs from 'dayjs'
import BatchImport from '@/components/BatchImport/index'

export default {
  name: 'FunctionHeader',
  components: {
    BatchImport,
    GeneralTag: {
      render: tagRender,
      functional: true
    }
  },
  props: {
    tags: {
      type: Array,
      require: true,
      default: () => []
    },
    importName: {
      type: String,
      default: ''
    },
    processId: {
      type: Number,
      default: undefined
    },
    processVersionId:{
      type: Number,
      default: undefined
    },
    searchTitle: {
      type: String,
      default: ''
    },
    exportName: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    hiddenSearch: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array
    },
    columnsSetting: {
      type: Boolean,
      default: true
    },
    exportParams: {
      type: Object,
      default: () => ({})
    },
    radio: {
      type: String,
      default: ''
    },
    columnsKey: {
      type: [String, Boolean],
      default: false
    },
    hiddenTitleSearch: {
      type: Boolean,
      default: false
    },
    hiddenMulSearch: {
      type: Boolean,
      default: false
    },
    tabRadio: {
      type: String,
      default: ''
    },
    exportAll: {
      type: Boolean,
      default: false
    },
    showExport:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isShowBkg: true,
      searchVal: '',
      form: {},
      exportAndImportTags: [
        {
          hidden: !this.permission(this.radio + 'Import') || !this.importName,
          tagName: 'el-button',
          innerText: '导入',
          props: { type: 'primary', size: 'small' },
          style: { width: 'auto', background: '#607FFF', margin: '0 0 0 10px' },
          on: {
            click: this.importExcel
          }
        },
        {
          hidden: (!this.permission(this.radio + 'Export') || (!this.exportName )) || !this.showExport,
          tagName: 'el-button',
          props: { type: 'primary', size: 'small', loading: false },
          style: { width: 'auto', background: '#607FFF', margin: '0 10px 0 10px' },
          innerText: this.exportName === 'exportBomLevelDownMList' ||  this.exportName === 'exportMBomLevelDownMList'  ? '导出' : '全部导出',
          on: {
            click: () => {
              this.export()
            }
          }
        }
      ],
      showImport: false
    }
  },
  computed: {
    searchIndexs() {
      return this.$store.state.user.searchIndexs
    }
  },
  watch: {
    importName() {
      this.exportAndImportTags[0].hidden =
        !this.permission(this.radio + 'Import') || !this.importName
    },
    exportName() {
      this.exportAndImportTags[1].hidden = (!this.permission(this.radio + 'Export') || (!this.exportName )) || !this.showExport
    },
    showExport() {
      this.exportAndImportTags[1].hidden = (!this.permission(this.radio + 'Export') || (!this.exportName )) || !this.showExport
    },
  },
  methods: {
    selectSearch() {
      this.isShowBkg = !this.isShowBkg
      this.$emit('checkSearch', this.isShowBkg)
    },
    fileUploadChange(file) {
      if (file.errorCode === 0) {
        this.$message.success('导入成功')
        this.$emit('search', '')
      } else {
        this.$message.error(file.result)
      }
    },
    async export(initParams) {
      let fields
      if (this.columns && this.columns.length) {
        const propsFields = this.columns.filter(item => item.prop)
        fields = propsFields.map(item => item.prop)
      } else {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        const data = (userInfo.fields && JSON.parse(userInfo.fields)) || {}
        const allFields = (data[this.columnsKey || this.$route.name] || [] ).filter(
          item => item.checked
        )
        fields = allFields.map(item => item.key)
      }
      if (
        this.$route.name === 'orderList' ||
        (!this.columnsKey && this.$route.name === 'workList') ||
        this.columnsKey === 'planListNoSchedule' ||
        this.columnsKey === 'planListHaveSchedule'
      ) {
        if (fields.includes('priority')) {
          fields.splice(
            fields.findIndex(item => item === 'priority'),
            1,
            'priorityStr'
          )
        }
      }
      // 工序增加字段
      if (this.$route.name === 'procedureList') {
        fields.push('stationCodes')
      }
      const exportFields = fields.join('^')
      const params = initParams || this.exportParams
      this.exportAndImportTags[1].props.loading = true
      const res = await exportApi[this.exportName](
        Object.assign(
          {
            exportFields: this.exportAll ? '' : exportFields
          },
          params
        )
      )
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        const fileName = `${dayjs().format('YYYYMMDDHHmmss')} ${name}.xls`
        this.downloadFile(urlP, fileName)
      } else {
        this.$message.error('导出失败，内容为空')
      }
      this.exportAndImportTags[1].props.loading = false
    },
    downloadFile(urlP, name) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = urlP
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    importExcel() {
      this.showImport = true
    },
    inputChange(val) {
      if (!val) this.search()
    },
    search() {
      this.$emit('input', this.searchVal)
      this.$emit('search', this.searchVal)
    }
  }
}
</script>

<style lang="scss">
.general-tag-wrap {
  display: inline-flex;
}

.function-header-wrap {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0;
  .function-left {
    display: flex;
  }
  .search-all {
    width: 88px;
    height: 32px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #607fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-left: 15px;
    span {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #607fff;
      width: calc(100% - 30px);
      text-align: center;
    }
    .all-ri {
      background: #f1f5fe;
      width: 30px;
      height: 100%;
      border-left: 1px solid #607fff;
      border-radius: 0 4px 4px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .img {
        width: 23px;
        height: 25px;
        display: block;
      }
    }
  }
  .input-wrapper {
    max-width: 400px;
    display: inline-flex;
    justify-content: space-between;
    position: relative;
    button {
      height: 32px;
    }
    .el-input {
      width: 200px;

      .el-input__inner {
        height: 32px;
      }
    }
  }
}
</style>
