var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom",
        width: "250",
        trigger: "click",
        "popper-class": "type-popover"
      },
      model: {
        value: _vm.inputVisible,
        callback: function($$v) {
          _vm.inputVisible = $$v
        },
        expression: "inputVisible"
      }
    },
    [
      _vm.type === "common"
        ? _c("div", [
            _vm.list.length > 0
              ? _c(
                  "div",
                  { staticClass: "content-container" },
                  _vm._l(_vm.list, function(item) {
                    return _c(
                      "div",
                      {
                        key: item[_vm.optionsKey.value],
                        staticClass: "selectType",
                        style: {
                          color:
                            _vm.currentIdx === item[_vm.optionsKey.label]
                              ? "#607FFF"
                              : "#606266"
                        },
                        attrs: {
                          label: item[_vm.optionsKey.label],
                          value: item[_vm.optionsKey.value]
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "name-tit",
                            on: {
                              click: function($event) {
                                return _vm.selectName(item, "change")
                              }
                            }
                          },
                          [_vm._v(_vm._s(item[_vm.optionsKey.label]))]
                        ),
                        !item.isSystem
                          ? _c("el-button", {
                              staticStyle: {
                                "font-size": "16px",
                                "z-index": "100000"
                              },
                              attrs: { type: "text", icon: "el-icon-edit" },
                              on: {
                                click: function($event) {
                                  return _vm.modifyTag(item)
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "nodata" }, [
                  _vm._v(" 暂无数据，请新增 ")
                ]),
            _c(
              "div",
              { staticClass: "select-add", on: { click: _vm.addType } },
              [
                _c("span", {
                  staticClass: "el-icon-circle-plus-outline icons"
                }),
                _c("span", { staticClass: "tit" }, [_vm._v("新增")])
              ]
            )
          ])
        : _vm._e(),
      _vm.type === "add" || _vm.type === "modify" || _vm.type === "del"
        ? _c("div", [
            _c(
              "div",
              { staticClass: "addType" },
              [
                _c("div", { staticClass: "left", on: { click: _vm.goBack } }, [
                  _c("img", {
                    staticStyle: { width: "8px" },
                    attrs: {
                      src: require("@/assets/information/procedure/左滑@2x.png")
                    }
                  }),
                  _c("span", { staticClass: "tit" }, [
                    _vm._v(_vm._s(_vm.typeTitle.get(_vm.type)))
                  ])
                ]),
                _c("el-button", {
                  staticClass: "icons",
                  attrs: { type: "text", icon: "el-icon-error" },
                  on: {
                    click: function($event) {
                      _vm.inputVisible = false
                    }
                  }
                })
              ],
              1
            ),
            _vm.type === "del"
              ? _c("div", { staticClass: "des-type" }, [
                  _vm._v("是否确认删除？")
                ])
              : _c(
                  "div",
                  { staticClass: "input-wrap" },
                  [
                    _vm.formType === "text"
                      ? _c("el-input", {
                          ref: "saveTagInput",
                          staticClass: "input-new-tag",
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.placeholder,
                            size: "small"
                          },
                          model: {
                            value: _vm.inputValue,
                            callback: function($$v) {
                              _vm.inputValue = $$v
                            },
                            expression: "inputValue"
                          }
                        })
                      : _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "controls-position": "right",
                            min: 10,
                            step: 1,
                            "step-strictly": true,
                            max: 300
                          },
                          model: {
                            value: _vm.inputValue,
                            callback: function($$v) {
                              _vm.inputValue = $$v
                            },
                            expression: "inputValue"
                          }
                        })
                  ],
                  1
                ),
            _vm.type === "add"
              ? _c(
                  "div",
                  { staticClass: "footer flex-cc" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btns",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.handleInputConfirm }
                      },
                      [_vm._v("创建")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.type === "modify"
              ? _c(
                  "div",
                  { staticClass: "footer flex-cc" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btns",
                        attrs: { type: "danger", size: "small", plain: "" },
                        on: { click: _vm.handleInputDel }
                      },
                      [_vm._v("删除")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btns",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.handleInputConfirm }
                      },
                      [_vm._v("完成")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.type === "del"
              ? _c(
                  "div",
                  { staticClass: "footer flex-cc" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btns",
                        attrs: { type: "danger", size: "small" },
                        on: { click: _vm.delProcedureTypes }
                      },
                      [_vm._v("删除")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "com-ipts",
          attrs: { slot: "reference" },
          on: { focus: _vm.goBack },
          slot: "reference"
        },
        [
          _vm._v(" " + _vm._s(_vm.name ? _vm.name : "请选择") + " "),
          _c("span", { staticClass: "el-icon-arrow-down icons" })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }