<template>
  <router-view v-if="isRouterAlive" :key="key" />
</template>

<script>
export default {
  name: 'Blank',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  computed: {
    key() {
      return this.$route.path + Math.random()
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style scoped>

</style>
