<template>
  <div class="tree-wrap">
    <GiantTree ref="giantTree" class="normalTree" :nodes="nodes" :setting="treeSetting" @onCreated="handleCreated" />
  </div>
</template>

<script>
/** write by luomingzhi */
import GiantTree from 'vue-giant-tree'
export default {
  name: 'Tree',
  components: { GiantTree },
  props: {
    nodes: {
      type: Array,
      required: true,
      default: () => ([])
    },
    view: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      treeSetting: {
        check: {
          enable: false,
          chkStyle: 'radio'
        },
        data: {
          simpleData: {
            enable: true,
            pIdKey: 'parentId'
          }
        },
        view: {
          showIcon: false,
          addHoverDom: this.addHoverDom,
          removeHoverDom: this.removeHoverDom
        },
        callback: {
          beforeClick: this.beforeClick
        }
      }
    }
  },
  mounted() {
  },
  methods: {
    handleCreated(ztreeObj) {
      ztreeObj.expandNode(ztreeObj.getNodes()[0], true)
      // 找到第一个节点
        const nodes = ztreeObj.getNodes()
        if (nodes.length > 0) {
          const firstNode = nodes[0]
          // 设置第一个节点为选中状态
          ztreeObj.selectNode(firstNode)
        }
    },
    beforeClick(treeId, treeNode) {
      // if (treeNode.id === -1) return false
      const item = document.getElementById(`${treeNode.tId}_a`)
      const classList = item.getAttribute('class').split(' ')
      const index = classList.indexOf('curSelectedNode')
      if (index !== -1) {
        this.$refs.giantTree.ztreeObj.cancelSelectedNode()
        this.$emit('nodeClick', treeId, treeNode)
        return false
      } else {
        this.$emit('nodeClick', treeId, treeNode)
      }
      return true
    },
    addHoverDom(treeId, treeNode) {
      const item = document.getElementById(`${treeNode.tId}_a`)
      if (item && !item.querySelector('.tree_extra_btn')) {
        item.style.background = '#F0F2F5'
        if (treeNode.id === -1) return
        const btn = document.createElement('div')
        const btnWrap = document.createElement('div')
        btnWrap.id = `${treeId}_${treeNode.id}_wrap`
        btnWrap.classList.add('tree_extra_wrap_btn')
        btnWrap.style.display = 'inline-block'
        btn.style.display = 'inline-block'
        btn.id = `${treeId}_${treeNode.id}_btn`
        btn.classList.add('tree_extra_btn')
        btn.classList.add('el-icon-more')
        btn.innerText = ''
        if (this.view) return
        btnWrap.addEventListener('mouseenter', (e) => {
          e.stopPropagation()
          this.showDropdown(treeId, treeNode)
        })
        btnWrap.addEventListener('mouseleave', (e) => {
          e.stopPropagation()
          this.showDropdown(treeId, treeNode)
        })
        btnWrap.appendChild(btn)
        item.appendChild(btnWrap)
      }
    },
    removeHoverDom(treeId, treeNode) {
      const item = document.getElementById(`${treeNode.tId}_a`)
      if (item) {
        item.style.background = ''
        if (treeNode.id === -1) return
        if (this.view) return
        const btn = item.querySelector('.tree_extra_wrap_btn')
        if (btn) {
          item.removeChild(btn)
        }
      }
    },
    showDropdown(treeId, treeNode) {
      const item = document.getElementById(`${treeNode.tId}_a`)
      const btnWrap = item.querySelector('.tree_extra_wrap_btn')
      const dropDown = item.querySelector('.c-drop-down')
      if (dropDown) {
        btnWrap.removeChild(dropDown)
      } else {
        const div = document.createElement('div')
        div.classList.add('c-drop-down')
        let menuItems = ''
        if (treeNode.isProjectTree) {
          // 当type为1时只显示详情选项
          menuItems = '<ul><li name="viewDetail">详情</li></ul>'
        } else {
          // 其他情况下显示所有选项
          menuItems = '<ul>' +
            '<li name="modify">修改</li>' +
            '<li name="del">删除</li>' +
            '</ul>'
        }
        div.innerHTML = menuItems
        Array.from(div.querySelectorAll('li')).forEach(li => {
          li.addEventListener('click', (e) => {
            e.stopPropagation()
            const name = li.getAttribute('name')
            this.$emit('command', name, treeNode)
          })
        })
        btnWrap.appendChild(div)
      }
    }
  }
}
</script>

<style lang="scss">
.button.chk.radio_true_full:after,
.button.chk.radio_true_part:after {
  top: 2px !important;
  left: 2px !important;
}
.tree-wrap {
  .normalTree {
    .button {
      width: 18px;
    }
    a[treenode_a] {
      position: relative;
      height: 42px;
      line-height: 42px;
      width: calc(100% - 30px);
    }
    .curSelectedNode {
      .node_name {
        background: none !important;
      }
    }
    .tree_extra_wrap_btn {
    position: absolute;
    right: 0;
    z-index: 999;
    text-align: right;
    .el-icon-more {
      transform: rotate(90deg);
      width: 26px;
      height: 16px;
      background: #E9EAEC;
      border-radius: 2px;
      line-height: 16px;
      text-align: center;
    }
    .c-drop-down {
      border-radius: 4px;
      box-shadow: 0 0 2px 2px rgba(0 , 0, 0, .1);
      background: #ffffff;
      ul {
        padding: 0 !important;
        position: relative;
        li {
          padding: 8px 20px;
          line-height: 16px;
          &:hover {
            background: rgb(240, 242, 245);
          }
        }
        &:before {
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-width: 0 5px 5px;
          border-style: solid;
          border-color: transparent transparent #fff;
          position: absolute;
          right: 5px;
          top: -5px;
          box-shadow: 2px 2px 2px -2px rgba(0 , 0, 0, .1);
        }
      }
    }
  }
  }

}
</style>
