import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/siteWarehouse/kiki/private/outInSitWarehouseRecord/'

export default {
  // 物料分页
  getMaterialsListPage(data) {
    return request.get(baseUrl + 'getOutInSitWarehouseRecordListPage', data)
  },
  // 包装分页
  getPackageListPage(data) {
    return request.get(baseUrl + 'getSitStockContainerPage', data)
  },
  // 托盘分页
  getTrayListPage(data) {
    return request.get(baseUrl + 'getSitStockPalletPage', data)
  }
  // 获取线边仓流水列表（分页）
  // getMaterialsList(data) {
  //   return request.get(baseUrl + 'getOutInSitWarehouseRecordListPage', data)
  // }
}
