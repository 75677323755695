import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/equipment/maintain/private/equipmentMaintainTask/'
const cycleUrl = 'api/web/v1/equipment/maintain/private/equipmentMaintainPlan/'

export default {
  // 获取保养单-不分页
  getMaintenanceBillsList(data) {
    return request.get(baseUrl + 'getEquipmentMaintainTaskList', data)
  },
  // 获取保养单-分页
  getMaintenanceBillsListPage(data) {
    return request.get(baseUrl + 'getEquipmentMaintainTaskListPage', data)
  },
  // 新增保养单
  addMaintenanceBills(data) {
    return request.post(baseUrl + 'addEquipmentMaintainTask', data)
  },
  // 新增保养单
  copyMaintenanceBills(data) {
    return request.post(baseUrl + 'addEquipmentMaintainTask', data)
  },
  // 修改保养单
  modifyMaintenanceBills(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 作废保养单
  abolishMaintenanceBills(data) {
    return request.put('api/web/v1/equipment/maintain/private/equipmentMaintainTask/nullifyMaintainTask', data)
  },
  // 跳过保养单
  passMaintenanceBills(data) {
    return request.put(`api/web/v1/equipment/maintain/private/equipmentMaintainTask/skipMaintainTask?skipReason=${data.skipReason}`, data.ids)
  },
  getMaintenanceBillsDetail(data) {
    return request.get(baseUrl + 'getEquipmentMaintainTaskDetail', data)
  },
  // 获取保养类型
  getCheckTypeList() {
    return request.get(cycleUrl + 'getMaintainPlanType')
  },
  // 获取保养原因
  getSkipReason(params) {
    return request.get(baseUrl + 'getEquipmentMaintainTaskSkipReasonList', params)
  }
}
