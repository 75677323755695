import request from '@/utils/requestForAsync'

const baseUrl = 'api/web/v1/production/private/project/'
const baseUrl1 = 'api/web/v1/production/private/'

export default {
  // 获取项目工作台列表
  getProjectWorkBenchList(data) {
    return request.get(baseUrl + 'list', data)
  },
  // 项目工作台导出
  exportProject(data) {
    return request.exportGet(baseUrl + 'templateExport', data)
  },
  // 获取项目工作台右侧列表
  getProjectWorkBenchDetail(data) {
    return request.get(baseUrl + 'projectTaskPlanNodeList', data)
  },
  // 节点调整
  adjustPlanNodes(data) {
    return request.put(baseUrl + 'projectTaskPlanNodeAdjust', data)
  },
  // 单节点调整
  adjustSingPlanNode(data) {
    return request.put(baseUrl1 + 'planNode/singPlanNodeAdjust', data)
  },
  //获取当前项目下的相关需求
  getProjectRelateList(data) {
    return request.get(baseUrl1 + 'projectRelateRequirement/currentProjectRelateList', data)
  },
  // 需求更新
  updateRelateRequirement(data) {
    return request.put(baseUrl1 + 'projectRelateRequirement/update', data)
  }
}
