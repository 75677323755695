var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "scale-box",
      style: {
        width: _vm.props.width + "px",
        height: _vm.props.height + "px",
        transform: "scale(" + _vm.scale + ") translate(-50%, -50%)",
        WebkitTransform: "scale(" + _vm.scale + ") translate(-50%, -50%)"
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }