import { Path } from '@antv/x6'

export const cellConfig = {
  width: '100%',
  height: '100%',
  scroller: {
    enabled: true,
    pannable: true,
    className: 'my-scroller'
  }, // 使画布具备滚动、平移、居中、缩放
  grid: {
    size: 12,
    visible: true,
    type: 'doubleMesh',
    args: [
      {
        color: '#DEDEEE', // 主网格线颜色
        thickness: 0.5 // 主网格线宽度
      },
      {
        color: '#ECECF7', // 次网格线颜色
        thickness: 1, // 次网格线宽度
        factor: 4 // 主次网格线间隔
      }
    ]
  }, // 背景网格参数
  mousewheel: { // 可缩放
    enabled: true,
    global: true,
    modifiers: ['ctrl', 'meta']
  },
  snapline: { // 自动对齐
    enabled: true,
    className: 'my-snapline'
  },
  connecting: {
    snap: true,
    allowBlank: false,
    allowLoop: false,
    allowMulti: false
  }
}

export const rectConfig = {
  x: 520,
  y: 80,
  width: 154,
  height: 48,
  attrs: {
    body: {
      fill: '#F3F5FF',
      stroke: '#7E9AFF',
      rx: 2,
      ry: 2
    },
    label: {
      fill: '#393D60'
    }
  },
  ports: {
    groups: {
      group1: {
        attrs: {
          circle: {
            r: 6,
            magnet: true,
            stroke: '#7E9AFF',
            strokeWidth: 1,
            fill: '#fff'
          }
        },
        position: {
          name: 'absolute'
        }
      }
    },
    items: [
      {
        group: 'group1',
        args: {
          x: 0,
          y: '50%'
        }
      },
      {
        group: 'group1',
        args: {
          x: '50%',
          y: 0
        }
      },
      {
        group: 'group1',
        args: {
          x: '100%',
          y: '50%'
        }
      },
      {
        group: 'group1',
        args: {
          x: '50%',
          y: '100%'
        }
      }
    ]
  }
}

export const edgeConfig = {
  attrs: {
    line: {
      stroke: '#a0a0a0',
      strokeWidth: 1,
      targetMarker: {
        name: 'classic',
        size: 7
      }
    }
  }
}

export const dragRectConfig = (procedure) => ({
  shape: 'rect',
  attrs: {
    label: {
      text: procedure.name,
      lineHeight: 18,
      textWrap: {
        ellipsis: true,
        breakWord: true,
        width: -10,
        height: '80%'
      }
    }
  }
})

export const curveConfig = (sourcePoint, targetPoint) => {
  const path = new Path()
  path.appendSegment(Path.createSegment('M', sourcePoint))
  path.appendSegment(
    Path.createSegment(
      'C',
      sourcePoint.x,
      sourcePoint.y + 180,
      targetPoint.x,
      targetPoint.y - 180,
      targetPoint.x,
      targetPoint.y
    )
  )
  return path.serialize()
}
