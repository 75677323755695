import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/inspection/private/inspectionSheet/'

export default {
  // 获取质检单不分页
  getInspectionOrderList(data) {
    return request.get(baseUrl + 'getInspectionSheetList', data)
  },
  // 获取质检单-分页
  getInspectionOrderListPage(data) {
    return request.get(baseUrl + 'getInspectionSheetListPage', data)
  },
  // 保存质检合格、不合格数据
  saveInspectionOrder(data) {
    return request.put(baseUrl + 'submit', data)
  },
  // 修改质检单
  modifyInspectionOrder(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 修改质检人员
  modifyInspectionUser(data) {
    return request.put(baseUrl + 'updateOneInspector', data)
  },
  // 删除质检单
  delInspectionOrder(id) {
    return request.del(baseUrl + `deleteOne?id=${id}`)
  },
  // 开始质检
  startInspection(id) {
    return request.get(baseUrl + `start?id=${id}`)
  },
  // 获取质检详情
  getInspectionOrderDetailById(params) {
    return request.get(baseUrl + `getInspectionSheetDetail?id=${params}`)
  },
   // 审核
   batchCheck(data) {
    return request.post(baseUrl + 'batchCheck', data)
  },
   // 审批
   batchApprove(data) {
    return request.post(baseUrl + 'batchApprove', data)
  },
}
