import request from '@/utils/requestForAsync'
const baseUrl = '/api/web/v1/equipment/fault/private/equipmentRepairTask/'

export default {
  // 获取故障报修-不分页
  getEquipmentFaultList(data) {
    return request.get(baseUrl + 'getEquipmentRepairTaskList', data)
  },
  // 获取详情
  getEquipmentFaultDetails(data) {
    return request.get(baseUrl + 'getEquipmentRepairTaskDetail', data)
  },
  // 获取故障报修-分页
  getEquipmentFaultListPage(data) {
    return request.get(baseUrl + 'getEquipmentRepairTaskListPage', data)
  },
  // 取消报修
  delRepair(data) {
    return request.del(baseUrl + 'deleteOne', data)
  }
}
