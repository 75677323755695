import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/purchase/private/purchaseRequirement/'

export default {
  // 物料属性下拉列表
  materialsAttributes(data) {
    return request.get(baseUrl + 'materialsAttributes', data)
  },
  // 左侧生产计划号列表
  producePlanList() {
    return request.get(baseUrl + 'producePlanList')
  },
  // 单计划采购需求接口
  singlePlanDetail(data) {
    return request.get(baseUrl + 'singlePlanDetail', data)
  },
  // 点击左侧全部查看数据
  detail(data) {
    return request.get(baseUrl + 'detail', data)
  },
  // 已占用库存修改接口
  updateLockedStock(data) {
    return request.put(baseUrl + 'updateLockedStock', data)
  },
  // 请购保存接口
  purchase(data) {
    return request.post(baseUrl + 'purchase', data)
  },
  // 导出
  export(data) {
    return request.exportGet(baseUrl + 'export', data)
  },

  // 生产工单下拉列表
  producePlanListByPage(data) {
    return request.get('api/web/v1/purchase/private/purchaseRequirement/producePlanListByPage', data)
  },
  // 工序下拉列表
  procedureList(data) {
    return request.get('api/web/v1/basicData/production/private/procedure/pullDownList', data)
  },
  // 物料请购列表
  materialsRequisitionList(data) {
    return request.get('api/web/v1/purchase/private/materialsRequisition/list', data)
  },
  // 请购单列表
  requisitionOrderList(data) {
    return request.get('api/web/v1/purchase/private/requisitionOrder/list', data)
  },
  // 请购单详情
  requisitionOrderDetail(data) {
    return request.get('api/web/v1/purchase/private/requisitionOrder/detail', data)
  },
  // 请购单编辑
  requisitionOrderUpdate(data) {
    return request.put('api/web/v1/purchase/private/requisitionOrder/update', data)
  },
  // 请购单删除
  requisitionOrderDel(data) {
    return request.del('api/web/v1/purchase/private/requisitionOrder/delete', data)
  },
  // 请购单导出
  purchaseRequirementExport(data) {
    return request.exportPost('api/web/v1/purchase/private/purchaseRequirement/export', data)
  },
  // 请购单平均处理时长-保存
  requisitionOrderDurationSave(data) {
    return request.post('api/web/v1/purchase/private/requisitionOrderDuration/save', data)
  },
  // 请购单平均处理时长-查看
  requisitionOrderDurationView(data) {
    return request.get('api/web/v1/purchase/private/requisitionOrderDuration/get', data)
  },
  // 创建人/更新人列表
  personList(data) {
    return request.get('api/web/v1/purchase/private/requisitionOrder/personList', data)
  },
  // 左侧生产编号列表
  prodCodeList() {
    return request.get('api/web/v1/production/private/productionRequirement/producePlanList')
  },
  // 生产需求物料列表
  prodDetail(data) {
    return request.get('api/web/v1/production/private/productionRequirement/detail', data)
  },
  // 已占用库存修改
  editLockedStock(data) {
    return request.put('api/web/v1/production/private/productionRequirement/updateLockedStock', data)
  },
  // 生成生产任务号
  getCode() {
    return request.get('api/web/v1/production/private/productionTask/getCode')
  },
  // 创建生产任务
  createTask(data) {
    return request.post('api/web/v1/production/private/productionTask/create', data)
  },
  // 修改生产任务
  updateTask(data) {
    return request.put('api/web/v1/production/private/productionTask/update', data)
  },
  // 生产任务列表
  getTaskList(data) {
    return request.get('api/web/v1/production/private/productionTask/list', data)
  },
  // 删除生产任务
  delTask(data) {
    return request.del('api/web/v1/production/private/productionTask/delete', data)
  },
  // 自动排程
  autoCreate(data) {
    return request.post('api/web/v1/production/private/productionTask/autoCreate', data)
  },
  // 保存自动排程
  saveAutoCreate(data) {
    return request.post('api/web/v1/production/private/productionTask/saveAutoCreate', data)
  },
  // 采购异常列表
  getExceptionList(data) {
    return request.get('api/web/v1/purchase/private/purchaseAnomaly/list', data)
  },
  // 导出
  exportExceptionList(data) {
    return request.exportGet('api/web/v1/purchase/private/purchaseAnomaly/export', data)
  },
  // 待定需求物料列表项
  getWaitDemandList(data) {
    return request.get('api/web/v1/production/private/undeterminedRequirement/itemList', data)
  },
  // 划定生产和采购
  waitDemandPurchase(data) {
    return request.post('api/web/v1/production/private/undeterminedRequirement/delimit', data)
  },
  // 生产需求计划列表
  getProductionRequirementListPage(data){
    return request.get('api/web/v1/production/private/productionRequirement/materialsSummaryListByPage', data)
  },
  // 获取生产需求计划列表
  getNewProductionRequirementListPage(data){
    return request.get('api/web/v1/production/private/productionRequirement/planNumberList', data)
  },
  // 获取生产需求明细表
  getProductionRequirementDetailsList(data){
    return request.get('api/web/v1/production/private/productionRequirement/materialsDetailList', data)
  },
  // 获取模组匹配列表
  getModuleMatchList(data){
    return request.get('api/web/v1/production/private/moduleMatching/list', data)
  },
  // 模组工单匹配
  MatchModule(data){
    return request.post('api/web/v1/production/private/moduleMatching/pairing', data)
  },
  exportProductionTask(data) {
    return request.exportPost('api/web/v1/production/private/productionTask/export', data)
  }
}
