import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/equipment/maintain/private/equipmentMaintainPlan/'
const settingUrl = 'api/web/v1/equipment/setting/private/'

export default {
  addMaintenanceItemAndBindEquipmentClass(data) {
    return request.post(settingUrl + 'maintenanceItemEquipmentClassMapping/addEquipmentClassMaintenanceItem', data)
  },
  // 获取保养项-不分页
  getMaintenanceItemEquipmentClassListPage(data) {
    return request.get(settingUrl + 'maintenanceItemEquipmentClassMapping/getMaintenanceItemEquipmentClassMappingListPage', data)
  },
  // 获取设备类列表
  getEquipmentClassList() {
    return request.get(`/api/web/v1/basicData/private/equipmentClass/getEquipmentClassList`)
  },
  // 获取保养计划-不分页
  getMaintenancePlanList(data) {
    return request.get(baseUrl + 'getEquipmentMaintainPlanList', data)
  },
  // 获取保养计划-分页
  getMaintenancePlanListPage(data) {
    return request.get(baseUrl + 'getEquipmentMaintainPlanListPage', data)
  },
  // 新增保养计划
  addMaintenancePlan(data) {
    return request.post(baseUrl + 'addEquipmentMaintainPlan', data)
  },
  // 新增保养计划
  copyMaintenancePlan(data) {
    return request.post(baseUrl + 'addEquipmentMaintainPlan', data)
  },
  // 修改保养计划
  modifyMaintenancePlan(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除保养计划
  delMaintenancePlan(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  getMaintenancePlanDetail(data) {
    return request.get(baseUrl + 'getEquipmentMaintainPlanDetail', data)
  },
  // 获取保养类型
  getMaintenanceTypeList() {
    return request.get(baseUrl + 'getMaintainPlanType')
  },
  // 获取单号
  getPlanCode() {
    return request.get(baseUrl + 'getMaxMaintainPlanNumber')
  }
}
