var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "options-box" },
    [
      _vm._l(_vm.optionItems, function(item, index) {
        return [
          item.type == "braid-table"
            ? _c(
                "el-popover",
                {
                  key: index,
                  attrs: { placement: "top", width: "200" },
                  model: {
                    value: _vm.tablePopover,
                    callback: function($$v) {
                      _vm.tablePopover = $$v
                    },
                    expression: "tablePopover"
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: item.selectCol,
                            callback: function($$v) {
                              _vm.$set(item, "selectCol", $$v)
                            },
                            expression: "item.selectCol"
                          }
                        },
                        [
                          _c(
                            "el-row",
                            _vm._l(item.columnsAttr, function(col) {
                              return _c(
                                "el-col",
                                { key: col.name, attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-checkbox",
                                    { attrs: { label: col.name } },
                                    [_vm._v(_vm._s(col.title))]
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function(e) {
                              _vm.addTempItem(e, item)
                            }
                          }
                        },
                        [_vm._v("确定")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { slot: "reference", size: "mini" },
                      slot: "reference"
                    },
                    [_vm._v(_vm._s(item.title))]
                  )
                ],
                1
              )
            : _c(
                "el-button",
                {
                  key: index,
                  attrs: { disabled: _vm.fixData(item), size: "mini" },
                  on: {
                    click: function(e) {
                      _vm.addTempItem(e, item)
                    }
                  }
                },
                [_vm._v(_vm._s(item.title))]
              )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }