<template>
  <MDialog v-model="modalShow" title="批量导入" width="80%">
    <div class="batchImport">
      <el-steps :active="stepActive" finish-status="success" process-status="finish">
        <el-step title="上传文件" description="请上传指定格式文件" />
        <el-step title="校验数据" description="检查数据格式是否正确" />
        <el-step title="写入数据" description="正在执行写入数据" />
      </el-steps>
      <!-- 上传文件 -->
      <div v-if="stepActive === 0">
        <div class="down-template">
          <div class="down-le" />
          <div class="down-ri">
            <div class="down-tit">下载模板</div>
            <div class="down-des">点击下方文字按钮，下载模版，并按照规定格式填写数据，每次填写数据总和不超过10000条</div>
            <div class="down-btn" @click="downloadTemplate">
              <img src="@/assets/l_c_M_images/download.png" class="icon">
              <span class="download">下载模板</span>
            </div>
          </div>
        </div>
        <div class="down-template">
          <div class="down-le" />
          <div class="down-ri">
            <div class="down-des">上传文件格式仅支持xls，xlsx，且文件大小不得超过5M</div>
            <div class="down-btn">
              <el-upload ref="upload" :file-list="fileList" v-bind="uploadProps">
                <el-button
                  type="text"
                  class="icons"
                  icon="el-icon-upload"
                  style="padding: 0"
                >上传文件</el-button>
              </el-upload>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <MTable ref="mUnitTable" :height="600" :show-page="false" :data="tableList" :columns="columns" :columns-setting="false">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <template v-for="item in slots" #[item]="{row,$index}">
            <el-input :ref="`${item}-${$index}`" :key="item" v-model="row[item]" :placeholder="`请输入${item}`" />
          </template>
        </MTable>
      </div>
    </div>
    <div slot="footer" class="mubai-dialog-footer center-content">
      <el-button @click="cancel">{{ preBtnMap[stepActive] }}</el-button>
      <el-button
        type="primary"
        :disabled="!fileList.length"
        @click="submitForm"
      >{{ nextBtnMap[stepActive] }}</el-button>
    </div>
  </MDialog>
</template>
<script>
import XLSX from 'xlsx'
export default {
  name: 'SimpleImport',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    importName: {
      type: String,
      default: ''
    },
    templateUrl: {
      type: String,
      default: ''
    },
    row: { // 从哪一行开始解析数据
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      tableList: [],
      columns: [],
      slots: [],
      modalShow: false,
      stepActive: 0,
      nextBtnMap: {
        0: '导入',
        1: '校验',
        2: '确定'
      },
      preBtnMap: {
        0: '取消',
        1: '上一步',
        2: '上一步'
      },
      fileList: [],
      uploadProps: {
        multiple: false,
        accept: '.xls,.xlsx',
        action: '/',
        showFileList: true,
        limit: 1,
        autoUpload: false,
        onSuccess: this.fileUploadChange,
        onRemove: this.fileRemove,
        onError: this.fileUploadChange,
        onExceed: this.fileExceed,
        onChange: this.fileUploadChange
      }
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 289 - 48 - 12 - 220
    },
    loadingHeight() {
      return 270
    }
  },
  watch: {
    visible(val) {
      this.modalShow = val
      if (val) {
        this.fileList = []
      }
    },
    modalShow(val) {
      if (!val) {
        this.$emit('update:visible', false)
      }
    }
  },
  mounted() {
    this.modalShow = this.visible
  },
  methods: {
    fileExceed() {
      this.$message.info('只能上传一个文件')
    },
    fileRemove() {
      this.fileList = []
    },

    // 文件选择回调
    fileUploadChange(file, files) {
      // 读取excel,解析数据，根据id去找到this.processFlowDetail.procedureList里面的对应项，修改其outputMaterialsList，planMaterialsList
      if (!file) {
        // 如果没有文件 - 当然也可以提醒用户弹个警告框 但是基本没有这种情况的出现
        return false
      } else if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
        // 文件格式的判断
        this.$message.error('上传格式不正确，请上传xls或者xlsx格式')
        return false
      } else {
        const isLt5M = file.size / 1024 / 1024 < 5
        if (!isLt5M) {
          return this.$message.error('上传图片大小不能超过 5M!')
        }
      }
      this.fileList = files
      const fileReader = new FileReader()
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary'
          })
          // 取第一张表
          const worksheet = (workbook.Sheets[workbook.SheetNames[0]])
          // 设置读取数据的范围，从第row行到最后一行
          const range = worksheet['!ref'].split(':')
          range[0] = range[0].replace(/\d+/, this.row)
          worksheet['!ref'] = range.join(':')
          // 生成json表格内容
          const ws = XLSX.utils.sheet_to_json(worksheet)
          if (!ws.length) {
            this.fileList = []
            return this.$message.warning('数据为空，请完善后重新导入！')
          }
          // 循环 ws 取得其中的数据
          this.tableList = ws
          // 生成slots
          this.tableList.length && (this.slots = Object.keys(this.tableList[0]))
          // 生成columns
          this.columns = this.slots.map(item => ({ prop: item, label: item, hiddenSearch: true,
            sortable: false, slotName: item }))
        } catch (e) {
          return false
        }
      }
      // 如果为原生 input 则应是 file[0]
      fileReader.readAsBinaryString(file.raw)
    },

    downloadTemplate() {
      this.downloadFile(this.templateUrl, this.importName)
    },
    downloadFile(url, name) {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          link.download = name
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
    },
    async submitForm() {
      switch (this.stepActive) {
        case 0: // 取消
          this.stepActive = 1

          break
        case 1:// 校验
          {
            this.params = this.formatData(this.tableList)
            // 后端校验，需要返回错误数据对应的index和字段
            console.log('提交数据=', this.tableList)
            console.log('ref=', this.$refs['请购单编号-1'][0])
            const errData = ['请购单编号-1']
            errData.forEach(item => {
              this.$refs[item][0].$el.classList.add('warning')
              this.$refs[item][0].$el.scrollIntoView({ behavior: 'smooth', block: 'start' })
            })
            this.stepActive = 2
          }
          break
        case 2: // 确定
          this.stepActive = 0
          this.modalShow = false
          break
        default:
          break
      }
    },
    cancel() {
      switch (this.stepActive) {
        case 0: // 取消
          this.modalShow = false
          break
        case 1:// 校验
          this.stepActive = 0
          break
        case 2:// 校验之后
          this.stepActive = 0
          break
        default:
          break
      }
    },
    // 格式化数据
    formatData(tableList) {
      return tableList
    }
  }
}
</script>

<style lang="scss" scoped>
.batchImport {
  padding: 10px;

  .down-template {
    background: #f6f7f9;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    margin: 30px 0 20px 0;

    .down-le {
      width: 6px;
      background: #607fff;
      border-radius: 4px 0px 0px 4px;
      margin-right: 20px;
      padding: 20px 0;
    }

    .down-ri {
      padding: 20px 0;

      .down-tit {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 20px;
        margin-bottom: 2px;
      }

      .down-des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9fa1b7;
        line-height: 20px;
      }

      .down-btn {
        margin-top: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
          width: 21px;
          height: 15px;
          color: #607fff;
        }

        .download {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #607fff;
          line-height: 20px;
          margin-left: 8px;
        }

        .icons {
          color: #607fff;
          display: inline-flex;
          align-items: center;

          ::v-deep .el-icon-upload {
            font-size: 22px;
            color: #607fff;
          }
        }

        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0091ff;
          line-height: 20px;
          margin-left: 8px;
        }
      }
    }
  }

  .check-success {
    margin-top: 30px;

    .suc-top {
      background: rgba(0, 171, 41, 0.02);
      border-radius: 4px;
      border: 1px solid #00ab29;
      padding: 20px 0 20px 25px;

      .tit {
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 22px;
      }

      .des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a9abbf;
        line-height: 22px;
      }
    }
  }

  .suc-tit {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #393d60;
    line-height: 20px;
    text-align: center;
    margin-top: -50px;
  }
}

.el-dialog__footer {
  padding: 0 !important;
  height: 57px;
}

.mubai-dialog-footer {
  position: absolute;
  height: 57px;
  width: 100%;
  border-top: 1px solid #dde3eb;
  padding: 10px 20px;
  bottom: 0;
  background: #ffffff;

  button {
    padding: 10px 40px !important;

    &:first-child {
      background: #f8f8fb;
      color: #8a8ca5;
      transition: all 0.5s;

      &:hover {
        border-color: #dadaf6;
        background: #f0f0f8;
      }
    }

    &:last-child {
      background: $primary-color;
      color: #ffffff;
      transition: all 0.5s;

      &:hover {
        background: #7691fc;
      }
    }
  }
}

.center-content {
  text-align: center;
}
::v-deep .has-error {
  background: rgba(224, 32, 32, 0.04);
}
.warning{
  border: 1px solid red;
  border-radius: 4px;
}
</style>
