var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "procedure-params-wrapper" },
    [
      _vm._l(_vm.paramsGroupList, function(row, index) {
        return _c("div", { key: row.name, staticClass: "params-card" }, [
          _c("div", { staticClass: "header flex-sbc" }, [
            _c("div", [_vm._v("参数组标题：" + _vm._s(row.name))]),
            !_vm.readOnly
              ? _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.modifyGroupName(row, index)
                          }
                        }
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.delGroup(index)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "body" }, [
            row.formList.length
              ? _c(
                  "div",
                  { staticClass: "params" },
                  [
                    _c("MFormBuilder", {
                      ref: "paramsFormList",
                      refInFor: true,
                      attrs: {
                        "label-position": "left",
                        "form-list": row.formList
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            !_vm.readOnly
              ? _c(
                  "div",
                  { staticClass: "body-bottom flex-cc" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.addParams(index)
                          }
                        }
                      },
                      [_vm._v("添加参数")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      }),
      !_vm.readOnly
        ? _c(
            "el-button",
            { staticClass: "add-button flex-cc", on: { click: _vm.addGroup } },
            [
              _c("div", { staticClass: "flex-sbc" }, [
                _c("img", {
                  staticStyle: { width: "22px" },
                  attrs: {
                    src: require("../../assets/information/procedure/新增.png")
                  }
                }),
                _c(
                  "span",
                  { staticClass: "l10", staticStyle: { color: "#607FFF" } },
                  [_vm._v("新建参数分组")]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.readOnly && !_vm.paramsGroupList.length ? _c("MBlank") : _vm._e(),
      _c(
        "MDialog",
        {
          staticClass: "params-group-dialog",
          attrs: { width: "400px", title: _vm.dialogMessage[_vm.currentType] },
          on: { onOk: _vm.submitGroupForm },
          model: {
            value: _vm.groupVisible,
            callback: function($$v) {
              _vm.groupVisible = $$v
            },
            expression: "groupVisible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "groupForm",
            attrs: {
              "label-position": "left",
              "form-data": _vm.groupFormData,
              "form-list": _vm.groupFormList
            }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.dialogMessage[_vm.currentType] },
          on: { onOk: _vm.submitParamsForm },
          model: {
            value: _vm.paramsVisible,
            callback: function($$v) {
              _vm.paramsVisible = $$v
            },
            expression: "paramsVisible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "paramsForm",
            staticStyle: { "padding-bottom": "10px" },
            attrs: {
              "form-data": _vm.paramsFormData,
              "form-list": _vm.paramsFormList,
              "custom-rules": _vm.customRules
            }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "新增工序参数" },
          on: { onOk: _vm.submitNewParams },
          model: {
            value: _vm.addParamsVisible,
            callback: function($$v) {
              _vm.addParamsVisible = $$v
            },
            expression: "addParamsVisible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "addParamsForm",
            staticStyle: { "padding-bottom": "10px" },
            attrs: {
              "form-data": _vm.addParamsFormData,
              "form-list": _vm.addParamsFormList
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }