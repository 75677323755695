import request from '@/utils/requestForAsync'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
const baseUrl = '/api/web/v1/market/private/marketType/'
const baseUrl2 = '/api/web/v1/market/private/marketDetail/'
export default {
  // 获取市场详情-不分页
  getApplyList(id, keyword) {
    return request.get(baseUrl + 'getMarketTypeList', {
      searchVal: Encrypt(JSON.stringify([{
        id: getUUid(),
        fieldName: 'tableId',
        fieldType: 'number',
        fieldValue: id,
        operator: 'eq'
      }])),
      searchKeyword: keyword
    })
  },
  // 模糊查询
  getApplyAqueryList(data) {
    return request.get(baseUrl2 + 'getMarketDetailList', data)
  },

  // 新增应用
  addApplyMarket(data) {
    return request.post(baseUrl2 + 'addMarketDetail', data)
  },
  // 获取市场详情
  getApplyDetails(data) {
    return request.get(baseUrl2 + 'getMarketDetailDetail', data)
  },
  // 修改市场详情
  modifyApplyDetails(data) {
    return request.put(baseUrl2 + 'updateOne', data)
  },
  // 删除市场详情
  delApply(data) {
    return request.del(baseUrl2 + 'deleteOne', data)
  },
  getDeviceParametersRecord(params) {
    return request.get(baseUrl2 + 'getDeviceParametersRecord', params)
  },
  getDeviceParametersRecordTrend(params) {
    return request.post(baseUrl2 + 'getDeviceParametersRecordTrend', params)
  }
}
