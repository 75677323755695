import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/invoices/private/facilityUpkeepWorkOrder/'

export default {
  // 获取设备保养工单-列表（不分页）
  getMaintainOrderList(data) {
    return request.get(baseUrl + 'getFacilityUpkeepWorkOrderList', data)
  },
  // 获取设备保养工单-列表（分页）
  getMaintainOrderListPage(data) {
    return request.get(baseUrl + 'getFacilityUpkeepWorkOrderListPage', data)
  },
  // 新建设备保养工单
  addMaintainOrder(data) {
    return request.post(baseUrl + 'addFacilityUpkeepWorkOrder', data)
  },
  // 修改保养工单
  modifyMaintainOrder(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除设备保养工单
  delMaintainOrder(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 批量删除设备保养工单
  delBatchMaintainOrder(data) {
    return request.post(baseUrl + 'batchDelete', data)
  },
  // 获取设备保养工单-详情
  getMaintainOrderByDetail(id) {
    return request.get(baseUrl + 'getFacilityUpkeepWorkOrderDetail', { id })
  },
  // 导出设备保养工单-列表
  exportMaintainOrder(id) {
    return request.exportGet(baseUrl + 'export', { id })
  },
  // 启用设备保养工单
  enableMaintainOrder(id) {
    return request.get(baseUrl + `enable?id=${id}`)
  },
  // 禁用设备保养工单
  disableMaintainOrder(id) {
    return request.get(baseUrl + `disable?id=${id}`)
  },
  // 批量跳过保养工单
  batchSkippingMaintainOrder(data) {
    return request.post(baseUrl + 'batchSkipping', data)
  },
  // 跳过保养工单
  skippingMaintainOrder(data) {
    return request.get(baseUrl + 'skipping', data)
  },
  // 工单保存校验服务时间
  checkTimeInfo(data) {
    return request.get('api/web/v1/field/facility/invoices/private/facilityRepairWordOrder/checkTime', data)
  }
}
