<template>
  <div class="search-form-wrap">
    <!--    todo: 兼容高级筛选-->
    <CFormBuilder v-if="showForm" :col-span="colSpan" :this="that" />
  </div>
</template>

<script>
import render from '@/components/MCustom/FormBuilder/render'
import dayjs from 'dayjs'
import { getUUid } from '@/utils'
import fieldApi from '@/api/xiewei/field/field'
import { Encrypt } from '@/utils/sercet'
import { paramsTypeListSearch } from '@/config/options.sales'

export default {
  name: 'SearchForm',
  components: {
    CFormBuilder: {
      render,
      functional: true
    }
  },
  props: {
    immediatelyQuery: {
      type: Boolean,
      default: false
    },
    labelPosition: {
      type: String,
      default: 'left'
    },
    colSpan: {
      type: Number,
      default: 12
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    defaultForm: {
      type: Object,
      default: () => ({})
    },
    formListExtend: {
      type: Array,
      require: true,
      default: () => ([])
    },
    buttonSpan: {
      type: Number,
      default: 3
    },
    searchFieldData: {
      type: Array,
      require: true,
      default: () => ([])
    }
  },
  data() {
    return {
      paramsTypeListSearch,
      that: this,
      form: {},
      formList: [],
      searchButtons: [
        {
          colSpan: this.buttonSpan,
          tagName: 'div',
          style: { minWidth: '160px' },
          children: [
            {
              tagName: 'el-button',
              props: { type: 'primary', size: 'small' },
              style: { width: 'auto', background: '#607FFF' },
              innerText: '查询',
              on: {
                click: this.search
              },
              children: []
            },
            {
              tagName: 'el-button',
              props: { size: 'small' },
              style: { width: 'auto' },
              innerText: '重置',
              on: {
                click: this.reset
              },
              children: []
            }
          ]
        }
      ],
      showForm: true,
      isProcess: false,
      searchFieldForm: {},
      formListField: []
    }
  },
  computed: {
    newForm() {
      return JSON.parse(JSON.stringify(this.form))
    }
  },
  watch: {
    defaultForm(val) {
      this.form = this._.cloneDeep(val)
    },
    isProcess(val) {
      if (val) {
        this.form.procedureId = ''
      }
    },
    newForm: {
      handler(newVal, oldVal) {
        const diffKeys = this.getDiffKey(newVal, oldVal)
        const needUpdateTableKeys = [...this.formListExtend, ...this.formListField]
          .filter(item => ['el-select', 'el-date-picker'].includes(item.tagName))
          .map(item => item.key)
        const needUpdate = needUpdateTableKeys.some(item => diffKeys.includes(item))
        if (needUpdate) {
          this.search()
        }
        this.$emit('update:formData', this.form)
      },
      deep: true
    },
    formListExtend() {
      this.setFormList()
    },
    searchFieldData: {
      handler(newVal, oldVal) {
        this.fixSearchFieldAll()
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.form = this._.cloneDeep(this.defaultForm)
    this.setFormList()
  },
  methods: {
    getDiffKey(newVal, oldVal) {
      const diffKey = []
      const newValClone = this._.cloneDeep(newVal)
      const oldValClone = this._.cloneDeep(oldVal)
      Object.keys(newValClone).forEach(key => {
        if (Array.isArray(newValClone[key])) {
          newValClone[key] = newValClone[key].length ? newValClone[key] : undefined
        }
        if (Array.isArray(oldValClone[key])) {
          oldValClone[key] = oldValClone[key].length ? oldValClone[key] : undefined
        }
        if (newValClone[key] !== oldValClone[key]) {
          diffKey.push(key)
        }
      })
      return diffKey
    },
    setForm(val) {
      this.$set(this, 'form', { ...this.form, ...val })
    },
    setFormList() {
      this.showForm = false
      if (!this.immediatelyQuery) {
        this.formList = [...this.formListExtend, ...this.formListField, ...this.searchButtons]
      } else {
        this.formList = [...this.formListExtend, ...this.formListField]
      }
      this.$nextTick(() => {
        this.showForm = true
      })
    },
    // 自定义字段组装成相同form格式
    fixSearchFieldAll() {
      const list = this.searchFieldData.map(item => {
        return {
          key: item.filedName,
          required: item.required !== 0,
          filedProperty: item.allFiledProperty,
          filedType: item.filedType,
          attrs: {
            placeholder: item.filedName
          },
          ...paramsTypeListSearch[item.filedType === 1 ? 0 : item.filedType]
        }
      })
      const data = list.map(item => {
        if (item.filedType === 2 || item.filedType === 3) {
          return {
            ...item,
            props: item.filedType === 2 ? {
              placeholder: item.key,
              clearable: true,
              size: 'small'
            } : {
              placeholder: item.key,
              multiple: true,
              clearable: true,
              collapseTags: true,
              size: 'small'
            },
            children: [
              {
                tagName: 'el-option',
                props: {
                  label: 'name',
                  value: 'id'
                },
                options: item.filedProperty ? item.filedProperty.split(',').map(item => {
                  return {
                    id: item,
                    name: item
                  }
                }) : []
              }
            ]
          }
        }
        return {
          ...item
        }
      })
      this.formListField = data
      this.setFormList()
    },
    search(val) {
      const searchFormatData = []
      // console.log('search=>', this.form)
      const formList = [...this.formListExtend, ...this.formListField]
      Object.keys(this.form).forEach(item => {
        if (!this.form[item] && this.form[item] !== 0) return false
        const current = formList.find(f => f.key === item)
        if (!current) return false
        if (current.tagName === 'el-date-picker') {
          // const isTimeRangeB = dayjs(this.form[item][1]).format('YYYY-MM-DD HH:mm:ss').indexOf('00:00:00') === -1
          // const isTimeRangeA = dayjs(this.form[item][0]).format('YYYY-MM-DD HH:mm:ss').indexOf('00:00:00') === -1
          searchFormatData.push({
            fieldKey: item,
            fieldType: 4,
            fieldValue: `${this.form[item][0].format('YYYY-MM-DD HH:mm:ss')}^${this.form[item][1].format('YYYY-MM-DD HH:mm:ss')}`
            // maxValue: isTimeRangeB ? dayjs(this.form[item][1]).format('YYYY-MM-DD HH:mm:ss') : current.dateType === 'date' ? dayjs(this.form[item][1]).format('YYYY-MM-DD') : `${dayjs(this.form[item][1]).format('YYYY-MM-DD')} 23:59:59`,
            // minValue: isTimeRangeA ? dayjs(this.form[item][0]).format('YYYY-MM-DD HH:mm:ss') : current.dateType === 'date' ? dayjs(this.form[item][0]).format('YYYY-MM-DD') : `${dayjs(this.form[item][0]).format('YYYY-MM-DD')} 00:00:00`,
          })
        } else if (current.tagName === 'el-select' && current.props && current.props.multiple) {
          if (this.form[item] && this.form[item].length) {
            searchFormatData.push({
              fieldKey: item,
              fieldType: 3,
              fieldValue: this.form[item].join('^')
            })
          }
        } else if (this.form[item] || this.form[item] === 0) {
          searchFormatData.push({
            fieldKey: item,
            fieldType: current.tagName === 'el-input' ? 0 : current.tagName === 'el-input-number' ? 1 : 2,
            fieldValue: this.form[item]
          })
        }
      })
      // val 用来判断是否是重置
      this.$emit('search', searchFormatData, val)
    },
    reset() {
      this.form = this._.cloneDeep(this.defaultForm)
      this.search(1)
    }
  }
}
</script>

<style scoped lang="scss">
.search-form-wrap {
  padding: 10px 10px 0 10px;
  background: #F0F2F5;
  margin-bottom: 10px;
  ::v-deep .el-form .el-row {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
  }
  ::v-deep .el-input--suffix .el-input__inner {
    min-height: 32px !important;
  }
  .el-select {
    height: 32px;
  }
}
</style>
