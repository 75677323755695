var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MDialog",
    {
      attrs: { width: "1000px", "append-to-body": true, title: "选择物料" },
      on: { onOk: _vm.submitForm, fullScreen: _vm.fullScreenChange },
      model: {
        value: _vm.modalShow,
        callback: function($$v) {
          _vm.modalShow = $$v
        },
        expression: "modalShow"
      }
    },
    [
      _c(
        "Split",
        {
          attrs: { offset: _vm.split.offset, height: _vm.splitHeight },
          on: {
            "update:offset": function($event) {
              return _vm.$set(_vm.split, "offset", $event)
            }
          }
        },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("MaterialsClass", {
                attrs: { view: true },
                on: {
                  setMaterialsClass: _vm.setMaterialsClass,
                  nodeClick: _vm.setMaterialsClassId
                }
              })
            ],
            1
          ),
          _c("template", { slot: "right" }, [
            _c(
              "div",
              { staticClass: "top" },
              [
                _c("SearchForm", {
                  ref: "searchForm",
                  attrs: {
                    "form-list-extend": _vm.searchFormList,
                    "form-data": _vm.searchFormData
                  },
                  on: {
                    "update:formData": function($event) {
                      _vm.searchFormData = $event
                    },
                    "update:form-data": function($event) {
                      _vm.searchFormData = $event
                    },
                    search: _vm.searchTable
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "materials-info" },
              [
                _c(
                  "FunctionHeader",
                  {
                    staticClass: "materials-head",
                    attrs: {
                      "search-title":
                        _vm.isContainer === 1
                          ? "物料名称/编码/规格/批次号/序列号/容器编号/容器名称"
                          : "物料名称/编码/规格/批次号/序列号",
                      tags: _vm.functionHeader.tags,
                      "columns-setting": false
                    },
                    on: {
                      search: function($event) {
                        return _vm.$refs.mTable.setTableData(true)
                      }
                    },
                    model: {
                      value: _vm.functionHeader.searchVal,
                      callback: function($$v) {
                        _vm.$set(_vm.functionHeader, "searchVal", $$v)
                      },
                      expression: "functionHeader.searchVal"
                    }
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "all" }, slot: "all" },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              margin: "0 20 0 0px",
                              display: "inline-block"
                            }
                          },
                          [
                            _vm._v(" 已选中 "),
                            _c("span", { staticStyle: { color: "#000" } }, [
                              _vm._v(_vm._s(_vm.getSelectionDataLength))
                            ]),
                            _vm._v(" 项 ")
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: { click: _vm.resetData }
                          },
                          [_vm._v("清空")]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c("MTable", {
                  ref: "mTable",
                  attrs: {
                    "highlight-current-row": _vm.single,
                    columns: _vm.columns,
                    height: _vm.height,
                    "set-data-method": _vm.getTableData,
                    "columns-setting": false
                  },
                  on: {
                    "selection-change": _vm.selectionChange,
                    "current-change": _vm.selectionChange,
                    "row-click": _vm.handleRowClick
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "index",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return _c("div", {}, [
                          _vm._v(" " + _vm._s($index + 1) + " ")
                        ])
                      }
                    },
                    {
                      key: "isEnabled",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", { staticClass: "is-enabled" }, [
                          _c("div", {
                            style: {
                              background: row.isEnabled ? "#24CAD7" : "#FFCC78"
                            }
                          }),
                          _c("div", [
                            _vm._v(_vm._s(row.isEnabled ? "启用" : "停用"))
                          ])
                        ])
                      }
                    },
                    {
                      key: "warehouseBatchNumber",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _c("span", [
                            _vm._v(_vm._s(row.warehouseBatchNumber || "-"))
                          ])
                        ])
                      }
                    }
                  ])
                })
              ],
              1
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }