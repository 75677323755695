<template>
  <el-select
    v-model="keyword"
    v-load-more="load"
    filterable
    remote
    reserve-keyword
    clearable
    placeholder="物料编码/名称/规格"
    :filter-method="filterMethod"
    :popper-append-to-body="false"
    popper-class="optionsContent"
    value-key="id"
    :loading="loading"
    v-bind="$attrs"
    v-on="$listeners"
    @change="changeValue"
    @focus="focusEvent"
  >
    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item">
      <div style="padding-top: 5px">
        <div>
          <b>{{ item.code }}</b>
        </div>
        <div class="mdetails">
          <span>名称：{{ item.name }}</span>
          <span style="margin-left: 20px">规格：{{ item.specifications }}</span>
        </div>
      </div>
    </el-option>
  </el-select>
</template>

<script>
import materialsApi from '@/api/information/materials'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'

export default {
  name: 'SelectMultipleMaterialsNew',
  directives: {
    loadMore: {
      inserted: function(el, binding) {
        const selectWrapperDom = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
        selectWrapperDom.addEventListener('scroll', function() {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight
          if (condition) {
            binding.value()
          }
        })
      }
    }
  },
  data() {
    return {
      materialsSource: '',
      keyword: '',
      options: [],
      currentValue: '',
      loading: false,
      pageSize: 30,
      currentPage: 1,
      canMore: true,
      searchKey: ''
    }
  },
  mounted() {},
  methods: {
    async getList() {
      this.loading = true
      const res = await materialsApi.getMaterialsListPage({
        page: this.currentPage,
        limit: this.pageSize,
        searchKeyword: this.searchKey,
        searchVal: Encrypt(JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isVirtualMaterials',
              fieldType: 'number',
              fieldValue: 0,
              operator: 'eq',
            }]
          ))
      })
      if (res && res.records.length > 0) {
        this.options = res.records
        this.materialsSource = res.records
      }
      if (res && res.records.length < this.pageSize) {
        this.canMore = false
      }
      this.loading = false
    },
    focusEvent() {
      this.searchKey = ''
      this.canMore = true
      this.currentPage = 1
      this.getList()
    },
    changeValue(val) {
      this.$emit('selectChange', 'code', val)
      this.$emit('typeChange')
    },
    load() {
      if (!this.canMore) {
        return
      }
      this.currentPage++
      this.getList()
    },
    filterMethod(val) {
      if (val) {
        this.searchKey = val
        this.currentPage = 1
        this.options = this.materialsSource.filter(item => {
          if (
            !!~item.name.toLowerCase().indexOf(val.toLowerCase()) ||
            !!~item.code.toLowerCase().indexOf(val.toLowerCase()) ||
            !!~(item.specifications || '').toLowerCase().indexOf(val.toLowerCase())
          ) {
            return true
          }
        })
        this.getList()
      } else {
        this.options = this.materialsSource
      }
    }
  }
}
</script>

  <style lang="scss" scoped>
.input-with-select {
  width: 100%;

  .input-select {
    width: 105px !important;
  }
}
.mdetails {
  width: 380px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
::v-deep {
  .el-input-group {
    width: 100%;

    .el-input-group__prepend {
      width: 105px;
    }
  }
  .el-select-dropdown {
    max-width: 380px !important;
  }
  .optionsContent {
    .el-select-dropdown__item {
      min-width: 100%;
      display: inline-block;
      height: auto !important;
    }
  }
}
</style>
