<template>
  <div class="gantt-leftbar">
    <div
      class="gantt-leftbar-item gantt-block-top-space"
      :style="{ height: topSpace + 'px' }"
    />
    <div
      v-for="(data, index) in showDatas"
      :key="dataKey ? data[dataKey] : index"
      class="gantt-leftbar-item"
      :style="{
        background: currentIdxs === data.id ? 'rgba(230, 232, 239, 0.3)' : '#fff',
        height: currentGanttBlocksHeights[data.id] || `${cellHeight}px`
      }"
      @click="selectBge(data)"
    >
      <slot :data="data">
        <div class="gantt-leftbar-defalutItem">need slot</div>
      </slot>
    </div>
  </div>
</template>

<script>
import dr from '../dynamic-render.js'
export default {
  name: 'LeftBar',
  mixins: [dr],
  props: {
    dataKey: String,
    datas: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentIdxs: undefined,
      currentName: ''
    }
  },
  computed: {
    currentGanttBlocksHeights() {
      return this.$store.state.user.currentGanttBlocksHeights
    },
    currentSelectIdx() {
      return this.$store.state.user.currentSelectIdx
    }
  },
  watch: {
    showDatas(val) {
      if (val && val.length > 0) {
        const idx = this.currentSelectIdx ? this.currentSelectIdx.id : this.currentIdx
        const data = val.length > 0 ? val.filter(item => item.id === idx) : []
        this.currentIdxs = data.length > 0 ? data[0].id : val[0].id
        this.currentName = data.length > 0 ? data[0].name : val[0].name
        this.$store.dispatch('user/currentSelectIdx', { id: this.currentIdxs, name: this.currentName })
        this.$emit('selectId', this.currentIdxs)
      }
    }
  },
  methods: {
    selectBge(item) {
      this.currentIdxs = item.id
      this.currentName = item.name
      this.$store.dispatch('user/currentSelectIdx', { id: this.currentIdxs, name: this.currentName })
      this.$emit('selectId', this.currentIdxs)
    }
  }

}
</script>
