<template>
  <div :id="commonIds" style="display: none">
    <div v-for="(item, index) in codes" :key="index" style="padding-top: 10px;display:inline-flex;justify-content:flex-start;">
      <div style="width:100px;">
        <VueQr :correct-level="3" :auto-color="false" color-dark="#000000" :text="item.batchNumber? item.batchNumber : item.code" :size="120" :margin="0" />
        <div style="padding-top: 14px">{{ item.batchNumber || item.code}}</div>
      </div>
      <div style="margin-left: 26px;text-align: left">
        <div style="font-size: 18px;">物料名称：{{item.materialsName || item.name}}</div>
        <div style="font-size: 18px;">物料编码：{{item.materialsCode || item.code}}</div>
        <div style="font-size: 18px;">物料规格：{{item.materialsSpecifications || item.specifications}}</div>
        <div v-if="item.materialsCode !== item.batchNumber && !item.code" style="font-size: 18px;">生产日期：{{times}}</div>
        <div v-if="item.memberCode === 'bbelc'" style="font-size: 18px;">工厂名称：上海工厂</div>
        <div v-if="item.memberCode === 'bbwz'" style="font-size: 18px;">工厂名称：温州工厂</div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
// import JsBarcode from 'jsbarcode'
import VueQr from 'vue-qr'
export default {
  name: 'PrintCode',
  components: { VueQr },
  props: {
    commonId: {
      type: String,
      default: 'print'
    }
  },
  data() {
    return {
      codes: [],
      commonIds: 'print',
      times: dayjs().format('YYYY-MM-DD')
    }
  },
  watch: {
    commonId(val) {
      this.commonIds = val
    }
  },
  methods: {
    print(code) {
      this.codes = [code]
      this.printIframe()
    },
    batchPrint(codes) {
      this.codes = codes
      this.printIframe()
    },
    printIframe() {
      setTimeout(() => {
        const printHtml = document.getElementById(`${this.commonIds}`).innerHTML
        const new_iframe = document.createElement('IFRAME')
        let doc = null
        new_iframe.setAttribute('style', 'width:0px;height:0px;position:absolute;left:-2000px;top:-2000px;')
        new_iframe.setAttribute('align', 'center')
        document.body.appendChild(new_iframe)
        doc = new_iframe.contentWindow.document
        doc.write('<div style="width:100%;height:auto;margin:0 auto;"align="center">' + printHtml + '</div>')
        // doc.body.style.zoom = '0.8'
        doc.close()
        new_iframe.contentWindow.focus()
        new_iframe.contentWindow.print()
      }, 0)
    }
  }
}
</script>

<style scoped></style>
