var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "equipments-class" },
    [
      _c("div", { staticClass: "top" }, [
        _c("div", [_vm._v("设备类")]),
        !_vm.view
          ? _c(
              "div",
              {
                staticClass: "add-materials-class",
                on: {
                  click: function($event) {
                    return _vm.add()
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-circle-plus" })]
            )
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "tree" },
        [
          _c("Tree", {
            ref: "zTrees",
            attrs: { view: _vm.view, nodes: _vm.nodes },
            on: { command: _vm.command, nodeClick: _vm.nodeClick }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.currentType },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuild",
              attrs: { "form-data": _vm.formData, "form-list": _vm.formList }
            },
            [
              _c("div", { attrs: { slot: "config" }, slot: "config" }, [
                _c(
                  "div",
                  [
                    _c("MTable", {
                      ref: "mTable",
                      attrs: {
                        columns: _vm.columns,
                        height: 200,
                        data: _vm.configData,
                        showPage: false
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "index",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        },
                        {
                          key: "configurationInstruction",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "div",
                              {},
                              [
                                _c("el-input", {
                                  model: {
                                    value: row.configurationInstruction,
                                    callback: function($$v) {
                                      _vm.$set(
                                        row,
                                        "configurationInstruction",
                                        $$v
                                      )
                                    },
                                    expression: "row.configurationInstruction"
                                  }
                                })
                              ],
                              1
                            )
                          }
                        },
                        {
                          key: "action",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c(
                              "div",
                              {},
                              [
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.delConfig($index)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ]
                              ],
                              2
                            )
                          }
                        }
                      ])
                    }),
                    _c(
                      "el-button",
                      {
                        staticClass: "add-btn",
                        attrs: { type: "primary" },
                        on: { click: _vm.addConfig }
                      },
                      [_vm._v("新增分类配置")]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }