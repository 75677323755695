<template>
  <section class="app-main">
    <div :class="routerClassName">
      <!-- <transition name="fade-transform" mode="out-in">-->
      <keep-alive :include="cachedViews" :exclude="excludeList">
        <router-view :key="key" />
      </keep-alive>
      <!-- <router-view /> -->
      <!--      </transition>-->
    </div>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    cachedViews() {
      // 兼容组件命名不规范
      const PascalCase = this.$store.state.tagsView.cachedViews.map(name => name.slice(0, 1).toUpperCase() + name.slice(1))
      return [...this.$store.state.tagsView.cachedViews, ...PascalCase]
    },
    excludeList() {
      return ['allBoardGroup', 'equipmentParams', 'basePrint', 'equipmentAccountDetail',
        'planAdd', 'materialsDetail', 'procedureDetail', 'processDetail', 'inspectionPlanDetail',
        'maintenancePlanDetail', 'qualityInspectionPlanDetail', 'systemParams', 'baseSetting',
        'warehouseAssignDetail', 'materialsSettingList', 'editBom','QualityPlanDetail','bomDetail','mbomDetail','changeToMbom']
    },
    key() {
      return this.$route.path
      // return this.$route.path + Math.random()
    },
    routerClassName() {
      return this.$route.meta.nb || this.$route.meta.nbc ? 'router-wrapper-default' : 'router-wrapper-default set-bg'
    },
    isHiddenLogo() {
      return localStorage.getItem('HL') === '1'
    }
  }
}
</script>

<style scoped>
.app-main {
  /*50 = navbar  */
  height: calc(100vh - 48px );
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #F0F2F5;
  padding: 12px;
}

.set-bg {
  background: #ffffff;
}

.router-wrapper-default {
  border-radius: 4px;
  overflow: auto;
  height: 100%;
}

.fixed-header + .app-main {
  padding-top: 84px;
}

</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
