var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.infoTipImgSrc
    ? _c("div", { attrs: { id: "naBox" } }, [
        _c("div", { staticClass: "top" }, [
          _c("img", {
            attrs: {
              src:
                "http://mubyte.cn/wp-content/uploads/2021/11/2021110409511844.png",
              alt: ""
            }
          }),
          _c("img", {
            attrs: {
              id: "cosButton",
              src: _vm.open
                ? "http://mubyte.cn/wp-content/uploads/2021/10/2021102205064353.png"
                : "http://mubyte.cn/wp-content/uploads/2021/10/2021102205064484.png"
            },
            on: {
              click: function($event) {
                _vm.open = !_vm.open
              }
            }
          })
        ]),
        _c(
          "div",
          {
            class: _vm.open ? "bottom" : "bottom close",
            attrs: { id: "infoTip" }
          },
          [
            _c("img", {
              attrs: { id: "infoTipImg", src: _vm.infoTipImgSrc, alt: "" }
            })
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }