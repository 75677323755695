import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/basicData/private/equipmentClass/'
const baseUrl2 = 'api/web/v1/field/facility/private/facilityClass/'

export default {
  // 获取设备类-不分页
  getEquipmentClassList(data) {
    return request.get(baseUrl + 'getEquipmentClassList', data)
  },
  // 获取售后设备类-不分页
  getAfterSaleEquipmentClassList(data) {
    return request.get(baseUrl2 + 'getFacilityClassList', data)
  },
  // 获取设备类-分页
  getEquipmentClassListPage(data) {
    return request.get(baseUrl + 'getEquipmentClassListPage', data)
  },
  // 新增设备类
  addEquipmentClass(data) {
    return request.post(baseUrl + 'addEquipmentClass', data)
  },
  // 新增设备类
  copyEquipmentClass(data) {
    return request.post(baseUrl + 'addEquipmentClass', data)
  },
  // 修改设备类
  modifyEquipmentClass(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除设备类
  delEquipmentClass(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  // 新增售后设备类
  addAfterSaleEquipmentClass(data) {
    return request.post(baseUrl2 + 'addFacilityClass', data)
  },
  // 新增售后设备类
  copyAfterSaleEquipmentClass(data) {
    return request.post(baseUrl2 + 'addFacilityClass', data)
  },
  // 修改售后设备类
  modifyAfterSaleEquipmentClass(data) {
    return request.put(baseUrl2 + 'updateOne', data)
  },
  // 删除售后设备类
  delAfterSaleEquipmentClass(data) {
    return request.del(baseUrl2 + 'deleteOne', data)
  },
}
