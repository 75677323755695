import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/basicData/private/equipmentClass/'
const baseUrl2 = 'api/web/v1/field/facility/private/facilityClass/'

export default {
  // 获取设备类-不分页
  getEquipmentClassList(data) {
    return request.get(baseUrl + 'getEquipmentClassList', data)
  },
  // 获取售后设备类-不分页
  getAfterSaleEquipmentClassList(data) {
    return request.get(baseUrl2 + 'getFacilityClassList', data)
  }
}
