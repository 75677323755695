import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/invoices/private/facilitySpotCheckPlanItemMapping/'

export default {
  // 新增点检项目
  addFacilitySpotCheckPlanItem(data) {
    return request.post(baseUrl + 'addFacilitySpotCheckPlanItemMapping', data)
  },
  // 修改点检项目
  modifyFacilitySpotCheckPlanItem(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除点检项目
  delFacilitySpotCheckPlanItem(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 获取点检工单列表
  getBatchPlanListPage(data) {
    return request.get('api/web/v1/field/facility/invoices/private/facilitySpotCheckPlan/getFacilitySpotCheckPlanListPage', data)
  },
  // 批量查询计划工单点检项目
  getSpockPlanList(data) {
    return request.post('api/web/v1/field/facility/invoices/private/facilityInspectionWorkOrder/batchQueryPlan', data)
  },
  // 获取点检计划模板-分页
  getPlanTemplateListPage(data) {
    return request.get('api/web/v1/field/facility/invoices/private/facilityPlanMaintenanceTemplate/getFacilityPlanMaintenanceTemplateListPage', data)
  }
}
