import request from '@/utils/requestForAsync'
const baseUrlDetail = 'api/web/v1/warehouse/kiki/private/purchaseRequestDetail/'
const baseUrl = 'api/web/v1/warehouse/kiki/private/purchaseRequestBill/'

export default {
  // 获取采购申请单详情-不分页
  getPurchaseList(data) {
    return request.get(baseUrlDetail + 'getPurchaseRequestDetailList', data)
  },
  // 获取采购申请单详情-分页
  getPurchaseListPage(data) {
    return request.get(baseUrlDetail + 'getPurchaseRequestDetailListPage', data)
  },
  // 新增采购申请单
  addPurchase(data) {
    return request.post(baseUrl + 'addPurchaseRequestBill', data)
  },
  // 修改采购申请单
  modifyPurchase(data) {
    return request.put(baseUrlDetail + 'updateOne', data)
  },
  // 删除采购申请
  delPurchase(data) {
    return request.del(baseUrlDetail + 'deleteOne', data)
  },
  // 批量删除采购申请
  delBatchPurchase(data) {
    return request.del(baseUrlDetail + 'deleteBatch', data)
  },
  // 确认采购申请
  confirmPurchase(data) {
    return request.get(baseUrlDetail + 'confirm', data)
  },
  // 反确认采购申请
  unConfirmPurchase(data) {
    return request.get(baseUrlDetail + 'unConfirm', data)
  },
  // 获取物料库存汇总列表
  getMaterialsInventoryList(data) {
    return request.get('api/web/v1/warehouse/kiki/private/warehouseMaterialsBatKiki/getMaterialsInventoryList', data)
  },
  // 驳回申请
  cancelPurchase(data) {
    return request.get(baseUrlDetail + 'cancel', data)
  }
}
