
export const sColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'status',
    label: '生产进度',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status',
    width: 120
  },
  {
    prop: 'quantity',
    label: '工单数量',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'reportWorkQuantity',
    label: '报工数量',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'produceWorkOrderNumber',
    label: '生产工单号',
    hiddenSearch: true,
    sortable: false,
    width: 200
  },
  {
    prop: 'batchNumber',
    label: '生产批次码',
    hiddenSearch: true,
    sortable: false,
    width: 220
  },
  {
    prop: 'currentProcedure',
    label: '工单当前所处工序',
    hiddenSearch: true,
    sortable: false,
    slotName: 'currentProcedure'
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    width: 400
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'deliveryTime',
    label: '交期',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'processFlowName',
    label: '工艺',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'areaName',
    label: '产线',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'planStartTime',
    label: '计划开始时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'planEndTime',
    label: '计划结束时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'actualStartTime',
    label: '实际开始时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'actualEndTime',
    label: '实际结束时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'priority',
    label: '计划优先级',
    hiddenSearch: true,
    sortable: false,
    slotName: 'priority'
  },
  {
    prop: 'produceOrderNumber',
    label: '所属生产订单',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'source',
    label: '工单来源',
    hiddenSearch: true,
    sortable: false,
    slotName: 'source'
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'creator',
    label: '创建人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '创建时间',
    hiddenSearch: true,
    sortable: false
  }
]
export const columns = [
  {
    type: 'selection',
    fixed: 'left',
    width: 120,
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'status',
    label: '生产进度',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status',
    width: 120
  },
  {
    prop: 'quantity',
    label: '工单数量',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'reportWorkQuantity',
    label: '报工数量',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'produceWorkOrderNumber',
    label: '生产工单号',
    hiddenSearch: true,
    sortable: false,
    width: 200
  },
  {
    prop: 'batchNumber',
    label: '生产批次码',
    hiddenSearch: true,
    sortable: false,
    width: 220
  },
  {
    prop: 'currentProcedure',
    label: '工单当前所处工序',
    hiddenSearch: true,
    sortable: false,
    slotName: 'currentProcedure'
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    width: 400
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'deliveryTime',
    label: '交期',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'processFlowName',
    label: '工艺',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'areaName',
    label: '产线',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'planStartTime',
    label: '计划开始时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'planEndTime',
    label: '计划结束时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'actualStartTime',
    label: '实际开始时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'actualEndTime',
    label: '实际结束时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'priority',
    label: '计划优先级',
    hiddenSearch: true,
    sortable: false,
    slotName: 'priority'
  },
  {
    prop: 'produceOrderNumber',
    label: '所属生产订单',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'source',
    label: '工单来源',
    hiddenSearch: true,
    sortable: false,
    slotName: 'source'
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'creator',
    label: '创建人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '创建时间',
    hiddenSearch: true,
    sortable: false
  }
]
