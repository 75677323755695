import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/invoices/private/facilityInspectionWorkOrder/'

export default {
  // 获取点检工单列表（不分页）
  getSpotCheckList(data) {
    return request.get(baseUrl + 'getFacilityInspectionWorkOrderList', data)
  },
  // 获取点检工单列表（分页）
  getSpotCheckListPage(data) {
    return request.get(baseUrl + 'getFacilityInspectionWorkOrderListPage', data)
  },
  // 新建设备点检工单
  addSpotCheck(data) {
    return request.post(baseUrl + 'addFacilityInspectionWorkOrder', data)
  },
  // 修改点检工单
  modifySpotCheck(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除设备点检工单
  delSpotCheck(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 批量删除设备点检工单
  delBatchSpotCheck(data) {
    return request.post(baseUrl + 'batchDelete', data)
  },
  // 获取设备点检工单-详情
  getSpotCheckByDetail(id) {
    return request.get(baseUrl + 'getFacilityInspectionWorkOrderDetail', { id })
  },
  // 导出设备点检工单-列表
  exportSpotCheck(id) {
    return request.exportGet(baseUrl + 'export', { id })
  },
  // 启用设备点检工单
  enableSpotCheck(id) {
    return request.get(baseUrl + `enable?id=${id}`)
  },
  // 禁用设备点检工单
  disableSpotCheck(id) {
    return request.get(baseUrl + `disable?id=${id}`)
  },
  // 批量跳过点检工单
  batchSkippingSpotCheck(data) {
    return request.post(baseUrl + 'batchSkipping', data)
  },
  // 跳过点检工单
  skippingSpotCheck(data) {
    return request.get(baseUrl + 'skipping', data)
  },
  // 工单保存校验服务时间
  checkTimeInfo(data) {
    return request.get('api/web/v1/field/facility/invoices/private/facilityRepairWordOrder/checkTime', data)
  }
}
