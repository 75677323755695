<template>
  <MDialog v-model="modalShow" width="1000px" :height="splitHeight"  :append-to-body="true" :title="title" @onOk="submitForm" @fullScreen="fullScreenChange">
    <div class="materials-info">
      <div class="top">
      <SearchForms
        ref="searchForms"
        :form-option="formOptions"
        @resetData="resetDataSearchForm"
        @searchData="searchTable"
      />
    </div>
          <FunctionHeader
            v-model="functionHeader.searchVal"
            search-title="请输入生产订单号"
            class="materials-head"
            :tags="functionHeader.tags"
            :columns-setting="false"
            @search="resetData();$refs.mTable.setTableData(true)"
          >
            <div v-if="!single" slot="all">
              <span style="margin: 0 20px;">已选中 <span style="color: #000">{{ getSelectionDataLength }}</span> 项</span>
              <el-button type="text" @click="resetData">清空</el-button>
            </div>
          </FunctionHeader>
          <MTable
            ref="mTable"
            :highlight-current-row="single"
            :columns="columns"
            :height="height"
            :set-data-method="getTableData"
            :columns-setting="false"
            @selection-change="selectionChange"
            @current-change="selectionChange"
            @row-click="handleRowClick"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="status" slot-scope="{ row }">{{ row.status===0?'未计划':row.status===1?'全部计划未完工':row.status===2?'已完工':row.status===3?'全部计划未完工':'--' }}</div>

            
          </MTable>
        </div>
  </MDialog>
</template>

<script>
import MaterialsClass from '@/components/MaterialsClass/MaterialsClass'
import Split from '@/components/Split/Split'
import SearchForm from '@/components/SearchForm/SearchForm'
import FunctionHeader from '@/components/FunctionHeader'
import { columns, ivColumns, sColumns } from './columns'
import { status } from '@/config/options.config'
import { getUUid } from '@/utils'
import api from '@/api/information/materials'
import inventoryApi from '@/api/warehouse/inventory'
import { Encrypt } from '@/utils/sercet'
import { searchFormListAll } from '@/views/warehouse/inventory/form-list'
import maApi from '@/api/information/materials-attribute'
import warehouseApi from '@/api/information/warehouse/model'
import ordersApi from '@/api/production/orders'
import SearchForms from "./search-forms";

export default {
  name: 'SelectMaterials',
  components: { MaterialsClass, Split, SearchForms, FunctionHeader },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    isProducible: {
      type: Boolean,
      default: false
    },
    conditions: {
      type: Array,
      default: () => ([])
    },
    selectMaterials: {
      type: Array,
      default: () => ([])
    },
    from: {
      type: String,
      default: 'materials' // inventory
    },
    isAdd: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '选择生产订单'
    },
    containerClassId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      modalShow: false,
      split: {
        offset: 0
      },
      columns,
      functionHeader: {
        searchVal: '',
        tags: []
      },
      materialsSearchFormList: [
        {
          key: 'isEnabled',
          tagName: 'el-select',
          colSpan: 6,
          props: {
            placeholder: '请选择物料状态'
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: status
            }
          ]
        },
        {
          colSpan: 6,
          key: 'materialsTypeId',
          tagName: 'el-select',
          defaultValue: 1,
          props: {
            placeholder: '请选择物料类型'
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: []
            }
          ]
        }
      ],
      searchFormList: [],
      searchFormData: {},
      searchFormatData: [],
      formOptions: {
        salesOrderCodes: ''
      },
      selectionData: [],
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      fullScreen: false
    }
  },
  computed: {
    height() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 83 - 77 : this.$store.state.app.contentHeight - 289 - 160 - 50
    },
    splitHeight() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 10 : this.$store.state.app.contentHeight - 289 - 45
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  watch: {
    visible(val) {
      this.modalShow = val
      this.columns = this.single ? sColumns : columns
      if (val) {
        this.echoData()
      }
    },
    modalShow(val) {
      if (!val) {
        this.searchFormData = {}
        this.searchFormatData = []
        this.functionHeader.searchVal = ''
        this.$emit('update:visible', false)
      }
    },
    from() {
      this.init()
    }
  },
  mounted() {
    this.modalShow = this.visible
    this.functionHeader.tags = []
    if (this.single) this.columns = sColumns
    this.init()
    if (this.modalShow) {
      this.echoData()
    }
  },
  methods: {
    // 重置
    resetDataSearchForm() {
      this.searchFormatData = []
      this.$refs.mTable.setTableData(true);
      this.resetData()
    },
    resetData() {
      console.log('selec:',this.selectionData)
      this.selectionData.forEach(item => {
        this.$refs.mTable.tableRefs().toggleRowSelection(item, false)

      })
      this.selectionData=[]

      // this.selectionData = this.selectionData.filter((item) => item.selectable)
    },
    echoData() {
      this.$nextTick(() => {
        // 数据回显
        this.materialsClassId = ''
        this.selectionData = this._.cloneDeep(this.selectMaterials)
        if (this.single) {
          this.$refs.mTable.tableRefs().setCurrentRow(this.selectionData[0])
        } else {
          this.selectionData.forEach(item => {
            this.$refs.mTable.tableRefs().toggleRowSelection(item, true)
          })
        }
      })
    },
    updateTable() {
      this.$refs.mTable.setTableData()
    },
    // 新增
    add() {
      this.$emit('addMaterial')
    },
    // 选中行复选框也会选中
    handleRowClick(row) {
      if (!row.selectable) {
        this.$refs.mTable.tableRefs().toggleRowSelection(row)
      }
    },
    fullScreenChange(val) {
      this.fullScreen = val
    },
    init() {
      this.searchFormatData = []
      if (this.from === 'materials') {
        this.searchFormList = this.materialsSearchFormList
        // this.getMaterialsTypeList()
        this.searchFormData = {}
        this.columns = columns
      }
    },
    // // 获取物料类型
    // async getMaterialsTypeList() {
    //   const res = await maApi.getMaterialsTypeList(this.defaultSearch)
    //   if (res) {
    //     this.searchFormList[1].children[0].options = res
    //   }
    // },
    // async getWarehouseList() {
    //   const res = await warehouseApi.getRepositoryModelList({
    //     searchVal: Encrypt(JSON.stringify([{
    //       id: getUUid(),
    //       fieldName: 'type',
    //       fieldType: 'number',
    //       fieldValue: 0,
    //       operator: 'eq'
    //     }]))
    //   })
    //   if (res) {
    //     this.searchFormList[0].children[0].options = res
    //   }
    // },
    selectionChange(data) {
      if (this.single) {
        this.selectionData = []
        this.selectionData.push(data[data.length - 1])
        return false
      }
      if (Array.isArray(data)) {
        this.selectionData = data
      } else if (!data.selectable) {
        this.selectionData = []
        this.selectionData.push(data)
      }
    },
    setMaterialsClassId(id) {
      this.materialsClassId = id
      // this.selectionData = []
      this.$refs.mTable.setTableData(true)
    },
   
    // 查询物料
    searchTable(data) {
      this.searchFormatData = data
      console.log('data:',data)
      this.resetData()

      this.$refs.mTable.setTableData()
    },
     // 订单
    //  async getTableData() {
    //     const res = await workApi.getOrderpage()
    //     if (res) {
    //       this.formOptions.orderOptions = res
    //       if (this.$refs.searchForms !== undefined) {
    //         this.$refs.searchForms.orderNumbers = res
    //       }
    //     }
    //   },
    // 获取物料数据
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      let res
      if (this.from === 'materials') {
        // if (this.materialsClassId) {
        //   search.push({
        //     id: getUUid(),
        //     fieldName: 'materialsClassId',
        //     fieldType: 'number',
        //     fieldValue: this.materialsClassId,
        //     operator: 'eq'
        //   })
        // }
        // if (this.isProducible) {
        //   search.push({
        //     id: getUUid(),
        //     fieldName: 'isProducible',
        //     fieldType: 'number',
        //     fieldValue: 1,
        //     operator: 'eq'
        //   })
        // }
        res = await ordersApi.getOrderpage({
          page: page.currentPage,
          limit: page.pageSize,
          sort: order,
          searchKeyword: this.functionHeader.searchVal,
          searchVal: Encrypt(JSON.stringify([
            ...this.searchFormatData
          ]
          ))
        })
      }
    

      if (this.selectMaterials) {
        this.selectMaterials.forEach((item) => {
          res.records.forEach(t => {
            if (item.selectable && t.id === item.id) {
              t.selectable = true
            }
          })
        })
      }
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    submitForm(callback) {
      if (this.selectionData.length === 0) {
        this.$message.info('请至少选择一条订单')
        callback()
        return
      }
      console.log('selectionData:',this.selectionData)
      this.$emit('submitForm', this.selectionData,callback)
      // callback()
    }
  }
}
</script>

<style lang="scss" scoped>
.materials-info {
  border:1px solid #dde3eb;
  padding: 10px;
}
.materials-head{
  padding:0 0 10px 0;
}
.top{
  border-bottom: 1px solid #dde3eb;
    margin-bottom: 20px;
}
</style>
