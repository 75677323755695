var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "materials-class" },
    [
      _c("div", { staticClass: "top" }, [
        _c("div", [_vm._v("工序分类")]),
        !_vm.view
          ? _c(
              "div",
              {
                staticClass: "add-materials-class",
                on: {
                  click: function($event) {
                    return _vm.add()
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-circle-plus" })]
            )
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "tree" },
        [
          _c("Tree", {
            ref: "zTrees",
            attrs: { view: _vm.view, nodes: _vm.nodes },
            on: { command: _vm.command, nodeClick: _vm.nodeClick }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.currentType },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuild",
            attrs: { "form-data": _vm.formData, "form-list": _vm.formList }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }