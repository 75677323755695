import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/tool/private/tool/'

export default {
  // 工具列表
  getToolLedgerListPage(data) {
    return request.get(baseUrl + 'getToolListPage', data)
  },
  // 获取工具详情
  getToolLedgerDetail(data) {
    return request.get(`${baseUrl}getToolDetail`, data)
  },
  // 工具新增
  add(data) {
    return request.post(baseUrl + 'addTool', data)
  },
  // 工具编辑
  edit(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 工具删除
  del(data) {
    return request.del(baseUrl + 'deleteOne', data)
  }
}
