<template>
  <MDialog v-model="modalShow" width="1000px" :append-to-body="true" title="选择备件" @onOk="submitForm" @fullScreen="fullScreenChange">
    <Split :offset.sync="split.offset" :height="splitHeight">
      <template slot="left">
        <PartClass :view="true" @nodeClick="setEquipmentClassId" />
      </template>
      <template slot="right">
        <div class="materials-info">
          <FunctionHeader
            v-model="functionHeader.searchVal"
            search-title="请输入备件编码/名称/规格"
            class="materials-head"
            :tags="functionHeader.tags"
            :columns-setting="false"
            @search="$refs.mTable.setTableData(true)"
          />
          <MTable
            ref="mTable"
            :highlight-current-row="single"
            :columns="columns"
            :height="height"
            :set-data-method="getTableData"
            :columns-setting="false"
            @selection-change="selectionChange"
            @current-change="selectionChange"
            @row-click="handleRowClick"
          >
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
              <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
              <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
            </div>
            <div slot="drawing" slot-scope="{ row }">
              <el-image
                v-if="row.drawing"
                :src="row.drawing"
                class="drawing-img"
                :preview-src-list="fixImg(row.drawing)"
              />
            </div>
          </MTable>
        </div>
      </template>
    </Split>
  </MDialog>
</template>

<script>
import PartClass from '@/components/PartClass/PartClass'
import Split from '@/components/Split/Split'
import SearchForm from '@/components/SearchForm/SearchForm'
import FunctionHeader from '@/components/FunctionHeader'
import { columns, sColumns } from './columns'
import { getUUid } from '@/utils'
import PartApi from '@/api/equipment/spare-part'
import inventoryApi from '@/api/warehouse/inventory'
import { Encrypt } from '@/utils/sercet'

export default {
  name: 'SelectMaterials',
  components: { Split, SearchForm, FunctionHeader, PartClass },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    isProducible: {
      type: Boolean,
      default: false
    },
    conditions: {
      type: Array,
      default: () => ([])
    },
    selectMaterials: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      modalShow: false,
      split: {
        offset: 20
      },
      columns,
      functionHeader: {
        searchVal: '',
        tags: []
      },
      searchFormData: {},
      searchFormatData: [],
      formOptions: {
        materialsClassList: []
      },
      selectionData: [],
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      fullScreen: false,
      sparePartClassIds: ''
    }
  },
  computed: {
    height() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 83 - 57 : this.$store.state.app.contentHeight - 289 - 160 - 10
    },
    splitHeight() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 10 : this.$store.state.app.contentHeight - 289 - 45
    }
  },
  watch: {
    visible(val) {
      this.modalShow = val
      if (this.single) this.columns = sColumns
      if (val) {
        this.echoData()
      }
    },
    modalShow(val) {
      if (!val) {
        this.searchFormData = {}
        this.searchFormatData = []
        this.functionHeader.searchVal = ''
        this.$emit('update:visible', false)
      }
    },
    from() {
      this.init()
    }
  },
  mounted() {
    this.modalShow = this.visible
    if (this.single) this.columns = sColumns
    this.init()
    if (this.modalShow) {
      this.echoData()
    }
  },
  methods: {
    echoData() {
      this.$nextTick(() => {
        // 数据回显
        this.sparePartClassIds = ''
        this.selectionData = this._.cloneDeep(this.selectMaterials)
        if (this.single) {
          this.$refs.mTable.tableRefs().setCurrentRow(this.selectionData[0])
        } else {
          this.selectionData.forEach(item => {
            this.$refs.mTable.tableRefs().toggleRowSelection(item, true)
          })
        }
      })
    },
    fixImg(img) {
      return [img]
    },
    updateTable() {
      this.$refs.mTable.setTableData()
    },
    // 新增
    add() {
      this.$emit('addMaterial')
    },
    // 选中行复选框也会选中
    handleRowClick(row) {
      this.$refs.mTable.tableRefs().toggleRowSelection(row)
    },
    fullScreenChange(val) {
      this.fullScreen = val
    },
    init() {
      this.searchFormatData = []
      this.searchFormData = {}
      this.columns = columns
    },
    selectionChange(data) {
      if (Array.isArray(data)) {
        this.selectionData = data
      } else {
        this.selectionData = []
        this.selectionData.push(data)
      }
    },
    setEquipmentClassId(val) {
      this.sparePartClassIds = val
      this.$refs.mTable.setTableData(true)
    },
    // 获取备料数据
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      const res = await PartApi.getSparePartListPage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        sparePartClassIds: this.sparePartClassIds,
        searchKeyword: this.functionHeader.searchVal,
        searchVal: Encrypt(JSON.stringify([...this.searchFormatData, ...this.conditions]))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    submitForm(callback) {
      callback()
      if (this.selectionData.length === 0) {
        this.$message.info('请至少选择一条备件')
        return
      }
      this.$emit('submitForm', this.selectionData)
    }
  }
}
</script>

<style lang="scss" scoped>
.materials-info {
  padding: 10px;
}
.materials-head{
  padding:0 0 10px 0;
}
  .drawing-img {
    width: 50px;
    height: 48px;
    display: block;
    cursor: pointer;
    color: #fff;
  }
</style>
