import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/inspection/private/inspectionProject/'

export default {
  // 获取检验项目不分页
  getInspectionProjectList(data) {
    return request.get(baseUrl + 'getInspectionProjectList', data)
  },
  // 获取检验项目-分页
  getInspectionProjectListPage(data) {
    return request.get(baseUrl + 'getInspectionProjectListPage', data)
  },
  // 新增检验项目
  addInspectionProject(data) {
    return request.post(baseUrl + 'addInspectionProject', data)
  },
  // 新增检验项目
  copyInspectionProject(data) {
    return request.post(baseUrl + 'addInspectionProject', data)
  },
  // 修改检验项目
  modifyInspectionProject(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除检验项目
  delInspectionProject(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  // 新增检验组并绑定检验项
  addProjectGroupAndBindItems(data) {
    return request.post(baseUrl + 'addInspectionProjectAndEntryList', data)
  },
  checkSameProjectName(params) {
    return request.get(baseUrl + 'checkSameProjectName', params)
  }
}
