import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/warehouse/private/pallet/'

export default {
  // 获取仓托盘不分页
  getPalletList(data) {
    return request.get(baseUrl + 'getPalletList', data)
  },
  // 获取托盘-分页
  getPalletListPage(data) {
    return request.get(baseUrl + 'getPalletListPage', data)
  },
  // 新增托盘
  addPallet(data) {
    return request.post(baseUrl + 'addContainerClass', data)
  },
  copyPallet(data) {
    return request.post(baseUrl + 'addContainerClass', data)
  },
  // 修改托盘
  modifyPallet(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除托盘
  delPallet(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  batchCreatePallet(count) {
    return request.get(baseUrl + `getPalletCodeList?count=${count}`)
  },
  batchAddPallet(data) {
    return request.post(baseUrl + 'insertBatch', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  }
}
