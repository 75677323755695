var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "MDialog",
        {
          attrs: { width: "1100px", title: "选择工艺" },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.modalShow,
            callback: function($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "top flex-sbc",
              staticStyle: { "padding-bottom": "20px" }
            },
            [
              _c("span", [_vm._v("请选择工艺路线：")]),
              _c(
                "el-input",
                {
                  staticStyle: { width: "300px" },
                  attrs: { classname: "select-materials" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        slot: "prepend",
                        filterable: "",
                        placeholder: "请选择工艺路线"
                      },
                      on: { change: _vm.selectProcessChange },
                      slot: "prepend",
                      model: {
                        value: _vm.params.processId,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "processId", $$v)
                        },
                        expression: "params.processId"
                      }
                    },
                    _vm._l(_vm.formOptions.processList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-more" },
                    on: { click: _vm.selectProcess },
                    slot: "append"
                  })
                ],
                1
              ),
              _vm.isAddProcessFlow
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.processVisible = true
                        }
                      }
                    },
                    [_vm._v("新增工艺路线")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.params.processId
            ? _c("div", { staticClass: "process-detail-body flex-sb" }, [
                _c("div", { staticClass: "left" }, [
                  _c("div", { staticStyle: { border: "1px solid #dde3eb" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "history-version",
                        staticStyle: { "padding-right": "10px" }
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "20px", "margin-right": "5px" },
                          attrs: {
                            src: require("@/assets/information/process/历史版本@2x.png")
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "relative",
                              top: "2px",
                              "word-break": "keep-all"
                            }
                          },
                          [_vm._v(" 历史版本 ")]
                        )
                      ]
                    ),
                    _c(
                      "ul",
                      { staticClass: "version-wrapper" },
                      _vm._l(_vm.versionList, function(item) {
                        return _c(
                          "li",
                          {
                            key: item.id,
                            class:
                              _vm.currentVersionId === item.id ? "active" : "",
                            on: {
                              click: function($event) {
                                return _vm.viewVersion(item)
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "li-left" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "name",
                                  attrs: { title: item.version }
                                },
                                [
                                  _c("div", { staticClass: "ellipsis" }, [
                                    _vm._v(_vm._s(item.version))
                                  ]),
                                  item.isMain
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "54px",
                                          "margin-left": "10px"
                                        },
                                        attrs: {
                                          src: require("@/assets/information/process/主版本.png")
                                        }
                                      })
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "info ellipsis",
                                  attrs: {
                                    title:
                                      "「 " +
                                      item.creator +
                                      " 」上传于" +
                                      item.createTime
                                  }
                                },
                                [
                                  _vm._v(
                                    " 「 " +
                                      _vm._s(item.creator) +
                                      " 」上传于" +
                                      _vm._s(item.createTime) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ]),
                _c("div", { staticClass: "right" }, [
                  _c("div", { staticClass: "detail-card" }, [
                    _c("div", { staticClass: "card-tit" }, [
                      _vm._v("基本信息")
                    ]),
                    _c(
                      "div",
                      { staticClass: "card-bot" },
                      [
                        _c(
                          "el-row",
                          { staticClass: "view-list", attrs: { gutter: 24 } },
                          _vm._l(_vm.columns.base, function(item, index) {
                            return _c(
                              "el-col",
                              {
                                key: item.prop,
                                style: {
                                  marginBottom: index !== 6 ? "20px" : 0,
                                  display: "inline-flex"
                                },
                                attrs: { title: _vm.getValue(item), span: 8 }
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "word-break": "keep-all" } },
                                  [_vm._v(_vm._s(item.label) + "：")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: { "word-break": "break-all" }
                                  },
                                  [_vm._v(_vm._s(_vm.getValue(item)))]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "detail-card" }, [
                    _c("div", { staticClass: "card-tit" }, [
                      _vm._v("工艺路线图")
                    ]),
                    _c(
                      "div",
                      { staticClass: "card-bot process-card" },
                      [
                        _c("process-flow", {
                          ref: "processFlow",
                          attrs: {
                            "base-form": { form: _vm.baseFormData },
                            "read-only": true,
                            "process-flow-detail": _vm.processFlowVersion
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            : _vm._e()
        ]
      ),
      _c("select-process-flow-by-table", {
        attrs: {
          single: true,
          visible: _vm.showProcessFlow,
          "materials-id": _vm.materialsId
        },
        on: {
          "update:visible": function($event) {
            _vm.showProcessFlow = $event
          },
          submitForm: _vm.submitProcessFlow
        }
      }),
      _c(
        "MDialog",
        {
          attrs: { width: "80%", title: "新增工艺" },
          on: { onOk: _vm.submitProcessForm },
          model: {
            value: _vm.processVisible,
            callback: function($$v) {
              _vm.processVisible = $$v
            },
            expression: "processVisible"
          }
        },
        [_c("add-process-flow", { ref: "addProcessFlow" })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }