import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/statistics/private/projectWorkHoursStatistics/'

export default {
    // 获取业务部门下拉列表
    getBusinessList(params = {}) {
        return request.get('api/web/v1/authority/private/Organization/getSecondLevelList', params)
    },
     // 获取用户下拉列表
    getUserList(params = {}) {
        return request.get('api/web/v1/production/private/productionTask/getUserList', params)
    },
   // 获取项目下拉分页列表
    getProjectListPage(params = {}) {
        return request.get('api/web/v1/production/private/project/pullDownListPage', params)
    },
    // 数量统计
    getWorkHoursStatistics(data = {}){
        return request.post(baseUrl + 'workHoursStatistics', data)
    },
    // 项目其他工时类型占比
    getProjectOtherWorkHoursRatio(data){	
        return request.post(baseUrl + 'projectOtherWorkHoursRatio', data)
    },
    // 工单其他工时类型占比
    getWorkOrderOtherWorkHoursRatio(data){		
        return request.post(baseUrl + 'workOrderOtherWorkHoursRatio', data)
    },
    // 工时原因累计工时占比
    getWorkHoursReasonWorkHoursRatio(data){	
        return request.post(baseUrl + 'workHoursReasonWorkHoursRatio', data)
    },
    // 项目任务节点类型占比
    getProjectTaskNodeTypeWorkHoursRatio(data){	
        return request.post(baseUrl + 'projectTaskNodeTypeWorkHoursRatio', data)
    },
    // 工时原因工时趋势
    getWorkHoursReasonBarChart(data){		
        return request.post(baseUrl + 'workHoursReasonBarChart', data)
    },
    // 工单工时类型工时趋势
    getWorkOrderWorkHoursTypeBarChart(data){		
        return request.post(baseUrl + 'workOrderWorkHoursTypeBarChart', data)
    },		
    // 人员工时统计
    getUserWorkHoursStatisticsBarChart(data){		
    return request.post(baseUrl + 'userWorkHoursStatisticsBarChart', data)
},	
    // 人员工时统计分页列表
    getUserWorkHoursStatisticsListPage(data){			
    return request.post(baseUrl + 'userWorkHoursStatisticsListPage', data)
},	
    // 导出人员工时统计分页列表
    getExportUserWorkHoursStatisticsList(data){
        return request.post(baseUrl + 'exportUserWorkHoursStatisticsList', data)
    }	
}
