<template>
  <MDialog :value="show" :title="title" :show-footer="false" :append-to-body="appends" @close="visible = false">
    <slot />
    <MTable ref="mTable" :columns="columns" :height="height" :data="tableData" :show-page="false" :columns-setting="false">
      <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
      <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
        <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
        <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
      </div>
      <div slot="needUpload" slot-scope="{ row }" class="is-enabled">
        <div :style="{background: row.needUpload ? '#24CAD7' : '#FFCC78'}" />
        <div>{{ row.needUpload ? '是' : '否' }}</div>
      </div>
      <div slot="sketchMap" slot-scope="{ row }">
        <el-image
          v-for="item in formatArr(row.sketchMap)"
          :key="item.id"
          style="padding-left: 10px"
          :src="item.url"
          class="table-row-img"
          :preview-src-list="[item.url]"
        />
      </div>
      <div slot="sparePartDrawing" slot-scope="{ row }">
        <el-image
          v-if="row.sparePartDrawing"
          style="padding-left: 10px"
          :src="row.sparePartDrawing"
          class="table-row-img"
          :preview-src-list="[row.sparePartDrawing]"
        />
      </div>
      <div slot="maintenanceResult" slot-scope="{ row }" class="is-enabled">
        <div :style="{background: row.result === 1 ? '#24CAD7' : '#FFCC78' }" />
        <div>{{ row.result === 1 ? '已保养' : '未保养' }}</div>
      </div>
      <div slot="equipmentNames" slot-scope="{ row}">{{ row.equipment.name }}</div>
      <div slot="parameterType" slot-scope="{ row}">{{ row.parameterType | parameterTypeName }}</div>
      <div slot="limitType" slot-scope="{ row}">{{ row.limitType | limitTypeName }}</div>
      <div slot="procedureType" slot-scope="{ row}">{{ row.type | procedureType }}</div>
      <div slot="spareStatus" slot-scope="{ row }" :style="{ color: colors[row.status] }">{{ row.status | spareStatusName }}</div>
      <div slot="action" slot-scope="{ row, $index }">
        <el-button type="text" @click="scans(row)">查看上传工序参数</el-button>
        <el-button v-if="row.updateReportFlag === 1" type="text" @click="cancelBao(row, $index)">撤回</el-button>
      </div>
      <div slot="moldInstances" slot-scope="{row }">
        <el-tooltip v-if="row.moldInstances" class="item"  :content="row.moldInstances" placement="top">
          <el-button type="text">{{ row.moldInstances&&row.moldInstances.split('、')[0]+'...' }}</el-button>
        </el-tooltip>
        <div v-else>-</div>
      </div>
      <div slot="actions" slot-scope="{ row, $index }">
        <el-button v-if="row.cancelFlag === 1" type="text" @click="cancelArrivals(row, $index)">撤回</el-button>
      </div>
    </MTable>
  </MDialog>
</template>

<script>
import { inspectionItemType, procedureType, spareStatus } from '@/config/options.config'

export default {
  name: 'TableDialog',
  filters: {
    spareStatusName(val) {
      if (val == 0 || val) return spareStatus.find(item => item.id === val).name
    },
    parameterTypeName(val) {
      return inspectionItemType.find(item => item.id === val).name
    },
    limitTypeName(val) {
      if (val === 0) {
        return '百分比'
      }
      if (val === 1) {
        return '数值'
      }
      return ''
    },
    procedureType(val) {
      return procedureType.find(item => item.id === val).name
    }
  },
  props: {
    appends: {
      type: Boolean,
      default: false
    },
    show: {
      require: true,
      type: Boolean,
      default: false
    },
    title: {
      require: true,
      type: String,
      default: ''
    },
    tableData: {
      require: true,
      type: Array,
      default: () => ([])
    },
    columns: {
      require: true,
      type: Array,
      default: () => ([])
    },
    calcHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      visible: false,
      colors: {
        '0': '#E02020',
        '1': '#9597AE',
        '2': '#9FA1B7',
        '-1': '#E02020'
      }
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70 - this.calcHeight
    }
  },
  watch: {
    visible(val) {
      if (!val) {
        this.$emit('update:show', false)
      }
    },
    show(val) {
      this.visible = val
    }
  },
  methods: {
    scans(row) {
      this.$emit('scanDetail', row)
    },
    cancelBao(row, index) {
      this.$emit('BaoDetail', { data: row, idx: index })
    },
    cancelArrivals(row, index) {
      this.$emit('cancelArrivals', { data: row, idx: index })
    },
    formatArr(arr) {
      try {
        return JSON.parse(arr)
      } catch (err) {
        return []
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .table-row-img {
    >img {
      width: 50px;
    }
  }
}
</style>
