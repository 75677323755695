import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/warehouse/private/materialPreparation/'
const baseUrl1 = 'api/web/v1/warehouse/private/materialPreparationDetails/'
const baseUrl2 = 'api/web/v1/materials/private/materialsRequisitionTask/'
export default {
  // 获取备料任务-不分页
  getWarehouseAssignList(data) {
    return request.get(baseUrl + 'getMaterialPreparationList', data)
  },
  // 获取备料任务--分页
  getWarehouseAssignListPage(data) {
    return request.get(baseUrl + 'getMaterialPreparationListPage', data)
  },
  // 备料任务详情
  getWarehouseAssignDetail(id) {
    return request.get(baseUrl + `getMaterialPreparationDetail?id=${id}`)
  },
  // 备料任务详情 - 分页
  getWarehouseAssignDetailList(data) {
    return request.get(baseUrl1 + `getMaterialPreparationDetailsListPage`, data)
  },
  // 批量完成
  batchWarehouseAssign(data) {
    return request.post(baseUrl + 'consummation', data)
  },
  // 领退料任务列表
  getApplyAssignListPage(data) {
    return request.get(baseUrl2 + 'listPage', data)
  },
  // 领退料任务详情
  getRequisitionTaskDetail(data) {
    return request.get(baseUrl2 + 'getMaterialsRequisitionTask', data)
  },
 // 领退料任务列表
  getMaterialsRequisitionTaskListPage(data) {
    return request.get(baseUrl2 + 'itemListPage', data)
  },
  // 领退料任务出入库
  outInWarehouseRequisitionTask(data) {
    return request.post(baseUrl2 + 'outInWarehouse', data)
  }
}
