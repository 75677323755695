import request from '@/utils/requestForAsync'
const settingUrl = 'api/web/v1/basicData/settings/private/'
const baseUrl = 'api/web/v1/authority/private/Organization/'

export default {
  // 工资统计列表
  getSalaryList(data) {
    return request.get('api/web/v1/salary/private/salary/statistic/employee', data)
  },

  // 工资统计图表
  getSalaryChart(data) {
    return request.get('api/web/v1/salary/private/salary/statistic/company', data)
  },
  // 获取用户组-分页
  getUserGroupListPage(data) {
    return request.get(settingUrl + 'userGroup/getUserGroupListPage', data)
  },
  // 获取组织架构列表（分页）
  getOrganizationListPage(data) {
    return request.get(baseUrl + 'getOrganizationListPage', data)
  },
  // 获取 type 0：工厂，1：车间， 2：产线、3：线边库
  getAreaList(data) {
    return request.get(`api/web/v1/basicData/private/area/getAreaList`, data)
  },
  // 生产订单单列表分页
  getOrderPages(data) {
    return request.get('api/web/v1/production/kiki/private/produceOrder/getProduceOrderListPage', data)
  },
  // 获取工资列表
  getPriceList(data) {
    return request.get('api/web/v1/salary/private/price/list?page=1', data)
  },
  //  创建单件工资
  createSalary(data) {
    return request.post('api/web/v1/salary/private/price/create', data)
  },
  //  修改单件工资
  editSalary(data) {
    return request.post('api/web/v1/salary/private/price/edit', data)
  },
  //  删除单件工资
  delSalary(data) {
    return request.post('api/web/v1/salary/private/price/delete', data)
  }
}
