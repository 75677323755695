// 默认系统模板数据
export const widgets = [{
  type: 'braid-txt',
  isEdit: true,
  title: '自定义文本',
  value: '自定义文本',
  defaultValue: '自定义文本',
  style: { zIndex: 1, textAlign: 'center' }
},
  {
    type: 'braid-image',
    isEdit: false,
    title: '自定义图片',
    value: 'http://47.102.153.162:4321/12252563_2717设备2.jpg',
    defaultValue: ''
  },
  {
    type: 'braid-txt',
    isEdit: false,
    title: '设备名称',
    value: '{设备名称}',
    defaultValue: '',
    name: 'name'
  },
  {
    type: 'braid-txt',
    isEdit: false,
    title: '设备编码',
    value: '{设备编码}',
    defaultValue: '',
    name: 'code'
  },
  {
    type: 'bar-code',
    title: '设备二维码',
    value: '设备二维码',
    defaultValue: '',
    name: 'code',
    lodopStyle: { QRCodeVersion: '1', QRCodeErrorLevel: 'L' }
  },
  {
    type: 'braid-txt',
    isEdit: false,
    title: '设备类名称',
    value: '{设备类名称}',
    defaultValue: '',
    name: 'equipmentClassName'
  },
  {
    type: 'braid-txt',
    isEdit: false,
    title: '采购日期',
    value: '{采购日期}',
    defaultValue: '',
    name: 'buyTime'
  },
  {
    type: 'braid-txt',
    isEdit: false,
    title: '所属工位',
    value: '{所属工位}',
    defaultValue: '',
    name: 'stationName'
  },
  {
    type: 'braid-txt',
    isEdit: false,
    title: '固定资产编码',
    value: '{固定资产编码}',
    defaultValue: '',
    name: 'fixedCode'
  },
  {
    type: 'braid-txt',
    isEdit: false,
    title: '资产名称',
    value: '{资产名称}',
    defaultValue: '',
    name: 'fixedName'
  },
  {
    type: 'braid-txt',
    isEdit: false,
    title: '资产位置',
    value: '{资产位置}',
    defaultValue: '',
    name: 'fixedPosition'
  },
  {
    type: 'braid-txt',
    isEdit: false,
    title: '设备型号',
    value: '{设备型号}',
    defaultValue: '',
    name: 'unitType'
  },
  {
    type: 'braid-txt',
    isEdit: false,
    title: '出厂编号',
    value: '{出厂编号}',
    defaultValue: '',
    name: 'manufacturingNumber'
  },
  {
    type: 'braid-txt',
    isEdit: false,
    title: '制造厂商',
    value: '{制造厂商}',
    defaultValue: '',
    name: 'manufacturer'
  },
  {
    type: 'braid-txt',
    isEdit: false,
    title: '设备使用部门',
    value: '{设备使用部门}',
    defaultValue: '',
    name: 'organizationName'
  },
  {
    type: 'braid-txt',
    isEdit: false,
    title: '设备责任人',
    value: '{设备责任人}',
    defaultValue: '',
    name: 'equipmentOwnerName'
  },
  {
    type: 'braid-txt',
    isEdit: false,
    title: '客户',
    value: '{客户}',
    defaultValue: '',
    name: 'customerName'
  }]

// 项目模板数据
export const projectWidgets = [{
  type: 'braid-txt',
  isEdit: true,
  title: '自定义文本',
  value: '自定义文本',
  defaultValue: '自定义文本',
  style: { zIndex: 1, textAlign: 'center' }
},
{
  type: 'braid-image',
  isEdit: false,
  title: '自定义图片',
  value: 'http://47.102.153.162:4321/12252563_2717设备2.jpg',
  defaultValue: ''
},
{
  type: 'braid-txt',
  isEdit: false,
  title: '项目名称',
  value: '{项目名称}',
  defaultValue: '木白',
  name: 'projectName'
},
{
  type: 'braid-txt',
  isEdit: false,
  title: '项目编码',
  value: '{项目编码}',
  defaultValue: '',
  name: 'projectNumber'
},
  {
    type: 'bar-code',
    title: '项目二维码',
    value: '项目二维码',
    defaultValue: '',
    name: 'code',
    lodopStyle: { QRCodeVersion: '1', QRCodeErrorLevel: 'L' }
  },
{
  type: 'braid-txt',
  isEdit: false,
  title: '业务部门',
  value: '{业务部门}',
  defaultValue: '',
  name: 'businessUnitName'
},
{
  type: 'braid-txt',
  isEdit: false,
  title: '主工单',
  value: '{主工单}',
  defaultValue: '',
  name: 'workOrderNumber'
},
{
  type: 'braid-txt',
  isEdit: false,
  title: '计划开工时间',
  value: '{计划开工时间}',
  defaultValue: '',
  name: 'planStartTime'
},
{
  type: 'braid-txt',
  isEdit: false,
  title: '计划完工时间',
  value: '{计划完工时间}',
  defaultValue: '',
  name: 'planEndTime'
},
]

export const bbelcWidgets = [{
  type: 'braid-txt',
  isEdit: true,
  title: '自定义文本',
  value: '自定义文本',
  defaultValue: '自定义文本',
  style: { zIndex: 1, textAlign: 'center' }
},
{
  type: 'braid-image',
  isEdit: false,
  title: '自定义图片',
  value: 'http://47.102.153.162:4321/12252563_2717设备2.jpg',
  defaultValue: ''
},
{
  type: 'braid-txt',
  isEdit: false,
  title: '设备名称',
  value: '{设备名称}',
  defaultValue: '木白',
  name: 'name'
},
{
  type: 'braid-txt',
  isEdit: false,
  title: '设备编码',
  value: '{设备编码}',
  defaultValue: '',
  name: 'code'
},
{
  type: 'bar-code',
  title: '设备二维码',
  value: '设备二维码',
  defaultValue: '',
  name: 'code',
  lodopStyle: { QRCodeVersion: '1', QRCodeErrorLevel: 'L' }
},
{
  type: 'braid-txt',
  isEdit: false,
  title: '固定资产编码',
  value: '{固定资产编码}',
  defaultValue: '',
  name: 'fixedCode'
},
{
  type: 'braid-txt',
  isEdit: false,
  title: '资产名称',
  value: '{资产名称}',
  defaultValue: '',
  name: 'fixedName'
},
{
  type: 'braid-txt',
  isEdit: false,
  title: '资产位置',
  value: '{资产位置}',
  defaultValue: '',
  name: 'fixedPosition'
}]
export const defaultTemp =
'[{"default":"true","title":"系统模板","type":1,"width":400,"height":300,"pageWidth":400,"pageHeight":300,"tempItems":[{"type":"braid-txt","isEdit":1,"dragable":true,"resizable":true,"width":120,"height":22,"left":17,"top":150,"title":"设备名称","value":"{ 设备名称 } ","defaultValue":"","name":"name","style":{"zIndex":0,"FontSize":12,"FontColor":"#000","Bold":false,"Italic":false,"Underline":false,"Alignment":"center","ItemType":1},"uuid":"7f7b0b45da"},{"type":"braid-txt","isEdit":1,"dragable":true,"resizable":true,"width":110,"height":22,"left":140,"top":150,"title":"设备编码","value":"{设备编码}","defaultValue":"","name":"code","style":{"zIndex":0,"FontSize":12,"FontColor":"#000000","Bold":false,"Italic":false,"Underline":false,"Alignment":"left","ItemType":1},"uuid":"54ca3dc671"},{"type":"bar-code","isEdit":1,"dragable":true,"resizable":true,"width":120,"height":120,"left":23,"top":25,"title":"项目二维码","value":"","defaultValue":"自定义文本","name":"code","style":{"FontSize": 9,"ItemType": 0,"ShowBarText": false,"codeType": "QRCode","zIndex": 0},"uuid":"dae9eb86e4"}]}]'
