<template>
  <div v-if="isLock" :class="className" :style="{ maxWidth: password ? '386px' : '316px'}">
    <div class="user-name">{{ userInfo.name }}</div>
    <div style="display: inline-flex;align-items: center">
      <div class="img-wrapper">
        <img src="@/assets/l_c_H_images/头像@2x.png" alt="头像">
      </div>
      <div :class="!password ? 'input-wrapper' : 'active-input-wrapper'">
        <el-input v-model="password" placeholder="请输入密码" show-password/>
        <el-button v-show="password" class="submit" @click="validatePassword()">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import userApi from '@/api/sets/user/user'
import { Encrypt } from '@/utils/sercet'
export default {
  name: 'LockScreen',
  data() {
    return {
      className: 'lock-screen',
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
      password: '',
      showButton: false
    }
  },
  computed: {
    isLock() {
      return this.$store.state.user.lockScreen
    }
  },
  watch: {
    isLock(val) {
      this.showButton = false
      if (val) {
        setTimeout(() => {
          this.className = 'lock-screen shadow'
        }, 500)
      }
    }
  },
  mounted() {
    this.$store.dispatch('user/lockScreen', sessionStorage.getItem('lock') === 'true')
  },
  methods: {
    async validatePassword() {
      const res = await userApi.validatePassword(Encrypt(this.password))
      if (res) {
        this.password = ''
        await this.$store.dispatch('user/lockScreen', false)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.lock-screen {
  position: absolute;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 0 #3F537D;
  z-index: 9999;
  transition: box-shadow 1s,max-width 1s;
  color: #FFFFFF;
  max-width: 316px;
  .img-wrapper {
    padding: 8px;
    position: relative;
    z-index: 9;
    border-radius: 100%;
  }
  .user-name {
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    text-align: center;
  }
  img {
    width: 50px;
    height: 50px;
    position: relative;
    z-index: 5;
    border-radius: 100%;
    box-shadow: 0 0 3px 3px #FFFFFF;
  }
  &:hover {
    .input-wrapper {
      ::v-deep input {
        left: -10px;
        width: 250px;
        padding: 0 15px;
        &:focus {

        }
      }
    }
  }
}
.shadow {
  background: #3F537D;
  box-shadow: 0 0 0 2000px #3F537D;
  .img-wrapper {
    background: #3F537D;
  }
}
.input-wrapper {
  position: relative;
  bottom: 1px;
  display: inline-flex;
  align-items: center;
  ::v-deep input {
    position: relative;
    left: -39px;
    width: 0;
    padding: 0;
    transition: all .5s;
    &:focus {
      left: -10px;
      width: 250px;
      padding: 0 15px;
    }
    .submit {
      position: absolute;
      right: 0;
    }
  }
  ::v-deep input::placeholder {
    padding-left: 25px;
  }
}
.active-input-wrapper {
  position: relative;
  bottom: 1px;
  display: inline-flex;
  align-items: center;
  ::v-deep input {
    position: relative;
    left: -10px;
    width: 250px;
    padding: 0 15px;
    .submit {
      position: absolute;
      right: 0;
    }
  }
  ::v-deep input::placeholder {
    padding-left: 25px;
  }
}
::v-deep .el-input__suffix {
    right: 8px;
  }
</style>
