<template>
  <div class="search-form-wrap">
    <CFormBuilder v-if="showForm" :col-span="colSpan" :this="that" />
  </div>
</template>

<script>
import render from '@/components/MCustom/FormBuilder/render'
import dayjs from 'dayjs'
import { getUUid } from '@/utils'

export default {
  name: 'SearchForm',
  components: {
    CFormBuilder: {
      render,
      functional: true
    }
  },
  props: {
    searchType:{
      type: Number,
      default: 0
    },
    immediatelyQuery: {
      type: Boolean,
      default: false
    },
    labelPosition: {
      type: String,
      default: 'left'
    },
    colSpan: {
      type: Number,
      default: 12
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    defaultForm: {
      type: Object,
      default: () => ({})
    },
    formListExtend: {
      type: Array,
      require: true,
      default: () => []
    },
    buttonSpan: {
      type: Number,
      default: 3
    },
    expandLimit: { // 是否显示展开收起的限制
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      that: this,
      form: {},
      formList: [],
      searchButtons: [
        {
          colSpan: this.buttonSpan,
          tagName: 'div',
          style: { minWidth: '160px' },
          children: [
            {
              tagName: 'el-button',
              props: { type: 'primary', size: 'small' },
              style: { width: 'auto', background: '#607FFF' },
              innerText: '查询',
              on: {
                click: this.search
              },
              children: []
            },
            {
              tagName: 'el-button',
              props: { size: 'small' },
              style: { width: 'auto' },
              innerText: '重置',
              on: {
                click: this.reset
              },
              children: []
            },
            {
              tagName: 'el-button',
              hidden: this.expandLimit > this.formListExtend.length, // 搜索框超限才需要按钮
              props: { size: 'small', type: 'text' },
              style: { width: 'auto' },
              innerText: '展开',
              on: {
                click: this.expand
              },
              children: []
            }
          ]
        }
      ],
      showForm: true,
      isProcess: false,
      formSearchList: []
    }
  },
  computed: {
    newForm() {
      return JSON.parse(JSON.stringify(this.form))
    }
  },
  watch: {
     formListExtend(newValue, oldValue) {
      // 当 message prop 数据发生变化时执行的逻辑
      console.log('Prop 数据发生变化:', newValue);
       // 备份筛选项
    this.formListExtendCopy = this._.cloneDeep(this.formListExtend)
    // 筛选项默认只展示限制之内的数量
    this.formSearchList = [...this.formListExtend.slice(0, this.expandLimit)]
    },
    defaultForm(val) {
      this.form = this._.cloneDeep(val)
    },
    isProcess(val) {
      if (val) {
        this.form.procedureId = ''
      }
    },
    newForm: {
      handler(newVal, oldVal) {
        const diffKeys = this.getDiffKey(newVal, oldVal)
        const needUpdateTableKeys = this.formSearchList.filter(item => ['el-select', 'el-date-picker'].includes(item.tagName)).map(item => item.key)
        const needUpdate = needUpdateTableKeys.some(item => diffKeys.includes(item))
        if (needUpdate) {
          this.search()
        }
        this.$emit('update:formData', this.form)
      },
      deep: true
    },
    formSearchList() {
      this.setFormList()
    }
  },
  created() {
    // 备份筛选项
    this.formListExtendCopy = this._.cloneDeep(this.formListExtend)
    // 筛选项默认只展示限制之内的数量
    this.formSearchList = [...this.formListExtend.slice(0, this.expandLimit)]
  },
  mounted() {
    this.form = this._.cloneDeep(this.defaultForm)
    this.setFormList()
  },
  methods: {
    // 展开收起按钮事件
    expand() {
      const btnLength = this.searchButtons[0].children.length
      const expandBtn = this.searchButtons[0].children[btnLength - 1]
      if (expandBtn.innerText === '展开') {
        expandBtn.innerText = '收起'
        // this.formSearchList = this.formListExtendCopy
        this.formSearchList = [...this.formSearchList, ...this.formListExtendCopy.slice(this.formSearchList.length)]
      } else {
        expandBtn.innerText = '展开'
        // this.formSearchList = this.formListExtendCopy.slice(0, this.expandLimit)
        this.formSearchList = this.formSearchList.slice(0, this.expandLimit)
      }
    },
    getDiffKey(newVal, oldVal) {
      const diffKey = []
      const newValClone = this._.cloneDeep(newVal)
      const oldValClone = this._.cloneDeep(oldVal)
      Object.keys(newValClone).forEach(key => {
        if (Array.isArray(newValClone[key])) {
          newValClone[key] = newValClone[key].length ? newValClone[key] : undefined
        }
        if (Array.isArray(oldValClone[key])) {
          oldValClone[key] = oldValClone[key].length ? oldValClone[key] : undefined
        }
        if (newValClone[key] !== oldValClone[key]) {
          diffKey.push(key)
        }
      })
      return diffKey
    },
    setForm(val) {
      this.$set(this, 'form', { ...this.form, ...val })
    },
    setFormList() {
      this.showForm = false
      if (!this.immediatelyQuery) {
        this.formList = [...this.formSearchList, ...this.searchButtons]
      } else {
        this.formList = [...this.formSearchList]
      }
      this.$nextTick(() => {
        this.showForm = true
      })
    },
    search(val) {
      const searchFormatData = []
      if(this.searchType === 0 ){

      Object.keys(this.form).forEach(item => {
        if (!this.form[item] && this.form[item] !== 0) return false
        const current = this.formSearchList.find(f => f.key === item)
        if (!current) return false
        if (current.tagName === 'el-date-picker') {
          consoleSelf.info("this.form",this.form);
          if(current.wcFlag && current.wcFlag === "TTT"){
            searchFormatData.push({
              id: getUUid(),
              fieldName: item,
              fieldType: 'date',
              fieldValue: this.form[item],
              operator: 'like'
            })
          }else if(current.wcFlag && current.wcFlag === "RRR"){
            searchFormatData.push({
              id: getUUid(),
              fieldName: 'planStartTime',
              fieldType: 'date',
              fieldValue: this.form[item][0],
              operator: 'ge'
            })

            searchFormatData.push({
              id: getUUid(),
              fieldName: 'planEndTime',
              fieldType: 'date',
              fieldValue: this.form[item][1],
              operator: 'le'
            })
          } else {
            if (!current.isDelete) {
              const isTimeRangeB = dayjs(this.form[item][1]).format('YYYY-MM-DD HH:mm:ss').indexOf('00:00:00') === -1
              const isTimeRangeA = dayjs(this.form[item][0]).format('YYYY-MM-DD HH:mm:ss').indexOf('00:00:00') === -1
              const { dayFormat } = current

              searchFormatData.push({
                id: getUUid(),
                fieldName: item,
                fieldType: 'date',
                maxValue: isTimeRangeB
                  ? dayjs(this.form[item][1]).format(dayFormat || 'YYYY-MM-DD HH:mm:ss')
                  : current.dateType === 'date'
                    ? dayjs(this.form[item][1]).format('YYYY-MM-DD')
                    : `${dayjs(this.form[item][1]).format('YYYY-MM-DD')} 23:59`,
                minValue: isTimeRangeA
                  ? dayjs(this.form[item][0]).format(dayFormat || 'YYYY-MM-DD HH:mm:ss')
                  : current.dateType === 'date'
                    ? dayjs(this.form[item][0]).format('YYYY-MM-DD')
                    : `${dayjs(this.form[item][0]).format('YYYY-MM-DD')} 00:00`,
                operator: 'range'
              })
            }
            if (current.props.fullTimeVal) {
              this.form[item][0] = this.form[item][0] + ' ' + current.props.fullTimeVal[0].trim()
              this.form[item][1] = this.form[item][1] + ' ' + current.props.fullTimeVal[1].trim()
            }
          }
        } else if (current.tagName === 'el-select' && current.props && current.props.multiple) {
          if (this.form[item] && this.form[item].length && !current.isDelete) {
            searchFormatData.push({
              id: getUUid(),
              fieldName: item,
              fieldType: current.fieldType || 'number',
              fieldValue: this.form[item].join('^'),
              operator: 'in'
            })
          }
        } else if (this.form[item] || this.form[item] === 0) {
          searchFormatData.push({
            id: getUUid(),
            fieldName: item,
            fieldType: typeof this.form[item],
            fieldValue: this.form[item],
            operator: typeof this.form[item] === 'number' ? 'eq' : 'like'
          })
        }
      })

      }else{
        Object.keys(this.form).forEach(item => {
        if (!this.form[item] && this.form[item] !== 0) return false
        const current = this.formListExtend.find(f => f.key === item)
        if (!current) return false
        searchFormatData.push({
          [item]: this.form[item]
        })
      })
      }
      // console.log(searchFormatData)
      // val 用来判断是否是重置
      this.$emit('search', searchFormatData, val)
    },
    reset() {
      this.form = this._.cloneDeep(this.defaultForm)
      this.search(1)
    }
  }
}
</script>

<style scoped lang="scss">
.search-form-wrap {
  padding: 10px 10px 0 10px;
  border-bottom: 1px solid #dde3eb;
  .el-row{
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
