var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-autocomplete",
    {
      staticClass: "input-with-select",
      attrs: {
        "popper-class": "my-autocomplete",
        "fetch-suggestions": _vm.querySearch,
        "value-key": _vm.searchKey[_vm.selectKeyword],
        placeholder: "请输入内容",
        clearable: ""
      },
      on: { clear: _vm.handleSelect, select: _vm.handleSelect },
      model: {
        value: _vm.keyword,
        callback: function($$v) {
          _vm.keyword = $$v
        },
        expression: "keyword"
      }
    },
    [
      _c(
        "el-select",
        {
          staticClass: "input-select",
          attrs: { slot: "prepend", placeholder: "选择物料" },
          on: { change: _vm.selectType },
          slot: "prepend",
          model: {
            value: _vm.selectKeyword,
            callback: function($$v) {
              _vm.selectKeyword = $$v
            },
            expression: "selectKeyword"
          }
        },
        [
          _c("el-option", { attrs: { label: "物料编码", value: "0" } }),
          _c("el-option", { attrs: { label: "物料名称", value: "1" } }),
          _c("el-option", { attrs: { label: "物料规格", value: "2" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }