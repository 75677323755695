import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/basicData/private/area/'

export default {
  // 获取工厂建模-不分页
  getFactoryModelList(data) {
    return request.get(baseUrl + 'getAreaList', data)
  },
  // 获取工厂建模-分页
  getFactoryModelListPage(data) {
    return request.get(baseUrl + 'getAreaListPage', data)
  },
  // 新增工厂建模
  addFactoryModel(data) {
    return request.post(baseUrl + 'addArea', data)
  },
  // 修改工厂建模
  modifyFactoryModel(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 复制工厂建模
  copyFactoryModel(data) {
    return request.post(baseUrl + 'addArea', data)
  },
  // 删除工厂建模
  delFactoryModel(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  createCode(params) {
    return request.get(baseUrl + 'getMaxAreaCode', params)
  }
}
