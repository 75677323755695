var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.optionsType !== "page",
          expression: "optionsType !=='page'"
        }
      ]
    },
    [
      _c(
        "div",
        {
          staticClass: "verti",
          style: {
            height: _vm.elm.height + "px",
            top: _vm.elm.top + "px",
            left: _vm.elm.left + "px"
          },
          on: {
            mousedown: function($event) {
              return _vm.handlemousedown($event, "left", "left", "width")
            }
          }
        },
        [_c("div", { staticClass: "square" })]
      ),
      _c(
        "div",
        {
          staticClass: "verti",
          style: {
            height: _vm.elm.height + "px",
            top: _vm.elm.top + "px",
            left: _vm.elm.left + _vm.elm.width + "px"
          },
          on: {
            mousedown: function($event) {
              return _vm.handlemousedown($event, "right", "width")
            }
          }
        },
        [_c("div", { staticClass: "square" })]
      ),
      _c(
        "div",
        {
          staticClass: "horiz",
          style: {
            width: _vm.elm.width + "px",
            top: _vm.elm.top + "px",
            left: _vm.elm.left + "px"
          },
          on: {
            mousedown: function($event) {
              return _vm.handlemousedown($event, "up", "top", "height")
            }
          }
        },
        [_c("div", { staticClass: "square" })]
      ),
      _c(
        "div",
        {
          staticClass: "horiz",
          style: {
            width: _vm.elm.width + "px",
            top: _vm.elm.top + _vm.elm.height + "px",
            left: _vm.elm.left + "px"
          },
          on: {
            mousedown: function($event) {
              return _vm.handlemousedown($event, "down", "height")
            }
          }
        },
        [_c("div", { staticClass: "square" })]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }