<template>
  <div class="add-process-flow-container">
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <MFormBuilder v-if="saveButton && ['add', 'copy'].indexOf(params.type) !== -1" ref="baseForm" :form-list="baseFormList" :form-data="baseFormData">
          <div slot="processFlowClassName">
            <CURDSelect :default-value="baseFormData.processFlowClassId" :default-value-index="0" api-name="ProcessType" @change="selectName" />
          </div>
        </MFormBuilder>
        <template v-else>
          <MFormBuilder v-show="false" ref="baseForm" :form-list="baseFormList" :form-data="baseFormData" />
          <el-row class="view-list" :gutter="24">
            <!--            暂时这么处理距离问题-->
            <el-col
              v-for="(item, index) in columns.base"
              :key="item.prop"
              :title="getValue(item)"
              :span="8"
              :style="{marginBottom: index !== 6 ? '20px' : 0, display: 'inline-flex'}"
            >
              <div style="word-break: keep-all">{{ item.label }}：</div>
              <div style="word-break: break-all">{{ getValue(item) }}</div>
            </el-col>
          </el-row>
        </template>
      </div>
    </div>

    <div class="detail-card">
      <div class="card-tit">工艺路线图</div>
      <div class="card-bot process-card">
        <process-flow ref="processFlow" :read-only="!saveButton" :process-flow-detail="processFlowVersion" @addDetail="addDetails" />
      </div>
    </div>
  </div>
</template>

<script>
import ProcessFlow from '@/components/ProcessFlow/ProcessFlow'
import detailMixin from '../../views/information/production/process/detail/detail-mixin'
import CURDSelect from '@/components/CURDSelect/CURDSelect'
import processApi from '@/api/information/production/process'
import curdApi from '@/api/CURDSelect'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'

export default {
  name: 'AddProcessFlow',
  components: { CURDSelect, ProcessFlow },
  mixins: [detailMixin],
  mounted() {
    this.params = { type: 'add' }
    this.baseFormData.type = 0
    this.saveButton = this.params.type !== 'view'
    this.getTypes()
    this.getAreaList()
    this.createProcessCode()
    // if (this.needErp) {
    //   this.getErpWarehouseList()
    // }
    this.baseFormList.forEach(item => {
      if (item.key === 'type') {
        item.props.disabled = true
      }
    })
  },
  methods: {
    // 获取路线类型
    async getTypes() {
      const res = await curdApi.getProcessTypeList({
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      })
      if (res) {
        await this.$refs.baseForm.setForm({
          processFlowClassId: res.length > 0 ? res[0].id : '',
          processFlowClassName: res.length > 0 ? res[0].name : ''
        })
      }
    },
    async modifyOrAddProcess() {
      const { form } = this.$refs.baseForm
      let res
      const bodyData = {
        ...form,
        ...this.formatReturnData
      }
      if (['add', 'copy'].indexOf(this.params.type) !== -1) {
        res = await processApi.addProcess(this.createSetData(bodyData))
      }
      this.buttonLoading = false
      if (res) {
        this.saveButton = false
        this.$message.success(this.messages[this.params.type])
        return res
      }
      return false
    },
    submitForm() {
      return new Promise(resolve => {
        this.buttonLoading = true
        this.$refs.baseForm.formRefs().validate(async(valid) => {
          if (valid) {
            this.formatReturnData = await this.$refs.processFlow.formatReturnData()
            if (this.formatReturnData) {
              if (['add', 'copy'].indexOf(this.params.type) !== -1) {
                this.versionFormData = {
                  name: '版本1',
                  isMain: 1
                }
                const res = await this.modifyOrAddProcess()
                resolve(res)
              }
            } else {
              this.buttonLoading = false
              resolve(false)
            }
          } else {
            this.buttonLoading = false
            resolve(false)
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.add-process-flow-container {
  .detail-card {
    margin-bottom: 20px;
  }
  .process-card {
    padding: 10px;
  }
}
</style>
