import request from '@/utils/requestForAsync'
const baseUrl1 = 'api/web/v1/basicData/materials/private/materialsCompleteCalculate/'
const baseUrl = 'api/web/v1/basicData/production/private/reserveStockOrder/'

export default {
  // 获取列表
  getReserveStockOrderList(data) {
    return request.get(baseUrl + 'listPage', data)
  },
  // 客户选项
  getCustomer() {
    return request.get('api/web/v1/basicData/private/customer/listAllCustoms')
  },
  // 负责人选项
  getResponsible() {
    return request.get('api/web/v1/authority/private/user/listAllUsers')
  },
  // 订单编号
  getCode() {
    return request.get(baseUrl + 'getCode')
  },
  // 查看详情
  viewDetail(data) {
    return request.get(baseUrl + 'detail', data)
  },
  // 新建
  addReserveStockOrder(data) {
    return request.post(baseUrl + 'create', data)
  },
  // 修改
  updateReserveStockOrder(data) {
    return request.put(baseUrl + 'update', data)
  },
  // 删除
  delete(data) {
    return request.put(baseUrl + 'delete', data)
  },
  // 完结
  finish(data) {
    return request.put(baseUrl + 'finish', data)
  },
  // 修改
  edit(data) {
    return request.post(baseUrl + 'update', data)
  },
  // 作废
  cancellation(data) {
    return request.put(baseUrl + 'cancel', data)
  },
  // 重启
  restart(data) {
    return request.put(baseUrl + 'restart', data)
  },
  // 货品清单库存查询
  getMaterialsVariableStock(data) {
    return request.get('api/web/v1/warehouse/kiki/private/warehouseMaterialsBatKiki/getMaterialsVariableStock', data)
  }
}
