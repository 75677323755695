import requestForAsync from '@/utils/requestForAsync'

const baseUrl = '/api/web/v1/notification/private/notificationTask/'

export default {
  getNotificationTaskList(postData) {
    return requestForAsync.get(baseUrl + 'getNotificationTaskList', postData)
  },
  delNotificationTask(id) {
    return requestForAsync.del(baseUrl + `deleteOne?id=${id}`)
  },
  readNotificationTask(id) {
    return requestForAsync.get(baseUrl + 'read', { id })
  },
  readAllNotificationTask(userId) {
    return requestForAsync.get(baseUrl + 'batchRead', { recipientId: userId })
  },
  delAllNotificationTask(userId) {
    return requestForAsync.get(baseUrl + 'batchDelete', { recipientId: userId })
  }
}
