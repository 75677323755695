var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "split-wrapper",
      style: { height: _vm.height + "px" },
      attrs: { id: "splitWrapper" }
    },
    [
      _c("div", { staticClass: "horizontal" }, [
        _c(
          "div",
          { staticClass: "left", style: { right: _vm.anotherOffset + "%" } },
          [_vm._t("left")],
          2
        ),
        _vm.move
          ? _c(
              "div",
              { staticClass: "trigger-con", style: { left: _vm.offset + "%" } },
              [
                _c("div", { staticClass: "split-line" }, [
                  _c(
                    "div",
                    {
                      staticClass: "icon-wrapper",
                      on: {
                        mousedown: function($event) {
                          return _vm.handleMouseDown($event)
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "12px" },
                        attrs: {
                          src: require("../../assets/split/split.png"),
                          alt: ""
                        }
                      })
                    ]
                  )
                ])
              ]
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "right", style: { left: _vm.offset + "%" } },
          [_vm._t("right")],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }