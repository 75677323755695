
export const sColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 1
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 2
  },
  // {
  //   prop: 'materialsClassName',
  //   label: '物料分类',
  //   hiddenSearch: true,
  //   sortable: false,
  //   minWidth: 180
  // },
  {
    prop: 'materialsSpecifications',
    label: '规格型号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },

  {
    prop: 'materialsType',
    label: '物料类型',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },

  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'currentQuantity',
    label: '可用库存',
    hiddenSearch: true,
    sortable: false
  }
]

export const columns = [
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 1
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 2
  },
  // {
  //   prop: 'materialsClassName',
  //   label: '物料分类',
  //   hiddenSearch: true,
  //   sortable: false,
  //   minWidth: 180
  // },
  {
    prop: 'materialsSpecifications',
    label: '规格型号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'warehouseBatchNumber',
    label: '批次',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseBatchNumber'
  },
  {
    prop: 'materialsType',
    label: '物料类型',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },

  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'currentQuantity',
    label: '可用库存',
    hiddenSearch: true,
    sortable: false
  }
]
export const columnsIn = [
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left'
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 1
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 2
  },
  // {
  //   prop: 'materialsClassName',
  //   label: '物料分类',
  //   hiddenSearch: true,
  //   sortable: false,
  //   minWidth: 180
  // },
  {
    prop: 'materialsSpecifications',
    label: '规格型号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsType',
    label: '物料类型',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },

  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'currentQuantity',
    label: '库存总量',
    hiddenSearch: true,
    sortable: false
  }
]

export const mColumns = [
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 1
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 2
  },
  {
    prop: 'materialsSpecifications',
    label: '规格型号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsType',
    label: '物料类型',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },

  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'warehouseName',
    label: '仓库',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'currentQuantity',
    label: '可用库存',
    hiddenSearch: true,
    sortable: false
  },
  // {
  //   prop: 'availableQuantity',
  //   label: '可用库存',
  //   hiddenSearch: true,
  //   sortable: false
  // }
]
export const columnsInAll = [
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 1
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 2
  },
  {
    prop: 'materialsSpecifications',
    label: '规格型号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },

  {
    prop: 'warehouseBatchNumber',
    label: '批次',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseBatchNumber'
  },
  // {
  //   prop: 'materialsClassName',
  //   label: '物料分类',
  //   hiddenSearch: true,
  //   sortable: false,
  //   minWidth: 180
  // },
  {
    prop: 'materialsType',
    label: '物料类型',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },

  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'containerNo',
    label: '容器编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'containerName',
    label: '容器名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'capacity',
    label: '容量',
    sortable: false,
    hiddenSearch: true
  },
  // {
  //   prop: 'currentQuantity',
  //   label: '可用库存',
  //   hiddenSearch: true,
  //   sortable: false
  // },
  {
    prop: 'containerAvailable',
    label: '整件可用数量',
    sortable: false,
    hiddenSearch: true
  }
]
export const columnsInOut = [
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 1
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 2
  },
  {
    prop: 'materialsSpecifications',
    label: '规格型号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'warehouseBatchNumber',
    label: '批次',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseBatchNumber'
  },
  // {
  //   prop: 'materialsClassName',
  //   label: '物料分类',
  //   hiddenSearch: true,
  //   sortable: false,
  //   minWidth: 180
  // },
  {
    prop: 'materialsType',
    label: '物料类型',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },

  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'containerSupport',
    label: '散件可用数量',
    sortable: false,
    hiddenSearch: true
  }
]
