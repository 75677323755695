import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/invoices/private/facilityRepairWordOrder/'
const baseUrl1 = 'api/web/v1/field/facility/invoices/private/facilityInstallWordOrder/'
const baseUrl2 = 'api/web/v1/field/facility/invoices/private/facilityReformWordOrder/'
const baseUrl3 = 'api/web/v1/field/facility/invoices/private/changeWorkOrder/'

export default {
  // 获取维修工单列表（不分页）
  getRepairsList(data) {
    return request.get(baseUrl + 'getFacilityRepairWordOrderList', data)
  },
  // 获取维修工单列表（分页）
  getRepairsListPage(data) {
    return request.get(baseUrl + 'getFacilityRepairWordOrderListPage', data)
  },
  // 新建维修工单
  addRepairs(data) {
    return request.post(baseUrl + 'addFacilityRepairWordOrder', data)
  },
  // 修改维修工单
  modifyRepairs(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除维修工单
  delRepairs(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 批量删除维修工单
  delBatchRepairs(data) {
    return request.post(baseUrl + 'batchDelete', data)
  },
  // 获取维修工单详情
  getRepairsByDetail(id) {
    return request.get(baseUrl + 'getFacilityRepairWordOrderDetail', { id })
  },
  // 导出维修工单列表
  exportRepairs(id) {
    return request.get(baseUrl + 'export', { id })
  },
  // 新建工单
  addWordOrder(data) {
    return request.post(baseUrl + 'addFacilityWordOrder', data)
  },
  // 获取工单详情
  getWordOrderDetail(data) {
    return request.post(baseUrl + 'getFacilityWordOrderDetail', data)
  },
  // 更新工单
  modifyWordOrder(data) {
    return request.post(baseUrl + 'updateWordOrderOne', data)
  },
  // 获取装机工单 列表（不分页）
  getInstallEquipmentList(data) {
    return request.get(baseUrl1 + 'getFacilityInstallWordOrderList', data)
  },
  // 获取装机工单 列表（分页）
  getInstallEquipmentListPage(data) {
    return request.get(baseUrl1 + 'getFacilityInstallWordOrderListPage', data)
  },
  // 删除装机工单
  delInstallEquipment(data) {
    return request.del(baseUrl1 + 'deleteOne', data)
  },
  // 批量删除装机工单
  delBatchInstallEquipment(data) {
    return request.post(baseUrl1 + 'batchDelete', data)
  },
  // 获取改造工单列表（不分页）
  getReformList(data) {
    return request.get(baseUrl2 + 'getFacilityReformWordOrderList', data)
  },
  // 获取改造工单列表（分页）
  getReformListPage(data) {
    return request.get(baseUrl2 + 'getFacilityReformWordOrderListPage', data)
  },
  // 获取更换工单列表（分页）
  getReplaceListPage(data) {
    return request.get(baseUrl3 + 'getFacilityChangeWorkOrderListPage', data)
  },
  // 获取更换工单详情
  getReplaceDetail(id) {
    return request.get(baseUrl3 + 'getFacilityChangeWorkOrderDetail?id=' + id)
  },
  // 删除改造工单
  delReform(data) {
    return request.del(baseUrl2 + 'deleteOne', data)
  },
  // 工单保存校验服务时间
  checkTimeInfo(data) {
    return request.get(baseUrl + 'checkTime', data)
  }
}
