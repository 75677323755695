import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/inspection/private/inspectionScrapRecord/'

export default {
  // 获取报废不分页
  getScrapOrderList(data) {
    return request.get(baseUrl + 'getInspectionScrapRecordList', data)
  },
  // 获取报废-分页
  getScrapOrderListPage(data) {
    return request.get(baseUrl + 'getInspectionScrapRecordListPage', data)
  },
  // 移至废品仓
  delScrapOrder(id) {
    return request.get(baseUrl + `moveToWasteWareshouse?id=${id}`)
  },
  // 创建计划
  addScrapOrder(data) {
    return request.post(`api/web/v1/production/kiki/private/producePlan/confirmScrap`, data)
  },
  // 取得批次码
  getBatchNumber(id) {
    return request.get(`api/web/v1/production/kiki/private/producePlan/getScrapPlanBatchNumber?scrapRecordId=${id}`)
  }
}
