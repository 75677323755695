<template>
  <div class="form-builder-wrap">
    <CFormBuilder :disabled="disabled" :col-span="colSpan" :this="that" />
  </div>
</template>

<script>
/** write by luomingzhi */
import render from './render'
/**
 @formList:[{
  tagName: String, // form表单类型 支持elementUi大多数tag  labelTitle代表区域标题
  key: String,
  label: String,
  colSpan: Number, // 栅格布局Span
  hidden: Boolean,  // 是否加载
  required: Boolean, // 是否必填,
  props: Object, // elementUi中的属性
  domProps: Object, // dom原生属性 如innerHtml等
  attrs: Object, // dom原生属性如id等
  on: Object[Function], // 事件监听器
  rules: Array, // 校验规则
  children: {
    ...以上,
    customSlots: String[], // 自定义slot,
    slots: String[], // elementUi自带slot
    options: Array, // 下拉菜单、radio等子节点列表
    props: {
      options中的属性: 'a', // 如果a在options中可找到，则options中的属性: options[index]['a']
    }
  }
}]; */
export default {
  name: 'FormBuilder',
  components: {
    CFormBuilder: {
      render,
      functional: true
    }
  },
  props: {
    colSpan: {
      type: Number,
      default: 12
    },
    disabled: {
      type: Boolean,
      default: false
    },
    labelPosition: {
      type: String,
      default: 'top'
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    customRules: {
      type: Object,
      default: () => ({})
    },
    formList: {
      type: Array,
      require: true,
      default: () => []
    }
  },
  data() {
    return {
      that: this,
      form: {},
      rules: {},
      defaultForm: {},
      formLists: []
    }
  },
  watch: {
    formData() {
      const formData = this._.cloneDeep(this.formData)
      const defaultForm = this._.cloneDeep(this.defaultForm)
      Object.assign(defaultForm, formData)
      this.form = this._.cloneDeep(defaultForm)
      setTimeout(() => {
        this.$refs.form.clearValidate()
      }, 0)
    },
    rules() {
      Object.assign(this.rules, this.customRules)
    },
    formList(){
      this.formLists = this.formList
      this.setSomeData()
    }
  },
  mounted() {
    this.formLists = this.formList
    this.setSomeData()
  },
  methods: {
    setSomeData() {
      this.setDefaultRules(this.formLists).then(res => {
        this.form = this._.cloneDeep({ ...res.defaultForm, ...this.formData })
        this.rules = this._.cloneDeep({ ...res.rules, ...this.customRules })
        this.defaultForm = this._.cloneDeep(res.defaultForm)
        if (this.$refs.form) {
          setTimeout(() => {
            this.$refs.form.clearValidate()
          }, 0)
        }
      })
    },
    setDefaultRules(data) {
      return new Promise(resolve => {
        const defaultForm = {}
        const rules = {}
        for (let i = 0; i < data.length; i++) {
          const item = data[i]
          if (item.key) {
            if (!rules[item.key]) rules[item.key] = []
            if ('required' in item && item.required) {
              rules[item.key].push({
                required: item.required,
                message: `${this.rulesMessage(item, item.tagName)}`,
                trigger: item.tagName === 'el-select' ? 'change' : 'blur'
              })
            }
            if (item.rules) {
              rules[item.key].push(...item.rules)
            }
            if ('defaultValue' in item) {
              defaultForm[item.key] = item.defaultValue
            }
          }
          if (item.children && item.children.length) {
            this.setDefaultRules(item.children).then(res => {
              const { rules: backRules, defaultForm: backDefaultForm } = res
              Object.assign(defaultForm, backDefaultForm)
              Object.assign(rules, backRules)
            })
          }
        }
        resolve({
          defaultForm,
          rules
        })
      })
    },
    rulesMessage(item, tagName) {
      let newTagName = tagName
      const attrsPlaceholder = item.attrs && item.attrs.placeholder
      const propsPlaceholder = item.props && item.props.placeholder
      if (attrsPlaceholder || propsPlaceholder) {
        newTagName = 'placeholder'
      }
      switch (newTagName) {
        case 'placeholder':
          return attrsPlaceholder || propsPlaceholder
        case 'el-input':
          return `请输入${item.label.split('：')[0]}`
        default:
          return `请选择${item.label.split('：')[0]}`
      }
    },
    formRefs() {
      return this.$refs.form
    },
    setForm(val) {
      this.$set(this, 'form', { ...this.form, ...val })
    },
    resetForm(val) {
      this.$set(this, 'form', val)
    },
    setRuleRequired(rules) {
      rules.forEach(rule => {
        if (this.rules[rule.key] && this.rules[rule.key][0]) {
          this.rules[rule.key][0].required = rule.required
        }
      })
      setTimeout(() => {
        this.$refs.form.clearValidate()
      }, 0)
    }
  }
}
</script>

<style lang="scss">
div[required='required'] .el-form-item__label:before {
  content: '*';
  color: #f56c6c;
  margin-right: 4px;
}
.form-builder-wrap .el-row{
  display: flex;
  flex-wrap: wrap;
}
.label-title {
  padding: 10px 0;
  font-weight: 700;
  font-size: 16px;
}
div.required-div:before {
  content: '*';
  color: #f56c6c;
  margin-right: 4px;
}
</style>
<style scoped lang="scss">
.el-form-item {
  margin-bottom: 10px !important;
}
</style>
