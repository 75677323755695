<template>
  <div>
    <el-form
      ref="ruleForm1"
      label-position="right"
      :model="baseData"
      :rules="rules1"
      label-width="100px"
      class="demo-form"
    >
      <el-row>
        <el-col :span="8">
          <el-form-item label="操作步骤" prop="name">
            <el-input v-model="baseData.name" placeholder="请输入操作步骤" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="操作内容" prop="description">
            <el-input v-model="baseData.description" type="textarea" placeholder="请输入操作内容" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="检验标准" prop="remark">
            <el-input v-model="baseData.remark" type="textarea" placeholder="请输入检验标准" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-button
      v-if="!readOnly"
      style="margin-bottom: 10px;"
      size="small"
      type="primary"
      @click="addParams"
    >添加参数</el-button>
    <MTable
      ref="planTable"
      :can-row-sortable="!readOnly"
      :show-page="false"
      :height="300"
      :columns="columns"
      :data="baseData.params"
      only-key="serialNo"
    >
      <div slot="index" slot-scope="{$index }">{{ $index+1 }}</div>
      <div slot="type" slot-scope="{row }">{{ typesMap[row.type] }}</div>
      <div slot="requiredWrite" slot-scope="{row }">{{ requiredWriteMap[row.requiredWrite] }}</div>
      <div slot="action" slot-scope="{ $index,row }">
        <el-button type="text" :disabled="readOnly" @click="baseData.params.splice($index,1)">删除
        </el-button>
        <el-button type="text" :disabled="readOnly" @click="onEdit(row,$index)">编辑
        </el-button>
      </div>
    </MTable>

    <MDialog v-model="visible" width="60%" title="添加参数" append-to-body @onOk="onSubmit">
      <el-form
        ref="ruleForm"
        label-position="right"
        :model="params"
        :rules="rules"
        label-width="150px"
        class="demo-form"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="参数名称" prop="name">
              <el-input v-model="params.name" placeholder="请输入参数名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="参数类型" prop="type">
              <el-select
                v-model="params.type"
                style="width:100%"
                placeholder="请选择参数类型"
                @change="typeChange"
              >
                <el-option
                  v-for="item in types"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="重复次数" prop="repeats">
              <el-input-number
                v-model="params.repeats"
                step-strictly
                :step="1"
                :min="1"
                style="width:100%"
                controls-position="right"
                placeholder="请输入重复次数"
              />
            </el-form-item>
          </el-col>
          <el-col v-if="[3,4].includes(params.type)" :span="12">
            <el-form-item label="可能值" prop="candidateValues">
              <el-popover
                placement="bottom"
                trigger="click"
              >
                <div>
                  <div
                    v-for="(item,idx) in params.candidateOptions"
                    :key="idx"
                    style="minWidth:150px;display:flex;alignItems:center;justifyContent:space-between"
                  >
                    {{ item.label }}
                    <el-button
                      type="text"
                      icon="el-icon-circle-close"
                      @click="()=>params.candidateOptions.splice(idx,1)"
                    />
                  </div>
                </div>
                <el-input
                  slot="reference"
                  v-model="params.candidateValues"
                  placeholder="请输入可能值，敲击回车"
                  @keyup.enter.native="onEnter"
                />
              </el-popover>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="params.type===2" label="默认值" prop="defaultValues">
              <el-input v-model="params.defaultValues" placeholder="请输入默认值" />
            </el-form-item>
            <el-form-item v-if="params.type===1" label="默认值" prop="defaultValues">
              <el-input-number
                v-model="params.defaultValues"
                style="width:100%"
                controls-position="right"
                placeholder="请输入默认值"
              />
            </el-form-item>
            <el-form-item v-if="params.type===6" label="默认值时间展示格式" prop="defaultValues">
              <el-select
                v-model="params.defaultValues"
                style="width:100%"
                placeholder="请选择默认值时间展示格式"
              >
                <el-option
                  v-for="item in timeOptions"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
            <el-form-item v-if="[3,4].includes(params.type)" label="默认值" prop="defaultValues">
              <el-select
                v-model="params.defaultValues"
                :multiple="params.type===4"
                style="width:100%"
                placeholder="请选择默认值"
              >
                <el-option
                  v-for="(item,idx) in params.candidateOptions"
                  :key="idx"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="params.type===6" :span="12">
            <el-form-item label="是否默认系统当前时间" prop="timeSys">
              <el-switch v-model="params.timeSys" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否必填" prop="requiredWrite">
              <el-select
                v-model="params.requiredWrite"
                style="width:100%"
                placeholder="请选择是否必填"
              >
                <el-option
                  v-for="item in requiredWriteTypes"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </MDialog>
  </div>
</template>

<script>

import { getUUid } from '@/utils'
export default {
  name: 'Index',
  props: {
    baseData: {
      type: Object,
      default: () => ({})
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    editIdx: {
      type: Number,
      default: -1
    }
  },
  data() {
    const columns = [
      {
        prop: 'index',
        slotName: 'index',
        label: '序号',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'name',
        label: '参数名称',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'type',
        slotName: 'type',
        label: '参数类型',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'requiredWrite',
        slotName: 'requiredWrite',
        label: '是否必填',
        sortable: false,
        hiddenSearch: true
      },
      {
        slotName: 'action',
        label: '操作',
        hiddenSearch: true,
        sortable: false
      }
    ]
    const types = [
      { label: '数值', val: 1 },
      { label: '文本', val: 2 },
      { label: '单选', val: 3 },
      { label: '多选', val: 4 },
      { label: '图片', val: 5 },
      { label: '时间', val: 6 },
      { label: '手写签名', val: 7 }

    ]
    const requiredWriteTypes = [
      { label: '必填', val: 1 },
      { label: '非必填', val: 0 }

    ]
    const typesMap = types.reduce((pre, cur) => ({ ...pre, [cur.val]: cur.label }), {})
    const requiredWriteMap = requiredWriteTypes.reduce((pre, cur) => ({ ...pre, [cur.val]: cur.label }), {})
    const timeOptions = [
      { label: 'yyyy-mm-dd hh:mm', val: 'yyyy-mm-dd hh:mm' },
      { label: 'yyyy-mm-dd hh:mm:ss', val: 'yyyy-mm-dd hh:mm:ss' },
      { label: 'yyyy-mm-dd', val: 'yyyy-mm-dd' }
    ]
    const validateCandidate = (rule, value, callback) => {
      if (this.params.candidateOptions.length === 0) {
        callback(new Error('请输入可能值'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      timeOptions,
      typesMap,
      requiredWriteMap,
      rules1: {
        name: [
          { required: true, message: '请输入操作步骤', trigger: 'blur' }
        ]
      },
      rules: {
        name: [
          { required: true, message: '请输入参数名称', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择参数类型', trigger: 'blur' }
        ],
        requiredWrite: [
          { required: true, message: '请选择是否必填', trigger: 'blur' }
        ],
        repeats: [
          { required: true, message: '请输入重复次数', trigger: 'blur' }
        ],
        timeFormat: [
          { required: true, message: '请选择默认值时间展示格式', trigger: 'blur' }
        ],
        candidateValues: [
          { required: true, validator: validateCandidate, trigger: 'blur' }
        ]
      },
      params: {
        name: '',
        repeats: 1,
        type: 1,
        defaultValues: '',
        candidateValues: '',
        timeFormat: 'yyyy-mm-dd hh:mm',
        timeSys: false,
        requiredWrite: 1,
        candidateOptions: []
      },
      columns: Object.freeze(columns),
      requiredWriteTypes: Object.freeze(requiredWriteTypes),
      types: Object.freeze(types)
    }
  },
  created() {
    if (this.editIdx !== -1) {
      if (this.baseData.params) {
        this.baseData.params.forEach(item => {
          item.candidateOptions = item.candidateValues ? item.candidateValues.split(',').map(i => ({ label: i, val: i })) : []
          if (item.type === 4) item.defaultValues = item.defaultValues ? item.defaultValues.split(',') : []
          item.candidateValues = ''
        })
      }
    }
  },
  methods: {
    typeChange() {
      this.params.defaultValues = ''
      this.params.candidateValues = ''
      this.params.timeFormat = 'yyyy-mm-dd hh:mm'
    },
    onEnter() {
      this.params.candidateOptions.unshift({ label: this.params.candidateValues, val: this.params.candidateValues })
      this.params.candidateValues = ''
    },
    onSubmit(callback) {
      this.$refs.ruleForm.validate((valid) => {
        callback()
        if (valid) {
          if (this.isEdit) {
            this.$set(this.baseData.params, this.editIndex, this._.cloneDeep(this.params))
          } else {
            const arr = []
            const repeats = this.params.repeats
            for (let i = 0; i < repeats; i++) {
              this.params.repeats = 1
              this.params.serialNo = getUUid()
              arr.push(this._.cloneDeep(this.params))
            }
            this.baseData.params.push(...arr)
          }
          this.params = {
            name: '',
            repeats: 1,
            type: 1,
            defaultValues: '',
            candidateValues: '',
            timeFormat: 'yyyy-mm-dd hh:mm',
            timeSys: false,
            requiredWrite: 1,
            candidateOptions: []
          }
          this.visible = false
        }
      })
    },
    addParams() {
      this.isEdit = false
      this.visible = true
      this.params = {
        name: '',
        repeats: 1,
        type: 1,
        defaultValues: '',
        candidateValues: '',
        timeFormat: 'yyyy-mm-dd hh:mm',
        timeSys: false,
        requiredWrite: 1,
        candidateOptions: []
      }
    },
    onEdit(row, idx) {
      this.visible = true
      this.isEdit = true
      this.editIndex = idx
      this.params = this._.cloneDeep(row)
    }
  }
}

</script>
<style lang='scss' scoped>
::v-deep .el-form-item {
  margin-bottom: 20px !important;
}
</style>
