import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/bom/private/'
const baseUrl1 = 'api/web/v1/mbom/private/'
export default {
    // 获取BOM分类-不分页
  getBomClassList(data) {
    return request.get(baseUrl + 'materialsBomClass/list', data)
  },
//   新建BOM分类
addBomClass(data){
    return request.post(baseUrl + 'materialsBomClass/create', data)
},
// 修改BOM分类
modifyBomClass(data){
    return request.put(baseUrl + 'materialsBomClass/update', data)
},
// 删除BOM分类
deleteBomClass(data){
    return request.del(baseUrl + 'materialsBomClass/delete', data)
},
// 获取BOM列表
getBomListPage(data){
    return request.get(baseUrl + 'materialsBom/listPage', data)
},
// 删除Bom
deleteBom(data){
    return request.del(baseUrl + 'materialsBom/delete', data)
},
// 批量删除Bom
batchDeleteBom(data){
    return request.post(baseUrl + 'materialsBom/batchDelete', data)
},
// 启用Bom
enableBom(data){
    return request.put(baseUrl + `materialsBom/enable?id=${data}`)
},
// 停用Bom
disableBom(data){
    return request.put(baseUrl + `materialsBom/disable?id=${data}`)
},
// 导出Bom列表
exportBomList(data){
    return request.exportPost(baseUrl + 'materialsBom/export', data)
},
// 获取物料BOM层级
getBomLevel(data){		
    return request.get(baseUrl + 'materialsBom/getBomLevel',data)
},
// 获取BOM层级下阶物料
getBomLevelDownList(data){			
    return request.get(baseUrl + 'materialsBom/getBomLevelDownMaterialsList',data)
},
// 工序投入产出物料绑定
bindProcedureMatreials(data){
    return request.post(baseUrl + 'materialsBom/bindingInputOutputMaterials',data)		
},
// BOM转MBOM
changeBomToMBom(data){		
    return request.post(baseUrl + 'materialsBom/changeBomToMBom',data)		
},
// 获取MBOM列表
getMBomListPage(data){
    return request.get(baseUrl1 + 'materialsMBom/listPage', data)
},
// 获取MBOM层级
getMBomLevel(data){		
    return request.get(baseUrl1 + 'materialsMBom/getMBomLevel', data)
},
// 获取MBOM层级下级物料
getMBomDownLevelList(data){		
    return request.get(baseUrl1 + 'materialsMBom/getMBomLevelDownMaterialsList', data)
},
// 导出MBOM列表
exportMBomList(data){
    return request.exportPost(baseUrl1 + 'materialsMBom/export', data)
},
// 导出BOM层级
exportBomLevelList(data){
    return request.exportGet(baseUrl + 'materialsBom/exportBomLevelList', data)
},
// 导出MBom层级	
exportMBomLevelList(data){
    return request.exportGet(baseUrl1 + 'materialsMBom/exportMBomLevelList', data)
},
// 撤回投入物料
withdrawMaterials(data){
    return request.post(baseUrl + 'materialsBom/bindingInputOutputMaterialsRevert',data)
}
}
