var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "searchs" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { md: 18, gutter: 10 } },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "flex-flow": "row wrap" },
                  attrs: { gutter: 10, type: "flex", justify: "start" }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { md: 5 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "生产进度" },
                          model: {
                            value: _vm.searchFormData.status,
                            callback: function($$v) {
                              _vm.$set(_vm.searchFormData, "status", $$v)
                            },
                            expression: "searchFormData.status"
                          }
                        },
                        _vm._l(_vm.productionStatus, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { md: 7 } },
                    [
                      _c("SelectMultipleMaterialsNew", {
                        ref: "multipleMaterial",
                        on: { typeChange: _vm.searchData }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { md: 6 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "工单属性" },
                          model: {
                            value: _vm.searchFormData.isRepair,
                            callback: function($$v) {
                              _vm.$set(_vm.searchFormData, "isRepair", $$v)
                            },
                            expression: "searchFormData.isRepair"
                          }
                        },
                        _vm._l(_vm.procedureClass, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { md: 6 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "当前所处工序/#多选",
                            filterable: "",
                            multiple: ""
                          },
                          model: {
                            value: _vm.searchFormData.currentProcedureIds,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.searchFormData,
                                "currentProcedureIds",
                                $$v
                              )
                            },
                            expression: "searchFormData.currentProcedureIds"
                          }
                        },
                        _vm._l(_vm.currentProcedures, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.name,
                              value: item.id.toString()
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.show
                ? _c(
                    "el-row",
                    {
                      staticStyle: { "flex-flow": "row wrap" },
                      attrs: { type: "flex", justify: "start", gutter: 10 }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { md: 5 } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "工单来源" },
                              model: {
                                value: _vm.searchFormData.source,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchFormData, "source", $$v)
                                },
                                expression: "searchFormData.source"
                              }
                            },
                            _vm._l(_vm.taskSource, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { md: 7 } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "工艺" },
                              model: {
                                value: _vm.searchFormData.processFlowId,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.searchFormData,
                                    "processFlowId",
                                    $$v
                                  )
                                },
                                expression: "searchFormData.processFlowId"
                              }
                            },
                            _vm._l(_vm.processFlowList, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.selectFlower(item)
                                  }
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { md: 6 } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "工艺版本" },
                              model: {
                                value: _vm.searchFormData.processFlowVersionId,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.searchFormData,
                                    "processFlowVersionId",
                                    $$v
                                  )
                                },
                                expression:
                                  "searchFormData.processFlowVersionId"
                              }
                            },
                            _vm._l(_vm.versionList, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.version, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { md: 6 } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "产线" },
                              model: {
                                value: _vm.searchFormData.areaId,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchFormData, "areaId", $$v)
                                },
                                expression: "searchFormData.areaId"
                              }
                            },
                            _vm._l(_vm.areaList, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { md: 5 } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "计划优先级/#多选",
                                filterable: "",
                                multiple: ""
                              },
                              model: {
                                value: _vm.searchFormData.priority,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchFormData, "priority", $$v)
                                },
                                expression: "searchFormData.priority"
                              }
                            },
                            _vm._l(_vm.priorityStatus, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: index }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "all-btns", attrs: { md: 6 } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.searchData }
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.resetData } },
                [_vm._v("重置")]
              ),
              _c(
                "div",
                {
                  staticClass: "showMore",
                  style: { background: _vm.show ? "" : "#F8F8F8" },
                  on: { click: _vm.changeMore }
                },
                [
                  _c("span", [_vm._v("高级筛选")]),
                  _vm.show
                    ? _c("i", { staticClass: "el-icon-caret-bottom icons" })
                    : _c("i", { staticClass: "el-icon-caret-top" })
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }