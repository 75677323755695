import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/invoices/private/facilitySpotCheckPlanFacilityMapping/'

export default {
  // 新增保养设备
  addSpotCheckPlanFacility(data) {
    return request.post(baseUrl + 'addFacilitySpotCheckPlanFacilityMapping', data)
  },
  // 修改保养设备
  modifySpotCheckPlanFacility(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除保养设备
  delSpotCheckPlanFacility(data) {
    return request.post(baseUrl + 'deleteOne', data)
  }
}
