import request from '@/utils/requestForAsync'

export default {
  // 根据工位查询可执行任务
  getProduceWorkOrderTaskLisWithoutLockStation(data) {
    return request.get('api/web/v1/production/kiki/private/produceWorkOrderTask/' + 'getProduceWorkOrderTaskLisWithoutLockStation', data)
  },
  //新增工序任务与工位的映射关系
  addProduceWorkOrderTaskLockStationMapping(data){
    return request.post('api/web/v1/production/kiki/private/produceWorkOrderTaskLockStationMapping/addProduceWorkOrderTaskLockStationMapping' , data)

  },
  getProduceWorkOrderTaskListByLockStation(data){
    return request.get('api/web/v1/production/kiki/private/produceWorkOrderTask/getProduceWorkOrderTaskListByLockStation' , data)

  },
  deleteProduceWorkOrderTaskLockStationMappingById(data){
    return request.get('api/web/v1/production/kiki/private/produceWorkOrderTaskLockStationMapping/deleteProduceWorkOrderTaskLockStationMappingById' , data)

  },
  updateProduceWorkOrderTaskLockStationMappingIsContinuousById(data){
    return request.get('api/web/v1/production/kiki/private/produceWorkOrderTaskLockStationMapping/updateProduceWorkOrderTaskLockStationMappingIsContinuousById' , data)

  },
  updateProduceWorkOrderTaskLockStationMapping(data){
    return request.get('api/web/v1/production/kiki/private/produceWorkOrderTaskLockStationMapping/updateProduceWorkOrderTaskLockStationMapping' , data)

  },
  //修改备注
  updateRemark(data){
    return request.put('api/web/v1/production/kiki//private/produceWorkOrderTask/updateRemark' , data)

  },
  //修改数量
  updateCount(data){
    return request.put('api/web/v1/production/kiki//private/produceWorkOrderTask/updateOutputMaterialsQuantity' , data)

  },
  //获取班次
  getFrequencyList() {
    return request.get('/api/web/v1/authority/private/frequency/getFrequencyList')
  },
   //获取报工人信息
   getUserListByStationId(params) {
    return request.get('/api/web/v1/authority/private/user/getUserListByStationId',params)
  },
  //批量报工
  batchFinishProduceWorkOrderTask(data){
    return request.post('api/web/v1/production/kiki/private/produceWorkOrderTask/batchFinishProduceWorkOrderTask' , data)

  },
  // 修改自定义字段内容
  saveOrUpdateFieldValue(data){
    return request.post('api/web/v1/production/kiki/private/produceWorkOrderTask/saveOrUpdateFieldValue' , data)

  }
  
}
