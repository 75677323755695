<template>
  <div class="blank-wrapper flex-cc">
    <img src="https://oss.mubyte.cn/static/noData.png">
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: 'MBlank',
  props: {
    title: {
      type: String,
      default: '暂无数据'
    }
  }
}
</script>

<style scoped lang="scss">
.blank-wrapper {
  width: 100%;
  flex-direction: column;
  img {
    width: 150px;
  }
  span {
    color: #909399;
    font-size: 14px;
    padding-top: 10px;
  }
}
</style>
