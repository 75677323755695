import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/materials/private/materialsBalance/'		
export default {
// 获取物料平衡总列表
getMaterialsBalanceListPage(data){
    return request.get(baseUrl + 'total/listPage', data)
},
// 获取物料平衡需求详情
getMaterialsBalanceRequireDetail(data){	
    return request.get(baseUrl + 'requirement/detail', data)
},
// 获取物料平衡供应详情
getMaterialsBalanceSupplyDetail(data){	
    return request.get(baseUrl + 'supply/detail', data)
}
}