import request from '@/utils/request'

export default {
  // 获取区域列表
  getArea() {
    return request.get('/api/web/v1/basicData/private/area/getAreaList', {})
  },
  // 新建区域
  addArea(postData) {
    return request.post('/api/web/v1/basicData/private/area/addArea', postData)
  },
  // 删除区域
  deleteArea(postData) {
    return request.del('/api/web/v1/basicData/private/area/deleteOne', postData)
  },
  // 修改区域
  ModifyUnQaulity(putData) {
    return request.put('/api/web/v1/basicData/private/area/updateOne', putData)
  },
  // 获取区域详情
  getAreaDetail() {
    return request.get('/api/web/v1/basicData/private/area/getAreaDetail', {})
  },
  // 导出区域列表
  exportUnQuality() {
    return request.get('/api/web/v1/basicData/private/area/export', {})
  },
  // 获取产线
  getLine(data) {
    return request.post('/api/web/v1/basicData/private/area/getProductionLineList', data)
  }
}
