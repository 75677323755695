import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/equipment/setting/private/'

export default {
  // 获取点巡检项-不分页
  getPointCheckItemList(data) {
    return request.get(baseUrl + 'checkItem/getCheckItemList', data)
  },
  // 获取点巡检项-分页
  getPointCheckItemListPage(data) {
    return request.get(baseUrl + 'checkItem/getCheckItemListPage', data)
  },
  // 新增点巡检项
  addPointCheckItem(data) {
    return request.post(baseUrl + 'checkItem/addCheckItem', data)
  },
  // 新增点巡检项
  copyPointCheckItem(data) {
    return request.post(baseUrl + 'checkItem/addCheckItem', data)
  },
  // 修改点巡检项
  modifyPointCheckItem(data) {
    return request.put(baseUrl + 'checkItem/updateOne', data)
  },
  // 删除点巡检项
  delPointCheckItem(data) {
    return request.del(baseUrl + 'checkItem/deleteOne', data)
  },

  // 获取设备类巡检项-不分页
  getInspectionList(data) {
    return request.get(baseUrl + 'checkItemEquipmentClassMapping/getCheckItemEquipmentClassMappingList', data)
  },
  // 获取设备类巡检项-分页
  getInspectionListPage(data) {
    return request.get(baseUrl + 'checkItemEquipmentClassMapping/getCheckItemEquipmentClassMappingListPage', data)
  },
  // 新增/修改/删除设备类巡检项
  addInspection(data) {
    return request.post(baseUrl + 'checkItemEquipmentClassMapping/updateEquipmentClassCheckItem', data)
  },
  // 新增设备类巡检项
  copyInspection(data) {
    return request.post(baseUrl + 'checkItemEquipmentClassMapping/updateEquipmentClassCheckItem', data)
  },
  // 修改设备类巡检项
  modifyInspection(data) {
    return request.post(baseUrl + 'checkItemEquipmentClassMapping/updateEquipmentClassCheckItem', data)
  },
  // 删除设备类巡检项
  delInspection(data) {
    return request.post(baseUrl + 'checkItemEquipmentClassMapping/updateEquipmentClassCheckItem', data)
  },

  // 获取设备类巡检项-不分页
  getPointCheckTypeList(data) {
    return request.get(baseUrl + 'spotCheckItemEquipmentClassMapping/getSpotCheckItemEquipmentClassMappingList', data)
  },
  // 获取设备类巡检项-分页
  getPointCheckTypeListPage(data) {
    return request.get(baseUrl + 'spotCheckItemEquipmentClassMapping/getSpotCheckItemEquipmentClassMappingListPage', data)
  },
  // 新增/修改/删除设备类巡检项
  addPointCheckType(data) {
    return request.post(baseUrl + 'spotCheckItemEquipmentClassMapping/updateEquipmentClassSpotCheckItem', data)
  },
  // 新增设备类巡检项
  copyPointCheckType(data) {
    return request.post(baseUrl + 'spotCheckItemEquipmentClassMapping/updateEquipmentClassSpotCheckItem', data)
  },
  // 修改设备类巡检项
  modifyPointCheckType(data) {
    return request.post(baseUrl + 'spotCheckItemEquipmentClassMapping/updateEquipmentClassSpotCheckItem', data)
  },
  // 删除设备类巡检项
  delPointCheckType(data) {
    return request.post(baseUrl + 'spotCheckItemEquipmentClassMapping/updateEquipmentClassSpotCheckItem', data)
  }
}
