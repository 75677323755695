import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/abnormal/private/abnormalOrder/'
const baseUrl1 = 'api/web/v1/notification/private/callOut/'

export default {
    // 获取呼叫查询列表
    getCallSearchListPage(params) {
      return request.get(baseUrl1 + 'getCallOutListPage', params)
    },
    // 删除呼叫查询
    delCallSearch(data) {
      return request.del(baseUrl1 + 'deleteOne', data)
    },
    // 批量删除
    batchDel(data) {
      return request.post(baseUrl1 + 'deleteBatch', data)
    },
  // 异常列表
  list(params) {
    return request.get(baseUrl + 'list', params)
  },
  // 异常详情
  detail(data) {
    return request.get(baseUrl + 'detail', data)
  },
  // 异常详情
  detailApp(data) {
    return request.get('api/app/v1/abnormal/private/abnormalOrder/detail', data)
  },
  // 异常类型
  exceptionTypeList(data) {
    return request.get('api/web/v1/notification/private/callType/getPullDownList', data)
  },
  // 发起部门
  dutyPowerList(data) {
    return request.get('api/app/v1/abnormal/private/abnormalOrder/getDutyPowerList', data)
  },
  // 催办
  onUrge(data){
    return request.get('api/web/v1/abnormal/private/abnormalOrder/urge', data)
  },

  // 催办
  getOrderNum(){
    return request.get('api/web/v1/abnormal/private/abnormalOrder/getCountByStatus')
  },
  // 批量删除
  // del(data) {
  //   return request.post('api/app/v1/abnormal/private/abnormalOrder/delete', data)
  // }
  // 异常类型
  exportExceptionList(data) {
    return request.exportPost(baseUrl + 'export', data)
  }
}
