<template>
  <el-autocomplete
    v-model="keyword"
    popper-class="my-autocomplete"
    :fetch-suggestions="querySearch"
    :value-key="searchKey[selectKeyword]"
    placeholder="请输入内容"
    class="input-with-select"
    clearable
    @clear="handleSelect"
    @select="handleSelect"
  >
    <el-select slot="prepend" v-model="selectKeyword" placeholder="选择物料" class="input-select" @change="selectType">
      <el-option label="物料编码" value="0" />
      <el-option label="物料名称" value="1" />
      <el-option label="物料规格" value="2" />
    </el-select>
  </el-autocomplete>
</template>

<script>
import materialsApi from '@/api/information/materials'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'

export default {
  name: 'SelectMultipleMaterials',
  data() {
    return {
      keyword: '',
      selectKeyword: '1',
      materialsName: [],
      materialsCode: [],
      materialsSpecifications: [],
      searchKey: {
        0: 'code',
        1: 'name',
        2: 'specifications'
      }
    }
  },
  watch: {
    selectKeyword(val) {
      if (val === '1') {
        this.selectType()
      }
    }
  },
  mounted() {
  },
  methods: {
    async querySearch(queryString, cb) {
      const res = await materialsApi.getMaterialsListPage({
        page: 1,
        limit: 50,
        searchVal: Encrypt(JSON.stringify(
          queryString ? [{
            id: getUUid(),
            fieldName: this.searchKey[this.selectKeyword],
            fieldType: 'string',
            fieldValue: queryString,
            operator: 'like'
          }] : []
        ))
      })
      if (res) {
        this.restaurants = res.records
      }
      cb(this.restaurants)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    handleSelect(item) {
      this.$emit('selectChange', this.searchKey[this.selectKeyword], item)
    },
    selectType() {
      this.restaurants = this.selectKeyword === '0'
        ? this.materialsCode : this.selectKeyword === '2'
          ? this.materialsSpecifications : this.materialsName
      this.keyword = ''
      this.$emit('typeChange', this.selectKeyword)
    }
  }
}
</script>

<style lang="scss" scoped>
.input-with-select {
  width: 100%;

  .input-select {
    width: 105px !important;
  }
}
::v-deep {
  .el-input-group {
    width: 100%;

    .el-input-group__prepend {
      width: 105px;
    }
  }
}
</style>
