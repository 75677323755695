import request from '@/utils/requestForAsync'
import { getUrl } from '@/utils/index'

const base = 'api/web/v1/equipment/private/'

export default {
  // 获取设备列表分页
  getEquipmentList(params) {
    return request.get(base + `equipment/getEquipmentListPage${getUrl(params)}`)
  },
  // 获取设备列表不分页
  getEquipmentListNoPage(data) {
    return request.get(base + `equipment/getEquipmentList`, data)
  },
  // 获取设备类列表
  getEquipmentClassList() {
    return request.get(`/api/web/v1/basicData/private/equipmentClass/getEquipmentClassList`)
  },
  // 添加设备
  addEquipment(postData) {
    return request.post(base + `equipment/addEquipment`, postData)
  },
  // 修改设备
  updateEquipment(postData) {
    return request.put(base + `equipment/updateOne`, postData)
  },
  deleteEquipment(id) {
    return request.del(base + `equipment/deleteOne?id=${id}`)
  },
  // 启动设备
  openEquipment(id) {
    return request.get(base + `equipment/enable?id=${id}`)
  },
  // 停用设备
  closeEquipment(id) {
    return request.get(base + `equipment/disable?id=${id}`)
  },
  // 导出设备
  exportEquipment() {
    return request.exportGet(base + `equipment/export`)
  },
  // 获取设备详情
  getEquipmentDetail(id) {
    return request.get(base + `equipment/getEquipmentDetail?id=${id}`)
  },
  // 添加设备维保记录
  addEquipmentRepairRecord(postData) {
    return request.post(base + `equipmentRepairRecord/addEquipmentRepairRecord`, postData)
  },
  // 获取维保人
  getUserList(params) {
    return request.get(`/api/web/v1/authority/private/user/getUserList${getUrl(params)}`)
  },
  // 获取班次
  getFrequencyList() {
    return request.get(`/api/web/v1/authority/private/frequency/getFrequencyList`)
  },
  // 获取设备维保列表分页
  getEquipmentRepairRecordList(params) {
    return request.get(base + `equipmentRepairRecord/getEquipmentRepairRecordListPage${getUrl(params)}`)
  },
  // 获取维保记录详情
  getEquipmentRepairRecordDetail(id) {
    return request.get(base + `equipmentRepairRecord/getEquipmentRepairRecordDetail?id=${id}`)
  },
  // 开始维保
  beginRepair(postData) {
    return request.put(base + `equipmentRepairRecord/beginRepair`, postData)
  },
  // 结束维保
  finishRepair(postData) {
    return request.put(base + `equipmentRepairRecord/finishRepair`, postData)
  },
  // 获取产线
  getAreaList() {
    return request.get(`api/web/v1/basicData/private/area/getAreaList`)
  }
}
