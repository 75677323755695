var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: _vm.ref, staticClass: "dv-scroll-board" }, [
    _vm.header.length && _vm.mergedConfig
      ? _c(
          "div",
          {
            staticClass: "header",
            style: "background: " + _vm.mergedConfig.headerBGC + ";"
          },
          [
            _vm._l(_vm.header, function(headerItem, i) {
              return [
                _vm.setHead(headerItem)
                  ? _c("div", {
                      key: "" + headerItem + i,
                      staticClass: "header-item",
                      style:
                        "\n          height: " +
                        _vm.mergedConfig.headerHeight +
                        "px;\n          line-height: " +
                        _vm.mergedConfig.headerHeight +
                        "px;\n          width: " +
                        _vm.widths[i] +
                        "px;\n          display:" +
                        (headerItem === "工序进度" && _vm.widths[i] === 2000
                          ? "none"
                          : "block") +
                        ";\n        ",
                      attrs: { align: _vm.aligns[i] },
                      domProps: { innerHTML: _vm._s(headerItem) }
                    })
                  : _c(
                      "div",
                      {
                        key: "" + headerItem + i,
                        staticClass: "header-item",
                        style:
                          "\n          height: " +
                          _vm.mergedConfig.headerHeight +
                          "px;\n          line-height: " +
                          _vm.mergedConfig.headerHeight +
                          "px;\n          width: " +
                          _vm.widths[i] +
                          "px;\n          display:" +
                          (headerItem === "工序进度" && _vm.widths[i] === 2000
                            ? "none"
                            : "block") +
                          ";\n        ",
                        attrs: { align: _vm.aligns[i] }
                      },
                      [
                        _vm._t(headerItem.split(":")[1], null, {
                          index: i,
                          zn: headerItem.split(":")[2]
                        })
                      ],
                      2
                    )
              ]
            })
          ],
          2
        )
      : _vm._e(),
    _vm.mergedConfig
      ? _c(
          "div",
          {
            staticClass: "rows",
            style:
              "height: " +
              (_vm.height -
                (_vm.header.length ? _vm.mergedConfig.headerHeight : 0)) +
              "px;overflow-y:scroll"
          },
          _vm._l(_vm.rows, function(row, ri) {
            return _c(
              "div",
              {
                key: "" + row.toString() + row.scroll,
                staticClass: "row-item",
                style:
                  "\n        height: " +
                  _vm.heights[ri] +
                  "px;\n        line-height: " +
                  _vm.heights[ri] +
                  "px;\n        background-color: " +
                  _vm.mergedConfig[
                    row.rowIndex % 2 === 0 ? "evenRowBGC" : "oddRowBGC"
                  ] +
                  ";\n      ",
                attrs: {
                  isDelay: _vm.config.delayIndex.includes(row.ceils[0] - 1)
                }
              },
              [
                _vm._l(row.ceils, function(ceil, ci) {
                  return [
                    _vm.set(ceil)
                      ? _c("div", {
                          key: "" + ceil + ri + ci,
                          staticClass: "ceil",
                          style:
                            "width: " +
                            _vm.widths[ci] +
                            "px; height: " +
                            (_vm.heights[ri] ? "auto" : "0px"),
                          attrs: { align: _vm.aligns[ci] },
                          domProps: {
                            innerHTML: _vm._s(
                              ![undefined, null].includes(ceil)
                                ? "<span>" + ceil + "</span>"
                                : "-"
                            )
                          },
                          on: {
                            click: function($event) {
                              return _vm.emitEvent("click", ri, ci, row, ceil)
                            },
                            mouseenter: function($event) {
                              return _vm.handleHover(true, ri, ci, row, ceil)
                            },
                            mouseleave: function($event) {
                              return _vm.handleHover(false)
                            }
                          }
                        })
                      : _c(
                          "div",
                          {
                            key: "" + ceil + ri + ci,
                            staticClass: "ceil",
                            style:
                              "width: " +
                              _vm.widths[ci] +
                              "px; height: " +
                              (_vm.heights[ri] ? "auto" : "0px"),
                            attrs: { align: _vm.aligns[ci] },
                            on: {
                              click: function($event) {
                                return _vm.emitEvent("click", ri, ci, row, ceil)
                              },
                              mouseenter: function($event) {
                                return _vm.handleHover(true, ri, ci, row, ceil)
                              },
                              mouseleave: function($event) {
                                return _vm.handleHover(false)
                              }
                            }
                          },
                          [
                            _vm._t(ceil.split("|")[0], null, {
                              index: ceil.split("|")[1]
                            })
                          ],
                          2
                        )
                  ]
                })
              ],
              2
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }