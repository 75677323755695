var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm1",
          staticClass: "demo-form",
          attrs: {
            "label-position": "right",
            model: _vm.baseData,
            rules: _vm.rules1,
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "操作步骤", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入操作步骤" },
                        model: {
                          value: _vm.baseData.name,
                          callback: function($$v) {
                            _vm.$set(_vm.baseData, "name", $$v)
                          },
                          expression: "baseData.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "操作内容", prop: "description" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入操作内容"
                        },
                        model: {
                          value: _vm.baseData.description,
                          callback: function($$v) {
                            _vm.$set(_vm.baseData, "description", $$v)
                          },
                          expression: "baseData.description"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "检验标准", prop: "remark" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入检验标准"
                        },
                        model: {
                          value: _vm.baseData.remark,
                          callback: function($$v) {
                            _vm.$set(_vm.baseData, "remark", $$v)
                          },
                          expression: "baseData.remark"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.readOnly
        ? _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.addParams }
            },
            [_vm._v("添加参数")]
          )
        : _vm._e(),
      _c("MTable", {
        ref: "planTable",
        attrs: {
          "can-row-sortable": !_vm.readOnly,
          "show-page": false,
          height: 300,
          columns: _vm.columns,
          data: _vm.baseData.params,
          "only-key": "serialNo"
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "type",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [_vm._v(_vm._s(_vm.typesMap[row.type]))])
            }
          },
          {
            key: "requiredWrite",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm._v(_vm._s(_vm.requiredWriteMap[row.requiredWrite]))
              ])
            }
          },
          {
            key: "action",
            fn: function(ref) {
              var $index = ref.$index
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", disabled: _vm.readOnly },
                      on: {
                        click: function($event) {
                          return _vm.baseData.params.splice($index, 1)
                        }
                      }
                    },
                    [_vm._v("删除 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", disabled: _vm.readOnly },
                      on: {
                        click: function($event) {
                          return _vm.onEdit(row, $index)
                        }
                      }
                    },
                    [_vm._v("编辑 ")]
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c(
        "MDialog",
        {
          attrs: { width: "60%", title: "添加参数", "append-to-body": "" },
          on: { onOk: _vm.onSubmit },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-form",
              attrs: {
                "label-position": "right",
                model: _vm.params,
                rules: _vm.rules,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "参数名称", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入参数名称" },
                            model: {
                              value: _vm.params.name,
                              callback: function($$v) {
                                _vm.$set(_vm.params, "name", $$v)
                              },
                              expression: "params.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "参数类型", prop: "type" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择参数类型" },
                              on: { change: _vm.typeChange },
                              model: {
                                value: _vm.params.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.params, "type", $$v)
                                },
                                expression: "params.type"
                              }
                            },
                            _vm._l(_vm.types, function(item) {
                              return _c("el-option", {
                                key: item.val,
                                attrs: { label: item.label, value: item.val }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "重复次数", prop: "repeats" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "step-strictly": "",
                              step: 1,
                              min: 1,
                              "controls-position": "right",
                              placeholder: "请输入重复次数"
                            },
                            model: {
                              value: _vm.params.repeats,
                              callback: function($$v) {
                                _vm.$set(_vm.params, "repeats", $$v)
                              },
                              expression: "params.repeats"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  [3, 4].includes(_vm.params.type)
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "可能值",
                                prop: "candidateValues"
                              }
                            },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "bottom",
                                    trigger: "click"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    _vm._l(
                                      _vm.params.candidateOptions,
                                      function(item, idx) {
                                        return _c(
                                          "div",
                                          {
                                            key: idx,
                                            staticStyle: {
                                              minWidth: "150px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.label) + " "
                                            ),
                                            _c("el-button", {
                                              attrs: {
                                                type: "text",
                                                icon: "el-icon-circle-close"
                                              },
                                              on: {
                                                click: function() {
                                                  return _vm.params.candidateOptions.splice(
                                                    idx,
                                                    1
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c("el-input", {
                                    attrs: {
                                      slot: "reference",
                                      placeholder: "请输入可能值，敲击回车"
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.onEnter($event)
                                      }
                                    },
                                    slot: "reference",
                                    model: {
                                      value: _vm.params.candidateValues,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.params,
                                          "candidateValues",
                                          $$v
                                        )
                                      },
                                      expression: "params.candidateValues"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.params.type === 2
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "默认值", prop: "defaultValues" }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入默认值" },
                                model: {
                                  value: _vm.params.defaultValues,
                                  callback: function($$v) {
                                    _vm.$set(_vm.params, "defaultValues", $$v)
                                  },
                                  expression: "params.defaultValues"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.params.type === 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "默认值", prop: "defaultValues" }
                            },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "controls-position": "right",
                                  placeholder: "请输入默认值"
                                },
                                model: {
                                  value: _vm.params.defaultValues,
                                  callback: function($$v) {
                                    _vm.$set(_vm.params, "defaultValues", $$v)
                                  },
                                  expression: "params.defaultValues"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.params.type === 6
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "默认值时间展示格式",
                                prop: "defaultValues"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择默认值时间展示格式"
                                  },
                                  model: {
                                    value: _vm.params.defaultValues,
                                    callback: function($$v) {
                                      _vm.$set(_vm.params, "defaultValues", $$v)
                                    },
                                    expression: "params.defaultValues"
                                  }
                                },
                                _vm._l(_vm.timeOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.val,
                                    attrs: {
                                      label: item.label,
                                      value: item.val
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      [3, 4].includes(_vm.params.type)
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "默认值", prop: "defaultValues" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    multiple: _vm.params.type === 4,
                                    placeholder: "请选择默认值"
                                  },
                                  model: {
                                    value: _vm.params.defaultValues,
                                    callback: function($$v) {
                                      _vm.$set(_vm.params, "defaultValues", $$v)
                                    },
                                    expression: "params.defaultValues"
                                  }
                                },
                                _vm._l(_vm.params.candidateOptions, function(
                                  item,
                                  idx
                                ) {
                                  return _c("el-option", {
                                    key: idx,
                                    attrs: {
                                      label: item.label,
                                      value: item.val
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.params.type === 6
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否默认系统当前时间",
                                prop: "timeSys"
                              }
                            },
                            [
                              _c("el-switch", {
                                model: {
                                  value: _vm.params.timeSys,
                                  callback: function($$v) {
                                    _vm.$set(_vm.params, "timeSys", $$v)
                                  },
                                  expression: "params.timeSys"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否必填", prop: "requiredWrite" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择是否必填" },
                              model: {
                                value: _vm.params.requiredWrite,
                                callback: function($$v) {
                                  _vm.$set(_vm.params, "requiredWrite", $$v)
                                },
                                expression: "params.requiredWrite"
                              }
                            },
                            _vm._l(_vm.requiredWriteTypes, function(item) {
                              return _c("el-option", {
                                key: item.val,
                                attrs: { label: item.label, value: item.val }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }