var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MElTransfer",
    {
      attrs: {
        "filter-placeholder": "请输入用户/用户组",
        filterable: "",
        titles: ["源列表", "目的列表"],
        data: _vm.data,
        props: { key: "key", label: "name" },
        "filter-method": _vm.filterMethod,
        "source-data-methods": _vm.sourceDataMethods,
        "set-label-r-method": _vm.setLabelRMethod
      },
      model: {
        value: _vm.selectIds,
        callback: function($$v) {
          _vm.selectIds = $$v
        },
        expression: "selectIds"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "left-body-header",
          attrs: { slot: "leftBodyHeader" },
          slot: "leftBodyHeader"
        },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "m-radio-group",
              model: {
                value: _vm.params.radio,
                callback: function($$v) {
                  _vm.$set(_vm.params, "radio", $$v)
                },
                expression: "params.radio"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "user" } }, [
                _vm._v("用户")
              ]),
              _c("el-radio-button", { attrs: { label: "userGroup" } }, [
                _vm._v("用户组")
              ])
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }