import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/inspection/private/inspectionRepairRecord/'
export default {
  // 获取质检返修不分页
  getRepairRecordList(data) {
    return request.get(baseUrl + 'getInspectionRepairRecordList', data)
  },
  // 获取质检返修-分页
  getRepairRecordListPage(data) {
    return request.get(baseUrl + 'getInspectionRepairRecordListPage', data)
  },
  // 创建计划
  addRepairRecord(data) {
    return request.post(`api/web/v1/production/kiki/private/producePlan/confirmRepair`, data)
  },
  // 取得批次码
  getBatchNumber(id) {
    return request.get(`api/web/v1/production/kiki/private/producePlan/getRepairPlanBatchNumber?repairRecordId=${id}`)
  }
}
