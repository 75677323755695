<template>
  <div style="display: inline-block;">
    <el-popover
      ref="popover5"
      v-model="visible"
    >
      <p>{{ message }}</p>
      <div style="text-align: right; margin: 0">
        <el-button size="mini" type="text" @click="visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="onOk()">确定</el-button>
      </div>
    </el-popover>

    <el-button v-popover:popover5 type="text">{{ buttonText }}</el-button>
  </div>
</template>

<script>
export default {
  name: 'DelPopover',
  props: {
    buttonText: {
      type: String,
      default: '删除'
    },
    message: {
      type: String,
      default: '您确定要删除吗？'
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    onOk() {
      this.$emit('onOk', () => {
        this.visible = false
      })
    }
  }
}
</script>

<style scoped>

</style>
