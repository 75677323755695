import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/private/facility/'
const repairUrl = 'api/web/v1/field/facility/private/facilityWarranty/'
const attachmentsUrl = 'api/web/v1/field/facility/private/facilityAttachments/'
const equipmentUrl = 'api/web/v1/equipment/private/equipment/'

export default {
  // 根据客户id查询生产设备/客户机台
  async getEpuByCustomerId(customerId){
    const res = await request.get(`${equipmentUrl}getCustomerEquipmentList?customerId=${customerId}`)
    return res;
  },
  // 根据客户机台和换上换下类型 查询售后设备
  async getAfterSaleEpuByEquId(equId){
    let url = ""
    if(equId){
      url = `${baseUrl}getEquipmentFacilityList?equipmentId=${equId}`
    }else {
      url = `${baseUrl}getEquipmentFacilityList`
    }
    return await request.get(url)
  },
  // 获取设备类列表
  getEquipmentClassList() {
    return request.get(`/api/web/v1/basicData/private/equipmentClass/getEquipmentClassList`)
  },
  // 获取设备-不分页
  getFacilityList(data) {
    return request.get(baseUrl + 'getFacilityList', data)
  },
  // 获取设备-分页
  getFacilityListPage(data) {
    return request.get(baseUrl + 'getFacilityListPage', data)
  },
  // 新增设备
  addFacility(data) {
    return request.post(baseUrl + 'addFacility', data)
  },
  // 新增设备
  copyFacility(data) {
    return request.post(baseUrl + 'addFacility', data)
  },
  // 修改设备
  modifyFacility(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除设备
  delFacility(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  getFacilityDetail(data) {
    return request.get(baseUrl + 'getFacilityDetail', data)
  },
  // 新增质保信息
  addFacilityWarranty(data) {
    return request.post(repairUrl + 'addFacilityWarranty', data)
  },
  // 批量新增质保信息
  addListFacilityWarranty(data) {
    return request.post(repairUrl + 'addListFacilityWarranty', data)
  },
  // 修改质保信息
  modifyFacilityWarranty(data) {
    return request.put(repairUrl + 'updateOne', data)
  },
  // 删除质保信息
  delFacilityWarranty(data) {
    return request.del(repairUrl + 'deleteOne', data)
  },
  // 通过设备id获取保养工单列表
  getMaintainRadioListPage(data) {
    return request.get('api/web/v1/field/facility/invoices/private/facilityUpkeepWorkOrder/getFacilityUpkeepWorkOrderBeanPage', data)
  },
  // 通过设备id获取点检工单列表
  getSpotCheckRadioListPage(data) {
    return request.get('api/web/v1/field/facility/invoices/private/facilityUpkeepWorkOrder/getFacilityInspectionWorkOrderPage', data)
  },
  // 获取设备点检附件列表（不分页）
  getFacilityAttachmentsList(data) {
    return request.get(attachmentsUrl + 'getFacilityAttachmentsList', data)
  },
  // 新建设备点检附件
  addFacilityAttachments(data) {
    return request.post(attachmentsUrl + 'addFacilityAttachments', data)
  },
  // 删除设备点检附件
  delFacilityAttachments(data) {
    return request.del(attachmentsUrl + 'deleteOne', data)
  },
  // 协微 - 查看设备关联辅材
  getAuxiliaryList(data) {
    return request.get('api/web/v1/field/facility/private/facility/getEquipmentDetailAboutAuxiliaryMaterials', data)
  },
  // 获取设备当前关联的辅材使用记录（不分页）
  getAuxiliaryRecordList(data) {
    return request.get('api/web/v1/auxiliary/private/auxiliaryMaterial/getAuxiliaryMaterialAllUserRecordByEquipmentIdList', data)
  },
  // 获取辅材详情
  getAuxiliaryMaterialDetail(data) {
    return request.get('api/web/v1/auxiliary/private/auxiliaryMaterial/getAuxiliaryMaterialDetail', data)
  },
  // 获取售后设备使用记录
  getAfterSaleEquRecDetail(data) {
    return request.get('api/web/v1/auxiliary/private/auxiliaryMaterialAllUserRecord/getAuxiliaryMaterialAllUserRecordListPage', data)
  },
  // 获取辅材类列表-分页
  getAuxiliaryClassListPage(data) {
    return request.get(`/api/web/v1/auxiliary/private/auxiliaryClass/getAuxiliaryClassListPage`, data)
  },
  // 获取辅材类列表-不分页
  getAuxiliaryClassList(data) {
    return request.get(`/api/web/v1/auxiliary/private/auxiliaryClass/getAuxiliaryClassList`, data)
  },
  // 新增辅材类
  addAuxiliaryClass(data) {
    return request.post('/api/web/v1/auxiliary/private/auxiliaryClass/addAuxiliaryClass', data)
  },
  // 修改辅材类
  modifyAuxiliaryClass(data) {
    return request.put('/api/web/v1/auxiliary/private/auxiliaryClass/updateOne', data)
  },
  // 删除辅材类
  delAuxiliaryClass(data) {
    return request.del('/api/web/v1/auxiliary/private/auxiliaryClass/deleteOne', data)
  },
  // 获取辅材列表-分页
  getAuxiliaryMaterialsListPage(data) {
    return request.get(`/api/web/v1/auxiliary/private/auxiliaryMaterial/getAuxiliaryMaterialListPage`, data)
  },
  // 获取辅材列表-不分页
  getAuxiliaryMaterialsList(data) {
    return request.get(`/api/web/v1/auxiliary/private/auxiliaryMaterial/getAuxiliaryMaterialList`, data)
  },
  // 新增辅材
  addAuxiliaryMaterials(data) {
    return request.post('/api/web/v1/auxiliary/private/auxiliaryMaterial/addAuxiliaryMaterial', data)
  },
  // 复制辅材
  copyAuxiliaryMaterials(data) {
    return request.post('/api/web/v1/auxiliary/private/auxiliaryMaterial/addAuxiliaryMaterial', data)
  },
  // 修改辅材
  modifyAuxiliaryMaterials(data) {
    return request.put('/api/web/v1/auxiliary/private/auxiliaryMaterial/updateOne', data)
  },
  // 删除辅材
  delAuxiliaryMaterials(data) {
    return request.del('/api/web/v1/auxiliary/private/auxiliaryMaterial/deleteOne', data)
  },
  // 获取运入运出配置单列表（分页）
  getAuxiliaryInstructionRecordListPage(data) {
    return request.get('api/web/v1/auxiliary/private/auxiliaryOutInInstructionRecord/getAuxiliaryOutInInstructionRecordListPage', data)
  },
  // 获取运入运出配置单 - 配置明细列表（不分页）
  getAuxiliaryInstructionDetail(data) {
    return request.get('/api/web/v1/auxiliary/private/auxiliaryOutInInstructionRecordDetail/getAuxiliaryOutInInstructionRecordDetailList', data)
  },
  // 根据客户查询换上换下设备
  getUpAndDownEquByCustomerid(id,status) {
    return request.get(`api/web/v1/field/facility/private/facility/getFacilityListByStatusAndCustomer?customerId=${id}&statusStr=${status}`)
  },
}
