<template>
    <div class="nodeElement" @click="nodeClick">
      <div  v-bind:class="[{isselected:selected} ,'cardBox']" >
              <div class="produces-li" >
                <div class="li-tit">
                  <span>{{ item.procedureName }}</span>
                  <img v-if="item.type == 0 && item.procedureType == 0" src="@/assets/plans/in.png" style="width: 24px;height:24px;margin: -5px 0 0 10px;">
                  <img v-if="item.type == 1 && item.procedureType == 1" src="@/assets/plans/out.png" style="width: 24px;height:24px;margin: -5px 0 0 10px;">
                  <img v-if="item.type === 1 && item.procedureType === 0" src="@/assets/plans/wai.png" style="width: 40px;height:22px;margin-left: 10px;">
                </div>
                <div class="li-text">
                  <div class="tit-top">
                    <span>报：</span>
                    <div class="tit-cen">
                      <el-progress v-if="!isNaN(parseInt(fixData(parseFloat(item.reportWorkQuantity) + parseFloat(item.inWarehouseQuantity), item.sourceQuantity)))" ref="progress" style="width:110px;" :percentage="fixData(parseFloat(item.reportWorkQuantity) + parseFloat(item.inWarehouseQuantity), item.sourceQuantity)" :show-text="false" :color="fixData(item.reportWorkQuantity, item.sourceQuantity) < 100 ? '#607FFF' : '#00AB29'" />
                    </div>
                    <div class="tit-ri">{{ item.reportWorkQuantity ? parseFloat(item.reportWorkQuantity) + parseFloat(item.inWarehouseQuantity) : 0 }}/{{ item.sourceQuantity }}</div>
                  </div>
                  <div class="tit-top">
                    <span>检：</span>
                    <div class="tit-cen">
                      <el-progress v-if="!isNaN(parseInt(fixData(item.okQuantity, item.checkQuantity)))" ref="progress1" style="width:110px;" :percentage="fixData(item.okQuantity, item.checkQuantity)" :show-text="false" :color="fixData(item.okQuantity, item.checkQuantity) < 100 ? '#607FFF' : '#00AB29'" />
                    </div>
                    <div class="tit-ri">{{ item.okQuantity ? item.okQuantity : 0 }}/{{ item.checkQuantity ? item.checkQuantity : 0 }}</div>
                  </div>
                  <div class="tit-top">
                    <span>返：</span>
                    <div class="tit-cen">
                      <el-progress v-if="!isNaN(parseInt(fixData(item.repairReportQuantity, item.repairQuantity)))" ref="progress2" style="width:110px;" :percentage="fixData(item.repairReportQuantity, item.repairQuantity)" :show-text="false" :color="fixData(item.repairReportQuantity, item.repairQuantity) < 100 ? '#607FFF' : '#00AB29'" />
                    </div>
                    <div class="tit-ri">{{ item.repairReportQuantity ? item.repairReportQuantity : 0 }}/{{ item.repairQuantity ? item.repairQuantity : 0 }}</div>
                  </div>
                </div>
              </div>
            </div>
    </div>
  </template>
   
  <script>
  import "@antv/x6-vue-shape"
  export default {
    name: "ProcedureContent",
    inject: ["getGraph", "getNode"],
  data() {
    return {
      item:{
      },
      selected:false
    };
  },
  mounted() {
    const self = this;
    const node = this.getNode();
    const graph = this.getGraph();
    this.item = node.data.item;
    console.log(this.item);
    // 监听数据改变事件
    // node.on("change:data", ({ current }) => {
    //   self.item = current.item;
    // });
  },
    methods: {
      fixData(val, total) {
      return val && total ? parseInt((val / total) * 100) > 100 ? 100 : parseInt((val / total) * 100) : 0
    },
    nodeClick(){
      this.selected = true
      setTimeout(() => {
      this.selected = false
        
      }, 600);
    }
    },
  };
  </script>
  <style lang="scss" scoped>
  .cardBox{

    display: flex;
    .produces-li {
      width: 235px;
      height: 155px;
      background: #FFFFFF;
      box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      border: 1px solid #ECEEF2;
      cursor: pointer;
      .li-tit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 55px;
        padding: 7px 12px;
        background: #F6F7F9;
        border-radius: 4px 4px 0px 0px;
        border: 1px solid #ECEEF2;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #393D60;
        line-height: 20px;
      }
      .li-text {
        padding: 7px 12px;
        .tit-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #393D60;
          line-height: 20px;
          margin-bottom: 7px;
          span{
            display: block;
            width: 30px;
          }
          .tit-cen {
            // width: 141px;
            width: 110px;
          }
          .tit-ri {
            width: 80px;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #9597AE;
            line-height: 20px;
            text-align: right;
          }
        }
      }
    }
  }
.isselected{
  border: 1px solid rgb(96, 127, 255) ;
}
  </style>