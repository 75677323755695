import { paramsType } from '@/config/options.config'
import _ from 'lodash'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
const paramsFormListTemplate = [
  {
    label: '参数名称',
    key: 'id',
    tagName: 'el-select',
    required: true,
    on: { change: (val) => { functionList.paramsNameChange(val) } },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      },
      {
        tagName: 'div',
        style: { height: '40px', width: '100%' },
        children: []
      },
      {
        tagName: 'el-button',
        style: {
          position: 'absolute',
          bottom: 0,
          border: 'none',
          borderRadius: '0 0 4px 4px',
          background: '#EBEEFA'
        },
        props: { icon: 'el-icon-circle-plus-outline' },
        innerText: '新增',
        children: [],
        on: {
          click: () => { functionList.addNewParams() }
        }
      }
    ]
  },
  {
    label: '参数类型',
    key: 'type',
    tagName: 'el-select',
    required: true,
    props: {
      disabled: true,
      placeholder: '请选择参数名称'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: paramsType
      }
    ]
  },
  {
    label: '填写',
    labelTip: '此处设置的数量为需填写几组此参数',
    key: 'repeatNumber',
    tagName: 'el-input-number',
    required: true,
    rules: [{ required: true, type: 'number', message: '请输入填写', trigger: 'blur' }],
    defaultValue: 1,
    props: {
      controlsPosition: 'right',
      min: 1,
      max: 100
    },
    children: []
  },
  {
    label: '是否必填',
    key: 'required',
    tagName: 'el-switch',
    props: {
      activeText: '是',
      inactiveText: '否'
    },
    children: []
  },
  {
    label: '是否只读',
    key: 'readonly',
    tagName: 'el-switch',
    props: {
      activeText: '是',
      inactiveText: '否'
    },
    children: []
  }
]

// 0:数值 1:文本 2:单选 3:多选 4:图片 5:时间
const inputFormList = _.cloneDeep(paramsFormListTemplate)
inputFormList.splice(3, 0, {
  label: '默认值',
  key: 'defaultValue',
  tagName: 'el-input',
  required: false,
  children: []
})
const numberFormList = _.cloneDeep(paramsFormListTemplate)
numberFormList.splice(3, 0, {
  label: '默认值',
  key: 'defaultValue',
  tagName: 'el-input-number',
  props: {
    controlsPosition: 'right'
  }
})
const singleFormList = _.cloneDeep(paramsFormListTemplate)
singleFormList.splice(3, 0, {
  label: '默认值',
  key: 'defaultValue',
  tagName: 'el-input',
  required: false,
  children: []
}, {
  label: '可能值',
  key: 'options',
  tagName: 'el-select',
  required: true,
  props: {
    multiple: true,
    filterable: true,
    allowCreate: true,
    defaultFirstOption: true,
    placeholder: '请输入可能值，敲击击回车'
  },
  children: [
    {
      tagName: 'el-option',
      options: []
    }
  ]
})
singleFormList.splice(6, 1)

const multipleFormList = _.cloneDeep(paramsFormListTemplate)
multipleFormList.splice(3, 0, {
  label: '默认值',
  key: 'defaultValue',
  tagName: 'el-select',
  props: {
    multiple: true,
    filterable: true,
    allowCreate: true,
    defaultFirstOption: true,
    placeholder: '请输入默认值，敲击回车'
  },
  children: [
    {
      tagName: 'el-option',
      options: []
    }
  ]
}, {
  label: '可能值',
  key: 'options',
  tagName: 'el-select',
  required: true,
  props: {
    multiple: true,
    filterable: true,
    allowCreate: true,
    defaultFirstOption: true,
    placeholder: '请输入可能值，敲击回车'
  },
  children: [
    {
      tagName: 'el-option',
      options: []
    }
  ]
})
multipleFormList.splice(6, 1)

const dateFormList = _.cloneDeep(paramsFormListTemplate)
dateFormList.splice(3, 0, {
  label: '默认值时间展示格式',
  key: 'format',
  tagName: 'el-select',
  props: {
    placeholder: '请选择参数名称'
  },
  children: [
    {
      tagName: 'el-option',
      props: {
        label: 'name',
        value: 'id'
      },
      options: [
        {
          id: 'yyyy-MM-dd HH:mm:ss',
          name: 'yyyy-mm-dd hh:mm:ss'
        },
        {
          id: 'yyyy-MM-dd HH:mm',
          name: 'yyyy-mm-dd hh:mm'
        },
        {
          id: 'yyyy-MM-dd',
          name: 'yyyy-mm-dd'
        }
      ]
    }
  ]
})
dateFormList.splice(5, 1, {
  label: '是否默认系统当前时间',
  key: 'isCurrentTime',
  tagName: 'el-switch',
  props: {
    activeText: '是',
    inactiveText: '否'
  },
  children: []
})

const uploadFormList = _.cloneDeep(paramsFormListTemplate)
uploadFormList.splice(4, 1)
uploadFormList.splice(2, 1)
export const paramsFormListObj = {
  0: numberFormList,
  1: inputFormList,
  2: singleFormList,
  3: multipleFormList,
  4: uploadFormList,
  5: dateFormList
}
