var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          class: _vm.allPropsData.width === "400px" ? "middle-dialog" : "",
          attrs: { visible: _vm.value, fullscreen: _vm.fullscreen },
          on: {
            close: function($event) {
              return _vm.close(true)
            }
          }
        },
        "el-dialog",
        _vm.allPropsData,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.$slots.title
        ? _c("template", { slot: "title" }, [_vm._t("title")], 2)
        : _c("template", { slot: "title" }, [
            _c("div", { staticClass: "head-all" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$attrs.title))
              ]),
              _c(
                "div",
                { staticClass: "head-ri", on: { click: _vm.showFull } },
                [
                  _vm.fullscreen
                    ? _c("img", {
                        staticClass: "ri-img",
                        attrs: {
                          src: require("@/assets/narrow-amplification/narrow.png")
                        }
                      })
                    : _c("img", {
                        staticClass: "ri-img",
                        attrs: {
                          src: require("@/assets/narrow-amplification/amplification.png"),
                          alt: ""
                        }
                      })
                ]
              )
            ])
          ]),
      _vm.showBody
        ? _c(
            "div",
            { staticClass: "body", style: { maxHeight: _vm.height + "px" } },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm.showFooter
        ? [
            _vm.$slots.footer
              ? _c("template", { slot: "footer" }, [_vm._t("footer")], 2)
              : _c(
                  "div",
                  {
                    class:
                      _vm.allPropsData.width === "400px"
                        ? "mubai-dialog-footer center-content"
                        : "mubai-dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _vm.needCancelBtn
                      ? _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.close()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.cancelButtonText))]
                        )
                      : _vm._e(),
                    _vm.saveButton
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              loading: _vm.saveloading
                            },
                            on: {
                              click: function($event) {
                                return _vm.onSave()
                              }
                            }
                          },
                          [_vm._v("保存并提交")]
                        )
                      : _vm._e(),
                    _vm.needSubBtn
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", loading: _vm.loading },
                            on: {
                              click: function($event) {
                                return _vm.onOk()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.confirmButtonText))]
                        )
                      : _vm._e()
                  ],
                  1
                )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }