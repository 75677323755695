import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/basicData/private/calendar/'		
export default {
  // 获取日历-不分页
  getCalendarList(data) {
    return request.get(baseUrl + 'getCalendarList', data)
  },
  // 获取日历-分页
  getCalendarListPage(data) {
    return request.get(baseUrl + 'getCalendarListPage', data)
  },
  // 新增日历
  addCalendar(data) {
    return request.post(baseUrl + 'addCalendar', data)
  },
  // 修改日历
  modifyCalendar(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除日历
  delCalendar(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  //批量删除
  batchDelete(data){
    return request.post(baseUrl + 'batchDelete', data)
  },
  // 获取日历详情
  getCalendarDetail(data){	
    return request.get(baseUrl + 'getCalendarDetail', data)
  },
  //批量启用
  batchEnable(data){
    return request.post(baseUrl + 'batchEnable', data)
  },
  //批量停用
  batchDisable(data){
    return request.post(baseUrl + 'batchDisable', data)
  },
  // 校验是否有生产日历
  checkExistDefaultCalendar(data){
    return request.get(baseUrl + 'checkExistDefaultCalendar', data)
  }
}
