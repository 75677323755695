import request from '@/utils/requestForAsync'
import { getUrl } from '@/utils/index'
const baseUrl = 'api/web/v1/production/kiki/private/outsourcingRequest/'

export default {
  // 外协申请分页
  getOutsourcingRequestListPage(data) {
    return request.get(baseUrl + 'getOutsourcingRequestListPage', data)
  },
  // 外协申请-不分页
  getOutsourcingRequestList(data) {
    return request.get(baseUrl + 'getOutsourcingRequestList', data)
  },
  // 外协申请
  saveOutsourcingRequest(taskIds, remark) {
    return request.post(baseUrl + `addOutsourcingRequest?remark=${remark}&taskIds=${taskIds}`)
  },
  // 外协通过
  reviewOutsourcingRequest(params) {
    return request.put(baseUrl + `reviewOutsourcingRequest${getUrl(params)}`)
  },
  // 外协撤销
  cancelOutsourcingRequest(params) {
    return request.put(baseUrl + `cancelOutsourcingRequest${getUrl(params)}`)
  },
  // 外协驳回
  rejectOutsourcingRequest(params) {
    return request.put(baseUrl + `rejectOutsourcingRequest${getUrl(params)}`)
  },
  // 外协删除
  delOutsourcingRequest(data) {
    return request.del(baseUrl + 'deleteOne', data)
  }
}
