import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/private/facilityConfigurationParameter/'

export default {
  // 新建设备配置参数
  addFacilityParameter(data) {
    return request.post(baseUrl + 'addFacilityConfigurationParameter', data)
  },
  // 修改设备配置参数
  modifyFacilityParameter(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除设备配置参数
  delFacilityParameter(data) {
    return request.del(baseUrl + 'deleteOne', data)
  }
}
