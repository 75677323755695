<template>
  <div v-if="showDatas && showDatas.length >0" ref="elements" class="gantt-blocks" :style="{height:blockHeight + 'px'}">
    <div class="gantt-block gantt-block-top-space" :style="{height:topSpace+'px'}" />
    <div
      v-for="(data,index) in showDatas"
      :key="dataKey?data[dataKey]:index"
      class="gantt-block"
      :style="{
        backgroundSize: `${cellWidth}px ${currentGanttBlocksHeights[data.id] || (cellHeight + 'px')}`,
        height: `${currentGanttBlocksHeights[data.id] || `${cellHeight}px`}`,
        backgroundColor: selectId === data.id ? 'rgba(230, 232, 239, 0.3)' : ''
      }"
      :class="[index === rowIndex ? 'rowColor':'']"
      @click="clickRow(index)"
    >
      <!-- 整个数据列表-->
      <template v-if="!customGenerateBlocks">
        <!-- 行-->
        <div
          v-for="(item, idx) in concatArray(data)"
          v-if="fixData(item)"
          :key="itemKey?item[itemKey]:idx"
          class="gantt-block-item block"
          :class="[blockState && (item === data.gtArray[operationIndex]) ? groundFloor : superstratum]"
          :style="{left: item.actualStartTime && compareDate(item.planStartTime , item.actualStartTime)
                     ? getPosition(item.actualStartTime)+'px' : getPosition(item.start)+'px',
                   width: item.actualStartTime? '' : getWidth(item)+'px',
                   top: getTop(item, data.gtArray, data, idx) + 'px'
          }"
        >
          <slot :data="data" :item="item" :getPositonOffset="getPositonOffset" :getWidthAbout2Times="getWidthAbout2Times">
            <div class="gantt-block-defaultBlock" style="width:100%">need slot</div>
          </slot>
        </div>
      </template>
      <template v-else>
        <slot
          :data="data"
          :getPositonOffset="getPositonOffset"
          :getWidthAbout2Times="getWidthAbout2Times"
          :isInRenderingTimeRange="isInRenderingTimeRange"
        >need slot</slot>
      </template>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import dr from '../dynamic-render.js'
import { isUndef, warn } from '@/components/ganttChart/utils/tool.js'
import api from '@/api/information/production/process'
import apis from '@/api/production/plans'
let levels = []
export default {
  name: 'Blocks',
  mixins: [dr],
  props: {
    selectIds: {
      type: Number
    },
    dataKey: String,
    itemKey: String,
    arrayKeys: {
      type: Array
    },
    scrollLeft: Number,
    cellWidth: {
      type: Number,
      required: true
    },
    scale: {
      type: Number,
      required: true
    },
    endTimeOfRenderArea: [Number, null],
    startTimeOfRenderArea: [Number, null],
    getPositonOffset: Function,
    getWidthAbout2Times: Function,
    customGenerateBlocks: Boolean,
    editStart: {
      type: Date
    },
    editEnd: {
      type: Date
    },
    state: {
      type: Boolean
    },
    uninstall: {
      type: Boolean
    },
    rowIndex: {
      type: Number
    },
    start: {
      default: () => dayjs().startOf('month').hour(0).minute(0)
        .toString()
    },
    end: {
      default: () => dayjs().endOf('month').hour(23).minute(59)
        .toString()
    }
  },
  data() {
    return {
      handleIndex: 0, // 行角标
      handleItem: {},
      handleData: {}, // 当前行数据
      dataCorner: 0, // 当前行角标
      dialogVisible: false,
      content: {},
      blockIndex: -1, // 行内航班所在行角标
      operationIndex: null,
      blockState: false,
      groundFloor: 'groundFloor',
      superstratum: 'superstratum',
      isStatus: 0,
      processId: null,
      plansId: null,
      currentDate: '',
      heightObj: {},
      selectId: undefined
    }
  },
  computed: {
    renderAarrys() {
      const { arrayKeys } = this
      if (arrayKeys.length > 0) {
        return arrayKeys
      }
      return ['gtArray']
    },
    currentGanttBlocksHeights() {
      return this.$store.state.user.currentGanttBlocksHeights
    },
    startTime() {
      return this.start
    },
    endTime() {
      return this.end
    },
    blockHeight() {
      const { datas, cellHeight } = this
      const defaultHeightData = datas.filter(item => !this.currentGanttBlocksHeights[item.id])
      const newHeight = Object.keys(this.currentGanttBlocksHeights).reduce((a, b) => parseInt(a) + parseInt(b), '0')
      return defaultHeightData.length * cellHeight + newHeight
    }
  },
  watch: {
    selectIds(val) {
      this.selectId = val
    },
    state() {
      if (this.state) {
        if ((Number(dayjs(this.editStart).valueOf()) >= Number(this.showDatas[this.dataCorner].gtArray[this.blockIndex - 1].end)) &&
            (Number(dayjs(this.editEnd).valueOf()) <= Number(this.showDatas[this.dataCorner].gtArray[this.blockIndex + 1].start))) {
          this.showDatas[this.dataCorner].gtArray[this.blockIndex].start = dayjs(this.editStart).valueOf()
          this.showDatas[this.dataCorner].gtArray[this.blockIndex].end = dayjs(this.editEnd).valueOf()
        } else {
          this.$emit('creatDialog', true)
        }
      }
    },
    uninstall() {
      if (this.uninstall) {
        this.showDatas[0].gtArray.push(this.handleItem)
        this.showDatas[this.dataCorner].gtArray.splice(this.handleIndex, 1)
        let arr = []
        arr = this.showDatas[0].gtArray
        this.$emit('changeUninstall', false, arr)
      }
      this.$emit('detailsItem', this.handleItem)
    }
  },
  mounted() {
    this.currentDate = dayjs().format('YYYY-MM-DD HH:mm:ss')
  },
  methods: {
    fixData(item) {
      const time = item.produceStatus !== 2 && item.isPlan === 1
      return time
    },
    compare(key) {
      return function(obj1, obj2) {
        if (obj1[key] < obj2[key]) {
          return -1
        } else if (obj1[key] === obj2[key]) {
          return 0
        } else {
          return 1
        }
      }
    },
    getTop(data, gtArray, originData, index) {
      let gtEndTimes
      if (!gtArray) gtArray = []
      const newGtArray = gtArray.filter(item => item.isPlan === 1 && item.produceStatus !== 2 &&
          this.compareDate(data.minTime, this.startTime.format('YYYY-MM-DD HH:00')) &&
          !this.compareDate(data.minTime, this.endTime.format('YYYY-MM-DD HH:59'))
      )
      if (index === 0) {
        levels.push({ id: data.id, level: index })
      } else {
        // 取最大层数
        let maxLevel = 0
        levels.map(items => {
          if (items.level > maxLevel) { maxLevel = items.level }
        })
        const tmplist = newGtArray.filter((tmpItem, tmpIdx) => tmpIdx < index)
        const overlapLevel = []
        // 过滤与当前block之前重叠的block
        const overlapLists = tmplist.filter(tmpItem => this.compareDate(data.minTime, tmpItem.minTime) && !this.compareDate(data.minTime, tmpItem.maxTime))
        // 筛选重叠block的层数并记录层数
        overlapLists.map(tmpItem => {
          levels.map(items => {
            if (tmpItem.id === items.id) {
              overlapLevel.push(items.level)
            }
          })
        })
        // 找到不重叠层
        for (let i = 0; i <= maxLevel + 1; i++) {
          if (!overlapLevel.includes(i)) {
            levels.push({ id: data.id, level: i })
            break
          }
        }
      }
      // 计算每个block的top
      const blockLevel = levels.length > 0 ? levels[levels.length - 1].level : 0
      const heights = blockLevel > 0 ? 18 * blockLevel + (blockLevel + 1) * 14 : 14
      // // 计算每行高度
      if (!this.heightObj[originData.id] || (parseInt(this.heightObj[originData.id]) <= heights + 18 + 14)) {
        this.heightObj[originData.id] = heights + 18 + 14 + 'px'
      }
      this.$store.dispatch('user/currentGanttBlocksHeights', this.heightObj)
      if (gtArray.length - 1 === index) {
        levels = []
      }
      return heights
    },
    compareDate(d1, d2) {
      if (d1 && d2) {
        return ((new Date(d1.replace(/\-/g, '\/'))) >= (new Date(d2.replace(/\-/g, '\/'))))
      }
      return false
    },
    /**
       * 根据renderAarrys拼接需要渲染的数组
       *
       * @param {*} data
       * @returns {[]} 该data中所有需要渲染的数据
       */
    concatArray(data) {
      const datas = this.renderAarrys.reduce((prev, curr) => {
        if (Array.isArray(data[curr])) {
          return prev.concat(data[curr])
        } else {
          return prev
        }
      }, [])
      return datas
    },
    /**
       * 判定数据是否在渲染的时间范围内
       *
       * @param {{time:string}} item
       * @returns {boolean} 该
       */
    isInRenderingTimeRange(time) {
      if (this.heightOfRenderAera === 0) {
        return false
      }

      const { startTimeOfRenderArea, endTimeOfRenderArea } = this
      if (isUndef(startTimeOfRenderArea) || isUndef(endTimeOfRenderArea)) {
        return false
      }
      const timeToMs = new Date(time).getTime()
      if (startTimeOfRenderArea <= timeToMs || timeToMs >= endTimeOfRenderArea) {
        return true
      }
      return false
    },
    /**
       * 计算时间块长度
       *
       * @param {{start:string,end:string}} block
       * @returns {number}
       */
    getWidth(block) {
      if (isUndef(block.start) || isUndef(block.end)) {
        // warn(`错误，该数据项不含start值 与 end 值 ${JSON.stringify(block)}，无法计算宽度值。`)
        return 0
      }

      return this.getWidthAbout2Times(block.start, block.end)
    },
    /**
       * 计算时间块偏移
       *
       * @param {{start:string}} block
       * @returns {number}
       */
    getPosition(block) {
      if (isUndef(block)) {
        warn(
          `错误，该数据项不含start 值 ${JSON.stringify(
            block
          )}，无法计算偏移值。`
        )
        return 0
      }
      return this.getPositonOffset(block)
    },
    // mousedown (data, item, index) {
    //   this.processId = item.processFlowId
    //   this.plansId = item.id
    //   if (item.status === 0 || item.status === 2) {
    //     this.$emit('showTime', true)
    //     this.handleItem = item
    //     this.handleData = data
    //     this.blockIndex = index
    //     for(let i = 0;i<this.showDatas.length;i++) {
    //       if(this.showDatas[i] === data){
    //         this.dataCorner = i
    //       }
    //     }
    //     this.$emit('nowRow', this.dataCorner)
    //     this.isStatus = 0
    //   } else {
    //     this.isStatus = 1
    //   }
    // },
    async drop(event, val, index) {
      const show = await this.processDetail(val.id)
      if (this.isStatus === 0 && show) {
        let str
        for (let j = 0; j < this.showDatas.length; j++) {
          if (this.showDatas[j] === val) {
            str = j
          }
        }
        const data = this.showDatas[str].gtArray
        // 判断是否重叠(不重叠)
        const blockStart = dayjs(this.handleItem.start).valueOf()
        const blockEnd = dayjs(this.handleItem.end).valueOf()
        let x = 0
        for (let i = 0; i < data.length; i++) {
          if (((blockEnd >= dayjs(data[i].end).valueOf()) && (blockStart >= dayjs(data[i].end).valueOf())) ||
              ((blockEnd <= dayjs(data[i].start).valueOf()) && (blockStart <= dayjs(data[i].start).valueOf()))) {
            ++x
          }
        }
        // if(x === data.length) {
        data.push(this.handleItem)
        this.showDatas[this.dataCorner].gtArray.splice(this.blockIndex, 1)
        this.$emit('nowRow', this.showDatas[str].id)
        // }
        this.fixPlan(this.plansId, val.id)
      }
      if (this.isStatus === 0 && !show) {
        this.$message.info('该工艺下没有此产线')
      }
    },
    newData() {
      console.log('newdata!', this.dataCorner, this.blockState)
      this.operationIndex = this.blockIndex
      this.blockState = true
    },
    clickRow(data) {
      this.$emit('nowRow', data)
    },
    async fixPlan(id, areaId) {
      await apis.modifyPlan({ id: id, areaId: areaId })
    },
    async processDetail(id) {
      const res = await api.getProcessDetail({ id: this.processId })
      if (res) {
        return res.areaList ? res.areaList.filter(item => item.id === id).length > 0 : false
      }
    }
  }
}
</script>

<style lang="scss">
  .gantt-block-item {
    height: 18px !important;
  }
  .block{
    // cursor: move;
    position: fixed;
  }
  .groundFloor{
    z-index: 0;
    position:absolute;
  }
  .superstratum{
    z-index: 1;
    position:absolute;
  }
  .rowColor{
    background-color: #edf3ef;
  }
  .creatColor{
    background-color: #dfe5e1 !important;
  }
  .gantt-blocks {
    .gantt-block {
      &:last-child {
        border-bottom: 1px solid #ececec;
      }
    }
  }
</style>

