var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mark-line", {
    attrs: {
      "mark-line-time": _vm.currentTime,
      "get-positon-offset": _vm.getPositonOffset,
      color: "rgba(255,0,0,.4)"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }