import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/invoices/private/facilitySpotCheckPlan/'
const baseUrl1 = 'api/web/v1/field/facility/invoices/private/facilityPlanMaintenanceTemplate/'

export default {
  // 获取点检计划-不分页
  getFacilitySpotCheckPlanList(data) {
    return request.get(baseUrl + 'getFacilitySpotCheckPlanList', data)
  },
  // 获取点检计划-分页
  getFacilitySpotCheckPlanListPage(data) {
    return request.get(baseUrl + 'getFacilitySpotCheckPlanListPage', data)
  },
  // 新增点检计划
  addFacilitySpotCheckPlan(data) {
    return request.post(baseUrl + 'addFacilitySpotCheckPlan', data)
  },
  // 修改点检计划
  modifyFacilitySpotCheckPlan(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 复制点检计划
  copyFacilitySpotCheckPlan(data) {
    return request.post(baseUrl + 'addFacilitySpotCheckPlan', data)
  },
  // 删除点检计划
  delFacilitySpotCheckPlan(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 批量删除点检计划
  delBatchFacilitySpotCheckPlan(data) {
    return request.del(baseUrl + 'deleteBatch', data)
  },
  // 获取点检计划-详情
  getFacilitySpotCheckPlanDetail(data) {
    return request.get(baseUrl + 'getFacilitySpotCheckPlanDetail', data)
  },
  // 获取点检计划模板-不分页
  getPlanSpotCheckTemplateList(data) {
    return request.get(baseUrl1 + 'getFacilityPlanMaintenanceTemplateList', data)
  },
  // 获取点检计划模板-分页
  getPlanSpotCheckTemplateListPage(data) {
    return request.get(baseUrl1 + 'getFacilityPlanMaintenanceTemplateListPage', data)
  },
  // 新增点检计划模板
  addPlanSpotCheckTemplate(data) {
    return request.post(baseUrl1 + 'addFacilityPlanMaintenanceTemplate', data)
  },
  // 修改点检计划模板
  modifyPlanSpotCheckTemplate(data) {
    return request.put(baseUrl1 + 'updateOne', data)
  },
  // 删除点检计划模板
  delPlanSpotCheckTemplate(data) {
    return request.del(baseUrl1 + 'deleteOne', data)
  },
  // 获取点检计划模板模板-详情
  getPlanSpotCheckTemplateDetail(data) {
    return request.get(baseUrl1 + 'getFacilityPlanMaintenanceTemplateDetail', data)
  }
}
