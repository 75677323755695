import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/equipment/check/private/equipmentSpotCheckTask/'
const cycleUrl = 'api/web/v1/equipment/setting/private/spotCheckItemEquipmentClassMapping/'

export default {
  // 获取点检单-不分页
  getPointBillsList(data) {
    return request.get(baseUrl + 'getEquipmentSpotCheckTaskList', data)
  },
  // 获取点检单-分页
  getPointBillsListPage(data) {
    return request.get(baseUrl + 'getEquipmentSpotCheckTaskListPage', data)
  },
  // 新增点检单
  addPointBills(data) {
    return request.post(baseUrl + 'addEquipmentSpotCheckTask', data)
  },
  // 新增点检单
  copyPointBills(data) {
    return request.post(baseUrl + 'addEquipmentSpotCheckTask', data)
  },
  // 修改点检单
  modifyPointBills(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 作废点检单
  abolishPointBills(data) {
    return request.put(baseUrl + 'nullify', data)
  },
  // 跳过点检单
  passPointBills(data) {
    return request.put(baseUrl + `skip?skipReason=${data.skipReason}`, data.ids)
  },
  getPointBillsDetail(data) {
    return request.get(baseUrl + 'getEquipmentSpotCheckTaskDetail', data)
  },
  // 获取点检类型
  getCheckTypeList(data) {
    return request.get(cycleUrl + 'getSpotCheckItemEquipmentClassMappingList', data)
  },
  // 获取跳过原因
  getSkipReason(params) {
    return request.get('api/web/v1/equipment/check/private/equipmentSpotCheckTask/getEquipmentSpotCheckSkipReasonList', params)
  }
}
