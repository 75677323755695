var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "materials-class",
      style: {
        height:
          _vm.type === "mbom" || _vm.type === "warnMoreM" ? "auto" : "100%",
        display: _vm.type === "warnMoreM" ? "flex" : "block"
      }
    },
    [
      _vm.type === "warnMoreM" && _vm.showWarnIcon
        ? _c("img", {
            staticStyle: {
              width: "15px",
              height: "15px",
              "margin-top": "22px",
              "margin-left": "10px"
            },
            attrs: { src: require("@/assets/information/bom/warning.png") }
          })
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpen,
              expression: "isOpen"
            }
          ],
          staticStyle: { height: "100%", width: "100%" }
        },
        [
          _vm.type != "warnMoreM"
            ? _c(
                "div",
                { staticClass: "top" },
                [
                  _c("div", [_vm._v("BOM层级")]),
                  _c("el-input-number", {
                    staticClass: "min-input",
                    attrs: {
                      "controls-position": "right",
                      min: 0,
                      max: _vm.maxLevel
                    },
                    model: {
                      value: _vm.level,
                      callback: function($$v) {
                        _vm.level = $$v
                      },
                      expression: "level"
                    }
                  }),
                  _vm.type == "bom"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "default", size: "small" },
                          on: { click: _vm.changeMBOM }
                        },
                        [_vm._v("转MBOM")]
                      )
                    : _c("el-switch", {
                        attrs: {
                          value: _vm.isCloseProcess,
                          title: "是否开启工艺路线",
                          "inactive-color": "#eee"
                        },
                        on: { change: _vm.switchStatus }
                      }),
                  _vm.type == "bom" || _vm.type == "mbom"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.exportBomLevel }
                        },
                        [_vm._v("全部导出")]
                      )
                    : _vm._e(),
                  _vm.mySelfShowFlag
                    ? _c(
                        "div",
                        {
                          staticStyle: { cursor: "pointer" },
                          attrs: { title: "点击收起" },
                          on: {
                            click: function($event) {
                              return _vm.changeWidthStatus(1)
                            }
                          }
                        },
                        [_c("i", { staticClass: "el-icon-d-arrow-left" })]
                      )
                    : _vm._e(),
                  _vm.type == "changeBom" && _vm.showWithdraw
                    ? _c(
                        "div",
                        { staticStyle: { "padding-top": "2px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "success", size: "small" },
                              on: { click: _vm.withdrawMaterials }
                            },
                            [_vm._v("撤回")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.withdrawalByProcess }
                            },
                            [_vm._v("按工序撤回")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type === "changeBom"
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            cursor: "pointer",
                            "padding-top": "2px"
                          },
                          attrs: {
                            title: _vm.showWithdraw
                              ? "点击收起撤回"
                              : "点击展开撤回"
                          },
                          on: {
                            click: function($event) {
                              return _vm.changeWithdrawStatus(!_vm.showWithdraw)
                            }
                          }
                        },
                        [
                          _c("i", {
                            class: _vm.showWithdraw
                              ? "el-icon-d-arrow-left"
                              : "el-icon-d-arrow-right"
                          })
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "tree", staticStyle: { height: "auto" } },
            [
              _c("BomTree", {
                ref: "zTrees",
                attrs: {
                  view: _vm.view,
                  nodes: _vm.nodes,
                  level: _vm.level,
                  type: _vm.type,
                  showWithdraw: _vm.showWithdraw
                },
                on: {
                  command: _vm.command,
                  nodeClick: _vm.nodeClick,
                  chooseNode: _vm.chooseNode,
                  getTreeLevel: _vm.getTreeLevel,
                  onFilterDrop: _vm.onFilterDrop
                }
              })
            ],
            1
          ),
          _c(
            "MDialog",
            {
              attrs: { title: _vm.currentType },
              on: { onOk: _vm.submitForm },
              model: {
                value: _vm.visible,
                callback: function($$v) {
                  _vm.visible = $$v
                },
                expression: "visible"
              }
            },
            [
              _c("MFormBuilder", {
                ref: "formBuild",
                attrs: { "form-data": _vm.formData, "form-list": _vm.formList }
              })
            ],
            1
          ),
          _c(
            "MDialog",
            {
              attrs: { "append-to-body": true, title: "撤回投入产出物料清单" },
              on: { onOk: _vm.submitWithdrawByMaterials },
              model: {
                value: _vm.materialVisible,
                callback: function($$v) {
                  _vm.materialVisible = $$v
                },
                expression: "materialVisible"
              }
            },
            [
              _c(
                "div",
                { staticClass: "model-tit" },
                [
                  _c("div", { staticClass: "tit" }, [_vm._v("目标工序")]),
                  _c("el-input", {
                    staticClass: "ipt",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.targetProcess,
                      callback: function($$v) {
                        _vm.targetProcess = $$v
                      },
                      expression: "targetProcess"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "model-tit" }, [
                _c(
                  "div",
                  [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c(
                        "div",
                        { staticClass: "tit", staticStyle: { width: "60%" } },
                        [_vm._v("投入物料")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tit",
                          staticStyle: { width: "15%", "text-align": "center" }
                        },
                        [_vm._v("使用量")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tit",
                          staticStyle: { width: "15%", "text-align": "center" }
                        },
                        [_vm._v("损耗率")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tit",
                          staticStyle: { width: "10%", "text-align": "center" }
                        },
                        [_vm._v("工序")]
                      )
                    ]),
                    _c("BomTree", {
                      ref: "zTrees",
                      attrs: {
                        view: true,
                        nodes: _vm.dropNode,
                        type: "chooseMaterialsChangeBom"
                      },
                      on: { chooseNode: _vm.chooseMaterialsNode }
                    })
                  ],
                  1
                )
              ])
            ]
          ),
          _c(
            "MDialog",
            {
              attrs: {
                "append-to-body": true,
                title: "按工序撤回投入产出物料清单"
              },
              on: { onOk: _vm.submitWithdrawByProcess },
              model: {
                value: _vm.processVisible,
                callback: function($$v) {
                  _vm.processVisible = $$v
                },
                expression: "processVisible"
              }
            },
            [
              _c(
                "div",
                { staticClass: "model-tit" },
                [
                  _c("div", { staticClass: "tit" }, [_vm._v("目标工序")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "ipt",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择", filterable: "" },
                      on: { change: _vm.changeProcess },
                      model: {
                        value: _vm.targetProcess,
                        callback: function($$v) {
                          _vm.targetProcess = $$v
                        },
                        expression: "targetProcess"
                      }
                    },
                    _vm._l(_vm.processList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.procedureName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "model-tit" }, [
                _c(
                  "div",
                  [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c(
                        "div",
                        { staticClass: "tit", staticStyle: { width: "60%" } },
                        [_vm._v("投入物料")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tit",
                          staticStyle: { width: "20%", "text-align": "center" }
                        },
                        [_vm._v("使用量")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tit",
                          staticStyle: { width: "20%", "text-align": "center" }
                        },
                        [_vm._v("损耗率")]
                      )
                    ]),
                    _c("BomTree", {
                      ref: "zTrees",
                      attrs: {
                        view: true,
                        nodes: _vm.dropNode,
                        type: "chooseProcessChangeBom"
                      },
                      on: { chooseNode: _vm.chooseProcessNode }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isOpen && _vm.mySelfShowFlag,
              expression: "!isOpen && mySelfShowFlag"
            }
          ],
          staticClass: "top"
        },
        [
          _c(
            "div",
            {
              staticStyle: { cursor: "pointer" },
              attrs: { title: "点击展开" },
              on: {
                click: function($event) {
                  return _vm.changeWidthStatus(0)
                }
              }
            },
            [_c("i", { staticClass: "el-icon-d-arrow-right" })]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }