import request from '@/utils/request'
const baseUrl = 'api/web/v1/inspection/private/inspectionTask/'

export default {
  // 获取待检看板
  getQualityTaskListPage(data) {
    return request.post(baseUrl + 'list', data)
  },
  //   获取质检任务详情
  getQualityTaskDetail(data) {
    return request.get(baseUrl + 'detail', data)
  },
  del(data) {
    return request.post('api/web/v1/inspection/private/inspectionTask/batchDelete', data)
  }
}
