import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/equipment/sparePart/private/sparePart/'

export default {
  // 获取备件-不分页
  getSparePartList(data) {
    return request.get(baseUrl + 'getSparePartList', data)
  },
  // 获取备件-分页
  getSparePartListPage(data) {
    return request.get(baseUrl + 'getSparePartListPage', data)
  },
  // 新增备件
  addSparePart(data) {
    return request.post(baseUrl + 'addSparePart', data)
  },
  // 复制备件
  copySparePart(data) {
    return request.post(baseUrl + 'addSparePart', data)
  },
  // 修改备件
  modifySparePart(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除备件
  delSparePart(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  // 详情
  getSparePartDetail(data) {
    return request.get(baseUrl + `getSparePartDetail?id=${data}`)
  },
  createCode(params) {
    return request.get(baseUrl + 'getMaxSparePartCode', params)
  },
  getSpartPartDetail(id) {
    return request.get(baseUrl + `getSparePartOutWarehouseBillById?id=${id}`)
  }
}
