/** write by luomingzhi */
import { equipmentType, status } from '@/config/options.config'
import { formatColumns } from '@/utils'
export const sColumns = [
  {
    prop: 'id',
    label: 'ID',
    sortable: true,
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'code',
    label: '备件编码',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'name',
    label: '备件名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格型号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'drawing',
    label: '备件图片',
    sortable: false,
    hiddenSearch: true,
    slotName: 'drawing'
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true,
    width: 100
  },
  {
    prop: 'equipmentNameList',
    label: '关联设备',
    sortable: false,
    hiddenSearch: true,
    slotName: 'equipmentList',
    width: 200
  },
  {
    prop: 'sparePartClassName',
    label: '备件类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'upperLimit',
    label: '库存上限',
    sortable: 'custom',
    hiddenSearch: true
  },
  {
    prop: 'lowerLimit',
    label: '库存下限',
    sortable: 'custom',
    hiddenSearch: true
  },
  {
    prop: 'currentQuantity',
    label: '当前存货',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isEnabled',
    label: '备件状态',
    sortable: false,
    slotName: 'isEnabled',
    hiddenSearch: true,
    filters: formatColumns(status)
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]

export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'code',
    label: '备件编码',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'name',
    label: '备件名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格型号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'drawing',
    label: '备件图片',
    sortable: false,
    hiddenSearch: true,
    slotName: 'drawing'
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true,
    width: 100
  },
  {
    prop: 'equipmentNameList',
    label: '关联设备',
    sortable: false,
    hiddenSearch: true,
    slotName: 'equipmentList',
    width: 200
  },
  {
    prop: 'sparePartClassName',
    label: '备件类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'upperLimit',
    label: '库存上限',
    sortable: 'custom',
    hiddenSearch: true
  },
  {
    prop: 'lowerLimit',
    label: '库存下限',
    sortable: 'custom',
    hiddenSearch: true
  },
  {
    prop: 'currentQuantity',
    label: '当前存货',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isEnabled',
    label: '备件状态',
    sortable: false,
    slotName: 'isEnabled',
    hiddenSearch: true,
    filters: formatColumns(status)
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]

