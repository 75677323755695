import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/private/fieldSet/'
const baseUrl1 = 'api/web/v1/field/private/numberSet/'
export default {
  // 获取字段设置-不分页
  getFieldSetList(data) {
    return request.get(baseUrl + 'getFieldSetList', data)
  },
  // 获取字段设置-分页
  getFieldSetListPage(data) {
    return request.get(baseUrl + 'getFieldSetListPage', data)
  },
  // 新增字段设置
  addFieldSet(data) {
    return request.post(baseUrl + 'addFieldSet', data)
  },
  // 修改字段设置
  modifyFieldSet(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除字段设置
  delFieldSet(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  getFieldListByDetial(id) {
    return request.get(baseUrl + 'getFieldSetDetail', { id })
  },
  // 查询是否存在系统生产编码
  getFieldIsExist(data) {
    return request.post(baseUrl1 + 'isExist', data)
  }
}
