<template>
  <div>
    <MDialog
      v-model="modalShow"
      width="1100px"
      title="选择工艺"
      @onOk="submitForm"
    >
      <div class="top flex-sbc" style="padding-bottom: 20px">
        <span>请选择工艺路线：</span>
        <el-input classname="select-materials" style="width: 300px">
          <el-select
            slot="prepend"
            v-model="params.processId"
            filterable
            placeholder="请选择工艺路线"
            @change="selectProcessChange"
          >
            <el-option
              v-for="item in formOptions.processList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
          <el-button slot="append" icon="el-icon-more" @click="selectProcess" />
        </el-input>
        <el-button
          v-if="isAddProcessFlow"
          type="primary"
          style="margin-left: 10px"
          @click="processVisible = true"
          >新增工艺路线</el-button
        >
      </div>
      <div v-if="params.processId" class="process-detail-body flex-sb">
        <div class="left">
          <div style="border: 1px solid #dde3eb">
            <div class="history-version" style="padding-right: 10px">
              <img
                src="@/assets/information/process/历史版本@2x.png"
                style="width: 20px; margin-right: 5px"
              />
              <div style="position: relative; top: 2px; word-break: keep-all">
                历史版本
              </div>
            </div>
            <ul class="version-wrapper">
              <li
                v-for="item in versionList"
                :key="item.id"
                :class="currentVersionId === item.id ? 'active' : ''"
                @click="viewVersion(item)"
              >
                <div class="li-left">
                  <div class="name" :title="item.version">
                    <div class="ellipsis">{{ item.version }}</div>
                    <img
                      v-if="item.isMain"
                      src="@/assets/information/process/主版本.png"
                      style="width: 54px; margin-left: 10px"
                    />
                  </div>
                  <div
                    class="info ellipsis"
                    :title="`「 ${item.creator} 」上传于${item.createTime}`"
                  >
                    「 {{ item.creator }} 」上传于{{ item.createTime }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="right">
          <div class="detail-card">
            <div class="card-tit">基本信息</div>
            <div class="card-bot">
              <el-row class="view-list" :gutter="24">
                <!--            暂时这么处理距离问题-->
                <el-col
                  v-for="(item, index) in columns.base"
                  :key="item.prop"
                  :title="getValue(item)"
                  :span="8"
                  :style="{
                    marginBottom: index !== 6 ? '20px' : 0,
                    display: 'inline-flex',
                  }"
                >
                  <div style="word-break: keep-all">{{ item.label }}：</div>
                  <div style="word-break: break-all">{{ getValue(item) }}</div>
                </el-col>
              </el-row>
            </div>
          </div>

          <div class="detail-card">
            <div class="card-tit">工艺路线图</div>
            <div class="card-bot process-card">
              <process-flow
                ref="processFlow"
                :base-form="{ form: baseFormData }"
                :read-only="true"
                :process-flow-detail="processFlowVersion"
              />
            </div>
          </div>
        </div>
      </div>
    </MDialog>

    <select-process-flow-by-table
      :single="true"
      :visible.sync="showProcessFlow"
      :materials-id="materialsId"
      @submitForm="submitProcessFlow"
    />

    <MDialog
      v-model="processVisible"
      width="80%"
      title="新增工艺"
      @onOk="submitProcessForm"
    >
      <add-process-flow ref="addProcessFlow" />
    </MDialog>
  </div>
</template>

<script>
import processApi from "@/api/information/production/process";
import ProcessFlow from "@/components/ProcessFlow/ProcessFlow";
import { base } from "@/views/information/production/process/detail/columns";
import { Encrypt } from "@/utils/sercet";
import { getUUid } from "@/utils";
import SelectProcessFlowByTable from "@/components/SelectProcessFlow/SelectProcessFlowByTable";
import AddProcessFlow from "@/components/SelectProcessFlow/add-process-flow";

export default {
  name: "SelectProcessFlow",
  components: { AddProcessFlow, SelectProcessFlowByTable, ProcessFlow },
  props: {
    selectProcessAndVersionIds: {
      type: Array,
      default: () => [], // 两个值  第一个工艺Id,第二个版本Id
    },
    visible: {
      type: Boolean,
      default: false,
    },
    proceType: {
      type: Number,
      default: 0,
    },
    isAddProcessFlow: {
      type: Boolean,
      default: false,
    },
    materialsId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      modalShow: false,
      baseFormData: {},
      params: {
        processId: null,
      },
      defaultSearch: {
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: "isEnabled",
              fieldType: "number",
              fieldValue: 1,
              operator: "eq",
            },
          ])
        ),
      },
      processFlowVersion: {},
      formOptions: {
        processList: [],
      },
      currentVersionId: null,
      versionList: [],
      columns: {
        base,
      },
      showProcessFlow: false,
      processVisible: false,
    };
  },
  watch: {
    visible(val) {
      this.modalShow = val;
      if (this.selectProcessAndVersionIds.length === 2) {
        this.params.processId = this.selectProcessAndVersionIds[0];
        this.currentVersionId = this.selectProcessAndVersionIds[1];
      }
    },
    modalShow(val) {
      if (!val) {
        this.$emit("update:visible", false);
        setTimeout(() => {
          this.params.processId = null;
          this.currentVersionId = null;
        }, 500);
      }
    },
    proceType(val) {
      this.getProcessList();
    },
    materialsId() {
      this.getProcessList();
    },
  },
  mounted() {
    // this.getProcessList()
  },
  methods: {
    async selectProcessChange(id) {
      const res = await processApi.getProcessDetail({ id });
      if (res) {
        this.baseFormData = res;
        this.versionList = res.versionList;
        const currentVersion = res.versionList.find(
          (item) => item.id === this.currentVersionId
        );
        if (currentVersion) {
          this.processFlowVersion = this._.cloneDeep(currentVersion);
        } else {
          this.processFlowVersion = res.processFlowVersion;
          this.currentVersionId = res.processFlowVersion.id;
        }
      }
    },
    async getProcessList() {
      console.log(
        "this.materialsId, this.type==",
        this.materialsId,
        this.proceType
      );
      const defaultSearch = {
        materialsId: this.materialsId,
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: "isEnabled",
              fieldType: "number",
              fieldValue: 1,
              operator: "eq",
            },
            {
              id: getUUid(),
              fieldName: "type",
              fieldType: "number",
              fieldValue: this.proceType,
              operator: "eq",
            },
          ])
        ),
      };
      const res = await processApi.getProcessList(defaultSearch);
      if (res) {
        this.formOptions.processList = res
      }
    },
    // 获取工序基本信息值
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options && !['areas', 'materialsFullName'].includes(row.prop)) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    },
    viewVersion (version) {
      this.processFlowVersion = version
      this.currentVersionId = version.id
    },
    submitForm (callback) {
      if (!this.params.processId) {
        callback();
        return this.$message.info("请选择工序");
      }
      this.$emit('submit', {
        processFlowVersion: this.processFlowVersion,
        processData: this.baseFormData
      })
      callback()
    },
    selectProcess() {
      this.showProcessFlow = true
    },
    submitProcessFlow(val) {
      this.params.processId = val[0].id
      this.selectProcessChange(val[0].id)
      this.showProcessFlow = false
    },
    async submitProcessForm(callback) {
      const res = await this.$refs.addProcessFlow.submitForm();
      if (res) {
        this.processVisible = false
        await this.getProcessList()
        this.submitProcessFlow([{ id: res }])
      }
      callback()
    }
  }
}
</script>

<style scoped lang="scss">
.process-detail-body {
  width: 100%;
  height: calc(100% - 60px);

  .left {
    height: 100%;
    width: 242px;
    margin-right: 20px;
    display: inline-block;
    overflow-y: auto;
    overflow-x: hidden;

    .history-version {
      height: 70px;
      padding: 14px;
      width: 100%;
      background: #ffffff;
      border-radius: 4px 4px 0 0;
      display: flex;
      align-items: center;
    }

    ul,
    li {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 12px;
      color: #9597ae;
      background: #ffffff;
      border-radius: 0 0 4px 4px;
    }

    li {
      height: 70px;
      padding: 14px;

      .li-left {
        width: 100%;
        height: 100%;
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .name {
          font-size: 14px;
          color: #41456b;
        }

        .info {
          position: relative;
          left: -6px;
        }

        .dropdown {
          position: absolute;
          right: -20px;
          top: 13px;

          .el-icon-more-btn {
            transform: rotate(90deg);
            background: #e9eaec;
            border-radius: 2px;
            padding: 0 10px;
          }
        }
      }

      &:hover {
        background: #f0f2f5;
      }

      &.active {
        background: #f0f2f5;
      }
    }
  }

  .right {
    height: 100%;
    flex: 1;
    transition: all 0.5s;
    overflow: auto;

    .detail-card {
      margin-bottom: 20px;
    }

    .process-card {
      padding: 10px;
    }
  }
}
::v-deep {
  .params-card {
    .body {
      padding: 0 !important;
    }
    .params {
      width: 100% !important;
    }
    .el-form-item__label {
      padding: 0 12px 0 0 !important;
    }
  }
}

::v-deep input[classname="select-materials"] {
  display: none;
}

::v-deep .el-input-group--prepend {
  width: 100%;
  height: 40px;
}

::v-deep .el-input-group__prepend {
  background: #ffffff;
  width: calc(100% - 56px);

  > div {
    width: calc(100% + 40px);
  }

  .input-prepend {
    width: 100%;
    color: #495060;
  }
}

::v-deep .el-input-group__append {
  width: 56px;
}
</style>
