import request from '@/utils/request'

export default {
  // 获取不合格品
  getUnQuality(data) {
    return request.get('/api/web/v1/quality.kiki/private/unqualifiedProduct/getUnqualifiedProductListPage', data)
  },
  // 增加不合格品
  addUnQuality(postData) {
    return request.post('api/web/v1/quality.kiki/private/unqualifiedProduct/addUnqualifiedProduct', postData)
  },
  // 批量增加不合格品
  addUnQualityList(postData) {
    return request.post(`/api/web/v1/quality.kiki/private/unqualifiedProduct/addUnqualifiedProductBatch`, postData)
  },
  // 删除不合格品
  deleteUnQuality(postData) {
    return request.del('/api/web/v1/quality.kiki/private/unqualifiedProduct/deleteOne', postData)
  },
  // 修改不合格品
  ModifyUnQaulity(putData) {
    return request.put('/api/web/v1/quality.kiki/private/unqualifiedProduct/updateOne', putData)
  },
  // 获取不合格品详情
  getUnQualityDetails(id) {
    return request.get(`/api/web/v1/quality.kiki/private/unqualifiedProduct/getUnqualifiedProductDetail?id=${id}`)
  },
  // 导出不合格品
  exportUnQuality() {
    return request.get('/api/web/v1/quality.kiki/private/unqualifiedProduct/export', {})
  },
  // 移入废品库
  moveJunkBank(id) {
    return request.get(`/api/web/v1/quality.kiki/private/unqualifiedProduct/moveToWasteWarehouse?id=${id}`)
  },
  // // 添加物料
  // getMaterial() {
  //   return request.get(``)
  // }.
  // 获取质检方案(不分页)
  getQualityPlan() {
    return request.get('/api/web/v1/quality.kiki/private/qualityInspectionPlan/getQualityInspectionPlanList')
  },
  // 获取单位
  getMaterialsUnit(data) {
    return request.get(`/api/web/v1/basicData/materials/private/materialsUnit/getMaterialsUnitList`, data)
  },
  // 根据批次码获取物料
  getMaterialsBatch(data) {
    return request.get(`/api/web/v1/basicData/materials/private/materials/getMaterialsListByBatchNumber?batchNumber=${data}`)
  }
}
