/** write by luomingzhi */
import { formItemContentDom } from './render'

export const tagRender = (h, context) => {
  return h('div', {
    domProps: {
      className: 'general-tag-wrap'
    }
  }, context.props.tags.map(item => formItemContentDom(h, context, item)))
}

export default tagRender
