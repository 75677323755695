
export const scolumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'code',
    label: '设备编码',
    sortable: false,
    hiddenSearch: true,
    width: 150
  },
  {
    prop: 'name',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'equipmentClassName',
    label: '设备类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentClassType',
    label: '设备属性',
    sortable: false,
    hiddenSearch: true,
    slotName: 'equipmentClassType'
  },
  {
    prop: 'buyTime',
    label: '采购日期',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'drawing',
    label: '设备图片',
    sortable: false,
    hiddenSearch: true,
    slotName: 'drawing'
  },
  {
    prop: 'stationName',
    label: '所属工位',
    sortable: false,
    hiddenSearch: true
  },

  {
    prop: 'status',
    label: '设备使用状态',
    sortable: false,
    slotName: 'status',
    hiddenSearch: true
  },
  {
    prop: 'isEnabled',
    label: '设备状态',
    sortable: false,
    slotName: 'isEnabled',
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
export const columns = [
  {
    type: 'selection',
    fixed: 'left',
    width: 80,
    reserveSelection: true
  },
  {
    prop: 'code',
    label: '设备编码',
    sortable: false,
    hiddenSearch: true,
    width: 150
  },
  {
    prop: 'name',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'equipmentClassName',
    label: '设备类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentClassType',
    label: '设备属性',
    sortable: false,
    hiddenSearch: true,
    slotName: 'equipmentClassType'
  },
  {
    prop: 'buyTime',
    label: '采购日期',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'drawing',
    label: '设备图片',
    sortable: false,
    hiddenSearch: true,
    slotName: 'drawing'
  },
  {
    prop: 'stationName',
    label: '所属工位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '设备使用状态',
    sortable: false,
    slotName: 'status',
    hiddenSearch: true
  },
  {
    prop: 'isEnabled',
    label: '设备状态',
    sortable: false,
    slotName: 'isEnabled',
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]

