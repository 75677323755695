import { procedureType } from '@/config/options.config'

export const base = [
  {
    prop: 'code',
    label: '工序编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '工序名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'procedureClassName',
    label: '工序分类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'type',
    label: '工序类别',
    sortable: false,
    hiddenSearch: true,
    form: { options: procedureType }
  },
  {
    prop: 'status',
    label: '工序状态'
  },
  {
    prop: 'groupName',
    label: '所属工序段',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'workCenterName',
    label: '工作中心',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注'
  }
]

export const inProduct = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'procedureName',
    label: '前道工序名称',
    form: { tagName: 'el-input', props: { disabled: true }},
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '在制品输入数量',
    form: { tagName: 'el-input-number', props: { controlsPosition: 'right', min: 1 }},
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false
  }
]

export const materialsBom = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '物料编码',
    form: { tagName: 'el-input', props: { disabled: true }},
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '物料名称',
    form: { tagName: 'el-input', props: { disabled: true }},
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'spec',
    label: '规格',
    form: { tagName: 'el-input', props: { disabled: true }},
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '物料属性',
    form: { tagName: 'el-input', props: { disabled: true }},
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '需求数量',
    form: { tagName: 'el-input-number', props: { controlsPosition: 'right', min: 1 }},
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '单位',
    form: { tagName: 'el-select', key: 'unit', options: [] },
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false
  }
]

export const fileAndDrawings = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '名称',
    slotName: 'fileName',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    slotName: 'remark',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false
  }
]

export const procedure = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  // {
  //   prop: 'procedureIndex',
  //   label: '工序执行顺序',
  //   sortable: false,
  //   slotName: 'procedureIndex',
  //   hiddenSearch: true
  // },
  {
    prop: 'procedureCode',
    label: '工序编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'serialsCode',
    label: '工序定位码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'procedureName',
    label: '工序名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'standardHours',
    label: '标准工时',
    sortable: false,
    hiddenSearch: true
  },
  // {
  //   prop: 'procedureType',
  //   label: '类型',
  //   slotName: 'procedureType',
  //   sortable: false,
  //   hiddenSearch: true
  // }
]
// 工序投入物料
export const plans = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsAttributeName',
    label: '物料属性',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'attritionRate',
    label: '损耗率',
    slotName: 'attritionRate',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planQuantity',
    label: '数量',
    slotName: 'planQuantity',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isKeyMaterials',
    label: '是否为关键料',
    slotName: 'isKeyMaterials',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  }
]
export const noplans = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsAttributeName',
    label: '物料属性',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'attritionRate',
    label: '损耗率',
    slotName: 'attritionRate',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planQuantity',
    label: '数量',
    slotName: 'planQuantity',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isKeyMaterials',
    label: '是否为关键料',
    slotName: 'isKeyMaterials',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  }
]

// 工序产出物料列名
export const proMaterial = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsAttributeName',
    label: '物料属性',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '数量',
    slotName: 'quantity',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  }
]
export const proMaterialDetail = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsAttributeName',
    label: '物料属性',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '数量',
    slotName: 'quantity',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  }
]

// BOM列表
export const BOMColumns = [
  {
    prop: 'tier',
    label: '层级',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    slotName: 'name',
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsTypeName',
    label: '物料类型',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsAttributeName',
    label: '物料属性',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'quantity',
    label: '标准用量',
    sortable: false,
    hiddenSearch: true,
    width: 120,
    slotName:'quantity'
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true,
    width: 120,
    slotName:'mainUnitName'
  }
]
// 模具列表
export const mouldColumns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left'
  },
  {
    prop: 'categoryName',
    label: '模具分类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '模具名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'spec',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remarks',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
// 质检项目列表
export const insepctionProjectColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName:'index',
    minWidth: 80,
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '功能码',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'code',
    label: '工序定位码',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'code',
    label: '工序',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'name',
    label: '检验项目',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '模具名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'spec',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remarks',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsClassName',
    label: '检验标准',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'materialsTypeName',
    label: '检测方法',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsAttributeName',
    label: '检验值类型',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '默认值',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'specifications',
    label: '可能值',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'quantity',
    label: '标准值',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'upperLimit',
    label: '上偏差',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'downLimit',
    label: '下偏差',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  }
]
