import request from '@/utils/requestForAsync'
import { getUrl } from '@/utils/index'
const baseUrl = 'api/web/v1/production/kiki/private/producePlan/'
const baseUrl1 = 'api/web/v1/production/private/'

export default {
  // 获取计划订单表分页
  getPlanpage(data) {
    return request.get(baseUrl + 'getProducePlanListPage', data)
  },
  // 获取计划表不分页
  getNoPlanPage(data) {
    return request.get(baseUrl + 'getProducePlanList', data)
  },
  // // 增加计划订单
  // addPlan(data) {
  //   return request.post(baseUrl +'addProducePlan', data)
  // },
  // 删除计划订单
  deletePlan(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 批量删除计划订单
  deletePlans(data) {
    return request.post(baseUrl + 'deleteBatch', data)
  },
  // 获取计划订单详情
  getPlanDetail(id) {
    return request.get(baseUrl + `getProducePlanDetail?id=${id}`)
  },
  // 修改计划订单
  modifyPlan(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 下发至工单
  getPage(data) {
    return request.post(baseUrl + 'releaseProducePlan', data)
  },
  // 工艺和产线
  getFlowers(data) {
    return request.get('/api/web/v1/basicData/production/private/processFlow/getProcessFlowList', data)
  },
  // 产线对应的计划列表
  getLineList(data, params) {
    return request.post(baseUrl + `getSchedulePlanList${getUrl(params)}`, data)
  },
  // 排程
  getSchedules(data) {
    return request.post(baseUrl + 'schedulePlan', data)
  },
  // 排程总数
  getScheduleNums(data) {
    return request.get(baseUrl + 'getProducePlanListWithCount', data)
  },
  // 成品追溯
  getProducePlanForTracePage(data) {
    return request.get(baseUrl + 'getProducePlanForTracePage', data)
  },
  // 批量处理时间
  modifyBatchPlanTime(data) {
    return request.post(baseUrl + 'updatePlanOrderByTime', data)
  },
  // 计划备料
  addPlanStocking(data) {
    return request.post(baseUrl + 'stocking', data)
  },
  // 计划备料-批量
  addBatchPlanStocking(data) {
    return request.post(baseUrl + 'stockingBatch', data)
  },
  // 工单计划用料-不分页
  getPlanStockingList(data) {
    return request.get(baseUrl1 + 'produceWorkOrderPlanMaterials/getProduceWorkOrderPlanMaterialsList', data)
  },
  // 工单计划用料-分页
  getPlanStockingListPage(data) {
    return request.get(baseUrl1 + 'produceWorkOrderPlanMaterials/getProduceWorkOrderPlanMaterialsListPage', data)
  },
  // 工单计划用料-编辑
  modifyPlanStockingList(data) {
    return request.put(baseUrl1 + 'produceWorkOrderPlanMaterials/edit', data)
  },
  // 北变 - 通过批次码获取客户的下载模板
  getCustomerDownloadTemplate(data) {
    return request.get('api/web/v1/basicData/private/customer/getCustomerDownloadTemplate', data)
  },
  // 导出excel文档
  getExportExcelDocument(data) {
    return request.exportGet('api/web/v1/production/kiki/private/experimentalDataDetail/changeExcel', data)
  },
}
