var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._g(
      _vm._b(
        {
          directives: [
            {
              name: "load-more",
              rawName: "v-load-more",
              value: _vm.load,
              expression: "load"
            }
          ],
          attrs: {
            filterable: "",
            remote: "",
            "reserve-keyword": "",
            clearable: "",
            placeholder: "物料编码/名称/规格",
            "filter-method": _vm.filterMethod,
            "popper-append-to-body": false,
            "popper-class": "optionsContent",
            "value-key": "id",
            loading: _vm.loading
          },
          on: { change: _vm.changeValue, focus: _vm.focusEvent },
          model: {
            value: _vm.keyword,
            callback: function($$v) {
              _vm.keyword = $$v
            },
            expression: "keyword"
          }
        },
        "el-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    _vm._l(_vm.options, function(item) {
      return _c(
        "el-option",
        { key: item.id, attrs: { label: item.name, value: item } },
        [
          _c("div", { staticStyle: { "padding-top": "5px" } }, [
            _c("div", [_c("b", [_vm._v(_vm._s(item.code))])]),
            _c("div", { staticClass: "mdetails" }, [
              _c("span", [_vm._v("名称：" + _vm._s(item.name))]),
              _c("span", { staticStyle: { "margin-left": "20px" } }, [
                _vm._v("规格：" + _vm._s(item.specifications))
              ])
            ])
          ])
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }