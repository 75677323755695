<template>
  <div class="wraps">
    <el-select
      ref="select"
      v-model="selectProcedureData"
      class="tags-select-input"
      placeholder="当前所处工序"
      multiple
      collapse-tags
      size="medium"
      @change="handSelect"
      @click.native="deptogglePanel($event)"
      @remove-tag="removeTag"
    >
      <!-- <el-select
      v-model="selectProcedureData"
      placeholder="当前所处工序"
      @visible-change='clearDrop($event)'
      multiple
      collapse-tags
      @change="handSelect"
      >
      <div class="search-input">
        <el-input placeholder="请输入内容" size="mini" prefix-icon="el-icon-search" v-model="searchKey" @input="handInput" clearable></el-input>
      </div>
      <div slot="empty" class="search-input" >
        <el-input  placeholder="请输入内容" size="mini" prefix-icon="el-icon-search" v-model="searchKey" @input="handInput" clearable></el-input>
        <p>无搜索内容</p>
      </div> -->
      <el-option
        v-for="(item,index) in list"
        :key="index"
        :label="item.name"
        :value="item.id"
      />
    </el-select>
    <div v-if="showTree" ref="tableList" class="treeDiv">
      <el-input
        v-model="searchKey"
        placeholder="搜索"
        size="small"
        @input="handInput"
      />
      <div class="checks">
        <el-checkbox-group v-model="checkedDatas" @change="handleCheckedChange">
          <el-checkbox v-for="item in initList" :key="item.id" :label="item.name">{{ item.name }}</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="all-btns">
        <el-button size="mini" type="primary" class="btn-le" @click="handleCheckAllChange">全选</el-button>
        <el-button size="mini" plain @click="resetData">清空</el-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SelectProcedureSearch',
  props: {
    list: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      checkedDatas: [],
      searchKey: '',
      showTree: false,
      selectProcedureData: [],
      isShowSelect: true,
      initList: []
    }
  },
  computed: {
    selectHeight() {
      console.log('height=>', this.$refs.select.height)
      return this.$refs.select.height || '32px'
    }
  },
  watch: {
    isShowSelect(val) {
      // 隐藏select自带的下拉框
      this.$refs.select.blur()
    },
    list(val) {
      this.initList = val
    }
  },
  mounted() {
    this.initList = this.list
  },
  methods: {
    removeTag(val) {
      const data = this.selectProcedureData.filter(item => item !== val)
      this.checkedDatas = this.list.filter(item => data.includes(item.id)).map(item => item.name)
      this.$emit('getValues', this.selectProcedureData)
    },
    // handInput () {
    //   this.initList = this.list.filter(item => {
    //     return item.name.toLowerCase()
    //       .indexOf(this.searchKey.toLowerCase()) > -1
    //   })
    // },
    clearDrop($event) { // 此处的clearDrop用于解决搜索内容不存在时，所有内容无法显示的bug
      if ($event) {
        // alert('active')
        this.searchKey = ''
        this.initList = this.list
      }
    },
    resetData() {
      this.checkedDatas = []
      this.selectProcedureData = []
      this.$emit('getValues', this.selectProcedureData)
    },
    handleCheckAllChange() {
      this.checkedDatas = this.initList.map(item => item.name)
      this.selectProcedureData = this.initList.map(item => item.id)
      this.$emit('getValues', this.selectProcedureData)
    },
    handleCheckedChange(value) {
      this.selectProcedureData = value.length > 0 ? this.list.filter(item => value.includes(item.name)).map(items => items.id) : []
      this.$emit('getValues', this.selectProcedureData)
    },
    handInput() {
      this.initList = this.list.filter(item => {
        return item.name.toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) > -1
      })
    },
    handSelect() {
      this.showTree = true
      console.log('选中值', this.selectProcedureData)
    },
    // 点击input 阻止冒泡 控制table显示隐藏
    deptogglePanel(event) {
      this.isShowSelect = !this.isShowSelect// 隐藏select本来的下拉框
      event || (event = window.event)
      event.stopPropagation ? event.stopPropagation() : (event.cancelBubble = true)
      this.showTree ? this.tableHide() : this.tableShow()
    },
    // 显示表格
    tableShow() {
      this.showTree = true
      document.addEventListener('click', this.tableHideList, false)
      if (this.checkedDatas.length > 0 && this.searchKey.length > 0) {
        // this.rowMultipleChecked(this.checkedDatas)
      }
    },
    // 隐藏表格
    tableHide() {
      this.showTree = false
      document.addEventListener('click', this.tableHideList, false)
    },
    tableHideList(e) {
      if (this.$refs.tableList && !this.$refs.tableList.contains(e.target)) {
        this.tableHide()
      }
    }
  }
}
</script>

  <style lang="scss" scoped>
  .tags-select-input {
    ::v-deep .el-select__tags {
      white-space: nowrap;
      overflow: hidden;
      flex-wrap: nowrap;
    }
    ::v-deep .el-select__tags-text {
      white-space: nowrap;
      overflow: hidden;
      flex-wrap: nowrap;
      display: inline-block;
      text-overflow: ellipsis;
      max-width: 78px;
    }
    ::v-deep .el-tag__close {
      top: -7px;
      right: -4px;
    }
  }
  .checks ::v-deep .el-checkbox__label {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 150px;
    overflow-x: hidden;
  }
  .search-input{
    width:90%;
    margin-left:5%;
  }
  p{
    font-size: 14px;
    color: #606266;
  }
  .wraps {
    position: relative;

    .treeDiv {
      height: 300px;
      background: #fff;
      /* margin-top: 10px; */
      border: 1px solid #eee;
      overflow: hidden;
      padding: 5px;
      position: absolute;
      top: 32px;
      left: 0;
      z-index: 100;
      width: 100%;
      .checks {
        overflow: auto;
        height: 210px;
      }
      .el-checkbox {
        border-bottom: 1px solid #eee;
        height: 30px;
        display: flex;
        align-items: center;
        width: 100%;
      }
      .all-btns {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        .btn-le {
          margin-right: 10px;
        }
      }
    }
  }

  </style>
