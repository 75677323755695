var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bar-code",
      style: {
        position: "absolute",
        width: _vm.val.width + "px",
        height: _vm.val.height + "px",
        left: _vm.val.left + "px",
        top: _vm.val.top + "px",
        zIndex: _vm.val.zIndex
      }
    },
    [
      _c("img", { attrs: { src: _vm.codeUrl } }),
      _c("div", {
        staticClass: "text",
        style: {
          display: _vm.val.style.ShowBarText ? "" : "none",
          fontSize: _vm.val.style.FontSize + "pt",
          fontWeight: _vm.val.style.Bold ? "bold" : "normal"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }