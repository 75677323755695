import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/notification/private/callType/'
const baseUrl1 = 'api/app/v1/abnormal/private/abnormalJudgeSet/'
const baseUrl2 = 'api/web/v1/notification/private/abnormalReason/'
import { getUrl } from '@/utils/index'
export default {
  // 获取呼叫查询列表
  getCallClassifyListPage(params) {
    return request.get(baseUrl + 'getCallTypeListPage', params)
  },
  // 获取异常原因列表
  getCallReasonListPage(params) {
    return request.get(baseUrl2 + 'getAbnormalReasonListPage', params)
  },
  // 修改原因
  modifyCallReason(data) {
    return request.put(baseUrl2 + 'updateOne', data)
  },
  // 删除物料
  delCallReason(data) {
    return request.del(baseUrl2 + 'deleteOne', data)
  },
  addCallReason(data) {
    return request.post(baseUrl2 + 'addAbnormalReason', data)
  },
  getCallClassifyList(params) {
    return request.get(baseUrl + 'getCallTypeList', params)
  },
  addCallClassify(data) {
    return request.post(baseUrl + 'addCallType', data)
  },
  // 新增物料
  copyCallClassify(data) {
    return request.post(baseUrl + 'addCallType', data)
  },
  // 修改物料
  modifyCallClassify(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 修改物料
  configUser(data) {
    return request.put(baseUrl + 'configuration', data)
  },
  // 删除物料
  delCallClassify(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  getCallTypeUsers(id) {
    return request.get(baseUrl + 'showCallTypeUser', { id })
  },
  // 获取判定人设置
  getJudgSettingsListPage(params){
    return request.get(baseUrl1 + 'listPage', params)
  },
  getJudgSettingsList(params) {
    return request.get(baseUrl1 + 'list', params)
  },
  // 获取判定人用户
  getJudgSettingsUsers(id) {
    return request.get(baseUrl1 + 'getOne', { id })
  },
  // 新建判定人设置
  addJudgSettings(data) {
    return request.post(baseUrl1 + 'create', data)
  },
  // 修改判定人设置
  modifyJudgSettings(data) {
    return request.put(baseUrl1 + 'update', data)
  },
  // 删除判定人设置
  delJudgSettings(data) {
    return request.del(baseUrl1+ 'delete', data)
  },
  // 配置人员
  configJudgSettingsUser(data) {
    return request.put(baseUrl1 + 'configuration', data)
  },
  // 异常类型下拉列表
  getPullDownList (params) {
    return request.get(`api/web/v1/notification/private/callType/getPullDownList${getUrl(params)}`)
  },
  // 获取业务部门下的分类
  getBusinessUnitList(data){
    return request.get('api/web/v1/authority/private/Organization/getBusinessUnitList',data)
  }
}
