var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "none" }, attrs: { id: _vm.commonIds } },
    _vm._l(_vm.codes, function(item, index) {
      return _c(
        "div",
        { key: index, staticStyle: { "padding-top": "10px" } },
        [
          _c("VueQr", {
            attrs: {
              "correct-level": 3,
              "auto-color": false,
              "color-dark": "#000000",
              text: item,
              size: 95,
              margin: 0
            }
          }),
          _c("div", { staticStyle: { "padding-top": "10px" } }, [
            _vm._v(_vm._s(item))
          ])
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }