import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/invoices/private/facilityPlanMaintenanceTemplateItemMapping/'
const baseUrl1 = 'api/web/v1/field/facility/invoices/private/facilityMaintenancePlanItemMapping/'
export default {
  // 新增保养项目-计划模板
  addFacilityPlanMaintenanceTemplateItem(data) {
    return request.post(baseUrl + 'addFacilityPlanMaintenanceTemplateItemMapping', data)
  },
  // 修改保养项目-计划模板
  modifyFacilityPlanMaintenanceTemplateItem(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除保养项目-计划模板
  delFacilityPlanMaintenanceTemplateItem(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 新增保养项目
  addFacilityPlanMaintenanceItem(data) {
    return request.post(baseUrl1 + 'addFacilityMaintenancePlanItemMapping', data)
  },
  // 修改保养项目
  modifyFacilityPlanMaintenanceItem(data) {
    return request.put(baseUrl1 + 'updateOne', data)
  },
  // 删除保养项目
  delFacilityPlanMaintenanceItem(data) {
    return request.del(baseUrl1 + 'deleteOne', data)
  }
}
