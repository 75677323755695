var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "none",
        "font-size": "12px",
        "text-align": "center"
      },
      attrs: { id: _vm.commonId }
    },
    _vm._l(_vm.codes, function(item, index) {
      return _c("div", { key: index }, [
        _c(
          "div",
          {
            staticStyle: {
              "page-break-before": "always",
              display: "flex",
              "justify-content": "space-between",
              "align-items": "center",
              border: "0.5px solid #000",
              height: "140px",
              width: "100%:"
            }
          },
          [
            _c(
              "div",
              {
                staticStyle: { "padding-left": "10px", "text-align": "center" }
              },
              [
                _c(
                  "div",
                  { staticStyle: { width: "100px", "text-align": "center" } },
                  [
                    _c("VueQr", {
                      attrs: {
                        "correct-level": 3,
                        "auto-color": false,
                        "color-dark": "#000000",
                        text: item.produceWorkOrderNumber,
                        size: 100,
                        margin: 0
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "padding-top": "10px",
                      "font-size": "12px",
                      transform: "scale(0.9)",
                      "-webkit-transform": "scale(0.9)",
                      "text-align": "left",
                      "transform-origin": "0 50%"
                    }
                  },
                  [_vm._v("生产工单号：" + _vm._s(item.produceWorkOrderNumber))]
                )
              ]
            ),
            _c("h2", [_vm._v("生产工单流转卡")]),
            _c(
              "div",
              {
                staticStyle: {
                  width: "24.9%",
                  "border-left": "0.5px solid #000"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      height: "70px",
                      width: "100%",
                      "border-bottom": "0.5px solid #000",
                      "line-height": "70px",
                      color: "rgba(90, 90, 90, 100)",
                      "font-size": "12px",
                      "text-align": "center",
                      "font-family": "PingFang SC",
                      display: "flex",
                      "justify-content": "flex-start",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "30%",
                          "border-right": "0.5px solid #000"
                        }
                      },
                      [_vm._v("优先级")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "70%",
                          "text-align": "center",
                          "font-weight": "700",
                          color: "#000"
                        }
                      },
                      [_vm._v(_vm._s(item.priority))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      height: "70px",
                      "line-height": "70px",
                      color: "rgba(90, 90, 90, 100)",
                      "font-size": "12px",
                      "text-align": "center",
                      "font-family": "PingFang SC",
                      display: "flex",
                      "justify-content": "flex-start",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "30%",
                          "border-right": "0.5px solid #000"
                        }
                      },
                      [_vm._v("交期")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "70%",
                          "text-align": "center",
                          "font-weight": "700",
                          color: "#000"
                        }
                      },
                      [_vm._v(_vm._s(item.deliveryTime))]
                    )
                  ]
                )
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            staticStyle: { border: "0.5px solid #000", "border-bottom": "none" }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "flex-start",
                  "align-items": "center",
                  "border-bottom": "0.5px solid #000"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      flex: "1",
                      display: "flex",
                      "border-right": "0.5px solid #000",
                      height: "55px",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "60px",
                          transform: "scale(0.9)",
                          "-webkit-transform": "scale(0.9)",
                          "font-size": "12px"
                        }
                      },
                      [_vm._v("产品名称：")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "3",
                          color: "#000",
                          "font-weight": "700",
                          transform: "scale(0.9)",
                          "-webkit-transform": "scale(0.9)",
                          "font-size": "12px",
                          "text-align": "left",
                          "transform-origin": "0 50%"
                        }
                      },
                      [_vm._v(_vm._s(item.materialsName))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      flex: "1",
                      display: "flex",
                      "border-right": "0.5px solid #000",
                      height: "55px",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "60px",
                          transform: "scale(0.9)",
                          "-webkit-transform": "scale(0.9)",
                          "font-size": "12px"
                        }
                      },
                      [_vm._v("编码：")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "3",
                          color: "#000",
                          "font-weight": "700",
                          transform: "scale(0.9)",
                          "-webkit-transform": "scale(0.9)",
                          "font-size": "12px",
                          "text-align": "left",
                          "transform-origin": "0 50%"
                        }
                      },
                      [_vm._v(_vm._s(item.materialsCode))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      flex: "1",
                      display: "flex",
                      "border-right": "0.5px solid #000",
                      height: "55px",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "60px",
                          transform: "scale(0.9)",
                          "-webkit-transform": "scale(0.9)",
                          "font-size": "12px"
                        }
                      },
                      [_vm._v("规格：")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "line-height": "14px",
                          flex: "3",
                          color: "#000",
                          "font-weight": "700",
                          transform: "scale(0.9)",
                          "-webkit-transform": "scale(0.9)",
                          "font-size": "12px",
                          "text-align": "left",
                          "transform-origin": "0 50%",
                          "word-break": "break-all"
                        }
                      },
                      [_vm._v(_vm._s(item.materialsSpecifications))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      flex: "1",
                      display: "flex",
                      height: "55px",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "60px",
                          transform: "scale(0.9)",
                          "-webkit-transform": "scale(0.9)",
                          "font-size": "12px"
                        }
                      },
                      [_vm._v("批次码：")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "3",
                          color: "#000",
                          "font-weight": "700",
                          "word-break": "break-all",
                          transform: "scale(0.9)",
                          "-webkit-transform": "scale(0.9)",
                          "font-size": "12px",
                          "text-align": "left",
                          "transform-origin": "0 50%"
                        }
                      },
                      [_vm._v(_vm._s(item.batchNumber))]
                    )
                  ]
                )
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "flex-start",
                  "align-items": "center",
                  "border-bottom": "0.5px solid #000"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      flex: "1",
                      display: "flex",
                      "border-right": "0.5px solid #000",
                      height: "55px",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "60px",
                          transform: "scale(0.9)",
                          "-webkit-transform": "scale(0.9)",
                          "font-size": "12px"
                        }
                      },
                      [_vm._v("工单数量：")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "3",
                          color: "#000",
                          "font-weight": "700",
                          transform: "scale(0.9)",
                          "-webkit-transform": "scale(0.9)",
                          "font-size": "12px",
                          "text-align": "left",
                          "transform-origin": "0 50%"
                        }
                      },
                      [_vm._v(_vm._s(item.quantity))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      flex: "1",
                      display: "flex",
                      "border-right": "0.5px solid #000",
                      height: "55px",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "60px",
                          display: "flex",
                          "align-items": "center",
                          transform: "scale(0.9)",
                          "-webkit-transform": "scale(0.9)",
                          "font-size": "12px"
                        }
                      },
                      [_vm._v("计划开始时间：")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "3",
                          color: "#000",
                          "font-weight": "700",
                          transform: "scale(0.9)",
                          "-webkit-transform": "scale(0.9)",
                          "font-size": "12px",
                          "text-align": "left",
                          "transform-origin": "0 50%"
                        }
                      },
                      [_vm._v(_vm._s(item.planStartTime))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      flex: "1",
                      display: "flex",
                      "border-right": "0.5px solid #000",
                      height: "55px",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "60px",
                          display: "flex",
                          "align-items": "center",
                          transform: "scale(0.9)",
                          "-webkit-transform": "scale(0.9)",
                          "font-size": "12px"
                        }
                      },
                      [_vm._v("计划结束时间：")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "3",
                          color: "#000",
                          "font-weight": "700",
                          transform: "scale(0.9)",
                          "-webkit-transform": "scale(0.9)",
                          "font-size": "12px",
                          "text-align": "left",
                          "transform-origin": "0 50%"
                        }
                      },
                      [_vm._v(_vm._s(item.planEndTime))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: { flex: "1", display: "flex", height: "55px" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "60px",
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "center",
                          transform: "scale(0.9)",
                          "-webkit-transform": "scale(0.9)",
                          "font-size": "12px"
                        }
                      },
                      [_vm._v("工艺路线：")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "3",
                          color: "#000",
                          "font-weight": "700",
                          "align-items": "center",
                          transform: "scale(0.9)",
                          "-webkit-transform": "scale(0.9)",
                          "font-size": "12px",
                          "text-align": "left",
                          "word-break": "break-all",
                          display: "flex",
                          "transform-origin": "0 50%"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(item.processFlowName) +
                            "-" +
                            _vm._s(item.processFlowVersion)
                        )
                      ]
                    )
                  ]
                )
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "flex-start",
                  "align-items": "center",
                  "border-bottom": "0.5px solid #000"
                }
              },
              [
                _vm._m(0, true),
                _vm._m(1, true),
                _vm._m(2, true),
                _c(
                  "div",
                  {
                    staticStyle: {
                      flex: "1",
                      display: "flex",
                      height: "55px",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "1",
                          "line-height": "80px",
                          transform: "scale(0.9)",
                          "-webkit-transform": "scale(0.9)",
                          "font-size": "12px"
                        }
                      },
                      [_vm._v("产线：")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "3",
                          color: "#000",
                          "font-weight": "700",
                          transform: "scale(0.9)",
                          "-webkit-transform": "scale(0.9)",
                          "font-size": "12px",
                          "text-align": "left",
                          "transform-origin": "0 50%"
                        }
                      },
                      [_vm._v(_vm._s(item.areaName))]
                    )
                  ]
                )
              ]
            )
          ]
        ),
        _c(
          "table",
          {
            staticStyle: {
              border: "1px solid #000",
              "border-collapse": "collapse",
              "font-family": "Arial, Helvetica, sans-serif",
              width: "100%"
            },
            attrs: { id: "customers" }
          },
          [
            _vm._m(3, true),
            _vm._l(item.taskData, function(items, idx) {
              return _c(
                "tr",
                {
                  key: items.id,
                  staticStyle: { "text-align": "center", "font-size": "12px" }
                },
                [
                  _c(
                    "td",
                    {
                      staticStyle: {
                        padding: "8px",
                        border: "1px solid #000",
                        width: "50px"
                      }
                    },
                    [_vm._v(_vm._s(idx + 1))]
                  ),
                  _c(
                    "td",
                    {
                      staticStyle: {
                        padding: "8px",
                        border: "1px solid #000",
                        width: "90px"
                      }
                    },
                    [_vm._v(_vm._s(items.procedureIndex))]
                  ),
                  _c(
                    "td",
                    {
                      staticStyle: {
                        padding: "8px",
                        border: "1px solid #000",
                        width: "80px"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          items.procedureType === 0
                            ? "自制"
                            : items.procedureType === 1
                            ? "外协"
                            : "自制和外协"
                        )
                      )
                    ]
                  ),
                  _c(
                    "td",
                    {
                      staticStyle: { padding: "8px", border: "1px solid #000" }
                    },
                    [_vm._v(_vm._s(items.procedureName))]
                  ),
                  _c("td", {
                    staticStyle: { padding: "8px", border: "1px solid #000" }
                  }),
                  _c("td", {
                    staticStyle: { padding: "8px", border: "1px solid #000" }
                  }),
                  _c("td", {
                    staticStyle: { padding: "8px", border: "1px solid #000" }
                  })
                ]
              )
            })
          ],
          2
        )
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          flex: "1",
          display: "flex",
          "border-right": "0.5px solid #000",
          height: "55px",
          "align-items": "center"
        }
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              width: "60px",
              height: "100%",
              display: "flex",
              "align-items": "center",
              transform: "scale(0.9)",
              "-webkit-transform": "scale(0.9)",
              "font-size": "12px"
            }
          },
          [_vm._v("所属销售订单：")]
        ),
        _c("div", {
          staticStyle: {
            flex: "3",
            color: "#000",
            "font-weight": "700",
            transform: "scale(0.9)",
            "-webkit-transform": "scale(0.9)",
            "font-size": "12px",
            "text-align": "left",
            "transform-origin": "0 50%"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          flex: "1",
          display: "flex",
          "border-right": "0.5px solid #000",
          height: "55px",
          "align-items": "center"
        }
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              width: "60px",
              transform: "scale(0.9)",
              "-webkit-transform": "scale(0.9)",
              "font-size": "12px"
            }
          },
          [_vm._v("客户编码：")]
        ),
        _c("div", {
          staticStyle: {
            flex: "3",
            color: "#000",
            "font-weight": "700",
            transform: "scale(0.9)",
            "-webkit-transform": "scale(0.9)",
            "font-size": "12px",
            "text-align": "left",
            "transform-origin": "0 50%"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          flex: "1",
          display: "flex",
          "border-right": "0.5px solid #000",
          height: "55px",
          "align-items": "center"
        }
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              width: "60px",
              transform: "scale(0.9)",
              "-webkit-transform": "scale(0.9)",
              "font-size": "12px"
            }
          },
          [_vm._v("项目名称：")]
        ),
        _c("div", {
          staticStyle: {
            flex: "3",
            color: "#000",
            "font-weight": "700",
            transform: "scale(0.9)",
            "-webkit-transform": "scale(0.9)",
            "font-size": "12px",
            "text-align": "left"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "tr",
      {
        staticStyle: {
          height: "40px",
          border: "1px solid #000",
          padding: "8px",
          "text-align": "center",
          background: "#D9D9D9"
        }
      },
      [
        _c(
          "th",
          {
            staticStyle: {
              "font-size": "12px",
              width: "50px",
              background: "#D9D9D9"
            }
          },
          [_vm._v("序号")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "font-size": "12px",
              width: "80px",
              background: "#D9D9D9"
            }
          },
          [_vm._v("工序执行顺序")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "font-size": "12px",
              width: "70px",
              background: "#D9D9D9"
            }
          },
          [_vm._v("工序类型")]
        ),
        _c(
          "th",
          { staticStyle: { "font-size": "12px", background: "#D9D9D9" } },
          [_vm._v("工序名称")]
        ),
        _c(
          "th",
          { staticStyle: { "font-size": "12px", background: "#D9D9D9" } },
          [_vm._v("完工数量")]
        ),
        _c(
          "th",
          { staticStyle: { "font-size": "12px", background: "#D9D9D9" } },
          [_vm._v("完工时间")]
        ),
        _c(
          "th",
          { staticStyle: { "font-size": "12px", background: "#D9D9D9" } },
          [_vm._v("备注")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }