import request from '@/utils/requestForAsync'
import { getUrl } from '@/utils/index'
const baseUrl = 'api/web/v1/basicData/materials/private/materialsCompleteCalculate/'

export default {
  // 获取齐套计算列表
  getList(data) {
    return request.get(baseUrl + 'listCompleteCalculateDetails', data)
  },
  // 齐套列表导出
  exportList(data) {
    return request.exportPost(baseUrl + 'exportCalculateListData', data)
  },
  // 删除
  delete(data) {
    return request.post(baseUrl + 'deleteCompleteCalculate', data)
  },
  // 工艺路线
  getListProcessFlows(data) {
    return request.get('api/web/v1/basicData/production/private/processFlow/listProcessFlows', data)
  },
  // BOM转MBOM导入工艺路线模板下拉列表
  getBomProcessFlowList(data) {
    return request.get('api/web/v1/basicData/production/private/processFlow/getBomProcessFlowList', data)
  },
  // 工艺路线下的版本
  getProcessFlowDetails(processFlowId, isBomType) {
    return request.get('api/web/v1/basicData/production/private/processFlow/getProcessFlowDetails', { processFlowId,isBomType })
  },
  // 查看详情
  viewDetail(id) {
    return request.get(baseUrl + 'getCompleteCalculateDetail', { id })
  },
  // 记录编号
  getCompleteCalculateCode() {
    return request.get(baseUrl + 'getCompleteCalculateCode')
  },
  // 齐套计算
  completeCalculateResult(data) {
    return request.get(baseUrl + 'completeCalculateResult', data)
  },
  // 保存计算结果
  addCompleteCalculate(data) {
    return request.post(baseUrl + 'addCompleteCalculate', data)
  },

  // 修改计算结果
  updateCompleteCalculate(data) {
    return request.put(baseUrl + 'updateCompleteCalculate', data)
  }

}
