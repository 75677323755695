<template>
  <MDialog v-model="modalShow" title="查看" :show-footer="false">
    <el-form ref="templatenameform">
      <el-form-item v-if="data.name" label="模板名称：">
        <span>{{ data.name }}</span>
      </el-form-item>
      <el-form-item>
        <div style="display:flex; justify-content: space-between;border: 1px solid #DEDEEE;">
          <div class="parmas" style="flex: 1; border: 1px solid #DEDEEE;">
            <div style="background: #F7F9FC;">参数组</div>
            <div v-for="(item, index) in data.details" :key="index" :class="showindex === index ? 'btns btn-active' : 'btns'" @click="showeindexs(index)">{{ item.name }}
            </div>
          </div>
          <div class="param-right" style="flex: 2; border: 1px solid #DEDEEE;">
            <div ref="handleindex" style="background: #F7F9FC;border-bottom: 1px solid #DEDEEE;">详细参数</div>
            <template v-if="data && data.details && data.details[showindex]">
              <div style="max-height:230px;overflow: auto;background: #FFF;">
                <div v-for="(item,index) in datas.details[showindex].details" :key="index" style="text-align: left;">
                  <div>
                    <div class="common-pad">
                      <p>{{ item.name }} :</p>
                      <el-input v-if="item.type ==='text'" v-model="item.defaultvalue" disabled style="width: 250px;margin: 0 20px 0 0;" />
                      <!-- 文本 -->
                      <el-input v-if="item.type ==='number'" v-model="item.defaultvalue" disabled controls-position="right" style="text-align:left;width: 250px;margin: 0 20px 0 0;" />
                      <!-- 数字 -->
                      <el-date-picker v-if="item.type ==='date'" v-model="item.defaultvalue" disabled type="date" placeholder="选择日期" style="width: 250px;margin: 0 20px 0 0;" />
                      <!-- 日期 -->
                      <el-select v-if="item.type === 'radio'" v-model="item.defaultvalue" disabled placeholder="请选择" style="width: 250px;margin: 0 20px 0 0;">
                        <el-option
                          v-for="iten in item.maybeNum"
                          :key="iten"
                          :label="iten"
                          :value="iten"
                        />
                      </el-select>
                      <!-- 单选 -->
                      <el-select v-if="item.type === 'checkbox'" v-model="item.defaultvalue" disabled multiple placeholder="请选择" style="width: 250px;margin: 0 20px 0 0;">
                        <el-option
                          v-for="itens in item.maybeNum"
                          :key="itens"
                          :label="itens"
                          :value="itens"
                        />
                      </el-select>
                      <!-- 多选 -->
                      <div v-if="item.type === 'files'" class="filess">
                        <div v-if="item.defaultvalue.length > 0">
                          <a v-for="(items, index) in item.defaultvalue" :key="items.id" :href="items.url" target="_blank" class="file-url">
                            {{ fixUrl(items.url) }}
                            <span v-if="index < item.defaultvalue.length-1">，</span>
                          </a>
                        </div>
                        <div v-if="item.filesValue.length > 0">
                          <a v-for="(items, index) in item.filesValue" :key="items.id" :href="items.url" target="_blank" class="file-url">
                            {{ items.name }}
                            <span v-if="index < item.filesValue.length-1">，</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </el-form-item>
    </el-form>

  </MDialog>
</template>

<script>

export default {
  name: 'ScanQualityTemplate',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    datas: {}
  },
  data() {
    return {
      modalShow: false,
      showindex: 0,
      data: {}
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 289 - 160 - 57
    }
  },
  watch: {
    visible(val) {
      this.modalShow = val
    },
    datas(val) {
      this.data = val
    },
    modalShow(val) {
      if (!val) {
        this.$emit('update:visible', false)
      }
    }
  },
  mounted() {
    this.modalShow = this.visible
  },
  methods: {
    fixUrl(item) {
      const urls = item.split('/')
      return urls[3]
    },
    showeindexs(index) {
      this.showindex = index
    }
  }
}
</script>

<style lang="scss" scoped>
  .filess {
    // display: inline-flex;
    // justify-content: flex-start;
    margin-right: 20px;
    .file-url {
      color: #607FFF;
      height: 25px;
      line-height: 25px;
      display: block;
    }
  }
  .parmas {
    width: 897px;
    text-align: center;
    line-height: 45px;
    background: rgb(247, 249, 252);
  }
  .param-right {
    text-align: center;
    line-height: 45px;
  }
  .el-dialog__footer {
    text-align: center !important;
  }
  .parmasItem {
    border-top: 1px solid #dedeee;
    background: #f7f9fc;
  }
  .parmasItem:hover {
    background-color: #ffffff;
  }
  .common-pad {
    align-items: center;
    padding: 10px 20px 0 20px;
    p{
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #565984;
      line-height: 20px;
      margin: 0;
    }
  }
  .btns {
    width: 100%;
    text-align: left;
    text-indent: 40px;
    border-top: 1px solid #dedeee;
    background-color: rgb(247, 249, 252);
    position: relative;
      cursor: pointer;
      .tit{
        width: 150px;
        line-height: 20px;
        display: inline-block;
      }
    &:last-child {
      border-bottom: 0;
    }
  }
  .btn-active {
    background-color: #ffffff !important;
  }
</style>
