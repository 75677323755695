import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/'

export default {
  // 获取某个url的数据
  getData(url, data) {
    return request.get(baseUrl + url, data)
  },
  // 获取某个table的数据
  getTableData(url, data) {
    return request.get(baseUrl + url, data)
  },
  // 获取某个table的数据
  getTablePutData(url, data) {
    return request.put(baseUrl + url, data)
  },
  // 修改应出勤天数
  modifyWorkingDays(data) {
    return request.put(baseUrl + 'com/mubai/mes/entity/field/manHour/private/dailyWorkReportMonths/updateOne', data)
  },
  //拿到大华token
  getDahuaToken() {
    return request.get('api/web/v1/basicConfig/private/fsd/getToken')
  },
  //拿到设备拥有的视频通道
  getDahuaChannelCodes(aut) {
    return request.get('api/web/v1/basicConfig/private/fsd/getChannelCodes?bearerToken=' + aut)
  },
  //拿到视频rtsp流链路地址
  getDahuaRtspUrl(channelIds,aut) {
    return request.get('api/web/v1/basicConfig/private/fsd/getChannelStartVideo?channelIds='+ channelIds +'&bearerToken=' + aut)
  },
}
