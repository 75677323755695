<template>
  <div class="news-container">
    <div class="news-icon" @click="showNews()">
      <el-badge :value="getUnReadNewsLength()" :max="99" :hidden="getUnReadNewsLength() === 0" class="lock-item">
        <img src="../../assets/l_c_H_images/通知.png">
        <span style="font-size: 14px">通知</span>
      </el-badge>
    </div>

    <div class="news-body" :style="{right: isShow ? '10px' : '-450px'}">
      <div class="news-body-title">
        <span>消息</span>
        <i class="el-icon-close" @click="showNews()"/>
      </div>
      <div class="news-body-card">
        <div class="new-news">
          <div class="top-title">
            <div>
              <!--            todo: 图标iconfont-->
              <span>新消息</span>
            </div>
            <el-button
              type="text"
              style="padding: 0"
              :disabled="!newNewsList.length"
              @click="operateNews('readAll', userInfo.id, '更新成功')"
            >全部已读
            </el-button>
          </div>
          <template v-if="newNewsList.length">
            <div v-for="(item, index) in newNewsList" :key="index" class="news-card-body" @click="viewNewsDetail(item)">
              <div class="title">
                <span>{{ item.type }}</span>
                <div>未读</div>
              </div>
              <div class="ellipsis">{{ item.message }}</div>
              <div class="send-info">
                <div>发件人：{{ item.creator ? item.creator : '系统自动推送' }}</div>
                <div>发件时间：{{ item.createTime }}</div>
              </div>
            </div>
          </template>
          <m-blank v-else style="padding-top: 20px" title="暂无新消息"/>
        </div>
        <div class="history-news">
          <div class="top-title">
            <div>
              <!--            todo: 图标iconfont-->
              <span>历史消息</span>
            </div>
            <el-button
              type="text"
              :disabled="!historyNewsList.length"
              style="padding: 0"
              @click="operateNews('delAll', userInfo.id, '清除成功')"
            >全部清除
            </el-button>
          </div>
          <template v-if="historyNewsList.length">
            <div v-for="(item, index) in historyNewsList" :key="index" class="news-card-body"
                 @click="viewNewsDetail(item)">
              <div class="title">
                <span>{{ newsTypesName[item.type] ? newsTypesName[item.type] : item.type }}</span>
                <div style="cursor: pointer" @click.stop="operateNews('del', item.id, '清除成功')">清除</div>
              </div>
              <div class="ellipsis">{{ item.message }}</div>
              <div class="send-info">
                <div>发件人：{{ item.creator ? item.creator : '系统自动推送' }}</div>
                <div>发件时间：{{ item.createTime }}</div>
              </div>
            </div>
          </template>
          <m-blank v-else style="padding-top: 20px" title="暂无历史消息"/>
        </div>
      </div>
    </div>

    <MDialog v-model="isVisible" title="消息详情" :show-footer="false">
      <div class="content">
        <div class="news-info">
          <span class="title">{{
              newsTypesName[newsDetail.type] ? newsTypesName[newsDetail.type] : newsDetail.type
            }}</span>
          <p style="line-height: 20px;white-space: pre-wrap;">{{ newsDetail.message }}</p>
          <div class="info">
            <div>发件人：{{ newsDetail.creator ? newsDetail.creator : '系统自动推送' }}</div>
            <div>发件时间：{{ newsDetail.createTime }}</div>
          </div>
        </div>
      </div>
    </MDialog>
  </div>
</template>

<script>
import api from '@/api/news'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'

export default {
  name: 'Index',
  data() {
    return {
      newsList: [],
      isShow: false,
      isVisible: false,
      newsDetail: {},
      userInfo: {},
      newsTypesName: {
        equipmentRepairRecordCreated: '设备维保'
      },
      websocket: null,
      websocketStatus: '未连接'
    }
  },
  computed: {
    newNewsList() {
      return this.newsList.filter(item => !item.isReaded)
    },
    historyNewsList() {
      return this.newsList.filter(item => item.isReaded)
    }
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    this.getNewsList()
  },
  beforeDestroy() {
    // 移除页面关闭事件监听器
    window.removeEventListener('beforeunload', this.disconnectWebSocket)
    // 在页面销毁时断开WebSocket连接
    this.disconnectWebSocket()
  },
  mounted() {
    this.connectWebSocket()
    // 监听页面关闭事件，在页面关闭时断开WebSocket连接
    window.addEventListener('beforeunload', this.disconnectWebSocket)
  },
  methods: {
    async getNewsList() {
      const res = await api.getNotificationTaskList({
        searchVal: Encrypt(JSON.stringify([{
          id: getUUid(),
          fieldName: 'recipientId',
          fieldType: 'number',
          fieldValue: this.userInfo.id,
          operator: 'eq'
        }]))
      })
      if (res) {
        this.newsList = res
      }
    },
    initWebSocket() {
      const url = process.env.VUE_APP_MSG_API.split('http://')[1]
      // const url = '192.168.0.75:4321/'
      const wsuri = `ws://${url}ws/${localStorage.getItem('memberCode')}/${JSON.parse(sessionStorage.getItem('userInfo')).id}`
      this.websock = new WebSocket(wsuri)
      this.websock.onmessage = this.websocketonmessage
      this.websock.onopen = this.websocketonopen
      this.websock.onerror = this.websocketonerror
      this.websock.onclose = this.websocketclose
    },
    websocketonopen() {
      // console.log('websocket连接成功')
    },
    websocketonerror() {
      this.initWebSocket()
    },
    websocketonmessage(e) {
      setTimeout(() => {
        this.getNewsList()
      }, 500)
    },
    websocketclose(e) {
      // console.log('断开连接', e)
    },
    getUnReadNewsLength() {
      return this.newsList.filter(item => !item.isReaded).length
    },
    showNews() {
      this.isShow = !this.isShow
    },
    async viewNewsDetail(data) {
      this.newsDetail = data
      this.isVisible = true
      if (!data.isReaded) {
        await api.readNotificationTask(data.id)
        await this.getNewsList()
      }
    },
    async operateNews(type, id, message) {
      const res = await api[`${type}NotificationTask`](id)
      if (res) {
        this.$message.success(message)
        await this.getNewsList()
      }
    },

    connectWebSocket() {
      const url = process.env.VUE_APP_MSG_API.split('://')[1]
      const wsuri = `ws://${url}ws/${localStorage.getItem('memberCode')}/${JSON.parse(sessionStorage.getItem('userInfo')).id}`
      this.websocket = new WebSocket(wsuri)
      this.websocket.onopen = () => {
        this.websocketStatus = '已连接'
        // console.log('已连接')
      }

      this.websocket.onmessage = (event) => {
        setTimeout(() => {
          this.getNewsList()
        }, 500)
        // 处理收到的消息
        // console.log('收到消息:', event.data)
      }

      this.websocket.onclose = (e) => {
        this.websocketStatus = '连接已关闭'
        // console.log('连接已关闭')
        this.websocket = null
        if (e.code !== 1000) {
          this.reconnectWebSocket()
        }
      }

      this.websocket.onerror = (error) => {
        // console.error('WebSocket错误:', error)
      }
    },
    disconnectWebSocket() {
      if (this.websocket) {
        this.websocket.close()
      }
    },
    reconnectWebSocket() {
      if (this.websocketStatus === '连接已关闭') {
        this.connectWebSocket()
      }
    }
  }
}
</script>

<style lang="scss">
$low-text-color: #DFDEE8;
$border-color: #dedeee;
$middle-text-color: #8A8CA5;
$main-hover-color: #b0b3ff;
.news-container {
  .news-body {
    width: 436px;
    background: #ffffff;
    height: calc(100% - 140px - 50px);
    position: fixed;
    right: -330px;
    top: 58px;
    z-index: 999;
    transition: all .5s ease-in-out;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.29);
    border-radius: 8px;
    overflow: hidden;

    .news-body-card {
      height: calc(100% - 50px);
      overflow: auto;
      display: flex;
      flex-direction: column;

      .new-news, .history-news {
        padding: 33px 37px;

        .top-title {
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0 15px 0;
          color: #41456B;
          font-weight: 700;
          width: 100%;
          border-bottom: 1px solid #DFDEE8;

          > span {
            color: #50AEFF;
          }
        }

        .news-card-body {
          padding-top: 15px;
          border-bottom: 1px solid #DFDEE8;
          padding-bottom: 10px;

          .title {
            width: 100%;
            display: inline-flex;
            justify-content: space-between;
            padding: 10px 0;

            > span {
              font-weight: 700;
              color: #41456B;
              font-size: 14px;

              &:hover {
                color: #8585F9;
              }
            }

            div {
              font-size: 12px;
            }
          }

          .ellipsis {
            color: #393D60;
            width: 100%;
            padding-bottom: 15px;
          }

          .send-info {
            div {
              font-size: 12px;
              color: #9597AE;
              padding-bottom: 5px;
            }
          }
        }
      }

      .new-news {
        background: #fff;
        border-bottom: 1px solid #DFDEE8;

        .news-card-body {
          cursor: pointer;

          .title {
            position: relative;

            div {
              color: #8585F9;
              padding: 3px 10px;
              border: 1px solid #8585F9;
              background: rgba(146, 146, 255, 0.1);
              border-radius: 30px;
            }

            span:before {
              content: '';
              width: 6px;
              height: 6px;
              border-radius: 100%;
              background: #958FFF;
              position: absolute;
              left: -15px;
              top: 14px;
            }
          }
        }
      }

      .history-news {
        flex: 1;
        background: #F8F8FB;

        .top-title {
          > span {
            color: #50AEFF;
          }
        }

        .news-card-body {
          .title {
            span {
              color: #8A8CA5;
            }

            div {
              color: #50AEFF;
              font-size: 14px;
            }
          }

          .ellipsis {
            color: #8A8CA5;
          }
        }
      }
    }

    .news-body-title {
      width: 100%;
      height: 64px;
      font-weight: 700;
      letter-spacing: 10px;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      background: #F8F8FB;

      span {
        margin-left: 40px;
        font-size: 16px;
        color: #393D60;
      }

      i {
        margin-left: 22px;
        font-size: 20px;
        color: #393D60;
        cursor: pointer;
      }
    }
  }

  .news-icon {
    cursor: pointer;

    > div {
      display: flex;
      align-items: center;
      vertical-align: middle;

      img {
        padding: 0 10px;
        vertical-align: middle;
      }

      span {
        color: #41456B;
      }
    }
  }

  .info {
    color: $low-text-color;

    .sender {
      font-size: 12px;
    }

    .send-date {
      font-size: 12px;
    }
  }

  .news-title {
    .has-read:hover {
      color: $main-hover-color !important;
    }
  }
}

.content {
  .news-info {
    .title {
      font-weight: 700;
      font-size: 16px;
    }

    .info {
      > div {
        padding: 5px 0;
        color: $middle-text-color;
      }
    }
  }

  p {
    margin-top: 20px;
  }
}

</style>
