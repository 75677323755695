var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gantt-leftbar" },
    [
      _c("div", {
        staticClass: "gantt-leftbar-item gantt-block-top-space",
        style: { height: _vm.topSpace + "px" }
      }),
      _vm._l(_vm.showDatas, function(data, index) {
        return _c(
          "div",
          {
            key: _vm.dataKey ? data[_vm.dataKey] : index,
            staticClass: "gantt-leftbar-item",
            style: {
              background:
                _vm.currentIdxs === data.id
                  ? "rgba(230, 232, 239, 0.3)"
                  : "#fff",
              height:
                _vm.currentGanttBlocksHeights[data.id] || _vm.cellHeight + "px"
            },
            on: {
              click: function($event) {
                return _vm.selectBge(data)
              }
            }
          },
          [
            _vm._t(
              "default",
              [
                _c("div", { staticClass: "gantt-leftbar-defalutItem" }, [
                  _vm._v("need slot")
                ])
              ],
              { data: data }
            )
          ],
          2
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }