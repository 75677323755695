var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLock
    ? _c(
        "div",
        {
          class: _vm.className,
          style: { maxWidth: _vm.password ? "386px" : "316px" }
        },
        [
          _c("div", { staticClass: "user-name" }, [
            _vm._v(_vm._s(_vm.userInfo.name))
          ]),
          _c(
            "div",
            {
              staticStyle: { display: "inline-flex", "align-items": "center" }
            },
            [
              _vm._m(0),
              _c(
                "div",
                {
                  class: !_vm.password
                    ? "input-wrapper"
                    : "active-input-wrapper"
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入密码", "show-password": "" },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.password,
                          expression: "password"
                        }
                      ],
                      staticClass: "submit",
                      on: {
                        click: function($event) {
                          return _vm.validatePassword()
                        }
                      }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-wrapper" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/l_c_H_images/头像@2x.png"),
          alt: "头像"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }