import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/production/kiki/private/batchNumberTrace/'
export default {
  // 获取产品批次码追溯详情
  getProduceBatchNumberTrace(data) {
    return request.get(baseUrl + 'produceBatchNumberTrace', data)
  },
  // 获取原材料批次码追溯详情
  getSourceBatchNumberTrace(data) {
    return request.get(baseUrl + 'sourceBatchNumberTrace', data)
  },
  // 获取产品批次码全景追溯
  getProduceBatchNumberFullTrace(data) {
    return request.get(baseUrl + 'produceBatchNumberFullTrace', data)
  },
  // 获取产品批次码全景追溯详情
  getProduceBatchNumberFullDeatailTrace(data) {
    return request.get(baseUrl + 'produceBatchNumberDetailTrace', data)
  }
}
