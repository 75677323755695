import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/private/facilityServiceOrder/'

export default {
  // 服务订单列表（不分页）
  getServiceOrderList(data) {
    return request.get(baseUrl + 'getFacilityServiceOrderList', data)
  },
  // 服务订单列表（分页）
  getServiceOrderListPage(data) {
    return request.get(baseUrl + 'getFacilityServiceOrderListPage', data)
  },
  // 新建服务订单
  addServiceOrder(data) {
    return request.post(baseUrl + 'addFacilityServiceOrder', data)
  },
  // 修改服务订单
  modifyServiceOrder(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除服务订单
  delServiceOrder(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 批量删除服务订单
  delBatchServiceOrder(data) {
    return request.post(baseUrl + 'batchDelete', data)
  },
  // 获取服务订单详情
  getServiceOrderDetail(id) {
    return request.get(baseUrl + 'getFacilityServiceOrderDetail', { id })
  }
}
