import request from '@/utils/request'
import requestForAsync from '@/utils/requestForAsync'
import requestForOperationalBackOffice from '@/utils/requestForOperationalBackOffice'
const baseUrl = 'api/web/v1/tenant/public/tenant/'
export default {
  // 获取租户-分页
  getTenantListPage(data) {
    return request.get(baseUrl + 'getTenantListPage', data)
  },
  // 获取租户-分页
  getTenantListPage2(data) {
    return requestForOperationalBackOffice.get(baseUrl + 'getTenantListPage', data)
  },
  //获取基础配置
  getBaseConfigSelf(data) {
    return requestForOperationalBackOffice.get('api/web/v1/basicConfig/public/basicConfig/getBasicConfigDetailByCode', data)
  },
  // 新增租户
  addTenant(data) {
    return request.post(baseUrl + 'addTenant', data)
  },
  // 修改租户
  modifyTenant(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 详情
  getTenantDetail(data) {
    return request.get(baseUrl + 'getTenantDetail', data)
  },
  // 详情
  getTenantDetail2(data) {
    return requestForOperationalBackOffice.get(baseUrl + 'getTenantDetail', data)
  },
  // 获取租户用户-分页
  getTenantUserListPage(data) {
    return request.get('api/web/v1/authority/public/user/getUserListPageByMemberCode', data)
  },
  // 获取租户用户-分页
  getTenantUserListPage2(data) {
    return requestForOperationalBackOffice.get('api/web/v1/tenant/public/tenantManage/getTenantDetailUserListPage', data)
  },
  //获取租户列表
  getMemberList(data){
    return requestForOperationalBackOffice.post('api/web/v1/tenant/public/tenantManage/getTenantListPage', data)
  },
  // 租户启用
  enableTenant(data) {
    return requestForOperationalBackOffice.put('api/web/v1/tenant/public/tenantManage/enableOne', data)
  },
  // 租户禁用
  disableTenant(data) {
    return requestForOperationalBackOffice.put('api/web/v1/tenant/public/tenantManage/disableOne', data)
  },
  //获取租户模板
  getTemplateTenantList(){
    return requestForOperationalBackOffice.get('api/web/v1/tenant/public/tenantManage/getTemplateTenantList')
  },
  //新增
  addNewMember(data){
    return requestForOperationalBackOffice.post('api/web/v1/tenant/public/tenantManage/addTenant', data)
  },
  //修改
  modifyMember(data){
    return requestForOperationalBackOffice.put('api/web/v1/tenant/public/tenantManage/updateOne', data)
  },
  // 删除
  deleteMember(data){
    return requestForOperationalBackOffice.del('api/web/v1/tenant/public/tenantManage/deleteOne', data)
  }
}
