import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/enterpriseDocument/private/faultExperienceClass/'

export default {
  // 获取故障经验分类列表（不分页）
  getFaultExperienceClassList(data) {
    return request.get(baseUrl + 'getFaultExperienceClassList', data)
  },
  // 获取故障经验分类列表（分页）
  getFaultExperienceClassListPage(data) {
    return request.get(baseUrl + 'getFaultExperienceClassListPage', data)
  },
  // 新建故障经验分类
  addFaultExperienceClass(data) {
    return request.post(baseUrl + 'addFaultExperienceClass', data)
  },
  // 修改故障经验分类
  modifyFaultExperienceClass(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除故障经验分类
  delFaultExperienceClass(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 获取故障经验分类-详情
  getFaultExperienceClassDetail(id) {
    return request.get(baseUrl + 'getFaultExperienceClassDetail', { id })
  }
}
