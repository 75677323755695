import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/equipment/sparePart/private/sparePartClass/'

export default {
  // 获取备件类-不分页
  getSparePartClassList(data) {
    return request.get(baseUrl + 'getSparePartClassList', data)
  },
  // 获取备件类-分页
  getSparePartClassListPage(data) {
    return request.get(baseUrl + 'getSparePartClassListPage', data)
  },
  // 新增备件类
  addSparePartClass(data) {
    return request.post(baseUrl + 'addSparePartClass', data)
  },
  // 修改备件类
  modifySparePartClass(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除备件类
  delSparePartClass(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用
  startStatus(id) {
    return request.get(baseUrl + `enable?id=${id}`)
  },
  // 停用
  stopStatus(id) {
    return request.get(baseUrl + `disable?id=${id}`)
  },
  // 详情
  getSparePartClassDetail(data) {
    return request.get(baseUrl + 'getSparePartClassDetail', data)
  }
}
