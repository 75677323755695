var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tree-wrap" },
    [
      _c("GiantTree", {
        ref: "giantTree",
        staticClass: "normalTree",
        attrs: { nodes: _vm.nodes, setting: _vm.treeSetting },
        on: { onCreated: _vm.handleCreated }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }