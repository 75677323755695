var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          staticClass: "kr-form",
          attrs: { "label-width": "80px", model: _vm.pageInfo, size: "small" }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 15 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "模板名称" } },
                    [
                      _c(
                        "el-select",
                        {
                          on: { change: _vm.selectTitle },
                          model: {
                            value: _vm.pageInfo.title,
                            callback: function($$v) {
                              _vm.$set(_vm.pageInfo, "title", $$v)
                            },
                            expression: "pageInfo.title"
                          }
                        },
                        _vm._l(_vm.getTemplateList, function(val) {
                          return _c("el-option", {
                            key: val.name,
                            attrs: { label: val.name, value: val.name }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { "margin-left": "20px" }, attrs: { span: 6 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "text" },
                      on: { click: _vm.addTemplate }
                    },
                    [_vm._v("另存为")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "text" },
                      on: { click: _vm.delTemplate }
                    },
                    [_vm._v("删除")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "模板宽度" } },
                    [
                      _c("el-input-number", {
                        staticClass: "min-input",
                        attrs: {
                          "controls-position": "right",
                          min: 200,
                          max: 750
                        },
                        model: {
                          value: _vm.pageInfo.width,
                          callback: function($$v) {
                            _vm.$set(_vm.pageInfo, "width", $$v)
                          },
                          expression: "pageInfo.width"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "模板高度" } },
                    [
                      _c("el-input-number", {
                        staticClass: "min-input",
                        attrs: {
                          "controls-position": "right",
                          min: 200,
                          max: 750
                        },
                        model: {
                          value: _vm.pageInfo.height,
                          callback: function($$v) {
                            _vm.$set(_vm.pageInfo, "height", $$v)
                          },
                          expression: "pageInfo.height"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }