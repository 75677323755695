// 注册自定义指令 v-scroll-end
let selectUl
let event
export const selectScrollEnd = {
  inserted: function(el, binding) {
    selectUl = el.querySelector('.el-scrollbar__wrap')
    // 定义一个变量，表示是否正在滚动
    let isScrolling = false
    // 定义一个防抖函数，间隔一定时间触发回调函数
    function debounce(fn, delay) {
      let timer = null
      return function() {
        if (!isScrolling) {
          isScrolling = true
          clearTimeout(timer)
          timer = setTimeout(() => {
            isScrolling = false
            fn.apply(this, arguments)
          }, delay)
        }
      }
    }
    event = debounce(function() {
      // 获取 ul 滚动到底部的位置
      const scrollBottom = this.scrollHeight - this.scrollTop - this.clientHeight
      // 如果滚动到底部则触发回调函数
      if (scrollBottom <= 0) {
        binding.value()
      }
    }, 500)
    // 给下拉框的 ul 绑定滚动事件
    selectUl.addEventListener('scroll', event)
  },
  // 移除监听器
  unbind: function(el, binding) {
    selectUl && selectUl.removeEventListener('scroll', event)
  }
}
