var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "news-container" },
    [
      _c(
        "div",
        {
          staticClass: "news-icon",
          on: {
            click: function($event) {
              return _vm.showNews()
            }
          }
        },
        [
          _c(
            "el-badge",
            {
              staticClass: "lock-item",
              attrs: {
                value: _vm.getUnReadNewsLength(),
                max: 99,
                hidden: _vm.getUnReadNewsLength() === 0
              }
            },
            [
              _c("img", {
                attrs: { src: require("../../assets/l_c_H_images/通知.png") }
              }),
              _c("span", { staticStyle: { "font-size": "14px" } }, [
                _vm._v("通知")
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "news-body",
          style: { right: _vm.isShow ? "10px" : "-450px" }
        },
        [
          _c("div", { staticClass: "news-body-title" }, [
            _c("span", [_vm._v("消息")]),
            _c("i", {
              staticClass: "el-icon-close",
              on: {
                click: function($event) {
                  return _vm.showNews()
                }
              }
            })
          ]),
          _c("div", { staticClass: "news-body-card" }, [
            _c(
              "div",
              { staticClass: "new-news" },
              [
                _c(
                  "div",
                  { staticClass: "top-title" },
                  [
                    _vm._m(0),
                    _c(
                      "el-button",
                      {
                        staticStyle: { padding: "0" },
                        attrs: {
                          type: "text",
                          disabled: !_vm.newNewsList.length
                        },
                        on: {
                          click: function($event) {
                            return _vm.operateNews(
                              "readAll",
                              _vm.userInfo.id,
                              "更新成功"
                            )
                          }
                        }
                      },
                      [_vm._v("全部已读 ")]
                    )
                  ],
                  1
                ),
                _vm.newNewsList.length
                  ? _vm._l(_vm.newNewsList, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "news-card-body",
                          on: {
                            click: function($event) {
                              return _vm.viewNewsDetail(item)
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "title" }, [
                            _c("span", [_vm._v(_vm._s(item.type))]),
                            _c("div", [_vm._v("未读")])
                          ]),
                          _c("div", { staticClass: "ellipsis" }, [
                            _vm._v(_vm._s(item.message))
                          ]),
                          _c("div", { staticClass: "send-info" }, [
                            _c("div", [
                              _vm._v(
                                "发件人：" +
                                  _vm._s(
                                    item.creator ? item.creator : "系统自动推送"
                                  )
                              )
                            ]),
                            _c("div", [
                              _vm._v("发件时间：" + _vm._s(item.createTime))
                            ])
                          ])
                        ]
                      )
                    })
                  : _c("m-blank", {
                      staticStyle: { "padding-top": "20px" },
                      attrs: { title: "暂无新消息" }
                    })
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "history-news" },
              [
                _c(
                  "div",
                  { staticClass: "top-title" },
                  [
                    _vm._m(1),
                    _c(
                      "el-button",
                      {
                        staticStyle: { padding: "0" },
                        attrs: {
                          type: "text",
                          disabled: !_vm.historyNewsList.length
                        },
                        on: {
                          click: function($event) {
                            return _vm.operateNews(
                              "delAll",
                              _vm.userInfo.id,
                              "清除成功"
                            )
                          }
                        }
                      },
                      [_vm._v("全部清除 ")]
                    )
                  ],
                  1
                ),
                _vm.historyNewsList.length
                  ? _vm._l(_vm.historyNewsList, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "news-card-body",
                          on: {
                            click: function($event) {
                              return _vm.viewNewsDetail(item)
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "title" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.newsTypesName[item.type]
                                    ? _vm.newsTypesName[item.type]
                                    : item.type
                                )
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.operateNews(
                                      "del",
                                      item.id,
                                      "清除成功"
                                    )
                                  }
                                }
                              },
                              [_vm._v("清除")]
                            )
                          ]),
                          _c("div", { staticClass: "ellipsis" }, [
                            _vm._v(_vm._s(item.message))
                          ]),
                          _c("div", { staticClass: "send-info" }, [
                            _c("div", [
                              _vm._v(
                                "发件人：" +
                                  _vm._s(
                                    item.creator ? item.creator : "系统自动推送"
                                  )
                              )
                            ]),
                            _c("div", [
                              _vm._v("发件时间：" + _vm._s(item.createTime))
                            ])
                          ])
                        ]
                      )
                    })
                  : _c("m-blank", {
                      staticStyle: { "padding-top": "20px" },
                      attrs: { title: "暂无历史消息" }
                    })
              ],
              2
            )
          ])
        ]
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "消息详情", "show-footer": false },
          model: {
            value: _vm.isVisible,
            callback: function($$v) {
              _vm.isVisible = $$v
            },
            expression: "isVisible"
          }
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "news-info" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v(
                  _vm._s(
                    _vm.newsTypesName[_vm.newsDetail.type]
                      ? _vm.newsTypesName[_vm.newsDetail.type]
                      : _vm.newsDetail.type
                  )
                )
              ]),
              _c(
                "p",
                {
                  staticStyle: {
                    "line-height": "20px",
                    "white-space": "pre-wrap"
                  }
                },
                [_vm._v(_vm._s(_vm.newsDetail.message))]
              ),
              _c("div", { staticClass: "info" }, [
                _c("div", [
                  _vm._v(
                    "发件人：" +
                      _vm._s(
                        _vm.newsDetail.creator
                          ? _vm.newsDetail.creator
                          : "系统自动推送"
                      )
                  )
                ]),
                _c("div", [
                  _vm._v("发件时间：" + _vm._s(_vm.newsDetail.createTime))
                ])
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("span", [_vm._v("新消息")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("span", [_vm._v("历史消息")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }