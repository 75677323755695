<template>
  <MDialog v-model="modalShow" title="工时导入">
    <div class="batchImport">
      <el-steps :active="stepActive" finish-status="success" process-status="finish">
        <el-step title="上传文件" description="请上传指定格式文件" />
        <el-step title="上传成功" description="成功上传文件" />
      </el-steps>
      <!-- 上传文件 -->
      <div v-if="stepActive === 0">
        <div class="down-template">
          <div class="down-le" />
          <div class="down-ri">
            <div class="down-tit">下载模板</div>
            <div class="down-des">点击下方文字按钮，下载模版，并按照规定格式填写数据，每次填写数据总和不超过10000条</div>
            <div class="down-btn" @click="downloadTemplate">
              <img src="@/assets/l_c_M_images/download.png" class="icon">
              <span class="download">下载模板</span>
            </div>
          </div>
        </div>
        <div class="down-template">
          <div class="down-le" />
          <div class="down-ri">
            <div class="down-tit">上传文件<el-checkbox v-if="showCover" v-model="cover" :true-label="1" :false-label="0" style="margin-left: 10px">重复数据是否覆盖</el-checkbox></div>
            <div class="down-des">上传文件格式仅支持xls，xlsx，且文件大小不得超过5M</div>
            <div class="down-btn">
              <el-upload ref="upload" :file-list="fileList" v-bind="uploadProps">
                <el-button type="text" class="icons" icon="el-icon-upload" style="padding: 0">上传文件</el-button>
              </el-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="mubai-dialog-footer center-content">
      <el-button v-if="stepActive !== 2" @click="cancel">取消</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="uploading" @click="submitForm">确定</el-button>
    </div>
  </MDialog>
</template>
<script>
import { importUrls, checkImportUrls } from './importUrls'
import templateUrls, { templateName } from './templateUrls'
import requestForAsync from '@/utils/requestForAsync'
import { export_json_to_excel } from '@/utils/plug/to-excel'
import { getToken } from '@/utils/auth'
import dayjs from 'dayjs'

export default {
  name: 'BatchImport',
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    importName: {
      type: String,
      default: ''
    },
    showCover: {
      type: Boolean,
      default: true
    },
    processId: {
      type: undefined,
      default:null
    }
  },
  data() {
    return {
      modalShow: false,
      stepActive: 0,
      total: 0,
      columns: [],
      tableData: [],
      fileList: [],
      loading: false,
      submitLoading: false,
      excelContent: [],
      cancelText: {
        0: '取 消',
        1: '上一步',
        2: '关 闭'
      },
      confirmText: {
        0: '校 验',
        1: '导 入',
        2: '查 看'
      },
      uploadProps: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/production/private/procedureDetails/updateByImport`,
        headers: { token: getToken() },
        showFileList: true,
        limit: 1,
        autoUpload: false,
        onSuccess: this.fileUploadChange,
        onRemove: this.fileRemove,
        onError: this.fileUploadChange,
        onExceed: this.fileExceed,
        onChange: this.fileUploadChange,
        beforeUpload: this.beforeUpload
      },
      uploading: false,
      percentage: 20,
      timer: '',
      cover: 0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 289 - 48 - 12 - 220
    },
    loadingHeight() {
      return 270
      // return this.$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70 - 77
    }
  },
  watch: {
    visible(val) {
      this.modalShow = val
      if (val) {
        this.stepActive = 0
        this.percentage = 20
        this.total = 0
        this.loading = false
        this.uploading = false
        this.tableData = []
        this.fileList = []
      }
    },
    modalShow(val) {
      if (!val) {
        this.$emit('update:visible', false)
      }
    }
  },
  mounted() {
    this.modalShow = this.visible
  },
  methods: {
    cellClassName({ row, column }) {
      return column.property !== 'reason' && row[column.property] && row[column.property].split(':')[1] ? 'has-error' : ''
    },
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5M!')
      }
      return isLt5M
    },
    fileExceed() {
      this.$message.info('只能上传一个文件')
    },
    fileRemove() {
      this.fileList = []
    },
    // 上传图纸/附件
    fileUploadChange(res, file) {
      if (res.raw) {
        if(res.response && res.response.errorCode !== 0){
          this.fileList = []
          return true
        }else {
          this.fileList = file
          return true
        }
      }

      if (res.errorCode === 0) {
        this.stepActive = 1
        this.modalShow = false
        return this.$message.success('上传成功')
      }
      if (res.errorCode !== 0) {
        this.fileList = []
        this.stepActive = 0
        return this.$message.error(res.result)
      }
    },
    async downloadTemplate() {
      const res = await requestForAsync.exportPost("api/web/v1/basicData/production/private/procedureDetails/exportProcedureDetails",[])
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        const fileName = `工时导入模板${dayjs().format('YYYYMMDDHHmmss')}.xls`
        this.downloadFile(urlP, fileName)
      }
    },
    downloadFile(urlP, name) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = urlP
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async submitForm() {
      if (this.fileList.length !== 1) {
        this.submitLoading = false
        return this.$message.info('请先上传文件')
      }
      consoleSelf.info(this.stepActive)
      if (this.stepActive === 0) {
        // 校验
        await this.$refs.upload.submit()
        this.submitLoading = false
        return true
      }
    },
    cancel() {
      if (this.stepActive === 0) {
        this.modalShow = false
        return true
      }
      if (this.stepActive === 1) {
        this.stepActive = 0
        this.loading = false
        this.tableData = []
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.batchImport {
  padding: 10px;

  .down-template {
    background: #F6F7F9;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    margin: 30px 0 20px 0;

    .down-le {
      width: 6px;
      background: #607FFF;
      border-radius: 4px 0px 0px 4px;
      margin-right: 20px;
      padding: 20px 0;
    }

    .down-ri {
      padding: 20px 0;

      .down-tit {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 20px;
        margin-bottom: 2px;
      }

      .down-des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9FA1B7;
        line-height: 20px;
      }

      .down-btn {
        margin-top: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
          width: 21px;
          height: 15px;
          color: #607FFF;;
        }

        .download {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #607FFF;
          line-height: 20px;
          margin-left: 8px;
        }

        .icons {
          color: #607FFF;
          display: inline-flex;
          align-items: center;

          ::v-deep .el-icon-upload {
            font-size: 22px;
            color: #607FFF;
          }
        }

        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0091FF;
          line-height: 20px;
          margin-left: 8px;
        }
      }
    }
  }

  .check-success {
    margin-top: 30px;

    .suc-top {
      background: rgba(0, 171, 41, 0.02);
      border-radius: 4px;
      border: 1px solid #00AB29;
      padding: 20px 0 20px 25px;

      .tit {
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 22px;
      }

      .des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A9ABBF;
        line-height: 22px;
      }
    }

    .imgs {
      width: 264px;
      height: 166px;
      margin: 43px auto 7px auto;
      display: block;
    }

    .check-tip {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #A9ABBF;
      line-height: 20px;
      text-align: center;
    }
  }

  .check-error {
    margin-top: 30px;

    .err-top {
      background: rgba(224, 32, 32, 0.02);
      border-radius: 4px;
      border: 1px solid #E02020;
      padding: 20px 0 20px 25px;
      margin-bottom: 25px;

      .tit {
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 22px;
      }

      .des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A9ABBF;
        line-height: 22px;
      }
    }

    .font-color {
      color: #f00;
    }

    .font-wight {
      font-weight: 700;
    }

  }

  .suc-tit {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #393D60;
    line-height: 20px;
    text-align: center;
    margin-top: -50px;
  }

  .check-tips {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #A9ABBF;
    line-height: 20px;
    text-align: center;
  }

  .img-suc {
    width: 279px;
    height: 279px;
    margin: 125px auto 7px auto;
    display: block;
  }
}

.el-dialog__footer {
  padding: 0 !important;
  height: 57px;
}

.mubai-dialog-footer {
  position: absolute;
  height: 57px;
  width: 100%;
  border-top: 1px solid #DDE3EB;
  padding: 10px 20px;
  bottom: 0;
  background: #FFFFFF;

  button {
    padding: 10px 40px !important;

    &:first-child {
      background: #F8F8FB;
      color: #8A8CA5;
      transition: all .5s;

      &:hover {
        border-color: #dadaf6;
        background: #f0f0f8;
      }
    }

    &:last-child {
      background: $primary-color;
      color: #ffffff;
      transition: all .5s;

      &:hover {
        background: #7691fc;
      }
    }
  }
}

.center-content {
  text-align: center;
}
::v-deep .has-error {
  background: rgba(224, 32, 32, 0.04);
}
</style>
