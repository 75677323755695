import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/enterpriseDocument/private/enterpriseDocument/'

export default {
  // 获取企业文件列表（不分页）
  getEnterpriseDocumentList(data) {
    return request.get(baseUrl + 'getEnterpriseDocumentList', data)
  },
  // 获取企业文件列表（分页）
  getEnterpriseDocumentListPage(data) {
    return request.get(baseUrl + 'getEnterpriseDocumentListPage', data)
  },
  // 新建企业文件
  addEnterpriseDocument(data) {
    return request.post(baseUrl + 'addEnterpriseDocument', data)
  },
  // 新建企业文件-批量
  addBatchEnterpriseDocument(data) {
    return request.post(baseUrl + 'addBatchEnterpriseDocument', data)
  },
  // 修改企业文件
  modifyEnterpriseDocument(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除企业文件
  delEnterpriseDocument(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 获取企业文件-详情
  getEnterpriseDocumentDetail(id) {
    return request.get(baseUrl + 'getEnterpriseDocumentDetail', { id })
  }
}
