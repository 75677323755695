import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/warehouse/private/warehouse/'
const stockBarseUrl = 'api/web/v1/warehouse/private/warehouseSafeStock/'
const customerBarseUrl = 'api/web/v1/warehouse/private/warehouseCustomer/'

export default {
  // 获取仓库建模-不分页
  getRepositoryModelList(data) {
    return request.get(baseUrl + 'getWarehouseList', data)
  },
  // 获取仓库建模-分页
  getRepositoryModelListPage(data) {
    return request.get(baseUrl + 'getWarehouseListPage', data)
  },
  // 根据仓库id列表查询仓库列表
  getWarehouseListByIdList(data) {
    return request.post(baseUrl + 'getWarehouseListByIdList', data)
  },
  // 获取仓库建模详情
  getRepositoryModelDeatil(data) {
    return request.get(baseUrl + 'getWarehouseDetail', data)
  },
  // 新增仓库建模
  addRepositoryModel(data) {
    return request.post(baseUrl + 'addWarehouse', data)
  },
  // 修改仓库建模
  modifyRepositoryModel(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 复制仓库建模
  copyRepositoryModel(data) {
    return request.post(baseUrl + 'addWarehouse', data)
  },
  // 删除仓库建模
  delRepositoryModel(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  // 启用/停用
  updateOneId(data) {
    return request.post(baseUrl + 'updateOneId', data)
  },
  // 判读新建下级是否被使用
  isAppendChild(id) {
    return request.get(baseUrl + `getParentId?id=${id}`)
  },
  getAllNodesByWarehouseId(id) {
    return request.get(baseUrl + 'getAllChildrenAndItself', { id })
  },
  createCode(params) {
    return request.get(baseUrl + 'getMaxWarehouseCode', params)
  },
  // 获取安全库存列表
  getWarehouseSafeStockList(params) {
    return request.get(stockBarseUrl + 'getWarehouseSafeStockListPage', params)
  },
  // 批量新建安全库存
  addListWarehouseSafeStock(data) {
    return request.post(stockBarseUrl + 'addListWarehouseSafeStock', data)
  },
  // 更新安全库存
  modifyWarehouseSafeStock(data) {
    return request.put(stockBarseUrl + 'updateOne', data)
  },
  // 删除安全库存
  delWarehouseSafeStock(data) {
    return request.del(stockBarseUrl + 'deleteOne', data)
  },

  // 获取适用客户列表-不分页
  getWarehouseCustomerList(params) {
    return request.get(customerBarseUrl + 'getWarehouseCustomerList', params)
  },
  // 获取适用客户列表-分页
  getWarehouseCustomerListPage(params) {
    return request.get(customerBarseUrl + 'getWarehouseCustomerListPage', params)
  },
  // 新建适用客户
  addWarehouseCustomer(data) {
    return request.post(customerBarseUrl + 'addWarehouseCustomer', data)
  },
  // 更新适用客户
  modifyWarehouseCustomer(data) {
    return request.put(customerBarseUrl + 'updateOne', data)
  },
  // 删除适用客户
  delWarehouseCustomer(data) {
    return request.del(customerBarseUrl + 'deleteOne', data)
  },
  // 通过客户id查询所属仓库
  getWarehouseByCustomerId(params) {
    return request.get(customerBarseUrl + 'customerId', params)
  }
}
