import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/basicData/private/'

export default {
  // 获取供应商-不分页
  getSupplierList(data) {
    return request.get(baseUrl + 'supplier/getSupplierList', data)
  },
  // 获取供应商-分页
  getSupplierListPage(data) {
    return request.get(baseUrl + 'supplier/getSupplierListPage', data)
  },
  // 新增供应商
  addSupplier(data) {
    return request.post(baseUrl + 'supplier/addSupplier', data)
  },
  copySupplier(data) {
    return request.post(baseUrl + 'supplier/addSupplier', data)
  },
  // 修改供应商
  modifySupplier(data) {
    return request.put(baseUrl + 'supplier/updateOne', data)
  },
  // 删除供应商
  delSupplier(data) {
    return request.del(baseUrl + 'supplier/deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${path}/${status}?id=${id}`)
  },
  createSupplierCode(params) {
    return request.get(baseUrl + 'supplier/getMaxSupplierCode', params)
  },

  // 获取客户-不分页
  getCustomerList(data) {
    return request.get(baseUrl + 'customer/getCustomerList', data)
  },
  // 获取客户-分页
  getCustomerListPage(data) {
    return request.get(baseUrl + 'customer/getCustomerListPage', data)
  },
  // 新增客户
  addCustomer(data) {
    return request.post(baseUrl + 'customer/addCustomer', data)
  },
  copyCustomer(data) {
    return request.post(baseUrl + 'customer/addCustomer', data)
  },
  // 修改客户
  modifyCustomer(data) {
    return request.put(baseUrl + 'customer/updateOne', data)
  },
  // 删除客户
  delCustomer(data) {
    return request.del(baseUrl + 'customer/deleteOne', data)
  },
  createCustomerCode(params) {
    return request.get(baseUrl + 'customer/getMaxCustomerCode', params)
  },

  // 获取现场-不分页
  getCustomerSiteList(data) {
    return request.get(baseUrl + 'customerSite/getCustomerSiteList', data)
  },
  // 获取现场-分页
  getCustomerSiteListPage(data) {
    return request.get(baseUrl + 'customerSite/getCustomerSiteListPage', data)
  },
  // 新增现场
  addCustomerSite(data) {
    return request.post(baseUrl + 'customerSite/addCustomerSite', data)
  },
  // 修改现场
  modifyCustomerSite(data) {
    return request.put(baseUrl + 'customerSite/updateOne', data)
  },
  // 删除现场
  delCustomerSite(data) {
    return request.del(baseUrl + 'customerSite/deleteOne', data)
  },
  // 获取和客户现场-不分页
  getAllCustomer(data) {
    return request.get(baseUrl + 'customer/getAllCustomer', data)
  }
}
