/** write by luomingzhi 2021/06/22 */
import MTable from './MTable/MTable.vue'
import MBlank from './MBlank/MBlank.vue'
import MDialog from './MDialog/MDialog.vue'
import MDrawer from './MDrawer/MDrawer.vue'
import MFormBuilder from './FormBuilder/FormBuilder.vue'
import MRemoteSelect from './MRemoteSelect/MRemoteSelect.vue'
import Import from './Import/Import.vue'

const mComponents = {
  MTable,
  MDialog,
  MDrawer,
  MBlank,
  MFormBuilder,
  MRemoteSelect,
  Import
}

const install = Vue => {
  if (install.installed) return
  Object.keys(mComponents).forEach(key => {
    Vue.component(key, mComponents[key])
  })
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

const MComponents = {
  install,
  ...MTable,
  ...MDialog,
  ...MDrawer,
  ...MBlank,
  ...MFormBuilder,
  ...MRemoteSelect,
  ...Import
}

export default MComponents
