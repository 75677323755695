<template>
  <div
    :id="commonId"
    style="display: none;font-size: 12px;
            text-align: center;"
  >
    <div v-for="(item, index) in codes" :key="index">
      <div style=" page-break-before:always;display:flex;justify-content:space-between;align-items:center;border:0.5px solid #000;height:140px;width:100%:">
        <div style="padding-left:10px;text-align:center">
          <div style="width:100px;text-align:center">
            <VueQr :correct-level="3" :auto-color="false" color-dark="#000000" :text="item.produceWorkOrderNumber" :size="100" :margin="0" />
          </div>
          <div
            style="padding-top: 10px;font-size: 12px;transform: scale(0.9);
    -webkit-transform: scale(0.9);text-align: left;transform-origin: 0 50%"
          >生产工单号：{{ item.produceWorkOrderNumber }}</div>
        </div>
        <h2>生产工单流转卡</h2>
        <div style="width:24.9%;border-left:0.5px solid #000">
          <div
            style="
              height:70px;
              width:100%;
              border-bottom:0.5px solid #000;
              line-height: 70px;
              color: rgba(90, 90, 90, 100);
              font-size: 12px;
              text-align: center;
              font-family: PingFang SC;
              display:flex;
              justify-content:flex-start;
              align-items:center;"
          >
            <div style="width:30%;border-right:0.5px solid #000">优先级</div>
            <div style="width:70%;text-align:center;font-weight:700;color:#000">{{ item.priority }}</div>
          </div>
          <div
            style="height:70px;
                line-height: 70px;
                color: rgba(90, 90, 90, 100);
                font-size:12px;
                text-align: center;
                font-family: PingFang SC;
                display:flex;
                justify-content:flex-start;
                align-items:center;"
          >
            <div style="width:30%;border-right:0.5px solid #000;">交期</div>
            <div style="width:70%;text-align:center;font-weight:700;color:#000">{{ item.deliveryTime }}</div>
          </div>
        </div>
      </div>
      <div style="border: 0.5px solid #000;border-bottom:none;">
        <div style="display:flex;justify-content:flex-start;align-items:center;border-bottom:0.5px solid #000;">
          <div style="flex:1;display:flex;border-right:0.5px solid #000;height:55px;align-items:center;">
            <div
              style="width:60px;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;"
            >产品名称：</div>
            <div
              style="flex:3;color:#000;font-weight:700;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;text-align:left;transform-origin: 0 50%"
            >{{ item.materialsName }}</div>
          </div>
          <div style="flex:1;display:flex;border-right:0.5px solid #000;height:55px;align-items:center;">
            <div
              style="width:60px;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;"
            >编码：</div>
            <div
              style="flex:3;color:#000;font-weight:700;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;text-align:left;transform-origin: 0 50%"
            >{{ item.materialsCode }}</div>
          </div>
          <div style="flex:1;display:flex;border-right:0.5px solid #000;height:55px;align-items:center;">
            <div
              style="width:60px;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;"
            >规格：</div>
            <div
              style="line-height:14px;flex:3;color:#000;font-weight:700;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;text-align:left;transform-origin: 0 50%; word-break:break-all;"
            >{{ item.materialsSpecifications }}</div>
          </div>
          <div style="flex:1;display:flex;height:55px;align-items:center;">
            <div
              style="width:60px;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;"
            >批次码：</div>
            <div
              style="flex:3;color:#000;font-weight:700;word-break:break-all;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;text-align:left;transform-origin: 0 50%"
            >{{ item.batchNumber }}</div>
          </div>
        </div>
        <div style="display:flex;justify-content:flex-start;align-items:center;border-bottom:0.5px solid #000;">
          <div style="flex:1;display:flex;border-right:0.5px solid #000;height:55px;align-items:center;">
            <div
              style="width:60px;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;"
            >工单数量：</div>
            <div
              style="flex:3;color:#000;font-weight:700;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;text-align:left;transform-origin: 0 50%"
            >{{ item.quantity }}</div>
          </div>
          <div style="flex:1;display:flex;border-right:0.5px solid #000;height:55px;align-items:center;">
            <div
              style="width:60px;display:flex;align-items:center;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;"
            >计划开始时间：</div>
            <div
              style="flex:3;color:#000;font-weight:700;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;text-align:left;transform-origin: 0 50%"
            >{{ item.planStartTime }}</div>
          </div>
          <div style="flex:1;display:flex;border-right:0.5px solid #000;height:55px;align-items:center;">
            <div
              style="width:60px;display:flex;align-items:center;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;"
            >计划结束时间：</div>
            <div
              style="flex:3;color:#000;font-weight:700;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;text-align:left;transform-origin: 0 50%"
            >{{ item.planEndTime }}</div>
          </div>
          <div style="flex:1;display:flex;height:55px;">
            <div
              style="width:60px;display:flex;align-items:center;justify-content:center;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;"
            >工艺路线：</div>
            <div
              style="flex:3;color:#000;font-weight:700;align-items:center;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;text-align:left;word-break:break-all;display:flex;
    align-items:center;transform-origin: 0 50%"
            >{{ item.processFlowName }}-{{ item.processFlowVersion }}</div>
          </div>
        </div>
        <div style="display:flex;justify-content:flex-start;align-items:center;border-bottom:0.5px solid #000;">
          <div style="flex:1;display:flex;border-right:0.5px solid #000;height:55px;align-items:center;">
            <div
              style="width:60px;height:100%;display:flex;align-items:center;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;"
            >所属销售订单：</div>
            <div
              style="flex:3;color:#000;font-weight:700;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;text-align:left;transform-origin: 0 50%"
            />
          </div>
          <div style="flex:1;display:flex;border-right:0.5px solid #000;height:55px;align-items:center;">
            <div
              style="width:60px;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;"
            >客户编码：</div>
            <div
              style="flex:3;color:#000;font-weight:700;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;text-align:left;transform-origin: 0 50%"
            />
          </div>
          <div style="flex:1;display:flex;border-right:0.5px solid #000;height:55px;align-items:center;">
            <div
              style="width:60px;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;"
            >项目名称：</div>
            <div
              style="flex:3;color:#000;font-weight:700;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;text-align:left"
            />
          </div>
          <div style="flex:1;display:flex;height:55px;align-items:center;">
            <div
              style="flex:1;line-height:80px;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;"
            >产线：</div>
            <div
              style="flex:3;color:#000;font-weight:700;transform: scale(0.9);
    -webkit-transform: scale(0.9);font-size: 12px;text-align:left;transform-origin: 0 50%"
            >{{ item.areaName }}</div>
          </div>
        </div>
      </div>
      <table id="customers" style="border: 1px solid #000;border-collapse: collapse;font-family: Arial, Helvetica, sans-serif; border-collapse: collapse;width: 100%;">
        <tr style="height:40px;border: 1px solid #000;padding: 8px;text-align:center;background:#D9D9D9">
          <th style="font-size: 12px;width:50px;background:#D9D9D9;">序号</th>
          <th style="font-size: 12px;width:80px;background:#D9D9D9;">工序执行顺序</th>
          <th style="font-size: 12px;width:70px;background:#D9D9D9;">工序类型</th>
          <th style="font-size: 12px;background:#D9D9D9;">工序名称</th>
          <th style="font-size: 12px;background:#D9D9D9;">完工数量</th>
          <th style="font-size: 12px;background:#D9D9D9;">完工时间</th>
          <th style="font-size: 12px;background:#D9D9D9;">备注</th>
        </tr>
        <tr v-for="(items, idx) in item.taskData" :key="items.id" style="text-align:center;font-size: 12px;">
          <td style="padding:8px;border: 1px solid #000;width:50px;">{{ idx+1 }}</td>
          <td style="padding:8px;border: 1px solid #000;width:90px;">{{ items.procedureIndex }}</td>
          <td style="padding:8px;border: 1px solid #000;width:80px;">{{ items.procedureType === 0 ? '自制': items.procedureType === 1 ? '外协':'自制和外协' }}</td>
          <td style="padding:8px;border: 1px solid #000;">{{ items.procedureName }}</td>
          <td style="padding:8px;border: 1px solid #000;" />
          <td style="padding:8px;border: 1px solid #000;" />
          <td style="padding:8px;border: 1px solid #000;" />
        </tr>
      </table>
    </div>

  </div>
</template>

<script>

import VueQr from 'vue-qr'
export default {
  name: 'PrintCode',
  components: { VueQr },
  props: {
    commonIds: {
      type: String,
      default: 'print'
    }
  },
  data() {
    return {
      code: '',
      info: {},
      deliveryTime: '',
      commonId: 'print',
      codes: []
    }
  },
  watch: {
    commonIds(val) {
      this.commonId = val
    }
  },

  methods: {
    prints(data) {
      this.codes = data
      console.log('ceshi=.', this.codes)
      this.printIframe()
    },
    print(data) {
      this.codes = [data]
      this.printIframe()
    },
    printIframe() {
      setTimeout(() => {
        const printHtml = document.getElementById(`${this.commonId}`).innerHTML
        const new_iframe = document.createElement('IFRAME')
        let doc = null
        new_iframe.setAttribute('style', 'width:0px;height:0px;position:absolute;left:-2000px;top:-2000px;')
        new_iframe.setAttribute('align', 'center')
        document.body.appendChild(new_iframe)
        doc = new_iframe.contentWindow.document
        doc.write('<div style="width:100%;height:auto;margin:0 auto;"align="center">' + printHtml + '</div>')
        // doc.body.style.zoom = '0.9'
        doc.close()
        new_iframe.contentWindow.focus()
        new_iframe.contentWindow.print()
      }, 0)
    }
  }
}
</script>

<style scoped>
 #prints{
   transform: scale(0.9);
   -webkit-transform: scale(0.9);
 }
</style>
