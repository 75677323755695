var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MDialog",
    {
      attrs: { width: "1000px", "append-to-body": true, title: _vm.title },
      on: { onOk: _vm.submitForm, fullScreen: _vm.fullScreenChange },
      model: {
        value: _vm.modalShow,
        callback: function($$v) {
          _vm.modalShow = $$v
        },
        expression: "modalShow"
      }
    },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c(
            "SearchForm",
            {
              attrs: {
                "form-list-extend": _vm.searchFormList,
                "form-data": _vm.searchFormData
              },
              on: {
                "update:formData": function($event) {
                  _vm.searchFormData = $event
                },
                "update:form-data": function($event) {
                  _vm.searchFormData = $event
                },
                search: _vm.searchTable
              }
            },
            [
              _c("SelectRemoteSearch", {
                ref: "categoryIds",
                staticClass: "select",
                attrs: {
                  slot: "categoryIds",
                  "search-name": "name",
                  clearable: "",
                  placeholder: "请选择模具类型",
                  multiple: true,
                  "option-val": "id",
                  "option-label": "name",
                  api: _vm.curdApi.getMouldTypeList,
                  "collapse-tags": ""
                },
                on: {
                  change: function(val) {
                    _vm.handleSelect("categoryIds", val)
                  }
                },
                slot: "categoryIds"
              })
            ],
            1
          ),
          !_vm.single
            ? _c(
                "div",
                [
                  _c("span", { staticStyle: { margin: "0 20px" } }, [
                    _vm._v("已选中 "),
                    _c("span", { staticStyle: { color: "#000" } }, [
                      _vm._v(_vm._s(_vm.getSelectionDataLength))
                    ]),
                    _vm._v(" 项")
                  ]),
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.resetData } },
                    [_vm._v("清空")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "materials-info" },
        [
          _c("MTable", {
            ref: "mTable",
            attrs: {
              "highlight-current-row": _vm.single,
              columns: _vm.columns,
              height: _vm.height,
              "set-data-method": _vm.getTableData,
              "columns-setting": false
            },
            on: {
              "selection-change": _vm.selectionChange,
              "current-change": _vm.selectionChange,
              "row-click": _vm.handleRowClick
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              }
            ])
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }