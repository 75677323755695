<template>
  <div class="gantt-leftbar">
    <div class="gantt-leftbar-item gantt-block-top-space" :style="{ height: topSpace + 'px' }" />
    <el-tree
      :data="showDatas"
      ref="tree"
      :props="defaultProps"
      node-key="id"
      empty-text=""
      :default-expanded-keys="expandedKeys"
      @node-collapse="nodeCollapse"
      @node-expand="nodeExpand"
    >
      <div class="custom-tree-node" slot-scope="{ node, data }">
        <div>{{ node.label }}</div>
        <div>
          <div style="display:flex;align-items: center;">
          <div v-if="data.isTaskNode && !data.status && !data.type" style="color: #FA6400">未下达</div>
          <div v-else>{{ data.processPercent }}%</div>
          <div style="position: absolute;right:10px">
          <el-tooltip placement="bottom">
            <div slot="content" style="padding: 0 10px; cursor: pointer">
              <div v-if="(data.isTaskNode && data.status) || data.type" @click="viewNodeDetail(data)">详情</div>
              <div @click="adjustNode(data)" v-if="data.status != 3 && !data.type" style="padding-top: 10px">调整</div>
              <div v-if="data.isTaskNode && !data.status && !data.type" @click="releaseNode(data)" style="padding-top: 10px" >
                下达
              </div>
              <div v-if="(data.status === 1 || data.status === 2) && !data.type" @click="onUrge(data)" style="padding-top: 10px" >
                催办
              </div>
              <div v-if="data.status && !data.type" @click="viewLog(data)" style="padding-top: 10px" >
                日志
              </div>
            </div>
            <img class="setting"  v-if="data.type != 2" src="@/assets/table_filter_images/setting.png" alt="设置" />
          </el-tooltip>
        </div>
      </div>
        </div>
      </div>
    </el-tree>
    <!-- <slot :data="data">
          <div class="gantt-leftbar-defalutItem">need slot</div>
        </slot> -->
  </div>
  <!-- </div> -->
</template>
  
  <script>
import dr from "../dynamic-render.js";
export default {
  name: "LeftBar",
  mixins: [dr],
  props: {
    dataKey: String,
    datas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentIdxs: undefined,
      currentName: "",
      defaultProps: {
        children: "children",
        label: "planName",
      },
      defaultShowNodes: [],
      expandedKeys: [], // 控制展开的节点
      treeOpenNum: 0,
    };
  },
  computed: {
    currentGanttBlocksHeights() {
      return this.$store.state.user.currentGanttBlocksHeights;
    },
    currentSelectIdx() {
      return this.$store.state.user.currentSelectIdx;
    },
  },
  watch: {
    showDatas(val) {
      if (val && val.length > 0) {
        const idx = this.currentSelectIdx ? this.currentSelectIdx.id : this.currentIdx;
        const data = val.length > 0 ? val.filter((item) => item.id === idx) : [];
        this.currentIdxs = data.length > 0 ? data[0].id : val[0].id;
        this.currentName = data.length > 0 ? data[0].name : val[0].name;
        this.$store.dispatch("user/currentSelectIdx", {
          id: this.currentIdxs,
          name: this.currentName,
        });
        this.$emit('selectId', this.currentIdxs)
        this.defaultShowNodes = this._.cloneDeep(this.showDatas);
        this.$nextTick(() => {
        let allNodes = this.$refs.tree.store.nodesMap;
        for (let x in allNodes) {
          if (allNodes[x].expanded) {
            const index = this.defaultShowNodes.findIndex(
              (node) => node.id === allNodes[x].data.id
            );
            if(index != -1){
              this.defaultShowNodes.splice(index + 1, 0, ...allNodes[x].data.children);
            }
          }
        }
      })
      }
    },
  },
  mounted() {},
  methods: {
    viewNodeDetail(data){
      if(!data.type){
        this.$router.push({ name: 'ProjectTaskDetail',query:{id:data.id,type:'view'}})
      }else{
        this.$router.push({ name: 'prodPlanCreate', query: { type:'view', id: data.productionId }})
      }
      },
    releaseNode(data) {
      this.$emit("releaseNode", data);
    },
    viewLog(data){
      this.$emit("viewLog", data);
    },
    onUrge(data){
      this.$emit("onUrge", data);
    },
    adjustNode(data) {
      this.$emit("adjustNode", data);
    },
    selectBge(item) {
      this.currentIdxs = item.id;
      this.currentName = item.name;
      this.$store.dispatch("user/currentSelectIdx", {
        id: this.currentIdxs,
        name: this.currentName,
      });
      this.$emit("selectId", this.currentIdxs);
    },
    nodeExpand(data, node, self) {
      let flag = false;
      this.expandedKeys.some((item) => {
        if (item === data.id) {
          // 判断当前节点是否存在， 存在不做处理
          flag = true;
          return;
        }
      });
      if (!flag) {
        // 不存在则存到数组里,保存当前展开的节点
        this.expandedKeys.push(data.id);
      }
      const itemsToInsert = [];
      data.children.forEach((item) => {
        if (item.parentId === data.id) {
          itemsToInsert.push(item);
        }
      });

      const index = this.defaultShowNodes.findIndex((node) => node.id === data.id);
      this.defaultShowNodes.splice(index + 1, 0, ...itemsToInsert);
      this.defaultShowNodes = Array.from(new Set(this.defaultShowNodes));
      this.$emit("nodeOpen", this.defaultShowNodes);
    },
    nodeCollapse(data, node, self) {
      // 删除当前关闭的节点
      this.expandedKeys.some((item, i) => {
        if (item === data.id) {
          this.expandedKeys.splice(i, 1);
        }
      });
      let allNodes = this.$refs.tree.store.nodesMap;
      for (let x in allNodes) {
        if (!allNodes[x].parent.expanded) {
          allNodes[x].expanded = false;
        }
      }
      this.removeChildIds(data);
      this.removeChildrenIds(data);
      this.$emit("nodeClose", this.defaultShowNodes);
    },
    removeChildIds(data) {
      const ts = this;
      if (data.children) {
        data.children.forEach(function (item) {
          const index = ts.expandedKeys.indexOf(item.id);
          if (index != -1) {
            ts.expandedKeys.splice(index, 1);
          }
          ts.removeChildIds(item);
        });
      }
    },
    // 删除树子节点
    removeChildrenIds(data) {
      const ts = this;
      //懒加载的时候想要删除子节点  得在加载下级数据时添加上data.children
      if (data.children) {
        data.children.forEach(function (item) {
          const index = ts.defaultShowNodes.findIndex((node) => node.id === item.id);
          if (index != -1) {
            ts.defaultShowNodes.splice(index, 1);
          }
          ts.removeChildrenIds(item);
        });
      }
    },
  },
};
</script>
  <style scoped lang="scss">
.custom-tree-node {
  width: 82%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .setting {
    width: 15px;
    position: relative;
    left: 4px;
  }
}
::v-deep {
  .el-tree-node__content {
    height: 46px;
    cursor: pointer;
    border-bottom: 1px solid #ececec;
  }
}
</style>
  
  