import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/equipment/check/private/equipmentCycleCheckTask/'
const cycleUrl = 'api/web/v1/equipment/check/private/cycleCheckPlan/'

export default {
  // 获取巡检单-不分页
  getInspectionBillsList(data) {
    return request.get(baseUrl + 'getEquipmentCycleCheckTaskList', data)
  },
  // 获取巡检单-分页
  getInspectionBillsListPage(data) {
    return request.get(baseUrl + 'getEquipmentCycleCheckTaskListPage', data)
  },
  // 新增巡检单
  addInspectionBills(data) {
    return request.post(baseUrl + 'addCycleCheckPlan', data)
  },
  // 新增巡检单
  copyInspectionBills(data) {
    return request.post(baseUrl + 'addCycleCheckPlan', data)
  },
  // 修改巡检单
  modifyInspectionBills(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 作废巡检单
  abolishInspectionBills(data) {
    return request.put(cycleUrl + 'nullifyTask', data)
  },
  // 跳过巡检单
  passInspectionBills(data) {
    return request.put(cycleUrl + `skipTask?skipReason=${data.skipReason}`, data.ids)
  },
  getInspectionBillsDetail(data) {
    return request.get(baseUrl + 'getEquipmentCycleCheckTaskDetail', data)
  },
  // 获取巡检类型
  getCheckTypeList() {
    return request.get(cycleUrl + 'getCheckTypeList')
  },
  // 获取巡检原因
  getSkipReason(params) {
    return request.get('api/web/v1/equipment/check/private/equipmentSpotCheckTask/getEquipmentTaskCheckSkipReasonList', params)
  }
}
