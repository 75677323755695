import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/enterpriseDocument/private/faultExperience/'

export default {
  // 获取故障经验列表（不分页）
  getFaultExperienceList(data) {
    return request.get(baseUrl + 'getFaultExperienceList', data)
  },
  // 获取故障经验列表（分页）
  getFaultExperienceListPage(data) {
    return request.get(baseUrl + 'getFaultExperienceListPage', data)
  },
  // 新建故障经验
  addFaultExperience(data) {
    return request.post(baseUrl + 'addFaultExperience', data)
  },
  // 修改故障经验
  modifyFaultExperience(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除故障经验
  delFaultExperience(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 获取故障经验-详情
  getFaultExperienceDetail(id) {
    return request.get(baseUrl + 'getFaultExperienceDetail', { id })
  }
}
