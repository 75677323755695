<template>
  <el-select
    v-model="value"
    v-selectScrollEnd="loadMore"
    filterable
    remote
    reserve-keyword
    :remote-method="remoteMethod"
    :loading="loading"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <el-option
      v-for="item in list"
      :key="item[optionKey]"
      :label="getLabel(item)"
      :value="item[optionVal]"
    />
  </el-select>
</template>

<script>
export default {
  props: {
    searchName: {
      type: String,
      default: 'search'
    },
    optionKey: {
      type: String,
      default: 'id'
    },
    optionLabel: {
      type: String,
      default: 'label'
    },
    optionVal: {
      type: [String, Array, Number],
      default: undefined
    },
    api: {
      type: Function,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    },
    sizeName: {
      type: String,
      default: 'limit'
    },
    resultFieldName: {
      type: String,
      default: 'records'
    },
    superDisabled: { // 控制是否请求接口
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      defaultParams: { page: 1, [this.sizeName]: 10, [this.searchName]: '' },
      value: '',
      list: []
    }
  },
  created() {
    this.defaultParams = { ...this.defaultParams, ...this.params }
    !this.superDisabled && this.getList(this.defaultParams)
  },
  methods: {
    getLabel(item) {
      let label = ''
      const arr = this.optionLabel.split(',')
      arr.forEach(i => {
        label += ' / ' + item[i]
      })
      return label.slice(2)
    },
    // 成品物料下拉触底分页加载
    loadMore() {
      if (this.list.length === this.listTotal) return
      this.defaultParams.page++
      this.getList(this.defaultParams)
    },

    // 远程搜索成品物料
    remoteMethod(val) {
      this.list.length = 0
      this.defaultParams = {
        page: 1,
        [this.sizeName]: 10,
        [this.searchName]: val,
        ...this.params
      }
      this.getList(this.defaultParams)
    },

    async getList(params) {
      const res = await this.api(params)
      if (res) {
        // 判断分页与不分页
        this.listTotal = res.total || res.length
        const list = res[this.resultFieldName] ? res[this.resultFieldName] : res
        this.list.push(...list)
      }
    },

    reset() {
      this.defaultParams = { page: 1, [this.sizeName]: 10, [this.searchName]: '', ...this.params }
      this.value = ''
      this.list = []
      this.getList(this.defaultParams)
    }
  }
}
</script>
