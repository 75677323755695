import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/invoices/private/facilityTechnicalParameterTemplate/'

export default {
  // 获取设备技术参数模板列表-不分页
  getTechnicalList(data) {
    return request.get(baseUrl + 'getFacilityTechnicalParameterTemplateLis', data)
  },
  // 获取设备技术参数模板列表-分页
  getTechnicalListPage(data) {
    return request.get(baseUrl + 'getFacilityTechnicalParameterTemplateListPage', data)
  },
  // 新建设备技术参数模板
  addTechnical(data) {
    return request.post(baseUrl + 'addFacilityTechnicalParameterTemplate', data)
  },
  // 修改设备
  modifyTechnical(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除设备
  delTechnical(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 获取设备技术参数模板详情
  getTechnicalDetail(data) {
    return request.get(baseUrl + 'getFacilityTechnicalParameterTemplateDetail', data)
  },
  // 批量删除设备技术参数模板
  delBatchTechnical(data) {
    return request.del(baseUrl + 'deleteBatch', data)
  }
}
