var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "holder",
      attrs: { id: "viewport", "data-type": "viewport" }
    },
    [
      _c(
        "div",
        {
          staticClass: "screen",
          style: {
            height: _vm.page.height + "px",
            width: _vm.page.width + "px",
            backgroundImage: "url(" + _vm.backImg + ")"
          }
        },
        [
          _vm._l(_vm.widgetStore, function(val) {
            return _c(val.type, {
              key: val.uuid,
              tag: "component",
              staticClass: "layer",
              class: { active: _vm.widgetId === val.uuid },
              attrs: {
                "data-title": val.type,
                val: val,
                "data-type": val.type,
                "data-uuid": val.uuid
              }
            })
          }),
          _vm.isResizes ? _c("control") : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }