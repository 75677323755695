var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._g(
      _vm._b(
        {
          directives: [
            {
              name: "selectScrollEnd",
              rawName: "v-selectScrollEnd",
              value: _vm.loadMore,
              expression: "loadMore"
            }
          ],
          attrs: {
            filterable: "",
            remote: "",
            "reserve-keyword": "",
            "remote-method": _vm.remoteMethod,
            loading: _vm.loading
          },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        },
        "el-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    _vm._l(_vm.list, function(item) {
      return _c("el-option", {
        key: item[_vm.optionKey],
        attrs: { label: _vm.getLabel(item), value: item[_vm.optionVal] }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }