<template>
  <el-dialog
    :visible="value"
    :class="allPropsData.width === '400px' ? 'middle-dialog' : ''"
    :fullscreen="fullscreen"
    v-bind="allPropsData"
    v-on="$listeners"
    @close="close(true)"
  >
    <template v-if="$slots.title" slot="title">
      <slot name="title" />
    </template>
    <template v-else slot="title">
      <div class="head-all">
        <div class="title">{{ $attrs.title }}</div>
        <div class="head-ri" @click="showFull">
          <img v-if="fullscreen" class="ri-img" src="@/assets/narrow-amplification/narrow.png">
          <img v-else class="ri-img" src="@/assets/narrow-amplification/amplification.png" alt="">
        </div>
      </div>
    </template>
    <div v-if="showBody" class="body" :style="{ maxHeight: height + 'px' }">
      <slot />
    </div>
    <template v-if="showFooter">
      <template v-if="$slots.footer" slot="footer">
        <slot name="footer" />
      </template>
      <!--      定制  400px footer居中-->
      <div v-else slot="footer" :class="allPropsData.width === '400px' ? 'mubai-dialog-footer center-content' : 'mubai-dialog-footer'">
        <el-button v-if="needCancelBtn" @click="close()">{{ cancelButtonText }}</el-button>
        <el-button v-if="saveButton" type="primary" :loading="saveloading" @click="onSave()">保存并提交</el-button>
        <el-button v-if="needSubBtn" type="primary" :loading="loading" @click="onOk()">{{ confirmButtonText }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
/** write by luomingzhi */
export default {
  name: 'MDialog',
  props: {
    value: {
      type: Boolean,
      require: true,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    cancelButtonText: {
      type: String,
      default: '取 消'
    },
    confirmButtonText: {
      type: String,
      default: '确 定'
    },
    customCancel: {
      type: Boolean,
      default: false
    },
    saveButton: {
      type: Boolean,
      default: false
    },
    needSubBtn: {
      type: Boolean,
      default: true
    },
    needCancelBtn: {
      type: Boolean,
      default: true
    },
    confirmBtn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      saveloading: false,
      showBody: false,
      fullscreen: false
    }
  },
  computed: {
    allPropsData() {
      return Object.assign({
        width: '850px',
        top: '100px',
        closeOnClickModal: false,
        ...this.$attrs,
        customClass: `${this.$attrs.className} mubai-modal-wrap`
      })
    },
    height() {
      return this.fullscreen ? this.$store.state.app.contentHeight - 60 - 60 - 50 : this.$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70
    }
  },
  watch: {
    value(val) {
      if (!val) {
        setTimeout(() => {
          this.showBody = false
        }, 250)
      } else {
        this.showBody = true
        this.loading = false
      }
    }
  },
  methods: {
    onOk() {
      this.loading = true
      this.$emit('onOk', reset => {
        this.loading = false
        if (reset) {
          if (this.$parent.$refs.formBuild) {
            this.$parent.$refs.formBuild.formRefs().clearValidate()
          }
        }
      })
      return false
    },
    // 保存并提交按钮
    onSave() {
      this.saveloading = true
      this.$emit('onSave', reset => {
        this.saveloading = false
        if (reset) {
          if (this.$parent.$refs.formBuild) {
            this.$parent.$refs.formBuild.formRefs().clearValidate()
          }
        }
      })
      return false
    },
    close(close = false) {
      if (this.customCancel && !close) {
        this.$emit('onCancel')
        return false
      }
      if (this.$parent.$refs.formBuild) {
        this.$parent.$refs.formBuild.formRefs().clearValidate()
      }
      this.$emit('input', false)
      return false
    },
    showFull() {
      this.fullscreen = !this.fullscreen
      this.$emit('fullScreen', this.fullscreen)
    }
  }
}
</script>

<style lang="scss">
.mubai-modal-wrap {
  .el-dialog__header {
    background: #f8f8fb;
    padding: 20px;

    .el-dialog__title {
      font-size: 16px;
    }

    .el-dialog__close {
      font-size: 20px;
      line-height: 24px;
    }
  }
  // .el-form-item__label{
  //   padding:0;
  // }
  .el-dialog__body {
    //overflow: hidden;
    padding: 20px 30px 20px;
  }

  .body {
    overflow-y: auto;
    overflow-x: hidden;

    > div {
      margin-right: 10px;
    }
  }

  .el-dialog__footer {
    padding: 0 !important;
    height: 57px;
  }

  .mubai-dialog-footer {
    position: absolute;
    height: 57px;
    width: 100%;
    border-top: 1px solid #dde3eb;
    padding: 10px 20px;
    bottom: 0;
    background: #ffffff;
    button {
      padding: 10px 40px !important;

      &:first-child {
        background: #f8f8fb;
        color: #8a8ca5;
        transition: all 0.5s;

        &:hover {
          border-color: #dadaf6;
          background: #f0f0f8;
        }
      }

      &:last-child {
        background: $primary-color;
        color: #ffffff;
        transition: all 0.5s;

        &:hover {
          background: #7691fc;
        }
      }
    }
  }
  .center-content {
    text-align: center;
  }
}
.middle-dialog {
  .mubai-modal-wrap {
    top: 50%;
    margin-top: 0 !important;
    transform: translateY(-50%);
  }
}
.head-all {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    height: 20px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #393d60;
  }
  .head-ri {
    cursor: pointer;
    margin-right: 40px;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      display: inline-block;
    }
  }
}
.el-dialog__headerbtn {
  top: 18px !important;
  background: url('../../../assets/narrow-amplification/arro.png') left no-repeat!important;
  background-size: 22px 22px!important;
}
.el-dialog__headerbtn i {
  font-size: 24px !important;
  visibility: hidden;
  display: inline-block;
}
</style>
<style scoped>
</style>
