<template>
  <div class="gantt-chart" @wheel.passive="wheelHandle">
    <div
      class="gantt-container"
      :style="{
        height: `calc(100% - ${scrollXBarHeight}px)`,
        width: `calc(100% - ${scrollYBarWidth}px)`
      }"
    >
      <div
        v-show="!hideHeader"
        class="gantt-header"
        :style="{ width: `calc(100% + ${scrollYBarWidth}px)` }"
      >
        <div
          class="gantt-header-title"
          :style="{
            'line-height': titleHeight + 'px',
            height: titleHeight + 'px',
            width: titleWidth + 'px'
          }"
        >
          <slot name="title">welcome v-gantt-chart</slot>
        </div>
        <div ref="headerTimeline" class="gantt-header-timeline">
          <div
            class="gantt-timeline-wrapper"
            :style="{ width: totalWidth + scrollYBarWidth + 'px' }"
          >
            <timeline
              :start="start"
              :end="end"
              :cell-width="cellWidth"
              :title-height="titleHeight"
              :scale="scale"
              :start-time-of-render-area="dayjs(startTimeOfRenderArea)"
              :end-time-of-render-area="dayjs(endTimeOfRenderArea)"
              :get-positon-offset="getPositonOffset"
               :ganttType ="ganttType"
            />
          </div>
        </div>
      </div>

      <div
        class="gantt-body"
        :style="{ height: `calc(100% - ${actualHeaderHeight}px)` }"
      >
        <div class="gantt-table">
          <div
            ref="marklineArea"
            :style="{ marginLeft: titleWidth + 'px' }"
            class="gantt-markline-area"
          >
            <CurrentTime
              v-if="showCurrentTime"
              :get-positon-offset="getPositonOffset"
            />
            <!-- <mark-line
              v-for="(times, index) in timeLines"
              :key="index"
              :markLineTime="times.time"
              :getPositonOffset="getPositonOffset"
              :color="times.color"
            ></mark-line> -->
          </div>
          <div
            ref="leftbarWrapper"
            class="gantt-leftbar-wrapper"
            :style="{
              width: titleWidth + 'px',
              height: `calc(100% + ${scrollXBarHeight})`
            }"
          >
            <LeftBar v-if="ganttType ==='tableType'"
              :datas="datas"
              :data-key="dataKey"
              :scroll-top="scrollTop"
              :height-of-blocks-wrapper="heightOfBlocksWrapper"
              :cell-height="cellHeight"
              :preload="preload"
              :style="{ height: totalHeight + 'px' }"
              @selectId="selectId"
            >
              <template v-slot="{ data }">
                <slot name="left" :data="data" />
              </template>
            </LeftBar>
            <LeftTreeBar v-else
            ref="leftBar"
              :datas="datasB"
              :data-key="dataKey"
              :scroll-top="scrollTop"
              :height-of-blocks-wrapper="heightOfBlocksWrapper"
              :cell-height="cellHeight"
              :preload="preload"
              :style="{ height: totalHeight + 'px' }"
              @selectId="selectId"
              @nodeOpen="nodeOpen"
              @nodeClose="nodeClose"
              @adjustNode="adjustNode"
              @releaseNode="releaseNode"
              @viewLog="viewLog"
              @onUrge="onUrge"
            >
              <template v-slot="{ data }">
                <slot name="left" :data="data" />
              </template>
            </LeftTreeBar>
          </div>
          <div ref="blocksWrapper" class="gantt-blocks-wrapper">
            <blocks
              :scroll-top="scrollTop"
              :scroll-left="scrollLeft"
              :height-of-blocks-wrapper="heightOfBlocksWrapper"
              :width-of-blocks-wrapper="widthOfBlocksWrapper"
              :array-keys="arrayKeys"
              :item-key="itemKey"
              :data-key="dataKey"
              :datas="datas"
              :cell-width="cellWidth"
              :cell-height="cellHeight"
              :scale="scale"
              :get-positon-offset="getPositonOffset"
              :get-width-about2-times="getWidthAbout2Times"
              :custom-generate-blocks="customGenerateBlocks"
              :start-time-of-render-area="startTimeOfRenderArea"
              :end-time-of-render-area="endTimeOfRenderArea"
              :preload="preload"
              :style="{ width: totalWidth + 'px', height: totalHeight + 'px' }"
              :start="start"
              :end="end"
              :select-ids="selectIds"
            >
              <template v-if="!customGenerateBlocks" v-slot="{ data, item , getPositonOffset, getWidthAbout2Times, selectIds}">
                <slot name="block" :data="data" :item="item" :getPositonOffset="getPositonOffset" :getWidthAbout2Times="getWidthAbout2Times" :selectIds="selectIds" />
              </template>
              <template
                v-else
                v-slot="{
                  data,
                  getPositonOffset,
                  getWidthAbout2Times,
                  isInRenderingTimeRange,
                  isAcrossRenderingTimeRange
                }"
              >
                <slot
                  name="block"
                  :data="data"
                  :getPositonOffset="getPositonOffset"
                  :getWidthAbout2Times="getWidthAbout2Times"
                  :isInRenderingTimeRange="isInRenderingTimeRange"
                  :isAcrossRenderingTimeRange="isAcrossRenderingTimeRange"
                  :startTimeOfRenderArea="startTimeOfRenderArea"
                  :endTimeOfRenderArea="endTimeOfRenderArea"
                />
              </template>
            </blocks>
          </div>
        </div>
      </div>
    </div>

    <div
      ref="scrollYBar"
      class="gantt-scroll-y"
      :style="{
        width: `${scrollYBarWidth}px`,
        height: `calc(100% - ${actualHeaderHeight}px`,
        marginTop: `${actualHeaderHeight}px`
      }"
      @scroll.passive="syncScrollY"
    >
      <div :style="{ height: totalHeight + 'px' }" />
    </div>

    <div
      ref="scrollXBar"
      class="gantt-scroll-x"
      :style="{
        height: `${scrollXBarHeight}px`,
        width: `calc(100% - ${titleWidth}px )`,
        marginLeft: titleWidth + 'px'
      }"
      @scroll.passive="syncScrollX"
    >
      <div :style="{ width: totalWidth + 'px' }" />
    </div>
  </div>
</template>

<script>
  import dayjs from 'dayjs'
  import ResizeObserver from 'resize-observer-polyfill'
  import {
    scaleList,
    getBeginTimeOfTimeLine,
    calcScalesAbout2Times
  } from '@/components/ganttChart/utils/timeLineUtils.js'
  import { isDef, warn } from '@/components/ganttChart/utils/tool.js'
  import {
    getPositonOffset as _getPositonOffset,
    getWidthAbout2Times as _getWidthAbout2Times
  } from '@/components/ganttChart/utils/gtUtils.js'
  import throttle from '@/components/ganttChart/utils/throttle.js'
  import Timeline from '@/components/ganttChart/time-line/index.vue'
  import CurrentTime from '@/components/ganttChart/mark-line/current-time.vue'
  import LeftBar from '@/components/ganttChart/left-bar/index.vue'
  import LeftTreeBar from '@/components/ganttChart/left-tree-bar/index.vue'
  import Blocks from '@/components/ganttChart/blocks/index.vue'
  import MarkLine from '@/components/ganttChart/mark-line/index.vue'

  export default {
    name: 'Gantt',
    components: { Timeline, LeftBar, Blocks, MarkLine, CurrentTime ,LeftTreeBar},
    props: {
      startTime: {
        default: () => dayjs(),
        validator(date) {
          const ok = dayjs(date).isValid()
          if (!ok) warn(`非法的开始时间 ${date}`)
          return ok
        }
      },
      endTime: {
        default: () => dayjs(),
        validator(date) {
          const ok = dayjs(date).isValid()
          if (!ok) warn(`非法的结束时间 ${date}`)
          return ok
        }
      },
      cellWidth: {
        type: Number,
        default: 70
      },
      cellHeight: {
        type: Number,
        default: 46
      },
      titleHeight: {
        type: Number,
        default: 40
      },
      titleWidth: {
        type: Number,
        default: 250
      },
      scale: {
        type: Number,
        default: 60,
        validator(value) {
          return scaleList.includes(value)
        }
      },
      datas: {
        type: Array,
        default: () => []
      },
      datasB: {
        type: Array,
        default: () => []
      },
      dataKey: {
        type: String,
        default: undefined
      },
      itemKey: {
        type: String,
        default: undefined
      },
      arrayKeys: {
        type: Array,
        default: () => []
      },
      showCurrentTime: {
        type: Boolean,
        default: false
      },
      timeLines: {
        type: Array
      },
      scrollToTime: {
        validator(date) {
          return dayjs(date).isValid()
        },
        default: dayjs().hour(0).minute(0).toString()
      },
      scrollToPostion: {
        validator(obj) {
          const validX = isDef(obj.x) ? !Number.isNaN(obj.x) : true
          const validY = isDef(obj.y) ? !Number.isNaN(obj.y) : true
          if (!validX && !validY) {
            warn('scrollToPostion x或y 有值为非Number类型')
            return false
          }
          return true
        }
      },
      hideHeader: {
        type: Boolean,
        default: false
      },
      hideXScrollBar: {
        type: Boolean,
        default: false
      },
      hideYScrollBar: {
        type: Boolean,
        default: false
      },
      customGenerateBlocks: {
        type: Boolean,
        default: false
      },
      timeRangeCorrection: {
        type: Boolean,
        default: true
      },
      preload: {
        type: Number
      },
      ganttType:{
        type: String,
        default: 'tableType'
      }
    },

    data() {
      return {
        // 缓存节点
        selector: {
          gantt_leftbar: {},
          gantt_table: {},
          gantt_scroll_y: {},
          gantt_timeline: {},
          gantt_scroll_x: {},
          gantt_markArea: {}
        },
        scrollTop: 0,
        scrollLeft: 0,
        // block 区域需要渲染的范围
        // 先渲染出空框架，在mounted后再得到真实的渲染范围，然后在根据范围渲染数据，比之前设置一个默认高度宽度，额外的渲染浪费更少了
        heightOfBlocksWrapper: 0,
        widthOfBlocksWrapper: 0,
        scrollBarWitdh: 17,
        dayjs,
        totalHeight: this.$store.state.app.contentHeight + 200,
        selectIds: undefined
      }
    },

    computed: {
      start() {
        return dayjs(this.startTime)
      },
      end() {
        const {
          start,
          widthOfBlocksWrapper,
          scale,
          cellWidth,
          timeRangeCorrection
        } = this
        let end = dayjs(this.endTime)
        const totalWidth = calcScalesAbout2Times(start, end, scale) * cellWidth
        // 时间纠正和补偿
        if (
          timeRangeCorrection &&
          (start.isAfter(end) || totalWidth <= widthOfBlocksWrapper)
        ) {
          end = start.add((widthOfBlocksWrapper / cellWidth) * scale, 'minute')
        }
        return end
      },
      totalWidth() {
        const { cellWidth, totalScales } = this
        return cellWidth * totalScales
      },
      totalScales() {
        const { start, end, scale } = this
        return calcScalesAbout2Times(start, end, scale)
      },
      currentGanttBlocksHeights() {
        console.log('666', this.$store.state.user.currentGanttBlocksHeights)
        return this.$store.state.user.currentGanttBlocksHeights
      },
      beginTimeOfTimeLine() {
        const value = getBeginTimeOfTimeLine(this.start, this.scale)
        return value
      },
      beginTimeOfTimeLineToString() {
        return this.beginTimeOfTimeLine.toString()
      },
      avialableScrollLeft() {
        // 不减这个1，滚动到时间轴尽头后继续滚动会慢慢的溢出
        const { totalWidth, widthOfBlocksWrapper } = this
        return totalWidth - widthOfBlocksWrapper - 1
      },
      avialableScrollTop() {
        const { totalHeight, heightOfBlocksWrapper } = this
        return totalHeight - heightOfBlocksWrapper - 1
      },
      scrollXBarHeight() {
        return this.hideXScrollBar ? 0 : this.scrollBarWitdh
      },
      scrollYBarWidth() {
        return this.hideYScrollBar ? 0 : this.scrollBarWitdh
      },
      actualHeaderHeight() {
        return this.hideHeader ? 0 : this.titleHeight
      },
      startTimeOfRenderArea() {
        if (this.heightOfBlocksWrapper === 0) {
          return
        }
        const { beginTimeOfTimeLine, scrollLeft, cellWidth, scale } = this

        return beginTimeOfTimeLine
          .add((scrollLeft / cellWidth) * scale, 'minute')
          .toDate()
          .getTime()
      },
      endTimeOfRenderArea() {
        if (this.heightOfBlocksWrapper === 0) {
          return
        }
        const {
          beginTimeOfTimeLine,
          scrollLeft,
          cellWidth,
          scale,
          widthOfBlocksWrapper,
          totalWidth
        } = this

        const renderWidth =
          totalWidth < widthOfBlocksWrapper ? totalWidth : widthOfBlocksWrapper

        return beginTimeOfTimeLine
          .add(((scrollLeft + renderWidth) / cellWidth) * scale, 'minute')
          .toDate()
          .getTime()
      }
    },
    watch: {
      scrollToTime: {
        handler(newV) {
          if (!newV) {
            return
          }
          const { start, end } = this
          console.log('ceshi=>', newV)
          const time = dayjs(newV)
          if (!(time.isAfter(start) && time.isBefore(end))) {
            warn(`当前滚动至${newV}不在${start}和${end}的范围之内`)
            return
          }
          const offset = this.getPositonOffset(newV)
          this.$nextTick(this.manualScroll(offset))
        },
        immediate: true
      },
      scrollToPostion: {
        handler(newV) {
          if (!newV) {
            return
          }
          const x = Number.parseFloat(newV.x)
          const y = Number.parseFloat(newV.y)
          if (!Number.isNaN(x) && x !== this.scrollLeft) {
            this.$nextTick(this.manualScroll(x))
          }
          if (!Number.isNaN(y) && y !== this.scrollTop) {
            this.$nextTick(this.manualScroll(undefined, y))
          }
        },
        immediate: true
      },
      datas(val) {
        this.$store.dispatch('user/currentGanttBlocksHeights', {})
      }
    },

    mounted() {
      this.cacheSelector()
      // 计算准确的渲染区域范围
      const observeContainer = throttle(entries => {
        entries.forEach(entry => {
          const cr = entry.contentRect
          this.heightOfBlocksWrapper = cr.height
          this.widthOfBlocksWrapper = cr.width
        })
      })
      const observer = new ResizeObserver(observeContainer)
      observer.observe(this.$refs.blocksWrapper)
      this.$once('hook:beforeDestroy', () => {
        observer.disconnect()
        this.releaseSelector()
      })
    },

    methods: {
      selectId(val) {
        this.selectIds = val
      },
      nodeClose(val){
        this.$emit('nodeClose',val)
      },
      nodeOpen(val){
        this.$emit('nodeOpen',val)
      },
      releaseNode(val){
        this.$emit('releaseNode',val)
      },
      viewLog(val){
        this.$emit('viewLog',val)
      },
      onUrge(val){
        this.$emit('onUrge',val)
      },
      adjustNode(val){
        this.$emit('adjustNode',val)
      },
      getWidthAbout2Times(start, end) {
        const options = {
          scale: this.scale,
          cellWidth: this.cellWidth
        }
        return _getWidthAbout2Times(start, end, options)
      },
      /**
       * 为时间线计算偏移
       */
      getPositonOffset(date) {
        const options = {
          scale: this.scale,
          cellWidth: this.cellWidth
        }

        return _getPositonOffset(date, this.beginTimeOfTimeLineToString, options)
      },
      // 缓存节点
      cacheSelector() {
        this.selector.gantt_leftbar = this.$refs.leftbarWrapper
        this.selector.gantt_table = this.$refs.blocksWrapper
        this.selector.gantt_scroll_y = this.$refs.scrollYBar
        this.selector.gantt_timeline = this.$refs.headerTimeline
        this.selector.gantt_scroll_x = this.$refs.scrollXBar
        this.selector.gantt_markArea = this.$refs.marklineArea
      },
      releaseSelector() {
        let key
        for (key in this.selector) {
          this.selector[key] = null
        }
      },

      totalHeights() {
        const { datas, cellHeight } = this
        console.log(this.currentGanttBlocksHeights)
        const defaultHeightData = datas.length > 0 ? datas.filter(item => !this.currentGanttBlocksHeights[item.id]) : 46
        let count = 0
        for (const i in this.currentGanttBlocksHeights) {
          count = count + parseInt(this.currentGanttBlocksHeights[i])
        }
        console.log('123高度', count)
        this.totalHeight = defaultHeightData.length * cellHeight + count + 20
        console.log('总高度', this.totalHeight)
      },
      wheelHandle(event) {
        this.totalHeights()
        const { deltaX, deltaY } = event
        const {
          scrollTop,
          scrollLeft,
          avialableScrollLeft,
          avialableScrollTop
        } = this

        if (deltaY !== 0) {
          if (
            scrollTop + deltaY >= avialableScrollTop &&
            scrollTop !== avialableScrollTop
          ) {
            this.manualScroll(undefined, avialableScrollTop)
          } else if (scrollTop + deltaY < 0 && scrollTop !== 0 /* 滚动为0限制*/) {
            this.manualScroll(undefined, 0)
          } else {
            this.manualScroll(undefined, scrollTop + deltaY)
          }
        }
        if (deltaX !== 0) {
          if (
            scrollLeft + deltaX >= avialableScrollLeft &&
            scrollLeft !== avialableScrollLeft
          ) {
            this.manualScroll(avialableScrollLeft)
          } else if (
            scrollLeft + deltaX < 0 &&
            scrollLeft !== 0 /* 滚动为0限制*/
          ) {
            this.manualScroll(0)
          } else {
            this.manualScroll(scrollLeft + deltaX)
          }
        }
      },
      manualScroll(x, y) {
        if (x != undefined) {
          this.selector.gantt_scroll_x.scrollLeft = x
        }
        if (y != undefined) {
          this.selector.gantt_scroll_y.scrollTop = y
        }
      },
      // 同步fixleft和block的滚动
      syncScrollY(event) {
        const { gantt_leftbar, gantt_table } = this.selector
        const topValue = event.target.scrollTop
        this.scrollTop = gantt_table.scrollTop = gantt_leftbar.scrollTop = topValue
        this.$emit('scrollTop', topValue)
      },
      syncScrollX(event) {
        const { gantt_table, gantt_timeline, gantt_markArea } = this.selector
        const leftValue = event.target.scrollLeft
        this.scrollLeft = gantt_timeline.scrollLeft = gantt_table.scrollLeft = leftValue
        gantt_markArea.style.left = -leftValue + 'px'
        this.$emit('scrollLeft', leftValue)
      }
    }
  }
</script>

<style lang="scss">
  $gray: #f0f0f0;
  $font-gray:#777;
  .gantt {
    &-chart {
      position: relative;
      overflow: hidden;
      height: 100%;
      width: 100%;
      outline: 1px solid $gray;
    }

    &-container{
      width: 100%;
      height: 100%;
    }

    &-header {
      display: flex;
      background-color: #fff;
      outline: 1px solid $gray;

      &-title {
        flex: none;
        width: 100%;
        background: #747e80;
        color: #fff;
        text-align: center;
      }

      &-timeline {
        overflow: hidden;
      }
    }

    &-body {
      position: relative;
    }

    &-timeline {
      position: relative;
      text-align: center;
      display: flex;

      &-day {
        overflow: hidden;
        font-weight: bold;
        color: $font-gray;
      }

      &-scale {
        display: flex;

        &>div {
          height: 100%;
          font-size: 0.8rem;
          font-weight: bold;
          color: $font-gray;
        }
      }

      // 隐藏第一个时间节点，不然会只显示一半，不好看
      &-block:first-child &-scale div:first-child {
        visibility: hidden;
      }
    }

    &-leftbar {
      width: 100%;
      height: 100%;
      background: #fff;
      color: $font-gray;
      font-size: 0.8rem;

      &-wrapper {
        flex: none;
        position: relative;
        overflow: hidden;
        background: #fff;
        outline: 1px solid $gray;
        z-index: 100;
      }

      &-item {}

      &-defalutItem {
        width: 100%;
        height: 100%;
        outline: 1px solid $gray
      }
    }

    &-table {
      display: flex;
      width: 100%;
      height: 100%;
    }

    &-markline-area {
      position: absolute;
      z-index: 99;
    }

    &-markline {
      position: absolute;
      z-index: 100;
      width: 2px;
      height: 100vh;

      &-label {
        padding: 3px;
        float: left;
        color: #fff;
        font-size: 0.7rem;
      }
    }

    &-blocks {
      &-wrapper {
        overflow: hidden;
      }
    }

    &-block {
      position: relative;
      background-image: linear-gradient(rgba(236, 236, 236) 1px, transparent 0),
      linear-gradient(90deg, rgba(236, 236, 236) 1px, transparent 0);

      &-container {
        position: relative;
        height: 100%;
      }

      &-item {
        position: absolute;
        height: 100%;
      }

      &-defaultBlock {
        width: 100%;
        height: 100%;
        outline: 1px solid $gray;
        background: $gray;
      }
    }

    &-scroll-y {
      overflow-y: scroll;
      position: absolute;
      z-index: 1000;
      top: 0;
      right: 0;
      height: 100%;
      width: 17px;

      &>div {
        width: 17px;
      }
    }

    &-scroll-x {
      overflow-x: scroll;
      position: absolute;
      z-index: 1000;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 17px;

      &>div {
        height: 17px;
      }
    }
  }
</style>
