import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/warehouse/private/containerClass/'
const baseUrl1 = 'api/web/v1/warehouse/private/containerClassMaterialsMapping/'

export default {
  // 获取仓包装容器不分页
  getContainerClassList(data) {
    return request.get(baseUrl + 'getContainerClassList', data)
  },
  // 获取包装容器-分页
  getContainerClassListPage(data) {
    return request.get(baseUrl + 'getContainerClassListPage', data)
  },
  // 新增包装容器
  addContainerClass(data) {
    return request.post(baseUrl + 'addContainerClass', data)
  },
  // 新增包装容器
  copyContainerClass(data) {
    return request.post(baseUrl + 'addContainerClass', data)
  },
  // 获取包装容器详情
  getContainerClassDeatil(data) {
    return request.get(baseUrl + 'getContainerClassDetail', data)
  },
  // 修改包装容器
  modifyContainerClass(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除包装容器
  delContainerClass(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  getContainerClassListByMaterialsIds(ids) {
    return request.get(baseUrl + 'getContainerListByMaterialsList', { materialsIdList: ids })
  },
  createCode(params) {
    return request.get(baseUrl + 'getMaxContainerClassCode', params)
  },
  // 批量新增包装容器详情配置
  addContainerClassMaterialsMappingBatch(data) {
    return request.post(baseUrl1 + 'insertBatch', data)
  },
  // 新增包装容器详情配置
  addContainerClassMaterialsMapping(data) {
    return request.post(baseUrl1 + 'addContainerClassMaterialsMapping', data)
  },
  modifyContainerClassMaterialsMapping(data) {
    return request.put(baseUrl1 + 'updateOne', data)
  },
  // 删除包装容器详情配置
  delContainerClassMaterialsMapping(data) {
    return request.del(baseUrl1 + 'deleteOne', data)
  }
}
