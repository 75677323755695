<template>
  <MDialog v-model="modalShow" width="85%" title="选择设备" :append-to-body="true" @onOk="submitForm" @fullScreen="fullScreenChange">
    <Split :offset.sync="split.offset" :height="splitHeight">
      <template slot="left">
        <EquipmentClass :view="true" @nodeClick="setEquipmentClassId" />
      </template>
      <template slot="right">
        <div class="top">
          <SearchForm ref="searchForm" :form-list-extend="searchFormList" :form-data="searchFormData" @search="searchTable" />
        </div>
        <div class="bottom">
          <FunctionHeader
            v-model="functionHeader.searchVal"
            search-title="请输入设备编码/名称"
            :tags="functionHeader.tags"
            :export-name="functionHeader.exportName"
            :import-name="functionHeader.importName"
            @search="$refs.mTable.setTableData(true)"
          />
          <MTable
            ref="mTable"
            :highlight-current-row="single"
            :columns="columns"
            :height="height"
            :set-data-method="getTableData"
            :columns-setting="false"
            @selection-change="selectionChange"
            @current-change="selectionChange"
            @row-click="handleRowClick"
          >
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="equipmentClassType" slot-scope="{ row }" type="text">{{ row.equipmentClassType | equipmentClassTypeName }}</div>
            <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
              <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
              <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
            </div>
            <div slot="drawing" slot-scope="{ row }">
              <el-image
                v-if="row.drawing"
                :src="row.drawing"
                class="drawing-img"
                :preview-src-list="fixImg(row.drawing)"
              />
            </div>
            <div slot="status" slot-scope="{ row }" class="is-enabled">
              <div :style="{background: statusColor[row.status] }" />
              <div>{{ row.status | statusName }}</div>
            </div>
          </MTable>
        </div>
      </template>
    </Split>
  </MDialog>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import { columns } from './columns'
import { getUUid } from '@/utils'
import equipmentClassApi from '@/api/information/equipment/equipment-class'
import api from '@/api/equipment/equipment'
import userApi from '@/api/sets/user/user'
import shiftApi from '@/api/sets/shift'
import { Encrypt } from '@/utils/sercet'
import conventionMixin from '@/mixin/conventional-page'
import dayjs from 'dayjs'
import { equipmentType, equipmentStatus } from '@/config/options.config'
import Split from '@/components/Split/Split'
import EquipmentClass from '@/components/EquipmentClass/EquipmentClass'
import stationApi from '@/api/information/production/station'

export default {
  name: 'SelectSetting',
  components: { EquipmentClass, Split, SearchForm, FunctionHeader },
  filters: {
    statusName(id) {
      const omp = equipmentStatus.find(item => item.id === id)
      if(omp){
        return omp.name
      }else {
        return '-'
      }
      // return equipmentStatus.find(item => item.id === id).name
    },
    equipmentClassTypeName(id) {
      return equipmentType.find(item => item.id === id).name
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    isProducible: {
      type: Boolean,
      default: false
    },
    conditions: {
      type: Array,
      default: () => ([])
    },
    selectMaterials: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      fullScreen: false,
      modalShow: false,
      split: {
        offset: 20
      },
      columns,
      functionHeader: {
        searchVal: '',
        tags: []
      },
      searchFormList: [
        {
          colSpan: 5,
          key: 'stationId',
          tagName: 'el-select',
          props: {
            placeholder: '所属工位'
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: []
            }
          ]
        },
        {
          colSpan: 5,
          key: 'status',
          tagName: 'el-select',
          props: {
            placeholder: '设备使用状态'
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: equipmentStatus
            }
          ]
        }
      ],
      searchFormData: {},
      searchFormatData: [],
      formOptions: {
        materialsClassList: []
      },
      selectionData: [],
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      equipmentClassId: '',
      statusColor: ['#FFCC78', '#24CAD7', '#E02020']
    }
  },
  computed: {
    height() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 83 - 57 : this.$store.state.app.contentHeight - 289 - 160 - 10
    },
    splitHeight() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 : this.$store.state.app.contentHeight - 289 - 45
    }
  },
  watch: {
    visible(val) {
      this.modalShow = val
      if (this.single) this.columns = sColumns
      if (val) {
        this.$nextTick(() => {
          // 数据回显
          this.equipmentClassId = ''
          this.searchFormatData = []
          // console.log('回显=》', this.selectMaterials)
          this.selectionData = this._.cloneDeep(this.selectMaterials)
          if (this.single) {
            this.$refs.mTable.tableRefs().setCurrentRow(this.selectionData[0])
          } else {
            this.selectionData.forEach(item => {
              this.$refs.mTable.tableRefs().toggleRowSelection(item, true)
            })
          }
        })
      }
    },
    modalShow(val) {
      if (!val) {
        this.$emit('update:visible', false)
      }
    }
  },
  mounted() {
    this.modalShow = this.visible
    this.getStationList()
    if (this.single) this.columns = sColumns
  },
  methods: {
    // 选中行复选框也会选中
    handleRowClick(row) {
      this.$refs.mTable.tableRefs().toggleRowSelection(row)
    },
    fullScreenChange(val) {
      this.fullScreen = val
    },
    async getStationList() {
      const res = await stationApi.getStationList()
      if (res) {
        this.searchFormList[0].children[0].options = res
      }
    },
    fixImg(img) {
      return [img]
    },
    selectionChange(data) {
      if (Array.isArray(data)) {
        this.selectionData = data
      } else {
        this.selectionData = []
        this.selectionData.push(data)
      }
    },
    setEquipmentClassId(id) {
      this.equipmentClassId = id
      // this.selectionData = []
      this.$refs.mTable.setTableData(true)
    },
    // 获取设备类型
    setMaterialsClass(res) {
      this.formOptions.materialsClassList = res
    },
    // 查询设备
    searchTable(data) {
      this.searchFormatData = data
      this.$refs.mTable.setTableData(true)
    },
    // 获取设备数据
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      // console.log('搜索', search, this.searchFormatData)
      const res = await api.getEquipmentListPage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchKeyword: this.functionHeader.searchVal,
        equipmentClassId: this.equipmentClassId,
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchFormatData]))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    submitForm(callback) {
      callback()
      console.log('shebei=>', this.selectionData)
      if (this.selectionData.length === 0) {
        this.$message.info('请至少选择一条设备')
        return
      }
      this.$emit('submitForm', this.selectionData)
    }
  }
}
</script>

<style lang="scss" scoped>
.function-header-wrap {
  padding-top: 0;
}
.materials-head{
  padding:0 0 10px 0;
}
.bottom {
  padding: 10px;
}
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
</style>
