import requestForAsync from '@/utils/requestForAsync'
import { getUrl } from '@/utils/index'

const baseUrl = 'api/web/v1/production/private/personalWorkbench/'

export default {
  // 当前相关相关数量、相关项目开工情况、个人完成任务情况
  getProjectCount() {
    return requestForAsync.get(baseUrl + 'getProjectCount')
  },
  // 任务执行数量统计
  getTaskExecutionCount(params) {
    return requestForAsync.get(baseUrl + `getTaskExecutionCount${getUrl(params)}`)
  },
  // 今天待处理任务
  getTodayWaitingTaskList(){
    return requestForAsync.get(baseUrl + 'getTodayWaitingTaskList')
  },
  // 本周新增任务
  getThisWeekAddTaskList(){
    return requestForAsync.get(baseUrl + 'getThisWeekAddTaskList')
  },
  // 全部待处理任务
  getAllWaitingTaskList(){
    return requestForAsync.get(baseUrl + 'getAllWaitingTaskList')
  },
}
