<template>
  <MDialog v-model="modalShow" width="90%" title="选择计划" class="total-model" @onOk="submitForm" @cancel="cancel()" @fullScreen="fullScreenChange">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button label="NoSchedule">未排程</el-radio-button>
      <el-radio-button label="HaveSchedule">已排程</el-radio-button>
    </el-radio-group>
    <div class="top" style="padding-top: 0">
      <SearchForms
        ref="searchForms"
        :form-option="formOptions"
        style="padding-top: 10px;"
        @resetData="resetData"
        @searchData="searchTable"
      />
    </div>
    <el-divider class="e-divider" />
    <div>
      <FunctionHeader
        v-model="functionHeader.searchVal"
        :radio="params.radio"
        search-title="请输入生产计划号/订单号"
        style="padding:0 0 10px 0"
        @search="$refs.mTables.setTableData(true)"
      />
      <MTable
        ref="mTables"
        class="mTable"
        :columns="columns"
        :columns-key="columnsKey"
        :highlight-current-row="single"
        :set-data-method="getTableData"
        :height="height"
        @selection-change="selectionChange"
        @current-change="selectionChange"
        @row-click="handleRowClick"
      >
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="source" slot-scope="{ row }">
          <div>{{ planSource[row.source].name }}</div>
        </div>
        <div slot="priority" slot-scope="{ row }">
          <div
            :style="{ color: priorityStatus[row.priority].color ? priorityStatus[row.priority].color : 'rgb(74, 79, 118)', background: `${priorityStatus[row.priority].color ? priorityStatus[row.priority].color : 'rgb(74, 79, 118)'}20`}"
            class="tag-priority"
          >
            {{ priorityStatus[row.priority].name ? priorityStatus[row.priority].name : '普通' }}
          </div>
        </div>
        <div slot="status" slot-scope="{ row }">
          <div>{{ planStatus[row.status].name }}</div>
        </div>
        <div slot="type" slot-scope="{ row }">
          <div>{{ productionType[row.type].name }}</div>
        </div>
        <div slot="isRepair" slot-scope="{ row }">
          <div>{{ row.isRepair === 0 ? '普通' : '返修' }}</div>
        </div>
        <div slot="producePlanNumber" slot-scope="{ row }">
          <img v-if="row.isRepair === 1" src="@/assets/plans/repair-icon-have.png" class="icon-img">
          <span>{{ row.producePlanNumber }}</span>
        </div>
        <div slot="produceStatus" slot-scope="{ row }">
          <div :style="{color: row.produceStatus === 0 ? '#FA6400' : row.produceStatus === 1 ? '#00AB29' : '#607fff'}"> {{ productionStatus[row.produceStatus].name }}</div>
        </div>
      </MTable>
      <div style="height: 15px;" />
    </div>
  </MDialog>
</template>

<script>
import SearchForms from './search-forms'
import FunctionHeader from '@/components/FunctionHeader/index'
import { columns, sColumns } from './columns'
import api from '@/api/production/plans'
import processApi from '@/api/information/production/process'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import { planSource, noPlanStatus, havePlanStatus, planStatus, productionType, productionStatus, procedureClass } from '@/config/options.config'

export default {
  name: 'SelectPlanOrder',
  components: { SearchForms, FunctionHeader },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    selectIds: {
      type: Array,
      default: () => ([])
    },
    produceOrderId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      planSource,
      planStatus,
      noPlanStatus,
      havePlanStatus,
      productionType,
      productionStatus,
      procedureClass,
      modalShow: false,
      columns,
      keyword: '',
      searchDatas: [
        {
          id: getUUid(),
          fieldName: 'status',
          fieldType: 'number',
          fieldValue: '2^3',
          operator: 'in'
        }
      ],
      selectionData: [],
      currentType: 1,
      params: {
        radio: 'HaveSchedule',
        processFlowName: '',
        processFlowId: undefined,
        processFlowVersionId: undefined
      },
      functionHeader: {
        searchVal: '',
        exportName: 'exportPlan',
        searchData: {}
      },
      selectKeyword: '1',
      formOptions: {
        orderOptions: [],
        operatorOptions: [],
        flowerOptions: [],
        areaOptions: []
      },
      selectionData: [],
      formOptions: {
        flowerOptions: [],
        areaOptions: []
      },
      flag: 'HaveSchedule',
      isHave: false,
      isShow: true,
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      fullScreen: false
    }
  },
  computed: {
    height() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 83 - 77 : this.$store.state.app.contentHeight - 289 - 160 - 50
    },
    columnsKey() {
      return this.$route.name + this.params.radio
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    }
  },
  watch: {
    visible(val) {
      this.modalShow = val
      if (this.single) this.columns = sColumns
      this.selectionData = []
      // if (val) {
      //   this.$nextTick(() => {
      //     // 数据回显
      //     this.selectionData = this._.cloneDeep(this.selectIds)
      //     this.selectionData.forEach(item => {
      //       this.$refs.mTables.tableRefs().toggleRowSelection(item, true)
      //     })
      //   })
      // }
    },
    modalShow(val) {
      if (!val) {
        this.searchFormData = {}
        this.searchFormatData = []
        this.functionHeader.searchVal = ''
        this.$emit('update:visible', false)
      }
    }
  },
  async mounted() {
    this.modalShow = this.visible
    if (this.single) this.columns = sColumns
    await this.processFlowList()
    this.getAreaList()
  },
  methods: {
    fullScreenChange(val) {
      this.fullScreen = val
    },
    handleRowClick(row) {
      this.$refs.mTables.tableRefs().toggleRowSelection(row)
    },
    // 获取产线
    async getAreaList() {
      const search = []
      search.push({
        id: getUUid(),
        fieldName: 'type',
        fieldType: 'number',
        fieldValue: 2,
        operator: 'eq'
      })
      search.push({
        id: getUUid(),
        fieldName: 'isEnabled',
        fieldType: 'number',
        fieldValue: 1,
        operator: 'eq'
      })
      const res = await processApi.getAreaList({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.formOptions.areaOptions = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.areaList = res
        }
      }
    },
    // 选择工艺
    async processFlowList() {
      const res = await api.getFlowers({
        isGetAreaList: 1,
        isGetVersionList: 1
      })
      if (res) {
        this.formOptions.flowerOptions = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.processFlowList = res
        }
      }
    },
    selectionChange(data) {
      if (Array.isArray(data)) {
        console.log('000')
        this.selectionData = data
      } else {
        this.selectionData = []
        this.selectionData.push(data)
        console.log('111')
      }
    },
    radioChange(label) {
      this.isHave = label === 'NoSchedule'
      if (label === 'NoSchedule') {
        this.functionHeader.searchData = {
          searchVal: Encrypt(JSON.stringify([{
            id: getUUid(),
            fieldName: 'isPlan',
            fieldType: 'number',
            fieldValue: 0,
            operator: 'eq'
          }
          ])
          )
        }
        this.searchDatas = []
      }
      if (label === 'HaveSchedule') {
        this.functionHeader.searchData = {
          searchVal: Encrypt(JSON.stringify([{
            id: getUUid(),
            fieldName: 'isPlan',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }
          ])
          )
        }
        this.searchDatas = []
      }
      this.flag = label
      this.keyword = ''
      this.selectKeyword = ''
      this.isShow = true
      this.$refs.mTables.getCheckedColumns()
      this.$refs.mTables.setTableData(true)
    },
    // 查询
    searchTable(data, val, keyword) {
      this.searchDatas = data
      this.keyword = keyword
      this.selectKeyword = val
      console.log(data)
      if (data.length > 0) {
        const datas = data.filter(item => item.fieldName === 'status')
        this.isShow = datas.length > 0 ? !datas[0].fieldValue : true
      }
      this.$refs.mTables.setTableData(true)
    },
    // 重置
    resetData() {
      this.searchDatas = []
      this.selectKeyword = ''
      this.keyword = ''
      this.$refs.mTables.setTableData(true)
    },
    // 列表
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      if (this.isShow) {
        search.push({
          id: getUUid(),
          fieldName: 'isPlan',
          fieldType: 'number',
          fieldValue: this.flag === 'NoSchedule' ? 0 : 1,
          operator: 'eq'
        })
      }
      const res = await api.getPlanpage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchKeyword: this.functionHeader.searchVal,
        materialsSearchType: this.selectKeyword,
        materialsSearchValue: this.keyword,
        produceOrderId: this.produceOrderId,
        relationType: 1,
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchDatas]))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    submitForm(callback) {
      callback()
      console.log(this.selectionData)
      if (this.selectionData.length === 0) {
        this.$message.info('请至少选择一条计划')
        return
      }
      console.log('8989=>', this.selectionData)
      this.$emit('submitForm', this.selectionData)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .mubai-modal-wrap .el-dialog__body {
  padding: 10px 0 10px 10px!important;
}
.icon-img {
  position: absolute;
  right: 0;
  top: 0;
  width: 19px;
  height: 19px;
  display: block;
}
.materials-info {
  padding: 10px;
}
.materials-head{
  padding:0 0 20px 0;
}
.functionHeaders {
  padding-top: 0;
}
.total-model {
  ::v-deep .el-divider--horizontal {
    margin: 5px 0 10px 0;
  }
}

</style>
