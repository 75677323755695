var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nodeElement", on: { click: _vm.nodeClick } },
    [
      _c("div", { class: [{ isselected: _vm.selected }, "cardBox"] }, [
        _c("div", { staticClass: "produces-li" }, [
          _c("div", { staticClass: "li-tit" }, [
            _c("span", [_vm._v(_vm._s(_vm.item.procedureName))]),
            _vm.item.type == 0 && _vm.item.procedureType == 0
              ? _c("img", {
                  staticStyle: {
                    width: "24px",
                    height: "24px",
                    margin: "-5px 0 0 10px"
                  },
                  attrs: { src: require("@/assets/plans/in.png") }
                })
              : _vm._e(),
            _vm.item.type == 1 && _vm.item.procedureType == 1
              ? _c("img", {
                  staticStyle: {
                    width: "24px",
                    height: "24px",
                    margin: "-5px 0 0 10px"
                  },
                  attrs: { src: require("@/assets/plans/out.png") }
                })
              : _vm._e(),
            _vm.item.type === 1 && _vm.item.procedureType === 0
              ? _c("img", {
                  staticStyle: {
                    width: "40px",
                    height: "22px",
                    "margin-left": "10px"
                  },
                  attrs: { src: require("@/assets/plans/wai.png") }
                })
              : _vm._e()
          ]),
          _c("div", { staticClass: "li-text" }, [
            _c("div", { staticClass: "tit-top" }, [
              _c("span", [_vm._v("报：")]),
              _c(
                "div",
                { staticClass: "tit-cen" },
                [
                  !isNaN(
                    parseInt(
                      _vm.fixData(
                        parseFloat(_vm.item.reportWorkQuantity) +
                          parseFloat(_vm.item.inWarehouseQuantity),
                        _vm.item.sourceQuantity
                      )
                    )
                  )
                    ? _c("el-progress", {
                        ref: "progress",
                        staticStyle: { width: "110px" },
                        attrs: {
                          percentage: _vm.fixData(
                            parseFloat(_vm.item.reportWorkQuantity) +
                              parseFloat(_vm.item.inWarehouseQuantity),
                            _vm.item.sourceQuantity
                          ),
                          "show-text": false,
                          color:
                            _vm.fixData(
                              _vm.item.reportWorkQuantity,
                              _vm.item.sourceQuantity
                            ) < 100
                              ? "#607FFF"
                              : "#00AB29"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("div", { staticClass: "tit-ri" }, [
                _vm._v(
                  _vm._s(
                    _vm.item.reportWorkQuantity
                      ? parseFloat(_vm.item.reportWorkQuantity) +
                          parseFloat(_vm.item.inWarehouseQuantity)
                      : 0
                  ) +
                    "/" +
                    _vm._s(_vm.item.sourceQuantity)
                )
              ])
            ]),
            _c("div", { staticClass: "tit-top" }, [
              _c("span", [_vm._v("检：")]),
              _c(
                "div",
                { staticClass: "tit-cen" },
                [
                  !isNaN(
                    parseInt(
                      _vm.fixData(_vm.item.okQuantity, _vm.item.checkQuantity)
                    )
                  )
                    ? _c("el-progress", {
                        ref: "progress1",
                        staticStyle: { width: "110px" },
                        attrs: {
                          percentage: _vm.fixData(
                            _vm.item.okQuantity,
                            _vm.item.checkQuantity
                          ),
                          "show-text": false,
                          color:
                            _vm.fixData(
                              _vm.item.okQuantity,
                              _vm.item.checkQuantity
                            ) < 100
                              ? "#607FFF"
                              : "#00AB29"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("div", { staticClass: "tit-ri" }, [
                _vm._v(
                  _vm._s(_vm.item.okQuantity ? _vm.item.okQuantity : 0) +
                    "/" +
                    _vm._s(_vm.item.checkQuantity ? _vm.item.checkQuantity : 0)
                )
              ])
            ]),
            _c("div", { staticClass: "tit-top" }, [
              _c("span", [_vm._v("返：")]),
              _c(
                "div",
                { staticClass: "tit-cen" },
                [
                  !isNaN(
                    parseInt(
                      _vm.fixData(
                        _vm.item.repairReportQuantity,
                        _vm.item.repairQuantity
                      )
                    )
                  )
                    ? _c("el-progress", {
                        ref: "progress2",
                        staticStyle: { width: "110px" },
                        attrs: {
                          percentage: _vm.fixData(
                            _vm.item.repairReportQuantity,
                            _vm.item.repairQuantity
                          ),
                          "show-text": false,
                          color:
                            _vm.fixData(
                              _vm.item.repairReportQuantity,
                              _vm.item.repairQuantity
                            ) < 100
                              ? "#607FFF"
                              : "#00AB29"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("div", { staticClass: "tit-ri" }, [
                _vm._v(
                  _vm._s(
                    _vm.item.repairReportQuantity
                      ? _vm.item.repairReportQuantity
                      : 0
                  ) +
                    "/" +
                    _vm._s(
                      _vm.item.repairQuantity ? _vm.item.repairQuantity : 0
                    )
                )
              ])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }