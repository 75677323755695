import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/invoices/private/facilityMaintenancePlanFacilityMapping/'

export default {
  // 新增保养设备
  addPlanMaintenanceFacility(data) {
    return request.post(baseUrl + 'addFacilityMaintenancePlanFacilityMapping', data)
  },
  // 修改保养设备
  modifyPlanMaintenanceFacility(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除保养设备
  delPlanMaintenanceFacility(data) {
    return request.post(baseUrl + 'deleteOne', data)
  }
}
