var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flow-wrapper" }, [
    _c(
      "div",
      [
        _c("FunctionHeader", {
          ref: "functionHeader",
          attrs: {
            tags: _vm.functionHeader.tags,
            "search-title": "请输入定位码/检验项目",
            "import-name":
              _vm.handleType === "view" || _vm.handleType === "add"
                ? ""
                : _vm.functionHeader.importName,
            processId: _vm.processFlowId,
            processVersionId: _vm.processFlowVersion.id,
            "export-name": _vm.functionHeader.exportName,
            "export-params": _vm.functionHeader.exportParams,
            "columns-setting": false
          },
          on: {
            search: function($event) {
              return _vm.searchTable(_vm.functionHeader.searchVal)
            }
          },
          model: {
            value: _vm.functionHeader.searchVal,
            callback: function($$v) {
              _vm.$set(_vm.functionHeader, "searchVal", $$v)
            },
            expression: "functionHeader.searchVal"
          }
        }),
        _c(
          "div",
          { staticClass: "m-table" },
          [
            _c(
              "el-table",
              { attrs: { data: _vm.BOMList, "show-header": true } },
              [
                _c("el-table-column", {
                  attrs: { prop: "index", label: "序号", width: "80" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { prop: "serialsCode", label: "工序定位码" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "procedureName", label: "工序名称" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "functionCode", label: "功能码" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "inspectionEntryName", label: "检验项目" }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "inspectionStandard",
                    label: "检验标准",
                    width: "200"
                  }
                }),
                _c("el-table-column", {
                  attrs: { prop: "inspectionMethod", label: "检测方法" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "parameterType", label: "检测值类型" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("parameterTypeName")(
                                  scope.row.parameterType
                                )
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { prop: "defaultValue", label: "默认值" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "oossiblevalue", label: "可能值" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "standardalue:", label: "标准值" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "upperLimit", label: "上偏差" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "lowerLimit", label: "下偏差" }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }