import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/warehouse/kiki/private/batchNumber/'

export default {
  // 获取批次码-不分页
  getBatchNumberList(data) {
    return request.get(baseUrl + 'getBatchNumberList', data)
  },
  // 获取批次码-分页
  getBatchNumberListPage(data) {
    return request.get(baseUrl + 'getBatchNumberListPage', data)
  },
  // 新增批次码
  addBatchNumber(data) {
    return request.post(baseUrl + 'addBatchNumber', data)
  },
  // 修改批次码
  modifyBatchNumber(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 复制批次码
  copyBatchNumber(data) {
    return request.post(baseUrl + 'addBatchNumber', data)
  },
  // 删除批次码
  delBatchNumber(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  batchAddBatchNumber(data) {
    return request.post(baseUrl + 'addBatchNumberBatch', data)
  },
  // 获取产品批次码详情（通过批次码查询）
  deatilBatchNumber(data) {
    return request.get(baseUrl + 'getBatchNumberDetailByBatchNumber', data)
  },
   // 获取批次码
   addBatchNumberListBatch(data) {
    return request.post(baseUrl + 'addBatchNumberListBatch', data)
  }
}
