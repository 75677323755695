import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/systemConfig/private/wordTemplate/'

export default {
  // 查询所有下拉列表
  getAllWordTemplateType(data){
    return request.get(baseUrl + 'getAllWordTemplateType', data)
  },
  // 打印模板列表
  getPrintingModuleListPage(data) {
    return request.get(baseUrl + 'getWordTemplateListPage', data)
  },
  // 新增打印模板
  addPrintingModule(data) {
    return request.post(baseUrl + 'addWordTemplate', data)
  },
  // 修改打印模板
  modifyPrintingModule(data) {
    return request.put(baseUrl + 'updateWordTemplate', data)
  },
  // 删除打印模板
  delPrintingModule(data) {
    return request.del(baseUrl + 'deleteWordTemplateById', data)
  },
  // 打印下载
  commomDownload(data){
    return request.exportPost('api/web/v1/common/private/word/download', data)
  }
}