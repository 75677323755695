<template>
  <div class="scale-box" :style="{width: props.width + 'px', height: props.height + 'px', transform: 'scale(' + scale +') translate(-50%, -50%)', WebkitTransform: 'scale(' + scale +') translate(-50%, -50%)'}">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BigScreenContainer',
  data() {
    return {
      props: { width: 1920, height: 1080 },
      scale: 1
    }
  },
  mounted() {
    this.scale = this.getScale()
    window.addEventListener('resize', this.debounce(() => {
      this.scale = this.getScale()
    }, 500))
  },
  methods: {
    getScale() {
      const { width = 1920, height = 1080 } = this.props
      const ww = window.innerWidth / width
      const wh = window.innerHeight / height
      return ww < wh ? ww : wh
    },
    debounce(fn, delay) {
      let ctx
      let args
      let timer = null
      const later = () => {
        fn.apply(ctx, args)
        timer = null
      }
      return () => {
        ctx = this
        args = arguments
        if (timer) {
          clearTimeout(timer)
          timer = null
        }
        timer = setTimeout(later, delay)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.scale-box{
  transform-origin: 0 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: 0.3s;
  background: #061235;
}

</style>
