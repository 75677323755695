var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._g(
      _vm._b(
        {
          directives: [
            {
              name: "load-more",
              rawName: "v-load-more",
              value: _vm.load,
              expression: "load"
            }
          ],
          attrs: {
            filterable: "",
            remote: "",
            "reserve-keyword": "",
            clearable: _vm.$attrs.clearable && true,
            placeholder: _vm.placeholder,
            "remote-method": _vm.remoteMethod,
            loading: _vm.loading
          },
          on: { change: _vm.changeValue, focus: _vm.focusEvent },
          model: {
            value: _vm.currentValue,
            callback: function($$v) {
              _vm.currentValue = $$v
            },
            expression: "currentValue"
          }
        },
        "el-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    _vm._l(_vm.list, function(item) {
      return _c("el-option", {
        key: item[_vm.valueKey || _vm.valueName],
        attrs: { label: item[_vm.labelName], value: item[_vm.valueName] }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }