<template>
  <div class="materials-class">
    <div class="top">
      <div>备件类</div>
      <div v-if="!view" class="add-materials-class" @click="add()"><i class="el-icon-circle-plus" /></div>
    </div>
    <div class="tree">
      <Tree ref="zTrees" :view="view" :nodes="nodes" @command="command" @nodeClick="nodeClick" />
    </div>
    <MDialog v-model="visible" :title="currentType" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" />
    </MDialog>
  </div>
</template>

<script>
import Tree from '@/components/Tree/Tree'
import api from '@/api/equipment/part-class'
import { equipmentType, status } from '@/config/options.config'

export default {
  name: 'PartClass',
  components: { Tree },
  props: {
    view: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      equipmentType,
      status,
      nodes: [{
        id: -1,
        parentId: 0,
        name: '全部',
        expand: true
      }],
      currentClicked: '',
      visible: false,
      currentType: 'add',
      formData: {},
      formList: [
        {
          label: '备件类名称',
          key: 'name',
          tagName: 'el-input',
          required: true
        },
        {
          label: '上级分类',
          key: 'parentId',
          tagName: 'el-select',
          required: false,
          props: {
            clearable: true
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              }
            }
          ]
        },
        {
          label: '备注',
          key: 'remark',
          tagName: 'el-input',
          props: {
            type: 'textarea'
          }
        }
      ],
      equipmentClassList: [{
        id: -1,
        name: ''
      }]
    }
  },
  mounted() {
    this.getSparePartClassList()
  },
  methods: {
    async getSparePartClassList() {
      const res = await api.getSparePartClassList()
      if (res) {
        this.nodes = [{
          id: -1,
          parentId: 0,
          name: '全部',
          expand: true
        }]
        this.equipmentClassList = [{
          id: -1,
          name: ' '
        }]
        this.nodes = [...this.nodes, ...res]
        this.equipmentClassList = [...this.equipmentClassList, ...res]
        this.$emit('setEquipmentClassList', res)
      }
    },
    command(name, node) {
      this[name](node)
    },
    add() {
      this.formData = { parentId: this.currentClicked }
      this.formList[1].children[0].options = this.equipmentClassList
      this.currentType = '新增备件类'
      this.visible = true
    },
    modify(node) {
      console.log('设置=》', node)
      this.formData = this.createResetData(node)
      this.formList[1].children[0].options = this.equipmentClassList
        .filter(item => item.parentId !== node.id && node.id !== item.id)
      this.currentType = '修改备件类'
      this.visible = true
    },
    del(node) {
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delSparePartClass({ id: node.id })
        if (res) {
          this.$message.success('删除成功')
          await this.getSparePartClassList()
          this.$emit('refrash')
        }
      })
    },
    nodeClick(treeId, node) {
      if (this.currentClicked === node.id || node.id === -1) this.currentClicked = ''
      else this.currentClicked = node.id
      this.$emit('nodeClick', this.currentClicked)
    },
    submitForm(callback) {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const type = this.currentType === '修改备件类' ? 'modify' : 'add'
          const res = await api[`${type}SparePartClass`](
            this.currentType === '修改备件类' ? this.createResetData(form) : this.createSetData(form)
          )
          if (res) {
            this.$message.success(type === 'add' ? '新增成功' : '修改成功')
            this.visible = false
            await this.getSparePartClassList()
            if (type === 'modify') {
              this.$emit('refrash')
            }
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    createSetData(raw) {
      return {
        name: raw.name,
        parentId: raw.parentId || -1,
        remark: raw.remark
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.materials-class {
  width: 100%;
  height: 100%;
  background: #F8F8F8;
  overflow: auto;
  .top {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: #DDE3EB;
    }
    .add-materials-class {
      i {
        color: rgb(96, 127, 255);
        cursor: pointer;
        font-size: 20px;
        position: relative;
        top: 1px;
      }
    }
  }
  .tree {
    height: calc(100% - 41px);
    padding: 10px 0 0 10px;
    overflow: auto;
  }
}
</style>
