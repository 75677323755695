import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/warehouse/kiki/private/outInWarehouseBillRecord/'

export default {
  // 获取库存总表-不分页
  getOutInWarehouseBillRecordList(data) {
    return request.get(baseUrl + 'getOutInWarehouseBillRecordList', data)
  },
  // 获取库存总表-分页
  getOutInWarehouseBillRecordListPage(data) {
    return request.get(baseUrl + 'getOutInWarehouseBillRecordListPage', data)
  }
}
