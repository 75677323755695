import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/siteWarehouse/kiki/private/sitWarehouse/'

export default {
  // 获取线边仓-不分页
  getSiteWarehouseList(data) {
    return request.get(baseUrl + 'getSitWarehouseList', data)
  },
  // 获取线边仓-分页
  getSiteWarehouseListPage(data) {
    return request.get(baseUrl + 'getSitWarehouseListPage', data)
  },
  // 新增线边仓
  addSiteWarehouse(data) {
    return request.post(baseUrl + 'addSitWarehouse', data)
  },
  // 修改线边仓
  modifySiteWarehouse(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 复制线边仓
  copySiteWarehouse(data) {
    return request.post(baseUrl + 'addSitWarehouse', data)
  },
  // 删除线边仓
  delSiteWarehouse(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  }
}
