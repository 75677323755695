import request from '@/utils/requestForAsync'
const baseUrl = 'api/app/v1/abnormal/private/abnormalOrder/'
import { getUrl } from '@/utils/index'
export default {
  // 获取我的异常列表
  getSelfAbnormalityListPage(params) {
    // return request.get(baseUrl + 'list', params)
    return request.get('api/web/v1/abnormal/private/abnormalOrder/list2', params)
  },
    // 异常类型下拉列表
    getPullDownList (params) {
      return request.get(`api/web/v1/notification/private/callType/getPullDownList${getUrl(params)}`)
    },
    // 责任方下拉列表
    getDutyPowerList (params) {
      return request.get(`api/app/v1/abnormal/private/abnormalOrder/getDutyPowerList${getUrl(params)}`)
    },
    // 异常呼叫创建
    createCall (params) {
      return request.post(`api/app/v1/abnormal/private/abnormalOrder/create`, params)
    },
    // 异常呼叫修改
    updateCall (params) {
      return request.post(`api/app/v1/abnormal/private/abnormalOrder/update`, params)
    },
    // 异常呼叫删除
    deleteCall (params) {
      return request.post(`api/app/v1/abnormal/private/abnormalOrder/delete`, params)
    },
    // 异常呼叫判定
    judgeCall (params) {
      return request.post(`api/app/v1/abnormal/private/abnormalOrder/judge`, params)
    },
    // 异常类型变更
    changeAbnormalType (params) {
      return request.post(`api/app/v1/abnormal/private/abnormalOrder/changeAbnormalType`, params)
    },
    //异常呼叫列表
    getList (params) {
      return request.get(
        `api/app/v1/abnormal/private/abnormalOrder/list${getUrl(params)}`
      )
    },
    // 异常呼叫详情
    getDetail (params) {
      return request.get(`api/app/v1/abnormal/private/abnormalOrder/detail${getUrl(params)}`)
    },
    //变更状态
    changeStatus (params) {
      return request.get(`api/app/v1/abnormal/private/abnormalOrder/changeStatus${getUrl(params)}`)
    },
    // 处理
    handleCreate (data) {
      return request.post(`api/app/v1/abnormal/private/abnormalOrder/handleProcess/create`,data)
    },
    // 处理修改
    handleUpdate (data) {
      return request.post(`api/app/v1/abnormal/private/abnormalOrder/handleProcess/update`,data)
    },
    // 处理删除
    handleDel (data) {
      return request.post(`api/app/v1/abnormal/private/abnormalOrder/handleProcess/delete`,data)
    },
    // 处理过程提交验收
    submitCheck (data) {
      return request.post(`api/app/v1/abnormal/private/abnormalOrder/handleProcess/submitCheck`,data)
    },
    // 处理过程撤回
    revocation (data) {
      return request.post(`api/app/v1/abnormal/private/abnormalOrder/handleProcess/revocation`,data)
    },
    // 验收关闭开始验收
    startCheck (data) {
      return request.post(`api/app/v1/abnormal/private/abnormalOrder/handleProcess/startCheck`,data)
    },
    // 验收关闭重新验收
    restartCheck (data) {
      return request.post(`api/app/v1/abnormal/private/abnormalOrder/handleProcess/restartCheck`,data)
    },
    // 工单列表
    workOrderList(data) {
		return request.get('api/web/v1/production/private/productionPlan/pullDownList',data)
	},
  // 获取异常判定人列表
 getAbnormalOrderUserList(data){
	return request.get('api/app/v1/abnormal/private/abnormalOrder/getAbnormalOrderUserList', data)  
 },
   // 获取供应商下拉列表
   getDutySupplierList(data){
	  return request.get('api/app/v1/abnormal/private/abnormalOrder/getDutySupplierList',data)
  }
}
