import requestForAsync from '@/utils/requestForAsync'

const baseUrl = 'api/web/v1/basicConfig/private/basicConfig/'

export default {
  // 查询菜单列表
  getColumnsList() {
    return requestForAsync.get(`${baseUrl}getColumnsList`)
  },
  // 查询菜单字段配置信息详情
  getColumnsDetail(data) {
    return requestForAsync.get(`${baseUrl}getColumnsDetail`,data)
  },
  // 修改保存字段配置详情
  setColumnsDetail(data) {
    return requestForAsync.post(`${baseUrl}setColumnsDetail`,data)
  },
  //查询流程记录列表
  getAbnormalListPage(data){
    return requestForAsync.get('api/web/v1/flowable/private/flowable/getDeploymentPage', data)
  },
  // 获取下载流程文件
  getDeploymentBpmnXml(data){
    return requestForAsync.exportGet('api/web/v1/flowable/private/flowable/getDeploymentBpmnXml', data)
  }
}
