<template>
  <el-drawer
    :visible="value"
    v-bind="allPropsData"
    v-on="$listeners"
    @close="close()"
  >
    <div class="body-wrap" :style="{height: height + 'px'}">
      <div v-if="showBody" class="body">
        <slot />
      </div>
    </div>
    <div class="mubai-drawer-footer">
      <el-button @click="close()">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="onOk()">确 定</el-button>
    </div>
  </el-drawer>
</template>

<script>
/** write by luomingzhi */
export default {
  name: 'MDrawer',
  props: {
    value: {
      type: Boolean,
      require: true,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      showBody: false
    }
  },
  computed: {
    allPropsData() {
      return Object.assign({
        wrapperClosable: false,
        direction: 'rtl',
        ...this.$attrs,
        customClass: `${this.$attrs.className} mubai-drawer-wrap`
      })
    },
    height() {
      return this.$store.state.app.contentHeight - 65 - 76
    }
  },
  watch: {
    value(val) {
      if (!val) {
        setTimeout(() => {
          this.showBody = false
        }, 500)
      } else {
        this.showBody = true
      }
    }
  },
  methods: {
    onOk() {
      this.loading = true
      this.$emit('onOk', (reset) => {
        this.loading = false
        if (reset) {
          if (this.$parent.$refs.formBuild) {
            this.$parent.$refs.formBuild.formRefs().clearValidate()
          }
        }
      })
    },
    close() {
      if (this.$parent.$refs.formBuild) {
        this.$parent.$refs.formBuild.formRefs().clearValidate()
      }
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss">
.mubai-drawer-wrap {
  width: 800px !important;
  .el-drawer__header {
    background: #F8F8FB;
    margin-bottom: 0;
    padding-bottom: 20px;
  }
  .body-wrap {
    overflow: auto;
    .body {
      padding: 20px 30px;
    }
  }
}
.mubai-drawer-footer {
  padding: 20px;
  box-shadow: 4px -4px 12px -6px rgba(0, 0, 0, 0.1);
  text-align: right;
  button {
    padding: 10px 40px !important;
    &:first-child {
      background: #F8F8FB;
      color: #8A8CA5;
      transition: all .5s;
      &:hover {
        border-color: #dadaf6;
        background: #f0f0f8;
      }
    }
    &:last-child {
      background: $primary-color;
      color: #ffffff;
      transition: all .5s;
      &:hover {
        background: #7691fc;
      }
    }
  }
}
</style>
<style scoped>

</style>
