<template>
  <div />
</template>

<script>
export default {
  name: 'Refresh',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.dispatch('tagsView/addCachedView', from)
      vm.$router.replace({
        path: from.path,
        query: from.query,
        params: from.params
      })
    })
  }
}
</script>

<style scoped>

</style>
