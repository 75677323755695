import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/'
const baseUrl2 = 'api/app/v1/'

export default {
  // 获取某个url的数据
  getData(url, data, isAppApi = false, method = 'get') {
    return request[method]((isAppApi ? baseUrl2 : baseUrl) + url, data)
  },
  // 获取某个table的数据
  getTableData(url, data, method = 'get') {
    return request[method](baseUrl + url, data)
  },
  // 获取某个url的数据
  getTableDataApp(url, data) {
    return request.get(baseUrl2 + url, data)
  },
  // 获取某个table的数据
  getTablePutData(url, data) {
    return request.put(baseUrl + url, data)
  },
  // 修改应出勤天数
  modifyWorkingDays(data) {
    return request.put(baseUrl + 'com/mubai/mes/entity/field/manHour/private/dailyWorkReportMonths/updateOne', data)
  },
  getWeekCount(data) {
    return request.get('api/web/v1/production/private/productionPlan/weekPlanFinishedCount', data)
  },
  // 导出统计报表
  exportDatas(url,data){
    return request.exportPost(baseUrl + url, data)
  }
}
