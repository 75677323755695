import request from '@/utils/requestForAsync'

export default {
  // 获取故障报修-分页
  getFaultRadioListPage(data) {
    return request.get('api/web/v1/equipment/fault/private/equipmentRepairTask/getEquipmentRepairTaskListPage', data)
  },
  // 获取报修记录-分页
  getRepairRadioListPage(data) {
    return request.get('api/web/v1/equipment/fault/private/equipmentRepairTask/getEquipmentRepairTaskListPage', data)
  },
  getInspectionRadioListPage(data) {
    return request.get('api/web/v1/equipment/check/private/equipmentCycleCheckTask/getEquipmentCycleCheckTaskListPage', data)
  },
  getPointRadioListPage(data) {
    return request.get('api/web/v1/equipment/check/private/equipmentSpotCheckTask/getEquipmentSpotCheckTaskListPage', data)
  },
  getMaintenanceRadioListPage(data) {
    return request.get('api/web/v1/equipment/maintain/private/equipmentMaintainTask/getEquipmentMaintainTaskListPage', data)
  }
}
