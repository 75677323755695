var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          staticClass: "kr-form",
          attrs: {
            "label-width": "80px",
            model: _vm.activeElement,
            size: "small"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "字体" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled:
                              _vm.activeElement.style.FontFamily === undefined
                          },
                          model: {
                            value: _vm.activeElement.style.FontFamily,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.activeElement.style,
                                "FontFamily",
                                $$v
                              )
                            },
                            expression: "activeElement.style.FontFamily"
                          }
                        },
                        _vm._l(_vm.fontFamilyArray, function(val) {
                          return _c("el-option", {
                            key: val.name,
                            attrs: { label: val.name, value: val.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "宽度" } },
                    [
                      _c("el-input-number", {
                        staticClass: "min-input",
                        attrs: {
                          "controls-position": "right",
                          min: 1,
                          max: _vm.width - _vm.activeElement.left
                        },
                        model: {
                          value: _vm.activeElement.width,
                          callback: function($$v) {
                            _vm.$set(_vm.activeElement, "width", $$v)
                          },
                          expression: "activeElement.width"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "高度" } },
                    [
                      _c("el-input-number", {
                        staticClass: "min-input",
                        attrs: {
                          "controls-position": "right",
                          min: 1,
                          max: _vm.height - _vm.activeElement.top
                        },
                        model: {
                          value: _vm.activeElement.height,
                          callback: function($$v) {
                            _vm.$set(_vm.activeElement, "height", $$v)
                          },
                          expression: "activeElement.height"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "横坐标" } },
                    [
                      _c("el-input-number", {
                        staticClass: "min-input",
                        attrs: {
                          "controls-position": "right",
                          min: 0,
                          max: _vm.width - _vm.activeElement.width
                        },
                        model: {
                          value: _vm.activeElement.left,
                          callback: function($$v) {
                            _vm.$set(_vm.activeElement, "left", $$v)
                          },
                          expression: "activeElement.left"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "纵坐标" } },
                    [
                      _c("el-input-number", {
                        staticClass: "min-input",
                        attrs: {
                          "controls-position": "right",
                          min: 0,
                          max: _vm.height - _vm.activeElement.height
                        },
                        model: {
                          value: _vm.activeElement.top,
                          callback: function($$v) {
                            _vm.$set(_vm.activeElement, "top", $$v)
                          },
                          expression: "activeElement.top"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "字号" } },
                    [
                      _c("el-input-number", {
                        staticClass: "min-input",
                        attrs: {
                          disabled:
                            _vm.activeElement.style.FontSize === undefined,
                          "controls-position": "right",
                          min: 7,
                          max: 28
                        },
                        model: {
                          value: _vm.activeElement.style.FontSize,
                          callback: function($$v) {
                            _vm.$set(_vm.activeElement.style, "FontSize", $$v)
                          },
                          expression: "activeElement.style.FontSize"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "字体颜色" } },
                    [
                      _c("el-color-picker", {
                        attrs: {
                          disabled:
                            _vm.activeElement.style.FontColor === undefined
                        },
                        model: {
                          value: _vm.activeElement.style.FontColor,
                          callback: function($$v) {
                            _vm.$set(_vm.activeElement.style, "FontColor", $$v)
                          },
                          expression: "activeElement.style.FontColor"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "加粗" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          disabled: _vm.activeElement.style.Bold === undefined,
                          "active-value": true,
                          "inactive-value": false
                        },
                        model: {
                          value: _vm.activeElement.style.Bold,
                          callback: function($$v) {
                            _vm.$set(_vm.activeElement.style, "Bold", $$v)
                          },
                          expression: "activeElement.style.Bold"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "斜体" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          disabled:
                            _vm.activeElement.style.Italic === undefined,
                          "active-value": true,
                          "inactive-value": false
                        },
                        model: {
                          value: _vm.activeElement.style.Italic,
                          callback: function($$v) {
                            _vm.$set(_vm.activeElement.style, "Italic", $$v)
                          },
                          expression: "activeElement.style.Italic"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "下划线" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          disabled:
                            _vm.activeElement.style.Underline === undefined,
                          "active-value": true,
                          "inactive-value": false
                        },
                        model: {
                          value: _vm.activeElement.style.Underline,
                          callback: function($$v) {
                            _vm.$set(_vm.activeElement.style, "Underline", $$v)
                          },
                          expression: "activeElement.style.Underline"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "对齐方式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled:
                          _vm.activeElement.style.Alignment === undefined
                      },
                      model: {
                        value: _vm.activeElement.style.Alignment,
                        callback: function($$v) {
                          _vm.$set(_vm.activeElement.style, "Alignment", $$v)
                        },
                        expression: "activeElement.style.Alignment"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: "left" } }, [
                        _vm._v("左对齐")
                      ]),
                      _c("el-radio", { attrs: { label: "center" } }, [
                        _vm._v("居中")
                      ]),
                      _c("el-radio", { attrs: { label: "right" } }, [
                        _vm._v("右对齐")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("el-row")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }