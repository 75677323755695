import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/equipment/setting/private/'

export default {
  // 获取维保经验-不分页
  getExperienceList(data) {
    return request.get(baseUrl + 'maintenanceExperience/getMaintenanceExperienceList', data)
  },
  // 获取维保经验-分页
  getExperienceListPage(data) {
    return request.get(baseUrl + 'maintenanceExperience/getMaintenanceExperienceListPage', data)
  },
  // 新增维保经验
  addExperience(data) {
    return request.post(baseUrl + 'maintenanceExperience/addMaintenanceExperience', data)
  },
  // 新增维保禁言
  copyExperience(data) {
    return request.post(baseUrl + 'maintenanceExperience/addMaintenanceExperience', data)
  },
  // 修改维保经验
  modifyExperience(data) {
    return request.put(baseUrl + 'maintenanceExperience/updateOne', data)
  },
  // 删除维保经验
  delExperience(data) {
    return request.del(baseUrl + 'maintenanceExperience/deleteOne', data)
  },

  // 获取故障原因-不分页
  getFaultReasonList(data) {
    return request.get(baseUrl + 'faultReason/getFaultReasonList', data)
  },
  // 获取故障原因-分页
  getFaultReasonListPage(data) {
    return request.get(baseUrl + 'faultReason/getFaultReasonListPage', data)
  },
  // 新增故障原因
  addFaultReason(data) {
    return request.post(baseUrl + 'faultReason/addFaultReason', data)
  },
  // 新增故障原因
  copyFaultReason(data) {
    return request.post(baseUrl + 'faultReason/addFaultReason', data)
  },
  // 修改故障原因
  modifyFaultReason(data) {
    return request.put(baseUrl + 'faultReason/updateOne', data)
  },
  // 删除故障原因
  delFaultReason(data) {
    return request.del(baseUrl + 'faultReason/deleteOne', data)
  },

  // 获取紧急程度-不分页
  getUrgencyLevelList(data) {
    return request.get(baseUrl + 'emergenceDegree/getEmergenceDegreeList', data)
  },
  // 获取紧急程度-分页
  getUrgencyLevelListPage(data) {
    return request.get(baseUrl + 'emergenceDegree/getEmergenceDegreeListPage', data)
  },
  // 新增紧急程度
  addUrgencyLevel(data) {
    return request.post(baseUrl + 'emergenceDegree/addEmergenceDegree', data)
  },
  // 新增紧急程度
  copyUrgencyLevel(data) {
    return request.post(baseUrl + 'emergenceDegree/addEmergenceDegree', data)
  },
  // 修改紧急程度
  modifyUrgencyLevel(data) {
    return request.put(baseUrl + 'emergenceDegree/updateOne', data)
  },
  // 删除紧急程度
  delUrgencyLevel(data) {
    return request.del(baseUrl + 'emergenceDegree/deleteOne', data)
  },

  // 获取设备部位-不分页
  getEquipmentPositionList(data) {
    return request.get(baseUrl + 'equipmentPart/getEquipmentPartList', data)
  },
  // 获取设备部位-分页
  getEquipmentPositionListPage(data) {
    return request.get(baseUrl + 'equipmentPart/getEquipmentPartListPage', data)
  },
  // 新增设备部位
  addEquipmentPosition(data) {
    return request.post(baseUrl + 'equipmentPart/addEquipmentPart', data)
  },
  // 新增设备部位
  copyEquipmentPosition(data) {
    return request.post(baseUrl + 'equipmentPart/addEquipmentPart', data)
  },
  // 修改设备部位
  modifyEquipmentPosition(data) {
    return request.put(baseUrl + 'equipmentPart/updateOne', data)
  },
  // 删除设备部位
  delEquipmentPosition(data) {
    return request.del(baseUrl + 'equipmentPart/deleteOne', data)
  },

  // 获取保养项目-不分页
  getMaintenanceProjectList(data) {
    return request.get(baseUrl + 'maintenanceItem/getMaintenanceItemList', data)
  },
  // 获取保养项目-分页
  getMaintenanceProjectListPage(data) {
    return request.get(baseUrl + 'maintenanceItem/getMaintenanceItemListPage', data)
  },
  // 新增保养项目
  addMaintenanceProject(data) {
    return request.post(baseUrl + 'maintenanceItem/addMaintenanceItem', data)
  },
  // 新增保养项目
  copyMaintenanceProject(data) {
    return request.post(baseUrl + 'maintenanceItem/addMaintenanceItem', data)
  },
  // 修改保养项目
  modifyMaintenanceProject(data) {
    return request.put(baseUrl + 'maintenanceItem/updateOne', data)
  },
  // 删除保养项目
  delMaintenanceProject(data) {
    return request.del(baseUrl + 'maintenanceItem/deleteOne', data)
  },

  // 获取保养配置-不分页
  getMaintenanceSettingList(data) {
    return request.get(baseUrl + 'maintenanceItemEquipmentClassMapping/getMaintenanceItemEquipmentClassMappingList', data)
  },
  // 获取保养配置-分页
  getMaintenanceSettingListPage(data) {
    return request.get(baseUrl + 'maintenanceItemEquipmentClassMapping/getMaintenanceItemEquipmentClassMappingListPage', data)
  },
  // 新增/修改保养配置
  addMaintenanceSetting(data) {
    return request.post(baseUrl + 'maintenanceItemEquipmentClassMapping/updateEquipmentClassMaintenanceItem', data)
  },
  // 新增保养配置
  copyMaintenanceSetting(data) {
    return request.post(baseUrl + 'maintenanceItemEquipmentClassMapping/updateEquipmentClassMaintenanceItem', data)
  },
  // 修改保养配置
  modifyMaintenanceSetting(data) {
    return request.post(baseUrl + 'maintenanceItemEquipmentClassMapping/updateEquipmentClassMaintenanceItem', data)
  },
  // 删除保养配置
  delMaintenanceSetting(id) {
    return request.del(baseUrl + `maintenanceItemEquipmentClassMapping/deleteEquipmentClassMaintenanceItem?equipmentClassId=${id}`)
  },
  createCode(params) {
    return request.get(baseUrl + 'maintenanceExperience/getMaintenanceExperienceCode', params)
  }
}
