var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "process-flow-wrapper" }, [
    _c(
      "div",
      { staticClass: "radio-group flex-sb" },
      [
        !_vm.readOnly && _vm.params.radio === "flow"
          ? _c(
              "div",
              [
                _c("el-button", { on: { click: _vm.beautifyX6 } }, [
                  _vm._v("一键美化")
                ]),
                _c("el-button", { on: { click: _vm.clearGraph } }, [
                  _vm._v("清空画布")
                ])
              ],
              1
            )
          : _c("div"),
        _c(
          "el-radio-group",
          {
            staticClass: "m-radio-group",
            on: { change: _vm.radioChange },
            model: {
              value: _vm.params.radio,
              callback: function($$v) {
                _vm.$set(_vm.params, "radio", $$v)
              },
              expression: "params.radio"
            }
          },
          [
            _c("el-radio-button", { attrs: { label: "flow" } }, [
              _c("img", {
                staticStyle: { width: "17px" },
                attrs: {
                  src:
                    _vm.params.radio === "flow"
                      ? _vm.flowActiveImg
                      : _vm.flowImg
                }
              })
            ]),
            _c("el-radio-button", { attrs: { label: "list" } }, [
              _c("img", {
                staticStyle: { width: "17px" },
                attrs: {
                  src:
                    _vm.params.radio === "list"
                      ? _vm.listActiveImg
                      : _vm.listImg
                }
              })
            ])
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "process-flow" },
      [
        _c(
          "Split",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.params.radio === "flow",
                expression: "params.radio === 'flow'"
              }
            ],
            attrs: {
              offset: _vm.split.offset,
              move: !_vm.readOnly,
              height: 400
            },
            on: {
              "update:offset": function($event) {
                return _vm.$set(_vm.split, "offset", $event)
              }
            }
          },
          [
            _c("template", { slot: "left" }),
            _c("template", { slot: "right" }, [
              _c(
                "div",
                { staticClass: "all-produces" },
                [
                  _c("show-process-flow", {
                    attrs: { allWorkOrderTaskList: _vm.allWorkOrderTaskList },
                    on: { selectProducesEvent: _vm.selectProduces }
                  })
                ],
                1
              )
            ])
          ],
          2
        ),
        _vm.params.radio === "list"
          ? _c(
              "div",
              { staticClass: "flow-table" },
              [
                _c("MTable", {
                  ref: "procedureTable",
                  attrs: {
                    "show-page": false,
                    "highlight-current-row": true,
                    height: 230,
                    columns: _vm.columns.procedure,
                    data: _vm.procedureList
                  },
                  on: { "current-change": _vm.selectionChange },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "index",
                        fn: function(ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      },
                      {
                        key: "procedureType",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "flex-start",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.procedureTypeNames[row.procedureType]
                                  )
                                )
                              ]),
                              row.type == 0 && row.procedureType == 0
                                ? _c("img", {
                                    staticStyle: {
                                      width: "24px",
                                      height: "24px",
                                      margin: "-5px 0 0 10px"
                                    },
                                    attrs: {
                                      src: require("@/assets/plans/in.png")
                                    }
                                  })
                                : _vm._e(),
                              row.type == 1 && row.procedureType == 1
                                ? _c("img", {
                                    staticStyle: {
                                      width: "24px",
                                      height: "24px",
                                      margin: "-5px 0 0 10px"
                                    },
                                    attrs: {
                                      src: require("@/assets/plans/out.png")
                                    }
                                  })
                                : _vm._e(),
                              row.procedureType === 0 && row.type === 1
                                ? _c("img", {
                                    staticStyle: {
                                      width: "40px",
                                      height: "22px",
                                      "margin-left": "10px"
                                    },
                                    attrs: {
                                      src: require("@/assets/plans/wai.png")
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        }
                      },
                      {
                        key: "procedureIndex",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {
                              style: { color: row.procedureIndex ? "" : "red" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row.procedureIndex || "未连接其他工序"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        }
                      }
                    ],
                    null,
                    false,
                    2125459133
                  )
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "procedure-detail" },
      [
        _vm.procedureParams[_vm.currentProcedureUUid]
          ? _c("h3", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.procedureParams[_vm.currentProcedureUUid].name) +
                  " "
              )
            ])
          : _vm._e(),
        _vm.currentProcedureUUid
          ? _c(
              "div",
              { staticClass: "procedure-detail-body" },
              [
                _c("div", { staticClass: "detail-card" }, [
                  _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
                  _c(
                    "div",
                    { staticClass: "card-bot" },
                    [
                      _c(
                        "el-row",
                        { staticClass: "view-list", attrs: { gutter: 24 } },
                        _vm._l(_vm.columns.base, function(item, index) {
                          return _c(
                            "el-col",
                            {
                              key: item.prop,
                              style: {
                                marginBottom: index !== 6 ? "20px" : 0,
                                display: "inline-flex"
                              },
                              attrs: { title: _vm.getValue(item), span: 8 }
                            },
                            [
                              item.prop == "type"
                                ? _c(
                                    "div",
                                    { staticStyle: { display: "inline-flex" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "word-break": "keep-all"
                                          }
                                        },
                                        [_vm._v(_vm._s(item.label) + "：")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "word-break": "break-all",
                                            display: "flex",
                                            "justify-content": "flex-start"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.getValue(item)))
                                          ]),
                                          _vm.procedureParams[
                                            _vm.currentProcedureUUid
                                          ].type == 0 &&
                                          _vm.procedureParams[
                                            _vm.currentProcedureUUid
                                          ].procedureTypes == 0
                                            ? _c("img", {
                                                staticStyle: {
                                                  width: "24px",
                                                  height: "24px",
                                                  margin: "-5px 0 0 10px"
                                                },
                                                attrs: {
                                                  src: require("@/assets/plans/in.png")
                                                }
                                              })
                                            : _vm._e(),
                                          _vm.procedureParams[
                                            _vm.currentProcedureUUid
                                          ].type == 1 &&
                                          _vm.procedureParams[
                                            _vm.currentProcedureUUid
                                          ].procedureTypes == 1
                                            ? _c("img", {
                                                staticStyle: {
                                                  width: "24px",
                                                  height: "24px",
                                                  margin: "-5px 0 0 10px"
                                                },
                                                attrs: {
                                                  src: require("@/assets/plans/out.png")
                                                }
                                              })
                                            : _vm._e(),
                                          _vm.procedureParams[
                                            _vm.currentProcedureUUid
                                          ].type == 0 &&
                                          _vm.procedureParams[
                                            _vm.currentProcedureUUid
                                          ].procedureTypes == 1
                                            ? _c("img", {
                                                staticStyle: {
                                                  width: "40px",
                                                  height: "22px",
                                                  margin: "-5px 0 0 10px"
                                                },
                                                attrs: {
                                                  src: require("@/assets/plans/wai.png")
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticStyle: { display: "inline-flex" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "word-break": "keep-all"
                                          }
                                        },
                                        [_vm._v(_vm._s(item.label) + "：")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "word-break": "break-all"
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.getValue(item)))]
                                      )
                                    ]
                                  )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "detail-card" }, [
                  _c("div", { staticClass: "card-tit" }, [
                    _vm._v("工序操作描述")
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-bot" },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入工序操作描述",
                          maxlength: "500",
                          disabled: _vm.readOnly,
                          "show-word-limit": ""
                        },
                        model: {
                          value:
                            _vm.procedureParams[_vm.currentProcedureUUid]
                              .operationDesc,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.procedureParams[_vm.currentProcedureUUid],
                              "operationDesc",
                              $$v
                            )
                          },
                          expression:
                            "procedureParams[currentProcedureUUid].operationDesc"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "detail-card" }, [
                  _c("div", { staticClass: "card-tit" }, [
                    _vm._v("工序计划用料")
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-bot" },
                    [
                      _c("MTable", {
                        ref: "planTable",
                        attrs: {
                          "show-page": false,
                          "highlight-current-row": true,
                          height: 300,
                          columns: _vm.columns.plans,
                          data: _vm.planMaterialsList
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function(ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return _c("div", {}, [
                                  _vm._v(_vm._s($index + 1))
                                ])
                              }
                            },
                            {
                              key: "planQuantity",
                              fn: function(ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return _c(
                                  "div",
                                  {},
                                  [
                                    _vm.readOnly
                                      ? _c("span", [
                                          _vm._v(_vm._s(row.planQuantity))
                                        ])
                                      : _c("el-input-number", {
                                          staticStyle: { width: "98%" },
                                          attrs: {
                                            min: 0,
                                            "controls-position": "right",
                                            step: 1
                                          },
                                          model: {
                                            value: row.planQuantity,
                                            callback: function($$v) {
                                              _vm.$set(row, "planQuantity", $$v)
                                            },
                                            expression: "row.planQuantity"
                                          }
                                        })
                                  ],
                                  1
                                )
                              }
                            }
                          ],
                          null,
                          false,
                          4083908560
                        )
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "detail-card" }, [
                  _c("div", { staticClass: "card-tit" }, [
                    _vm._v("工序图纸及附件")
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-bot" },
                    [
                      _vm._m(0),
                      _c("br"),
                      !_vm.readOnly
                        ? _c(
                            "el-upload",
                            _vm._b(
                              {
                                staticClass: "b20",
                                attrs: { type: "primary" }
                              },
                              "el-upload",
                              _vm.uploadProps,
                              false
                            ),
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function($event) {
                                      _vm.uploadKey = "drawingsList"
                                    }
                                  }
                                },
                                [_vm._v("上传图纸")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("MTable", {
                        ref: "mTable",
                        attrs: {
                          "show-page": false,
                          height: 300,
                          columns: _vm.columns.drawings,
                          data: _vm.drawingsList
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function(ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return _c("div", {}, [
                                  _vm._v(_vm._s($index + 1))
                                ])
                              }
                            },
                            {
                              key: "fileName",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#607FFF" },
                                      attrs: {
                                        href: row.url,
                                        download: row.name,
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v(_vm._s(row.name))]
                                  ),
                                  _c("img", {
                                    staticStyle: {
                                      width: "11px",
                                      "margin-left": "5px"
                                    },
                                    attrs: {
                                      src: require("@/assets/information/procedure/附件@2x.png")
                                    }
                                  })
                                ])
                              }
                            },
                            {
                              key: "remark",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("el-input", {
                                  attrs: {
                                    disabled: _vm.readOnly,
                                    placeholder: "请输入"
                                  },
                                  model: {
                                    value: row.remark,
                                    callback: function($$v) {
                                      _vm.$set(row, "remark", $$v)
                                    },
                                    expression: "row.remark"
                                  }
                                })
                              }
                            },
                            {
                              key: "action",
                              fn: function(ref) {
                                var $index = ref.$index
                                return _c(
                                  "div",
                                  {},
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "text",
                                          disabled: _vm.readOnly
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.delFrontData(
                                              "drawingsList",
                                              $index
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 删除 ")]
                                    )
                                  ],
                                  1
                                )
                              }
                            }
                          ],
                          null,
                          false,
                          3584056501
                        )
                      }),
                      _vm._m(1),
                      _c("br"),
                      !_vm.readOnly
                        ? _c(
                            "el-upload",
                            _vm._b(
                              {
                                staticClass: "b20",
                                attrs: { type: "primary" }
                              },
                              "el-upload",
                              _vm.uploadProps,
                              false
                            ),
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function($event) {
                                      _vm.uploadKey = "filesList"
                                    }
                                  }
                                },
                                [_vm._v("上传附件")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("MTable", {
                        ref: "mTable",
                        attrs: {
                          "show-page": false,
                          height: 300,
                          columns: _vm.columns.files,
                          data: _vm.filesList
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function(ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return _c("div", {}, [
                                  _vm._v(_vm._s($index + 1))
                                ])
                              }
                            },
                            {
                              key: "fileName",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#607FFF" },
                                      attrs: {
                                        href: row.url,
                                        download: row.name,
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v(_vm._s(row.name))]
                                  ),
                                  _c("img", {
                                    staticStyle: {
                                      width: "11px",
                                      "margin-left": "5px"
                                    },
                                    attrs: {
                                      src: require("@/assets/information/procedure/附件@2x.png")
                                    }
                                  })
                                ])
                              }
                            },
                            {
                              key: "remark",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("el-input", {
                                  attrs: {
                                    disabled: _vm.readOnly,
                                    placeholder: "请输入"
                                  },
                                  model: {
                                    value: row.remark,
                                    callback: function($$v) {
                                      _vm.$set(row, "remark", $$v)
                                    },
                                    expression: "row.remark"
                                  }
                                })
                              }
                            },
                            {
                              key: "action",
                              fn: function(ref) {
                                var $index = ref.$index
                                return _c(
                                  "div",
                                  {},
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "text",
                                          disabled: _vm.readOnly
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.delFrontData(
                                              "filesList",
                                              $index
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("删除 ")]
                                    )
                                  ],
                                  1
                                )
                              }
                            }
                          ],
                          null,
                          false,
                          2069396115
                        )
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "detail-card" }, [
                  _c("div", { staticClass: "card-tit" }, [
                    _vm._v("工序上传参数")
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-bot" },
                    [
                      _c("ProcedureParams", {
                        ref: "procedureParams",
                        attrs: {
                          "read-only": _vm.readOnly,
                          "group-list": _vm.groupList
                        }
                      })
                    ],
                    1
                  )
                ]),
                !["bbelc"].includes(_vm.memberCode)
                  ? [
                      _vm.procedureParams[_vm.currentProcedureUUid].type === 1
                        ? _c("div", { staticClass: "detail-card" }, [
                            _c("div", { staticClass: "card-tit" }, [
                              _vm._v("外协工序时间设置")
                            ]),
                            _c(
                              "div",
                              { staticClass: "card-bot" },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "padding-left": "10px" } },
                                  [_vm._v("外协默认周期：")]
                                ),
                                _c("el-input-number", {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    size: "small",
                                    "controls-position": "right",
                                    min: 0,
                                    step: 1,
                                    disabled: _vm.readOnly
                                  },
                                  model: {
                                    value:
                                      _vm.procedureParams[
                                        _vm.currentProcedureUUid
                                      ].outsourceTime,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.procedureParams[
                                          _vm.currentProcedureUUid
                                        ],
                                        "outsourceTime",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "procedureParams[currentProcedureUUid].outsourceTime"
                                  }
                                }),
                                _c(
                                  "span",
                                  { staticStyle: { "padding-left": "10px" } },
                                  [_vm._v("天")]
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      !_vm.readOnly ||
                      _vm.procedureParams[_vm.currentProcedureUUid]
                        .isNeedProcessInspect ||
                      _vm.procedureParams[_vm.currentProcedureUUid]
                        .isNeedFirstInspect
                        ? _c("div", { staticClass: "detail-card" }, [
                            _c("div", { staticClass: "card-tit" }, [
                              _vm._v("工序质检设置")
                            ]),
                            _c(
                              "div",
                              { staticClass: "card-bot" },
                              [
                                _c(
                                  "el-tabs",
                                  {
                                    model: {
                                      value: _vm.activeName,
                                      callback: function($$v) {
                                        _vm.activeName = $$v
                                      },
                                      expression: "activeName"
                                    }
                                  },
                                  [
                                    _vm._l(_vm.tabs, function(item, index) {
                                      return [
                                        _vm.procedureParams[
                                          _vm.currentProcedureUUid
                                        ].type !== 1 ||
                                        (_vm.procedureParams[
                                          _vm.currentProcedureUUid
                                        ].type === 1 &&
                                          item.key !==
                                            "inspectionProjectGroupFirst")
                                          ? _c(
                                              "el-tab-pane",
                                              {
                                                key: index,
                                                attrs: {
                                                  label: item.label,
                                                  name: item.key
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "padding-left": "10px"
                                                        }
                                                      },
                                                      [_vm._v("是否需要质检：")]
                                                    ),
                                                    _c(
                                                      "el-radio-group",
                                                      {
                                                        attrs: {
                                                          name: item.key,
                                                          disabled: _vm.readOnly
                                                        },
                                                        on: {
                                                          change: function(
                                                            val
                                                          ) {
                                                            _vm.isNeedQualityInspectionChange(
                                                              val,
                                                              item.key
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .qualityInspectionPlan[
                                                              item.key
                                                            ].isNeedInspect,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .qualityInspectionPlan[
                                                                item.key
                                                              ],
                                                              "isNeedInspect",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "qualityInspectionPlan[item.key].isNeedInspect"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: { label: 1 }
                                                          },
                                                          [_vm._v("是")]
                                                        ),
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: { label: 0 }
                                                          },
                                                          [_vm._v("否")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-top": "10px"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "padding-left": "10px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "质检完成才能出站："
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-radio-group",
                                                      {
                                                        attrs: {
                                                          name: item.key,
                                                          disabled: _vm.readOnly
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.procedureParams[
                                                              _vm
                                                                .currentProcedureUUid
                                                            ].isMustCheck,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .procedureParams[
                                                                _vm
                                                                  .currentProcedureUUid
                                                              ],
                                                              "isMustCheck",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "procedureParams[currentProcedureUUid].isMustCheck"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: { label: 1 }
                                                          },
                                                          [_vm._v("是")]
                                                        ),
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: { label: 0 }
                                                          },
                                                          [_vm._v("否")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm.qualityInspectionPlan[
                                                  item.key
                                                ].isNeedInspect === 1
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "padding-top": "15px"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "padding-left":
                                                                "10px"
                                                            }
                                                          },
                                                          [_vm._v("检验类型：")]
                                                        ),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            attrs: {
                                                              name: item.key,
                                                              disabled:
                                                                _vm.readOnly
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .procedureParams[
                                                                  _vm
                                                                    .currentProcedureUUid
                                                                ]
                                                                  .isFullInspection,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .procedureParams[
                                                                    _vm
                                                                      .currentProcedureUUid
                                                                  ],
                                                                  "isFullInspection",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "procedureParams[currentProcedureUUid].isFullInspection"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "el-option",
                                                              {
                                                                attrs: {
                                                                  label: "全检",
                                                                  value: 1
                                                                }
                                                              },
                                                              [_vm._v("全检")]
                                                            ),
                                                            _c(
                                                              "el-option",
                                                              {
                                                                attrs: {
                                                                  label: "抽检",
                                                                  value: 0
                                                                }
                                                              },
                                                              [_vm._v("抽检")]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.qualityInspectionPlan[
                                                  item.key
                                                ].isNeedInspect === 1
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          padding: "15px 0"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "padding-left":
                                                                "10px"
                                                            }
                                                          },
                                                          [_vm._v("质检方案：")]
                                                        ),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticStyle: {
                                                              width: "200px"
                                                            },
                                                            attrs: {
                                                              size: "small",
                                                              disabled: "",
                                                              filterable: "",
                                                              placeholder:
                                                                "请选择质检方案"
                                                            },
                                                            on: {
                                                              change: function(
                                                                val
                                                              ) {
                                                                return _vm.qualityInspectionChange(
                                                                  val,
                                                                  item.key
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .qualityInspectionPlan[
                                                                  item.key
                                                                ].inspectId,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .qualityInspectionPlan[
                                                                    item.key
                                                                  ],
                                                                  "inspectId",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "qualityInspectionPlan[item.key].inspectId"
                                                            }
                                                          },
                                                          _vm._l(
                                                            _vm.formOptions
                                                              .inspectionPlanList,
                                                            function(is) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: is.id,
                                                                  attrs: {
                                                                    label:
                                                                      is.name,
                                                                    value: is.id
                                                                  }
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.qualityInspectionPlan[
                                                  item.key
                                                ].inspectId
                                                  ? _c(
                                                      "quality-inspection-project",
                                                      {
                                                        ref: item.ref,
                                                        refInFor: true,
                                                        attrs: {
                                                          "inspection-entry-map":
                                                            _vm
                                                              .qualityInspectionPlan[
                                                              item.key
                                                            ].inspectEntryMap,
                                                          attachments:
                                                            _vm
                                                              .qualityInspectionPlan[
                                                              item.key
                                                            ]
                                                              .inspectAttachments,
                                                          "user-list":
                                                            _vm
                                                              .qualityInspectionPlan[
                                                              item.key
                                                            ].inspectorIdList,
                                                          "read-only":
                                                            _vm.readOnly
                                                        }
                                                      }
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ]
                  : _vm._e()
              ],
              2
            )
          : _c("m-blank", { attrs: { title: "请选择工序" } })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "b20 flex-sbc" }, [
      _c("img", {
        staticStyle: { width: "18px" },
        attrs: { src: require("@/assets/information/procedure/图纸@2x.png") }
      }),
      _c(
        "span",
        {
          staticClass: "l10",
          staticStyle: { position: "relative", top: "2px" }
        },
        [_vm._v("图纸")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bt20 flex-sbc" }, [
      _c("img", {
        staticStyle: { width: "18px" },
        attrs: {
          src: require("@/assets/information/procedure/其他附件@2x.png")
        }
      }),
      _c(
        "span",
        {
          staticClass: "l10",
          staticStyle: { position: "relative", top: "2px" }
        },
        [_vm._v("其他附件")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }