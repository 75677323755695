
export const sColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'status',
    label: '计划状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status',
    width: 120
  },
  {
    prop: 'quantity',
    label: '计划数量',
    width: 100,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '生产批次码',
    hiddenSearch: true,
    sortable: false,
    width: 220
  },
  {
    prop: 'produceStatus',
    label: '生产状态',
    width: 100,
    hiddenSearch: true,
    sortable: false,
    slotName: 'produceStatus'
  },
  {
    prop: 'reportWorkQuantity',
    label: '报工数量',
    width: 100,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'producePlanNumber',
    label: '生产计划号',
    hiddenSearch: true,
    sortable: false,
    width: 220,
    slotName: 'producePlanNumber'
  },
  {
    prop: 'source',
    label: '计划来源',
    hiddenSearch: true,
    sortable: false,
    slotName: 'source'
  },
  {
    prop: 'type',
    label: '计划类型',
    hiddenSearch: true,
    sortable: false,
    slotName: 'type'
  },
  {
    prop: 'produceOrderNumber',
    label: '所属生产订单',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'priority',
    label: '计划优先级',
    hiddenSearch: true,
    sortable: false,
    slotName: 'priority'
  },
  {
    prop: 'isRepair',
    label: '计划属性',
    hiddenSearch: true,
    sortable: false,
    width: 150,
    slotName: 'isRepair'
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    width: 400
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'deliveryTime',
    label: '交期',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'processFlowName',
    label: '工艺',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'areaName',
    label: '产线',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'planStartTime',
    label: '计划开始时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'planEndTime',
    label: '计划结束时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'actualStartTime',
    label: '实际开始时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'actualEndTime',
    label: '实际结束时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'creator',
    label: '创建人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '创建时间',
    hiddenSearch: true,
    sortable: false
  }
]
export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'status',
    label: '计划状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status',
    width: 120
  },
  {
    prop: 'quantity',
    label: '计划数量',
    width: 100,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '生产批次码',
    hiddenSearch: true,
    sortable: false,
    width: 220
  },
  {
    prop: 'produceStatus',
    label: '生产状态',
    width: 100,
    hiddenSearch: true,
    sortable: false,
    slotName: 'produceStatus'
  },
  {
    prop: 'reportWorkQuantity',
    label: '报工数量',
    width: 100,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'producePlanNumber',
    label: '生产计划号',
    hiddenSearch: true,
    sortable: false,
    width: 220,
    slotName: 'producePlanNumber'
  },
  {
    prop: 'source',
    label: '计划来源',
    hiddenSearch: true,
    sortable: false,
    slotName: 'source'
  },
  {
    prop: 'type',
    label: '计划类型',
    hiddenSearch: true,
    sortable: false,
    slotName: 'type'
  },
  {
    prop: 'produceOrderNumber',
    label: '所属生产订单',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'priority',
    label: '计划优先级',
    hiddenSearch: true,
    sortable: false,
    slotName: 'priority'
  },
  {
    prop: 'isRepair',
    label: '计划属性',
    hiddenSearch: true,
    sortable: false,
    width: 150,
    slotName: 'isRepair'
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    width: 400
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'deliveryTime',
    label: '交期',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'processFlowName',
    label: '工艺',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'areaName',
    label: '产线',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'planStartTime',
    label: '计划开始时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'planEndTime',
    label: '计划结束时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'actualStartTime',
    label: '实际开始时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'actualEndTime',
    label: '实际结束时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'creator',
    label: '创建人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '创建时间',
    hiddenSearch: true,
    sortable: false
  }
]
