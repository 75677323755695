<template>
  <div>
    <!-- 公共属性 -->
    <el-form label-width="80px" :model="activeElement" size="small" class="kr-form">
      <el-row>
        <el-col :span="24">
          <el-form-item label="字体">
            <el-select
              v-model="activeElement.style.FontFamily"
              :disabled="activeElement.style.FontFamily===undefined"
            >
              <el-option v-for="val in fontFamilyArray" :key="val.name" :label="val.name" :value="val.value" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="宽度">
            <el-input-number
              v-model="activeElement.width"
              controls-position="right"
              :min="1"
              :max="width-activeElement.left"
              class="min-input"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="高度">
            <el-input-number
              v-model="activeElement.height"
              controls-position="right"
              :min="1"
              :max="height-activeElement.top"
              class="min-input"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="横坐标">
            <el-input-number v-model="activeElement.left" controls-position="right" :min="0" :max="width-activeElement.width" class="min-input" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="纵坐标">
            <el-input-number v-model="activeElement.top" controls-position="right" :min="0" :max="height-activeElement.height" class="min-input" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="字号">
            <el-input-number
              v-model="activeElement.style.FontSize"
              :disabled="activeElement.style.FontSize===undefined"
              controls-position="right"
              :min="7"
              :max="28"
              class="min-input"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="字体颜色">
            <el-color-picker
              v-model="activeElement.style.FontColor"
              :disabled="activeElement.style.FontColor===undefined"
            />
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="横坐标">
            <el-input-number
              v-model="activeElement.left"
              controls-position="right"
              :min="0"
              class="min-input"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="纵坐标">
            <el-input-number
              v-model="activeElement.top"
              controls-position="right"
              :min="0"
              class="min-input"
            ></el-input-number>
          </el-form-item>
        </el-col> -->
      </el-row>

      <el-row>
        <el-col :span="8">
          <el-form-item label="加粗">
            <el-switch
              v-model="activeElement.style.Bold"
              :disabled="activeElement.style.Bold===undefined"
              :active-value="true"
              :inactive-value="false"
            />
          </el-form-item>
        </el-col>

        <!-- <el-col :span="8">
          <el-form-item label="边框颜色">
            <el-color-picker
              :disabled="activeElement.style.BorderColor===undefined"
              v-model="activeElement.style.BorderColor"
            ></el-color-picker>
          </el-form-item>
        </el-col> -->
        <el-col :span="8">
          <el-form-item label="斜体">
            <el-switch
              v-model="activeElement.style.Italic"
              :disabled="activeElement.style.Italic===undefined"
              :active-value="true"
              :inactive-value="false"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="下划线">
            <el-switch
              v-model="activeElement.style.Underline"
              :disabled="activeElement.style.Underline===undefined"
              :active-value="true"
              :inactive-value="false"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-form-item label="对齐方式">
          <el-radio-group
            v-model="activeElement.style.Alignment"
            :disabled="activeElement.style.Alignment===undefined"
          >
            <el-radio :label="'left'">左对齐</el-radio>
            <el-radio :label="'center'">居中</el-radio>
            <el-radio :label="'right'">右对齐</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-row>
      <el-row>
        <!-- <el-col :span="12">
          <el-form-item label="条码类型">
            <el-select
              :disabled="activeElement.style.codeType===undefined"
              v-model="activeElement.style.codeType"
              class="min-input"
            >
              <el-option v-for="val in codeTypeArray" :key="val" :label="val" :value="val" />
            </el-select>
          </el-form-item>
        </el-col> -->
        <!-- <el-col :span="12">
          <el-form-item label="条码值">
            <el-switch
              :disabled="activeElement.style.ShowBarText===undefined"
              v-model="activeElement.style.ShowBarText"
              :active-value="true"
              :inactive-value="false"
            ></el-switch>
          </el-form-item>
        </el-col> -->
        <!-- <el-col :span="12">
          <el-form-item label="打印类型">
            <el-select
              :disabled="activeElement.style.ItemType===undefined"
              v-model="activeElement.style.ItemType"
              class="min-input"
            >
              <el-option
                v-for="val in itemTypeArray"
                :key="val.value"
                :label="val.label"
                :value="val.value"
              />
            </el-select>
          </el-form-item>
        </el-col> -->
        <!-- <el-col :span="12">
          <el-form-item label="高度自动">
            <el-switch
              :disabled="activeElement.style.AutoHeight===undefined"
              v-model="activeElement.style.AutoHeight"
              :active-value="true"
              :inactive-value="false"
            ></el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="下边距">
            <el-input-number
              :disabled="activeElement.style.BottomMargin===undefined || !activeElement.style.AutoHeight"
              v-model="activeElement.style.BottomMargin"
              controls-position="right"
              :min="0"
              class="min-input"
            ></el-input-number>
          </el-form-item>
        </el-col> -->
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { getCodeTypeArray, getItemTypeArray } from '../../libs/props.js'
import vptd from '../../mixins/vptd'
export default {
  mixins: [vptd],
  data() {
    return {
      codeTypeArray: getCodeTypeArray(),
      itemTypeArray: getItemTypeArray(),
      fontFamilyArray: [
        { name: '默认浏览器', value: '' },
        { name: '微软雅黑', value: 'Microsoft YaHei,微软雅黑' },
        { name: 'Arial', value: 'Arial' }]
    }
  },
  computed: {
    activeElement() {
      return this.$vptd.state.activeElement
    },
    // 页面高度
    height() {
      return this.$vptd.state.page.height
    },
    width() {
      return this.$vptd.state.page.width
    }
  }
}
</script>
<style lang="scss" scoped>
.kr-form {
  ::v-deep.el-form-item--mini.el-form-item {
    margin-bottom: 10px;
  }
  ::v-deep .el-form-item--small.el-form-item {
    margin-bottom: 10px;
  }
  ::v-deep {
    .el-form-item--small .el-form-item__content {
      height: 32px;
    }
  }
}
</style>
