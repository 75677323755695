var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "second-bar-container",
      style: {
        width:
          (_vm.opened || _vm.siderIconHovered) && _vm.list.length ? "145px" : 0,
        position: _vm.siderIconHovered ? "fixed" : "relative",
        left: _vm.siderIconHovered ? "50px" : 0,
        top: 0,
        transition: _vm.opened ? "width .5s" : "initial"
      }
    },
    [
      _c(
        "div",
        { staticClass: "current-parent", staticStyle: { width: "145px" } },
        [_vm._v(_vm._s(_vm.parentTitle))]
      ),
      _c(
        "ul",
        [
          _vm._l(_vm.list, function(item) {
            return [
              !item.hidden && _vm.hasAuth(item)
                ? _c(
                    "li",
                    {
                      key: item.name,
                      attrs: { title: item.meta.title },
                      on: {
                        click: function($event) {
                          return _vm.openSecond(item)
                        }
                      }
                    },
                    [
                      _vm.isShowSecondBar(item)
                        ? _c("div", { staticClass: "li-content" }, [
                            _c("span", {
                              staticStyle: {
                                width: "15px",
                                display: "inline-block"
                              }
                            }),
                            _c("span", { staticClass: "ellipsis" }, [
                              _vm._v(_vm._s(item.meta.title))
                            ])
                          ])
                        : _vm._e(),
                      _c(
                        "ul",
                        [
                          _vm._l(item.children, function(child) {
                            return [
                              !child.hidden && _vm.hasAuth(child)
                                ? _c(
                                    "li",
                                    {
                                      key: child.name,
                                      attrs: { title: child.meta.title },
                                      on: {
                                        click: function($event) {
                                          return _vm.openSecond(child)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "li-contents",
                                          class:
                                            _vm.routerName ===
                                            child.meta.activeMenu
                                              ? "active"
                                              : "inactive"
                                        },
                                        [
                                          _c("span", {
                                            staticStyle: {
                                              width: "18px",
                                              display: "inline-block"
                                            }
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "ellipsis" },
                                            [_vm._v(_vm._s(child.meta.title))]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      ),
                      _vm.showVideo
                        ? _c("img", {
                            staticStyle: {
                              position: "absolute",
                              bottom: "60px",
                              width: "90%",
                              left: "5%"
                            },
                            attrs: {
                              src:
                                "http://mubyte.cn/wp-content/uploads/2021/10/2021102207331539.png"
                            },
                            on: { click: _vm.viewVideo }
                          })
                        : _vm._e(),
                      _vm.showVideo
                        ? _c("img", {
                            staticStyle: {
                              position: "absolute",
                              bottom: "20px",
                              width: "90%",
                              left: "5%"
                            },
                            attrs: {
                              src:
                                "http://mubyte.cn/wp-content/uploads/2021/11/2021110409345441.png"
                            }
                          })
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }