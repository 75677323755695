<template>
  <MDialog v-model="modalShow" width="1000px" :append-to-body="true" :title="title" @onOk="submitForm" @fullScreen="fullScreenChange">
    <div class="top">
      <SearchForm :form-list-extend="searchFormList" :form-data.sync="searchFormData" @search="searchTable">
        <SelectRemoteSearch
          slot="categoryIds"
          ref="categoryIds"
          search-name="name"
          class="select"
          clearable
          placeholder="请选择模具类型"
          :multiple="true"
          option-val="id"
          option-label="name"
          :api="curdApi.getMouldTypeList"
          collapse-tags
          @change="val => {
            handleSelect('categoryIds', val)
          }
          "
        />
      </SearchForm>
      <div v-if="!single">
        <span style="margin: 0 20px;">已选中 <span style="color: #000">{{ getSelectionDataLength }}</span> 项</span>
        <el-button type="text" @click="resetData">清空</el-button>
      </div>
    </div>
    <div class="materials-info">
      <MTable
        ref="mTable"
        :highlight-current-row="single"
        :columns="columns"
        :height="height"
        :set-data-method="getTableData"
        :columns-setting="false"
        @selection-change="selectionChange"
        @current-change="selectionChange"
        @row-click="handleRowClick"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      </MTable>
    </div>
  </MDialog>
</template>

<script>
import SearchForm from '@/components/SearchForm/SearchForm'
import api from '@/api/mould/index'
import curdApi from '@/api/CURDSelect'
const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    minWidth: 80
  },
  {
    prop: 'categoryName',
    label: '模具分类',
    hiddenSearch: true,
    sortable: false,
    minWidth: 100
  },
  {
    prop: 'name',
    label: '模具名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'spec',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'remarks',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    minWidth: 100
  }
]
const searchFormList = [
  {
    key: 'name',
    tagName: 'el-input',
    colSpan: 6,
    props: {
      placeholder: '请输入模具名称'
    }
  },
  {
    colSpan: 6,
    key: 'categoryIds',
    searchName: 'name',
    tagName: 'div',
    domProps: { className: 'custom-sel' },
    children: [
      {
        slots: ['categoryIds']
      }
    ]
  },
  {
    key: 'spec',
    tagName: 'el-input',
    colSpan: 6,
    props: {
      placeholder: '请输入模具规格'
    }
  }
]
const singleColumns = columns.slice(2)
export default {
  name: 'SelMoulds',
  components: { SearchForm },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    selectMoulds: {
      type: Array,
      default: () => ([])
    },
    title: {
      type: String,
      default: '选择模具'
    }
  },
  data() {
    return {
      modalShow: false,
      columns: this.single ? singleColumns : columns,
      searchFormList,
      searchFormData: {},
      selectionData: [],
      fullScreen: false,
      curdApi
    }
  },
  computed: {
    height() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 83 - 57 : this.$store.state.app.contentHeight - 289 - 160 - 10
    },
    splitHeight() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 10 : this.$store.state.app.contentHeight - 289 - 45
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  watch: {
    visible(val) {
      this.modalShow = val
      if (val) {
        this.echoData()
      }
    },
    modalShow(val) {
      if (!val) {
        this.searchFormData = {}
        this.$emit('update:visible', false)
      }
    }
  },
  mounted() {
    this.modalShow = this.visible
    this.init()
    if (this.modalShow) {
      this.echoData()
    }
  },
  methods: {
    handleSelect(key, val) {
      this.searchFormData[key] = val
      this.$refs.mTable.setTableData(true)
    },
    // 查询物料
    searchTable(data, val) {
      console.log('val--', val)
      val === 1 && (this.searchFormData = {})
      this.$refs.mTable.setTableData()
    },
    resetData() {
      this.selectionData = []
      this.$refs.mTable.tableRefs().clearSelection()
    },
    echoData() {
      this.$nextTick(() => {
        // 数据回显
        this.materialsClassId = ''
        this.selectionData = this._.cloneDeep(this.selectMoulds)
        if (this.single) {
          this.$refs.mTable.tableRefs().setCurrentRow(this.selectionData[0])
        } else {
          this.selectionData.forEach(item => {
            this.$refs.mTable.tableRefs().toggleRowSelection(item, true)
          })
        }
      })
    },
    // 选中行复选框也会选中
    handleRowClick(row) {
      if (!row.selectable) {
        this.$refs.mTable.tableRefs().toggleRowSelection(row)
      }
    },
    fullScreenChange(val) {
      this.fullScreen = val
    },
    init() {
      this.searchFormData = {}
    },
    selectionChange(data) {
      if (this.single) {
        this.selectionData = []
        this.selectionData.push(data[data.length - 1])
        return false
      }
      if (Array.isArray(data)) {
        this.selectionData = data
      } else if (!data.selectable) {
        this.selectionData = []
        this.selectionData.push(data)
      }
    },
    // 获取模具数据
    async getTableData(condition, callback) {
      const { categoryIds, spec, name } = this.searchFormData
      const { page, order } = condition
      const res = await api.getSelMouldList({
        categoryIds: categoryIds?.join(','),
        spec,
        name,
        page: page.currentPage,
        limit: page.pageSize,
        sort: order
      })

      if (this.selectMoulds) {
        this.selectMoulds.forEach((item) => {
          res.records.forEach(t => {
            if (item.selectable && t.id === item.id) {
              t.selectable = true
            }
          })
        })
      }
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    submitForm(callback) {
      if (this.selectionData.length === 0) {
        this.$message.info('请至少选择一条模具')
        callback()
        return
      }
      callback()
      this.modalShow = false
      this.$emit('submitForm', this.selectionData)
    }
  }
}
</script>

<style lang="scss" scoped>
.materials-info {
  padding: 10px;
}
</style>
