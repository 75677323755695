import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/basicData/settings/private/wasteReason/'

export default {
  // 获取仓库建模-不分页
  getWasteReasonList(data) {
    return request.get(baseUrl + 'getWasteReasonList', data)
  },
  // 获取仓库建模-分页
  getWasteReasonListPage(data) {
    return request.get(baseUrl + 'getWasteReasonListPage', data)
  },
  // 新增仓库建模
  addWasteReason(data) {
    return request.post(baseUrl + 'addWasteReason', data)
  },
  // 修改仓库建模
  modifyWasteReason(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 复制仓库建模
  copyWasteReason(data) {
    return request.post(baseUrl + 'addWasteReason', data)
  },
  // 删除仓库建模
  delWasteReason(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  }
}
