import request from '@/utils/request'

const baseUrl1 = 'api/web/v1/basicData/production/private/processFlowClass/'
const baseUrl2 = 'api/web/v1/basicData/production/private/procedureGroup/'
const baseUrl3 = 'api/web/v1/inspection/private/inspectionMethod/'
const baseUrl4 = 'api/web/v1/inspection/private/inspectionStandard/'
const baseUrl5 = 'api/web/v1/inspection/private/inspectionProject/'
const baseUrl6 = 'api/web/v1/basicData/materials/private/materialsUnit/'
const baseUrl7 = 'api/web/v1/equipment/check/private/equipmentCycleCheckClass/'
const baseUrl8 = 'api/web/v1/equipment/maintain/private/equipmentMaintainClass/'
const baseUrl9 = 'api/web/v1/production/private/planType/'
const baseUr20 = 'api/web/v1/mold/category/private/'
export default {
  // 获取工艺路线类型
  getProcessTypeList(data) {
    return request.get(baseUrl1 + 'getProcessFlowClassList', data)
  },
  // 新增工艺路线类型
  addProcessType(data) {
    return request.post(baseUrl1 + 'addProcessFlowClass', data)
  },
  // 修改工艺路线类型
  modifyProcessType(data) {
    return request.put(baseUrl1 + 'updateOne', data)
  },
  // 删除工艺路线类型
  delProcessType(id) {
    return request.del(baseUrl1 + 'deleteOne', { id })
  },
  // 获取工序组
  getProcedureGroupList(data) {
    return request.get(baseUrl2 + 'getProcedureGroupList', data)
  },
  // 新增工序组
  addProcedureGroup(data) {
    return request.post(baseUrl2 + 'addProcedureGroup', data)
  },
  // 修改工序组
  modifyProcedureGroup(data) {
    return request.put(baseUrl2 + 'updateOne', data)
  },
  // 删除工序组
  delProcedureGroup(id) {
    return request.del(baseUrl2 + 'deleteOne', { id })
  },

  // 获取检验方法
  getInspectionMethodsList(data) {
    return request.get(baseUrl3 + 'getInspectionMethodList', data)
  },
  // 新增检验方法
  addInspectionMethods(data) {
    return request.post(baseUrl3 + 'addInspectionMethod', data)
  },
  // 修改检验方法
  modifyInspectionMethods(data) {
    return request.put(baseUrl3 + 'updateOne', data)
  },
  // 删除检验方法
  delInspectionMethods(id) {
    return request.del(baseUrl3 + 'deleteOne', { id })
  },

  // 获取检验标准
  getInspectionStandardList(data) {
    return request.get(baseUrl4 + 'getInspectionStandardList', data)
  },
  // 新增检验标准
  addInspectionStandard(data) {
    return request.post(baseUrl4 + 'addInspectionStandard', data)
  },
  // 修改检验标准
  modifyInspectionStandard(data) {
    return request.put(baseUrl4 + 'updateOne', data)
  },
  // 删除检验标准
  delInspectionStandard(id) {
    return request.del(baseUrl4 + 'deleteOne', { id })
  },
  // 获取检验项目
  getInspectionProjectList(data) {
    return request.get(baseUrl5 + 'getInspectionProjectList', data)
  },
  // 新增检验项目
  addInspectionProject(data) {
    return request.post(baseUrl5 + 'addInspectionProject', data)
  },
  // 修改检验项目
  modifyInspectionProject(data) {
    return request.put(baseUrl5 + 'updateOne', data)
  },
  // 删除检验项目
  delInspectionProject(id) {
    return request.del(baseUrl5 + 'deleteOne', { id })
  },
  // 获取单位
  getUnitList(data) {
    return request.get(baseUrl6 + 'getMaterialsUnitList', data)
  },
  // 新增单位
  addUnit(data) {
    return request.post(baseUrl6 + 'addMaterialsUnit', data)
  },
  // 修改单位
  modifyUnit(data) {
    return request.put(baseUrl6 + 'updateOne', data)
  },
  // 删除单位
  delUnit(id) {
    return request.del(baseUrl6 + 'deleteOne', { id })
  },
  // 获取巡检类别
  getEquipmentCycleCheckClassList(data) {
    return request.get(baseUrl7 + 'getEquipmentCycleCheckClassList', data)
  },
  // 新增巡检类别
  addEquipmentCycleCheckClass(data) {
    return request.post(baseUrl7 + 'addEquipmentCycleCheckClass', data)
  },
  // 修改巡检类别
  modifyEquipmentCycleCheckClass(data) {
    return request.put(baseUrl7 + 'updateOne', data)
  },
  // 删除巡检类别
  delEquipmentCycleCheckClass(id) {
    return request.del(baseUrl7 + 'deleteOne', { id })
  },
  // 获取保养类别
  getEquipmentMaintainClassList(data) {
    return request.get(baseUrl8 + 'getEquipmentMaintainClassList', data)
  },
  // 新增保养类别
  addEquipmentMaintainClass(data) {
    return request.post(baseUrl8 + 'addEquipmentMaintainClass', data)
  },
  // 修改保养类别
  modifyEquipmentMaintainClass(data) {
    return request.put(baseUrl8 + 'updateOne', data)
  },
  // 删除保养类别
  delEquipmentMaintainClass(id) {
    return request.del(baseUrl8 + 'deleteOne', { id })
  },
    // 新建计划类型
    addPlanType(data){
      return request.post(baseUrl9 + 'create',data)
  },
  // 修改计划类型
  modifyPlanType(data){
      return request.put(baseUrl9 + 'update',data)
  },
  //删除计划类型
  delPlanType(id){
      return request.del(baseUrl9 + 'delete',{id})
  },
  // 获取计划类型
  getPlanTypeList(data){
      return request.get(baseUrl9 + 'list',data)
  },
  // 获取模具类型
  getMouldTypeList(data) {
    return request.get(baseUr20 + 'list', data)
  },
  // 新增模具类型
  addMouldType(data) {
    return request.post(baseUr20 + 'create', data)
  },
  // 修改模具类型
  modifyMouldType(data) {
    return request.post(baseUr20 + 'name/edit', data)
  },
  // 删除模具类型
  delMouldType(id) {
    return request.post(baseUr20 + 'delete', { id })
  }
}
