var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tags-view-container" }, [
    _c(
      "div",
      {
        staticStyle: {
          display: "inline-flex",
          width: "100%",
          "align-items": "center"
        }
      },
      [
        _vm.showMove
          ? _c("img", {
              staticClass: "switch-left",
              attrs: {
                src: require("../../../assets/l_c_H_images/路径_左.png")
              },
              on: {
                click: function($event) {
                  return _vm.move(0)
                }
              }
            })
          : _vm._e(),
        _c("div", { staticClass: "tags" }, [
          _c(
            "div",
            {
              staticClass: "app-tag",
              staticStyle: { "justify-content": "flex-end" }
            },
            [
              _c(
                "transition-group",
                { key: "tag-group", attrs: { name: "breadcrumb" } },
                _vm._l(_vm.tags, function(item) {
                  return item.title !== "刷新"
                    ? _c(
                        "el-tag",
                        {
                          key: item.name,
                          class:
                            _vm.activeTag === item.name
                              ? "active-tag"
                              : "inactive-tag",
                          attrs: { size: "small", closable: "" },
                          on: {
                            close: function($event) {
                              return _vm.clearTag(item)
                            },
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.handleLink(item)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item.title) + " ")]
                      )
                    : _vm._e()
                }),
                1
              )
            ],
            1
          )
        ]),
        _vm.showMove
          ? _c("img", {
              staticClass: "switch-right",
              attrs: {
                src: require("../../../assets/l_c_H_images/路径_右.png")
              },
              on: {
                click: function($event) {
                  return _vm.move(1)
                }
              }
            })
          : _vm._e(),
        _c("div", { staticClass: "operate" }, [
          _c("div", { staticClass: "clear" }, [
            _c(
              "span",
              {
                staticStyle: { position: "relative", top: "-1px" },
                on: {
                  click: function($event) {
                    return _vm.clearTags()
                  }
                }
              },
              [_c("a", [_vm._v("清空标签")])]
            )
          ]),
          _c("div", { staticClass: "clear" })
        ]),
        _c("Header")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }