import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/basicData/production/private/procedure/'
const baseUrlN = 'api/web/v1/basicData/production/private/'
const baseUrlGroup = 'api/web/v1/basicData/production/private/procedureGroup/'
const baseUrlClass = 'api/web/v1/basicData/production/private/procedureClass/'

export default {
  // 获取工序-不分页
  getProcedureList(data) {
    return request.get(baseUrl + 'getProcedureList', data)
  },
  // 获取工序-分页
  getProcedureListPage(data) {
    return request.get(baseUrl + 'getProcedureListPage', data)
  },
  // 新增工序
  addProcedure(data) {
    return request.post(baseUrl + 'addProcedure', data)
  },
  // 修改工序
  modifyProcedure(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 复制工序
  copyProcedure(data) {
    return request.post(baseUrl + 'addProcedure', data)
  },
  // 删除工序
  delProcedure(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrlN + `${path}/${status}?id=${id}`)
  },
  // 工序详情
  getProcedureDetail(id) {
    return request.get(baseUrl + 'getProcedureDetail', { id })
  },
  synchronousToProcess(id, versionIds) {
    return request.get(baseUrl + 'syncToProcessFlow', { id, versionIds })
  },
  // 获取工序段-不分页
  getProcedureGroupList(data) {
    return request.get(baseUrlGroup + 'getProcedureGroupList', data)
  },
  // 获取工序段-分页
  getProcedureGroupListPage(data) {
    return request.get(baseUrlGroup + 'getProcedureGroupListPage', data)
  },
  // 新增工序段
  addProcedureGroup(data) {
    return request.post(baseUrlGroup + 'addProcedureGroup', data)
  },
  // 修改工序段
  modifyProcedureGroup(data) {
    return request.put(baseUrlGroup + 'updateOne', data)
  },
  // 复制工序段
  copyProcedureGroup(data) {
    return request.post(baseUrlGroup + 'addProcedureGroup', data)
  },
  // 删除工序段
  delProcedureGroup(data) {
    return request.del(baseUrlGroup + 'deleteOne', data)
  },
  createCode(params) {
    return request.get(baseUrl + 'getMaxProcedureCode', params)
  },
  // 工序id查找工艺
  getProcedureIdProcess(id) {
    return request.get(baseUrl + `getProcessFlowByProcedureId?id=${id}`)
  },
  // 获取工序分类-不分页
  getProcedureClassList(data) {
    return request.get(baseUrlClass + 'getProcedureClassList', data)
  },
  // 获取工序分类-分页
  getProcedureClassListPage(data) {
    return request.get(baseUrlClass + 'getProcedureClassListPage', data)
  },
  // 新增工序分类
  addProcedureClass(data) {
    return request.post(baseUrlClass + 'addProcedureClass', data)
  },
  // 修改工序分类
  modifyProcedureClass(data) {
    return request.put(baseUrlClass + 'updateOne', data)
  },
  // 删除工序分类
  delProcedureClass(data) {
    return request.del(baseUrlClass + 'deleteOne', data)
  },
  //获取工序字段
  getProcedureFieldList(data) {
    return request.get(baseUrl + `getProcedureFieldList?id=${id}`)
  },
}
