import request from '@/utils/requestForAsync'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
const baseUrl1 = 'api/web/v1/equipment/parameters/private/deviceParameters/'
const baseUrl2 = 'api/web/v1/equipment/parameters/private/dataSource/'
const baseUrl3 = 'api/web/v1/equipment/parameters/private/dataPoint/'

export default {
  // 获取缺陷项不分页
  getCallParamsList(data) {
    return request.get(baseUrl1 + 'getDeviceParametersList', data)
  },
  // 获取缺陷项-分页
  getCallParamsListPage(data) {
    return request.get(baseUrl1 + 'getDeviceParametersListPage', data)
  },
  // 新增缺陷项
  addCallParams(data) {
    return request.post(baseUrl1 + 'addDeviceParameters', data)
  },
  // 新增缺陷项
  copyCallParams(data) {
    return request.post(baseUrl1 + 'addDeviceParameters', data)
  },
  // 修改缺陷项
  modifyCallParams(data) {
    return request.put(baseUrl1 + 'updateOne', data)
  },
  // 删除缺陷项
  delCallParams(data) {
    return request.del(baseUrl1 + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get((path === 'callParams' ? baseUrl1 : baseUrl2) + `${status}?id=${id}`)
  },

  // 获取缺陷原因不分页
  getDataOriginList(data) {
    return request.get(baseUrl2 + 'getDataSourceList', data)
  },
  // 获取缺陷原因-分页
  getDataOriginListPage(data) {
    return request.get(baseUrl2 + 'getDataSourceListPage', data)
  },
  // 新增缺陷原因
  addDataOrigin(data) {
    return request.post(baseUrl2 + 'addDataSource', data)
  },
  // 新增缺陷原因
  copyDataOrigin(data) {
    return request.post(baseUrl2 + 'addDataSource', data)
  },
  getDataSourceAndDataPointList(params) {
    return request.get(baseUrl2 + 'getDataSourceAndDataPointList', params)
  },
  dataOriginDetail(id) {
    return request.get(baseUrl2 + 'getDataSourceAndDataPointList', {
      searchVal: Encrypt(JSON.stringify([{
        id: getUUid(),
        fieldName: 'id',
        fieldType: 'number',
        fieldValue: id,
        operator: 'eq'
      }]))
    })
  },
  // 修改缺陷原因
  modifyDataOrigin(data) {
    return request.put(baseUrl2 + 'updateOne', data)
  },
  // 删除缺陷原因
  delDataOrigin(data) {
    return request.del(baseUrl2 + 'deleteOne', data)
  },
  getDataOriginAndAgreement() {
    return request.get(baseUrl2 + 'getAdaptorAndProtocolMap')
  },
  addPointDataOrigin(data) {
    return request.post(baseUrl3 + 'addDataPoint', data)
  },
  modifyPointDataOrigin(data) {
    return request.put(baseUrl3 + 'updateOne', data)
  },
  delDataPoint(data) {
    return request.del(baseUrl3 + 'deleteOne', data)
  },
  batchDelDataPoint(data) {
    return request.del(baseUrl3 + 'deleteBatch', data)
  }
}
