<template>
  <div class="procedure-params-wrapper">
    <div v-for="(row, index) in paramsGroupList" :key="row.name" class="params-card">
      <div class="header flex-sbc">
        <div>参数组标题：{{ row.name }}</div>
        <div v-if="!readOnly" class="buttons">
          <el-button type="text" @click="modifyGroupName(row, index)">修改</el-button>
          <el-button type="text" @click="delGroup(index)">删除</el-button>
        </div>
      </div>
      <div class="body">
        <div v-if="row.formList.length" class="params">
          <MFormBuilder ref="paramsFormList" label-position="left" :form-list="row.formList" />
        </div>
        <div v-if="!readOnly" class="body-bottom flex-cc">
          <el-button type="primary" @click="addParams(index)">添加参数</el-button>
        </div>
      </div>
    </div>

    <el-button v-if="!readOnly" class="add-button flex-cc" @click="addGroup">
      <div class="flex-sbc">
        <img src="../../assets/information/procedure/新增.png" style="width: 22px">
        <span class="l10" style="color: #607FFF">新建参数分组</span>
      </div>
    </el-button>
    <MBlank v-if="readOnly && !paramsGroupList.length" />

    <!--添加参数组-->
    <MDialog v-model="groupVisible" class="params-group-dialog" width="400px" :title="dialogMessage[currentType]" @onOk="submitGroupForm">
      <MFormBuilder ref="groupForm" label-position="left" :form-data="groupFormData" :form-list="groupFormList" />
    </MDialog>

    <!--添加参数-->
    <MDialog v-model="paramsVisible" :title="dialogMessage[currentType]" @onOk="submitParamsForm">
      <MFormBuilder
        ref="paramsForm"
        style="padding-bottom: 10px"
        :form-data="paramsFormData"
        :form-list="paramsFormList"
        :custom-rules="customRules"
      />
    </MDialog>
    <!--新建工序参数-->
    <MDialog v-model="addParamsVisible" title="新增工序参数" @onOk="submitNewParams">
      <MFormBuilder
        ref="addParamsForm"
        style="padding-bottom: 10px"
        :form-data="addParamsFormData"
        :form-list="addParamsFormList"
      />
    </MDialog>
  </div>
</template>

<script>
import { paramsFormListObj, getFunctionList } from './form-list'
import paramsApi from '@/api/sets/params/procedure'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { paramsType } from '@/config/options.config'
import addParamsFormList from '@/views/setting/params/procedure/form-list'

export default {
  name: 'ProcedureParams',
  props: {
    groupList: {
      type: Array,
      default: () => ([])
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validateLength = (rule, value, callback) => {
      if (!value || !value.length) {
        return callback(new Error('可能值不能为空'))
      }
      callback()
    }
    const validateDefaultValue = (rule, value, callback) => {
      const { form } = this.$refs.paramsForm
      if ([2, 3].indexOf(form.type) === -1) {
        return callback()
      }
      const flagMultiple = value && Array.isArray(value) && !value.every(item => form.options.indexOf(item) !== -1)
      const flagSingle = value && !Array.isArray(value) && form.options.indexOf(value) === -1
      if (flagMultiple || flagSingle) {
        return callback(new Error('默认值应在可能值范围内'))
      }
      callback()
    }
    return {
      value10: [],
      options5: [],
      groupFormData: {},
      groupFormList: [
        {
          label: '参数组标题：',
          key: 'name',
          tagName: 'el-input',
          colSpan: 24,
          required: true,
          attrs: {
            placeholder: '请输入参数组标题'
          }
        }
      ],
      groupVisible: false,
      paramsGroupList: [],
      paramsVisible: false,
      paramsFormData: {},
      paramsFormList: [],
      addParamsVisible: false,
      addParamsFormData: {},
      addParamsFormList: [],
      currentType: 'add',
      currentIndex: [],
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      formOptions: {
        paramsList: []
      },
      dialogMessage: {
        'add': '新增',
        'modify': '修改',
        'del': '删除'
      },
      customRules: {
        options: [{ validator: validateLength, trigger: 'change' }],
        defaultValue: [{ validator: validateDefaultValue, trigger: 'change' }]
      }
    }
  },
  watch: {
    'groupList': {
      immediate: true,
      handler: function() {
        if (this.groupList) {
          this.initParamsGroup()
        }
      }
    },
    readOnly() {
      this.initParamsGroup()
    }
  },
  beforeMount() {
    getFunctionList({
      paramsNameChange: this.paramsNameChange,
      addNewParams: this.addNewParams
    })
  },
  mounted() {
    this.getParamsList()
  },
  methods: {
    async getParamsList(update = false) {
      const res = await paramsApi.getProcedureParamList(this.defaultSearch)
      if (res) {
        this.formOptions.paramsList = res
        if (update) {
          this.paramsFormList[0].children[0].options = res
        }
      }
    },
    initParamsGroup() {
      const newGroupList = []
      const copyGroupList = this._.cloneDeep(this.groupList)
      copyGroupList.forEach((item, index) => {
        const originFormList = this._.cloneDeep(item.formList)
        const formList = []
        item.formList.forEach((form, index2) => {
          this.currentIndex = [index, index2]
          formList.push(...this.formatFormItem(form, form.form))
        })
        newGroupList.push({ name: item.name, originFormList, formList })
      })
      this.paramsGroupList = newGroupList
    },
    addGroup() {
      this.currentType = 'add'
      this.groupFormData = {}
      this.groupVisible = true
    },
    modifyGroupName(row, index) {
      this.currentType = 'modify'
      this.currentIndex = [index]
      this.groupFormData = row
      this.groupVisible = true
    },
    delGroup(index) {
      this.paramsGroupList.splice(index, 1)
      this.$message.success('删除成功')
    },
    addNewParams() {
      this.addParamsFormList = addParamsFormList
      this.addParamsFormData = { isEnabled: 1 }
      this.addParamsVisible = true
    },
    paramsNameChange(val) {
      const current = this.formOptions.paramsList.find(item => item.id === val)
      paramsFormListObj[current.type][0].children[0].options = this.formOptions.paramsList
      if (this.$refs.paramsForm) {
        this.$refs.paramsForm.formRefs().clearValidate()
        this.$refs.paramsForm.setForm({
          type: current.type,
          defaultValue: current.type === 3 ? [] : '',
          options: []
        })
      }
      this.paramsFormList = paramsFormListObj[current.type]
    },
    addParams(index) {
      paramsFormListObj[0][0].children[0].options = this.formOptions.paramsList
      this.paramsFormList = paramsFormListObj[0]
      this.paramsFormData = {}
      this.currentType = 'add'
      this.currentIndex = [index]
      this.paramsVisible = true
    },
    copyOrModifyParams(groupIndex, type, form) {
      const formIndex = this.paramsGroupList[groupIndex].formList.findIndex(item => item.id === form.id)
      this.currentIndex = [groupIndex, formIndex]
      this.currentType = type
      const currentParamsName = this.paramsGroupList[groupIndex].formList[formIndex]
      this.paramsNameChange(currentParamsName.id)
      this.paramsFormData = form
      this.paramsVisible = true
    },
    delParams(groupIndex, type, form) {
      const originIndex = this.paramsGroupList[groupIndex].originFormList.findIndex(item => item.id === form.id)
      this.paramsGroupList[groupIndex].originFormList.splice(originIndex, 1)
      const formIndex = this.paramsGroupList[groupIndex].formList.findIndex(item => item.id === form.id)
      this.paramsGroupList[groupIndex].formList.splice(formIndex, form.repeatNumber + 1)
      this.$message.success('参数删除成功')
    },
    submitGroupForm(callback) {
      const { form } = this.$refs.groupForm
      this.$refs.groupForm.formRefs().validate(valid => {
        if (valid) {
          const current = this.paramsGroupList.find(item => item.name === form.name)
          if (current) {
            callback()
            return this.$message.info('参数组名称重复')
          }
          if (this.currentType === 'add') {
            this.paramsGroupList.push({
              name: form.name,
              originFormList: [],
              formList: []
            })
            callback(true)
            this.groupVisible = false
          }
          if (this.currentType === 'modify') {
            this.paramsGroupList[this.currentIndex[0]].name = form.name
            callback(true)
            this.groupVisible = false
          }
        } else {
          callback()
        }
      })
    },
    submitParamsForm(callback) {
      const { form } = this.$refs.paramsForm
      this.$refs.paramsForm.formRefs().validate(async(valid) => {
        if (valid) {
          const ids = this.paramsGroupList[this.currentIndex[0]].originFormList.map(item => item.id)
          if (ids.indexOf(form.id) !== -1 && this.currentType !== 'modify') {
            callback()
            return this.$message.info('参数名称已存在')
          }
          const formTem = await this.configParams()
          if (['add', 'copy'].indexOf(this.currentType) !== -1) {
            this.paramsGroupList[this.currentIndex[0]].originFormList
              .push(this._.cloneDeep(formTem))
            this.paramsGroupList[this.currentIndex[0]].formList
              .push(...this.formatFormItem(formTem, form))
            if (
              this.$refs.paramsFormList &&
              this.$refs.paramsFormList[this.currentIndex[0]] &&
              this.$refs.paramsFormList[this.currentIndex[0]].setSomeData) {
              this.$nextTick(this.$refs.paramsFormList[this.currentIndex[0]].setSomeData)
            }
            this.$message.success('参数新增成功')
            callback(true)
            this.paramsVisible = false
          }
          // 修改参数
          if (this.currentType === 'modify') {
            const originIndex = this.paramsGroupList[this.currentIndex[0]].originFormList
              .findIndex(item => item.id === form.id)
            this.paramsGroupList[this.currentIndex[0]].originFormList
              .splice(originIndex, 1, this._.cloneDeep(formTem))
            this.paramsGroupList[this.currentIndex[0]].formList
              .splice(this.currentIndex[1], this.paramsFormData.repeatNumber + 1, ...this.formatFormItem(formTem, form))
            this.$refs.paramsFormList[this.currentIndex[0]].setSomeData()
            this.$message.success('参数修改成功')
            callback(true)
            this.paramsVisible = false
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    async submitNewParams(callback) {
      const { form } = this.$refs.addParamsForm
      this.$refs.addParamsForm.formRefs().validate(async(valid) => {
        if (valid) {
          const body = {
            name: form.name,
            type: form.type,
            isEnabled: form.isEnabled,
            remark: form.remark
          }
          const res = await paramsApi.addProcedureParam(body)
          if (res) {
            await this.getParamsList(true)
            this.addParamsVisible = false
            callback(true)
          }
          callback(true)
        }
      })
    },
    configParams() {
      // 参数类型 0:数值 1:文本 2:单选 3:多选 4:附件 5:时间
      // 不同参数类型配置不同属性
      // 新增参数
      return new Promise(resolve => {
        const { form } = this.$refs.paramsForm
        const currentParamsType = paramsType.find(item => item.id === form.type)
        const currentParamsName = this.formOptions.paramsList.find(item => item.id === form.id)
        const formTem = currentParamsType.template
        Object.assign(formTem, {
          id: form.id,
          form,
          label: currentParamsName.name,
          key: currentParamsName.name,
          required: form.required,
          defaultValue: form.defaultValue,
          props: {
            ...formTem.props,
            disabled: form.readonly
          }
        })
        if ([0].indexOf(form.type) !== -1) {
          delete formTem.required
          formTem.defaultValue = Number(form.defaultValue)
          formTem.rules.push({
            required: form.required,
            type: 'number',
            message: `请输入${currentParamsName.name}`
          })
        }
        if ([2, 3].indexOf(form.type) !== -1) {
          formTem.children[0].options = form.options.map(item => ({
            label: item,
            value: item
          }))
        }
        if ([5].indexOf(form.type) !== -1) {
          formTem.rules.push({
            required: form.required,
            type: 'date',
            message: `请选择${currentParamsName.name}`
          })
          if (form.format) {
            formTem.props.type = form.format.indexOf('HH') !== -1 ? 'datetime' : 'date'
            formTem.props.format = form.format
          }
        }
        resolve(formTem)
      })
    },
    formatFormItem(formTem, form) {
      formTem.props && (formTem.props.disabled = this.readOnly)
      const arr = []
      const operateRenderList = []
      const operateList = [{ name: '复制', type: 'copy', fun: 'copyOrModifyParams' }, {
        name: '修改',
        type: 'modify',
        fun: 'copyOrModifyParams'
      }, { name: '删除', type: 'del', fun: 'delParams' }]
      const operateRenderTem = [
        {
          tagName: 'el-button',
          props: { type: 'text' },
          style: { width: 'auto' },
          children: []
        },
        {
          tagName: 'el-divider',
          props: { direction: 'vertical' },
          children: []
        }
      ]
      operateList.forEach((item, index) => {
        const copyTem = this._.cloneDeep(operateRenderTem)
        copyTem[0].innerText = item.name
        console.log('currentIndex:',this.currentIndex[0])
        let groupIndexTem = this.currentIndex[0]
        copyTem[0].on = {
          click: () => {
            this[item.fun](groupIndexTem, item.type, form)
          }
        }
        if (index === operateList.length - 1) {
          copyTem.pop()
        }
        operateRenderList.push(...copyTem)
      })
      // 图片不循环 仅设置最大允许上传数limit
      if (form.type === 4) {
        const copyFormTem = this._.cloneDeep(formTem)
        copyFormTem.key = `${formTem.label}${0}`
        Object.assign(copyFormTem.props, {
          limit: form.repeatNumber,
          onExceed: () => {
            this.$message.info(`最多可上传${form.repeatNumber}张图片`)
          }
        })
        arr.push({ ...copyFormTem, colSpan: 16 }, {
          tagName: 'div',
          colSpanAttrs: { classDom: 'operate' },
          colSpan: 8,
          children: this.readOnly ? [] : operateRenderList
        })
        return arr
      }
      for (let i = 0; i < form.repeatNumber; i++) {
        const copyFormTem = this._.cloneDeep(formTem)
        copyFormTem.key = `${formTem.label}${i}`
        if (i === 0) {
          arr.push({ ...copyFormTem, colSpan: 16 }, {
            tagName: 'div',
            colSpanAttrs: { classDom: 'operate' },
            colSpan: 8,
            children: this.readOnly ? [] : operateRenderList
          })
        } else {
          copyFormTem.label = ''
          arr.push({ ...copyFormTem, colSpan: 16 })
        }
      }
      return arr
    }
  }
}
</script>
<style lang="scss">
.procedure-params-wrapper {
  button {
    .el-icon-circle-plus-outline {
      color: #607FFF !important;
    }
  }
}

</style>
<style scoped lang="scss">
.procedure-params-wrapper {
  .params-card {
    border: 1px solid #ECECF7;
    margin-bottom: 20px;

    .header {
      width: 100%;
      height: 48px;
      padding: 0 20px;
      background: #ECECF7;
    }

    .body {
      width: 100%;

      .params {
        width: 555px;
        padding: 20px;
      }

      .body-bottom {
        width: 100%;
        height: 98px;
      }
    }

    ::v-deep {
      div[classdom='operate'] {
        .el-form-item__content {
          margin-left: 0 !important;
        }
      }
      .el-input, .el-upload, .el-input-number{
        margin-left: 10px;
      }
      .el-input-number .el-input {
        margin-left: 0;
      }
    }
  }

  .add-button {
    background: rgba(96, 127, 255, 0.1);
    width: 100%;
    border: 1px solid rgba(96, 127, 255, 1);
  }
}
.params-group-dialog {
  .form-builder-wrap {
    padding-bottom: 10px;
  }
}
</style>
