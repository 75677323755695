import request from '@/utils/requestForAsync'

export default {
  //查询个人代办
  getMyTaskListPage(params) {
    return request.post(`api/web/v1/flowable/private/flowable/getMyTaskListPage`, params)
  },
  //查询个人已办
  getMyFinishTaskListPage(params) {
    return request.post(`api/web/v1/flowable/private/flowable/getMyFinishTaskListPage`, params)
  },
  // 根据异常类型查询异常原因
  getAbnormalReasonArr(id) {
    return request.get(
      `api/web/v1/notification/private/abnormalReason/getAbnormalReasonByAbnormalTypeId?id=${id}`)
  },
  // 异常呼叫修改
  updateCall(params) {
    return request.post(`api/app/v1/abnormal/private/abnormalOrder/update`, params)
  },
//节点撤回
  callTaskCancel(taskId) {
    return request.put(`api/web/v1/flowable/private/flowable/returnPreviousNode?taskId=${taskId}`)
  },
  // 通过/驳回按钮  流程操作
  btnProcess(params) {
    return request.post(`api/web/v1/flowable/private/flowable/completeTask`, params)
  }
}
