import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/production/private/projectplan/'
const baseUrl1 = 'api/web/v1/production/private/'

export default {
    // 获取方案列表
    getPlanSchemeListPage(data) {
        return request.get(baseUrl + 'listPage', data)
    },
    // 新增计划方案
    addPlanScheme(data){
        return request.post(baseUrl + 'create', data)
    },
    // 修改计划方案
    modifyPlanScheme(data){
        return request.put(baseUrl + 'update', data)
    },
    // 获取节点计划下拉列表
    getNodeTimeTypeList(data){
        return request.get(baseUrl + 'nodeTimeTypeList', data)
    },
    // 获取计划方案基本信息
    getPlanSchemeDetail(data) {
        return request.get(baseUrl + 'getOne', data)
    },
    // 删除计划方案
    delPlanScheme(data) {
        return request.del(baseUrl + 'delete', data)
    },
    // 计划方案设为默认
    setPlanSchemeDefault(data){
        return request.put(baseUrl + 'changeIsDefault', data)
    },
    // 获取节点信息
    getPlanSchemeNodeListPage(data) {
        return request.get(baseUrl + 'nodeList', data)
    },
    // 获取计划类型
    getPlanType(data){
        return request.get(baseUrl1 + 'planType/list',data)
    },
    // 新增计划方案节点
    addPlanSchemeNode(data) {
        return request.post(baseUrl + 'nodeCreate',data)
    },
    // 修改计划方案节点
    modifyPlanSchemeNode(data) {
        return request.put(baseUrl + 'nodeUpdate',data)
    },
    // 删除计划方案节点
    delPlanSchemeNode(data) {
        return request.del(baseUrl + 'nodeDelete',data)
    }
}
