/** write by luomingzhi */
export const render = (h, context) => {
  return h('el-form', {
    ref: 'form',
    props: {
      disabled: context.props.disabled,
      labelWidth: 'auto',
      labelPosition: context.props.this.labelPosition,
      model: context.props.this.form,
      rules: context.props.this.rules || {}
    }
  }, [
    h('el-row', {
      props: {
        gutter: 20
      }
    }, colsDom(h, context))
  ])
}

const colsDom = (h, context) => {
  const colsDom = []
  const $this = context.props.this
  context.props.this.formList.forEach(item => {
    const attrs = {}
    const required = $this.rules &&
      $this.rules[item.key] &&
      $this.rules[item.key][0] &&
      $this.rules[item.key][0].required
    if (required || item.required) {
      attrs.required = 'required'
    }
    if (item.colSpanAttrs) {
      Object.assign(attrs, item.colSpanAttrs)
    }
    colsDom.push(h('el-col', {
      props: {
        span: item.colSpan || context.props.colSpan
      },
      attrs: attrs
    }, formItemDom(h, context, item)))
  })
  return colsDom
}

const formItemDom = (h, context, item) => {
  if (item.hidden) return []
  const labelTip = []
  if (item.tagName === 'labelTitle') {
    const children = item.children
    const options = []
    if (children && children.customSlots) {
      children.customSlots.forEach(item => {
        options.push(context.parent.$slots[item])
      })
    }
    return [h('div', {
      domProps: { className: 'label-title' }
    }, item.label), ...options]
  }
  if (item.labelTip) {
    labelTip.push(h('template', { slot: 'label' }, [h('span', { domProps: { className: 'custom-label' }}, item.label), h('el-tooltip', { props: { effect: 'dark', content: item.labelTip, placement: 'top' }, style: { marginLeft: '5px' }}, [h('span', { domProps: { className: 'el-icon-question' }})])]))
  }
  return [h('el-form-item', {
    props: {
      label: item.label,
      prop: item.key
    }
  }, [...labelTip, ...formItemContentDom(h, context, item)])]
}

export const formItemContentDom = (h, context, item) => {
  const $this = context.props.this
  let defaultProps = {}
  const tags = ['el-select', 'el-date-picker']
  if (item.hidden) return []
  if (tags.indexOf(item.tagName) !== -1) {
    defaultProps = {
      clearable: true,
      filterable: true
    }
  }

  // 给下拉框 日期增加删除功能
  return [
    h(item.tagName, {
      props: Object.assign({
        value: $this && $this.form && $this.form[item.key],
        placeholder: placeholder(item),
        ...defaultProps
      }, item.props || {}),
      domProps: Object.assign({}, item.domProps || {}),
      attrs: Object.assign({
        placeholder: placeholder(item)
      }, item.attrs || {}),
      style: Object.assign({
        width: '100%'
      }, item.style || {}),
      directives: item.directives || [],
      on: Object.assign({
        input: (val) => {
          if ($this && $this.form) $this.$set($this.form, item.key, val)
          if (item.on) {
            Object.keys(item.on).forEach(eventName => {
              if (typeof eventName === 'function') {
                eventName && eventName()
              }
            })
          }
        }
      }, item.on || {})
    }, tagChildren(h, context, item))
  ]
}

export const tagChildren = (h, context, parent) => {
  const $this = context.props.this
  let options = []
  if (parent.innerText) {
    options.push(h('span', { style: { margin: 0, padding: 0, display: 'inline-block' }}, parent.innerText))
  }
  if (parent.children) {
    parent.children.forEach(children => {
      if (children && !children.hidden) {
        if (children && children.tagName && children.options) {
          if (!children.props) children.props = {}
          if (!children.attrs) children.attrs = {}
          if (!children.domProps) children.domProps = {}
          options = children.options.map(option => {
            const props = {}
            const domProps = {}
            // 自定义拼接label
            if (children.props['configLabel']) {
              const labelArr = children.props['configLabel'].split('-')
              const separator = children.props.separator || '-'
              option[children.props['configLabel']] = `${option[labelArr[0]]} ${separator} ${option[labelArr[1]]}`
            }
            Object.keys(children.props).forEach(prop => {
              if (children.props[prop] in option) {
                props[prop] = option[children.props[prop]]
              } else {
                props[prop] = children.props[prop]
              }
            })
            Object.keys(children.domProps).forEach(prop => {
              if (children.domProps[prop] in option) {
                domProps[prop] = option[children.domProps[prop]]
              } else {
                domProps[prop] = children.domProps[prop]
              }
            })
            const isFunction = (f) => Object.prototype.toString.call(f) == '[object Function]'
            return h(children.tagName, {
              props: props,
              domProps: domProps,
              attrs: Object.assign({}, children.attrs || {}),
              style: Object.assign({}, children.style || {}),
              on: Object.assign({}, children.on || {}),
              directives: children.directives || []
            }, children.children ? formItemContentDom(h, context, children)
              : isFunction(children.innerValue)
                ? children.innerValue(option)
                : option[children.innerValue]
            )
          })
        }
        if (children && children.customSlots) {
          children.customSlots.forEach(item => {
            options.push(context.parent.$slots[item])
          })
        }
        if (children && children.slots) {
          children.slots.forEach(item => {
            options.push(h('div', {
              slot: item,
              style: { display: 'inline-block' }
            }, context.parent.$slots[item]))
          })
        }
        if (children && children.children) {
          options.push(...formItemContentDom(h, context, children))
        }
      }
    })
  }
  return options
}

const placeholder = ({ tagName, label, props }) => {
  const str = props?.placeholder
  switch (tagName) {
    case 'el-input': return str || `请输入${label}`
    default: return str || `请选择${label}`
  }
}
export default render

