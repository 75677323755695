import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/equipment/private/equipment/'
const repairUrl = 'api/web/v1/equipment/private/equipmentRepairRecord/'
const baseUrl2 = 'api/web/v1/field/facility/private/equipment/'

export default {
  // 获取设备类列表
  getEquipmentClassList() {
    return request.get(`/api/web/v1/basicData/private/equipmentClass/getEquipmentClassList`)
  },
  // 获取设备-不分页
  getEquipmentList(data) {
    return request.get(baseUrl + 'getEquipmentList', data)
  },
  // 获取设备-分页
  getEquipmentListPage(data) {
    return request.get(baseUrl + 'getEquipmentListPage', data)
  },
  // 新增设备
  addEquipment(data) {
    return request.post(baseUrl + 'addEquipment', data)
  },
  // 新增设备
  copyEquipment(data) {
    return request.post(baseUrl + 'addEquipment', data)
  },
  // 修改设备
  modifyEquipment(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除设备
  delEquipment(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  getEquipmentDetail(data) {
    return request.get(baseUrl + 'getEquipmentDetail', data)
  },
  // 新建维保任务
  addRepairRecord(data) {
    return request.post(repairUrl + 'addEquipmentRepairRecord', data)
  },
  // 获取维保记录--分页
  getRepairRecordListPage(data) {
    return request.get(repairUrl + 'getEquipmentRepairRecordListPage', data)
  },
  createCode(params) {
    return request.get(baseUrl + 'getMaxEquipmentCode', params)
  },
  // 获取生产设备下的售后设备
  getAfterSaleEquByEqu(equId){
    const url = `${baseUrl2}getEquipmentFacilityListPage?equipmentId=${equId}&page=1&limit=200`
    return request.get(url);
  },
  // 获取生产设备下的售后设备记录
  getAfterSaleEquRecByEqu(equId){
    const url = `${baseUrl2}getEquipmentFacilityRecordListPage?equipmentId=${equId}&page=1&limit=200`
    return request.get(url);
  }
}
