<template>
  <div class="kr-collapse">
    <el-collapse v-model="activeNames">
      <el-collapse-item title name="1">
        <template slot="title">
          <span>页面参数</span>
        </template>
        <page :get-template-list="getTemplateList" @selectTitle="selectTitle" @addTemplate="addTemplate" @delTemplate="delTemplate" />
      </el-collapse-item>
      <el-collapse-item name="2">
        <template slot="title">
          <span>样式</span>
        </template>
        <appearance class="pd-l-10" />
      </el-collapse-item>
      <el-collapse-item name="3">
        <template slot="title">
          <span>字段</span>
        </template>
        <options-single />
      </el-collapse-item>
      <el-collapse-item name="4">
        <template slot="title">
          <span>自定义</span>
        </template>
        <options />
      </el-collapse-item>
      <el-collapse-item name="5">
        <template slot="title">
          <span>已加字段</span>
        </template>
        <layers ref="activeLayers" />
      </el-collapse-item>
    </el-collapse>
    <MDialog
      v-model="addModel"
      :append-to-body="true"
      title="新建打印模板"
      @onOk="addparmases"
    >
      <div class="model-tit">
        <span class="tit">模板名称：</span>
        <el-input v-model="templateName" class="ipt" />
      </div>
    </MDialog>
  </div>

</template>

<script>
import page from './page.vue'
import style from './style.vue'
import options from './options/index.vue'
import optionsSingle from './optionsSingle/index.vue'
import layers from './layers.vue'
import vptd from '../../mixins/vptd'
import api from '@/api/print'
import { bbelcWidgets, defaultTemp, widgets } from '@/config/defaultTemp'

export default {
  components: {
    page,
    appearance: style,
    options,
    layers,
    optionsSingle
  },
  mixins: [vptd],
  props: {
    getTemplateLists: {
      type: Array,
      default: []
    },
    templateIds: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      bbelcWidgets,
      defaultTemp,
      widgets,
      activeNames: ['1', '2', '3', '4', '5'],
      addModel: false,
      templateName: '',
      getTemplateList: [],
      templateId: undefined
    }
  },
  computed: {
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  watch: {
    getTemplateLists(val) {
      if (val) {
        this.getTemplateList = val
      }
    },
    templateIds(val) {
      if (val) {
        this.templateId = val
      }
    }
  },
  mounted() {
    this.templateId = this.templateIds
    this.getTemplate()
  },
  methods: {
    async getTemplate() {
      const res = await api.getPrintTemplateList()
      if (res) {
        this.getTemplateList = res
        localStorage.setItem('getTemplateList', JSON.stringify(res))
      }
    },
    addTemplate() {
      this.templateName = ''
      this.addModel = true
    },
    async delTemplate() {
      const that = this
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delPrintTemplate(this.templateId ? this.templateId : 1)
        if (res) {
          that.$message.success('删除成功')
          const data = that.getTemplateList.filter(item => item.id == 1)
          const widgets = this.memberCode === 'bbelc' ? this.bbelcWidgets : this.widgets
          that.$vptd.dispatch('designerInit', {
            tempValue: that._.cloneDeep(JSON.parse(data[0].info)),
            options: widgets
          })
          that.getTemplate()
          this.$emit('selectTitle', 1)
          this.$refs.activeLayers.activeLayer('', { uuid: -1 })
        }
      })
    },
    async addparmases(done) {
      console.log('当前id>', this.templateId)
      done()
      if (this.templateName === '') {
        this.$message.error('请输入模板名称')
        return false
      }
      this.templateId = this.templateId ? this.templateId : 1
      const tempList = this.getTemplateList.filter(item => item.id == this.templateId)
      const info = JSON.parse(tempList[0].info)
      info.title = this.templateName

      const data = {
        name: this.templateName,
        type: 1,
        info: JSON.stringify(info)
      }
      const res = await api.addPrintTemplate(data)
      if (res) {
        this.addModel = false
        await this.getTemplate()
        // let id = this.getTemplateList.filter(item => item.name == this.templateName)
        this.selectTitle(this.templateName)
        this.$refs.activeLayers.activeLayer('', { uuid: -1 })
      }
    },
    selectTitle(title) {
      const data = this.getTemplateList.filter(item => item.name == title)
      const widgets = this.memberCode === 'bbelc' ? this.bbelcWidgets : this.widgets
      this.$vptd.dispatch('designerInit', {
        tempValue: this._.cloneDeep(JSON.parse(data[0].info)),
        options: widgets
      })
      this.templateId = data[0].id
      this.$emit('selectTitle', data[0].id)
    }
  }
}
</script>
<style lang="scss" scoped>
.model-tit {
  .tit {
    font-size: 14px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    color: #4a4f76;
  }
  .ipt {
    width: 250px;
  }
}
</style>
