var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !this.$route.meta.hiddenSiderbar
    ? _c(
        "div",
        { staticClass: "layout-container" },
        [
          _c("LockScreen"),
          _c("div", { staticClass: "body-container" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.fullScreen,
                    expression: "!fullScreen"
                  }
                ],
                staticClass: "left",
                attrs: { id: "layoutLeft" }
              },
              [_c("MSideBar")],
              1
            ),
            _c(
              "div",
              { staticClass: "right", style: { width: _vm.rightWidth + "px" } },
              [
                _c("div", { staticClass: "header" }, [_c("Header")], 1),
                _c("div", { staticClass: "content" }, [_c("AppMain")], 1)
              ]
            )
          ]),
          _c("Navigation")
        ],
        1
      )
    : this.$route.meta.activeMenu == "applyMarket"
    ? _c("div", { staticClass: "layout-container" }, [_c("applyMarket")], 1)
    : this.$route.meta.activeMenu == "applyDetails"
    ? _c("div", { staticClass: "layout-container" }, [_c("applyDetails")], 1)
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }