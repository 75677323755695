/** write by luomingzhi */

export const sColumns = [
  {
    prop: 'id',
    label: 'ID',
    sortable: true,
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'code',
    label: '工艺编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '工艺名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'areas',
    label: '适用产线',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]

export const columns = [
  {
    type: 'selection',
    fixed: 'left',
    width: 80,
    reserveSelection: true
  },
  {
    prop: 'id',
    label: 'ID',
    sortable: true,
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'code',
    label: '工艺编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '工艺名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'areas',
    label: '适用产线',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
