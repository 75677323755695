var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "el-transfer" },
    [
      _c(
        "transfer-panel",
        _vm._b(
          {
            ref: "leftPanel",
            attrs: {
              data: _vm.sourceData,
              title: _vm.titles[0] || _vm.t("el.transfer.titles.0"),
              "default-checked": _vm.leftDefaultChecked,
              setLabelMethod: _vm.setLabelLMethod,
              placeholder:
                _vm.filterPlaceholder || _vm.t("el.transfer.filterPlaceholder")
            },
            on: { "checked-change": _vm.onSourceCheckedChange }
          },
          "transfer-panel",
          _vm.$props,
          false
        ),
        [
          _vm._t("left-footer"),
          _c(
            "div",
            { attrs: { slot: "bodyHeader" }, slot: "bodyHeader" },
            [_vm._t("leftBodyHeader")],
            2
          )
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "el-transfer__buttons" },
        [
          _c(
            "el-button",
            {
              class: [
                "el-transfer__button",
                _vm.hasButtonTexts ? "is-with-texts" : ""
              ],
              attrs: {
                type: "primary",
                disabled: _vm.rightChecked.length === 0
              },
              nativeOn: {
                click: function($event) {
                  return _vm.addToLeft($event)
                }
              }
            },
            [
              _c("i", { staticClass: "el-icon-arrow-left" }),
              _vm.buttonTexts[0] !== undefined
                ? _c("span", [_vm._v(_vm._s(_vm.buttonTexts[0]))])
                : _vm._e()
            ]
          ),
          _c(
            "el-button",
            {
              class: [
                "el-transfer__button",
                _vm.hasButtonTexts ? "is-with-texts" : ""
              ],
              attrs: {
                type: "primary",
                disabled: _vm.leftChecked.length === 0
              },
              nativeOn: {
                click: function($event) {
                  return _vm.addToRight($event)
                }
              }
            },
            [
              _vm.buttonTexts[1] !== undefined
                ? _c("span", [_vm._v(_vm._s(_vm.buttonTexts[1]))])
                : _vm._e(),
              _c("i", { staticClass: "el-icon-arrow-right" })
            ]
          )
        ],
        1
      ),
      _c(
        "transfer-panel",
        _vm._b(
          {
            ref: "rightPanel",
            attrs: {
              data: _vm.targetData,
              title: _vm.titles[1] || _vm.t("el.transfer.titles.1"),
              "default-checked": _vm.rightDefaultChecked,
              setLabelMethod: _vm.setLabelRMethod,
              placeholder:
                _vm.filterPlaceholder || _vm.t("el.transfer.filterPlaceholder")
            },
            on: { "checked-change": _vm.onTargetCheckedChange }
          },
          "transfer-panel",
          _vm.$props,
          false
        ),
        [
          _vm._t("right-footer"),
          _c(
            "div",
            { attrs: { slot: "bodyHeader" }, slot: "bodyHeader" },
            [_vm._t("rightBodyHeader")],
            2
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }