import request from '@/utils/requestForAsync'
import { getUrl } from '@/utils/index'
const baseUrl = 'api/web/v1/production/kiki/private/'

export default {
  // 获取工单分页
  getPlanpage(data) {
    return request.get(baseUrl + 'produceWorkOrder/getProduceWorkOrderListPage', data)
  },
  // 获取不分页
  getPlanpageno(data) {
    return request.get(baseUrl + 'produceWorkOrder/getProduceWorkOrderList', data)
  },
  // 删除工单
  deletePlan(data) {
    return request.del(baseUrl + 'produceWorkOrder/deleteOne', data)
  },
  // 获取工单详情
  getPlanDetail(id) {
    return request.get(baseUrl + `produceWorkOrder/getProduceWorkOrderDetail?id=${id}`)
  },
  // 批量删除
  deleteOrders(data) {
    return request.post(baseUrl + 'produceWorkOrder/deleteBatch', data)
  },
  // 所属订单列表不分页
  getOrderpage(data) {
    return request.get(baseUrl + 'produceOrder/getProduceOrderList', data)
  },
  // 所属订单列表分页
  getOrderpages(data) {
    return request.get(baseUrl + 'produceOrder/getProduceOrderListPage', data)
  },
  // 所属工序不分页
  getOperatorPage(data) {
    return request.get('api/web/v1/basicData/production/private/procedure/getProcedureList', data)
  },
  // 获取投产记录
  getReceiveMaterials(data) {
    return request.get(baseUrl + 'materialsReceiveRecord/getMaterialsReceiveRecordList', data)
  },
  // 获取报工记录不分页
  getReportWork(data) {
    return request.get(baseUrl + 'reportWorkRecord/getReportWorkRecordList', data)
  },
  // 获取报废记录不分页
  getReportWaste(data) {
    return request.get(baseUrl + 'reportWasteRecord/getReportWasteRecordList', data)
  },
  // 批量且删除批次
  deleteBatchNumber(data) {
    return request.post(baseUrl + 'produceWorkOrderBatchNumberMapping/deleteBatch', data)
  },
  // 添加批次
  addBatchNumber(data) {
    return request.post(baseUrl + 'produceWorkOrderBatchNumberMapping/addProduceWorkOrderBatchNumberMappingBatch', data)
  },
  // 获取返修
  getRepairDetail(data) {
    return request.get(baseUrl + 'produceWorkOrder/getRepairBatchList', data)
  },
  // 确认返修
  saveRepairDetail(data) {
    return request.post(baseUrl + 'produceWorkOrder/confirmRepair', data)
  },
  // 撤回报工
  cancelReportRecord(data) {
    return request.del(baseUrl + 'reportWorkRecord/cancelReportWorkRecord', data)
  },
  // 撤回进站
  cancelArrivals(data) {
    return request.del(baseUrl + 'produceWorkOrderTask/cancelCheckIn', data)
  },
  // 撤回出站
  cancelDepartures(data) {
    return request.del(baseUrl + 'produceWorkOrderTask/cancelCheckOut', data)
  },
  // 添加工单
  addWorkOrder(data) {
    return request.put(baseUrl + 'produceOrder/addWorkOrderToOrder', data)
  },
  // 取消工单
  cancelWorkOrder(data) {
    return request.put(baseUrl + 'produceOrder/removeWorkOrderFromOrder', data)
  },
  // 暂停工单
  pauseWorkOrder(data) {
    return request.put(baseUrl + `produceWorkOrder/pause?ids=${data}`)
  },
  // 恢复工单
  startWorkOrder(data) {
    return request.put(baseUrl + `produceWorkOrder/replay?ids=${data}`)
  },
  // 获取拆单分页
  getProduceDivisionRecordListPage(data) {
    return request.get(baseUrl + 'produceDivisionRecord/getProduceDivisionRecordListPage', data)
  },
  // 获取拆单不分页
  getProduceDivisionRecordList(data) {
    return request.get(baseUrl + 'produceDivisionRecord/getProduceDivisionRecordList', data)
  },
  // 获取减产分页
  getProduceReductionRecordListPage(data) {
    return request.get(baseUrl + 'produceReductionRecord/getProduceReductionRecordListPage', data)
  },
  // 获取减产不分页
  getProduceReductionRecordList(data) {
    return request.get(baseUrl + 'produceReductionRecord/getProduceReductionRecordList', data)
  },
  // 获取工单列表所有工序-工艺路线
  getProduceProcessList(data) {
    return request.get(baseUrl + 'produceWorkOrder/getAllWorkOrderTask', data)
  },
  // 获取工单列表工序任务列表
  getProduceAllLists(data) {
    return request.get(baseUrl + 'produceWorkOrder/getWorkOrderTaskMap', data)
  },
  // 首页获取工单
  getWorkData(params) {
    return request.get(baseUrl + `produceWorkOrder/getProduceWorkOrderListByLine${getUrl(params)}`)
  },
  // 唯一编码/模具名称/规格搜索模具列表
  getMouldList(data) {
    return request.get(`api/web/v1/mold/instance/private/candidate`, data)
  },
  // 强制结束工单
  forceCloseWorkOrder(id) {
    return request.put('api/web/v1/production/kiki/private/produceWorkOrder/close?id=' + id)
  },
  // 工步记录
  getStepList(data) {
    return request.get(`api/web/v1/production/procedure/step/private/workorder/detail`, data)
  },
  // 工艺文件
  getFileList(data) {
    return request.get(`api/web/v1/processfile/private/produceWorkOrder/detail`, data)
  },
  //工位任务映射
  stationMapping(data) {
    return request.post(`api/web/v1/production/kiki/private/produceWorkOrderTaskLockStationMapping/addOrUpdateProduceWorkOrderTaskLockStationMappingBatch`, data)
  },
// 获取流程卡信息
getProductProcessCard(data) {
  return request.get(`api/web/v1/production/kiki/private/produceWorkOrder/getProductProcessCard`, data)
},
// 获取过程检信息
getProcessInspectionReport(data) {
  return request.get(`api/web/v1/production/kiki/private/produceWorkOrder/getProcessInspectionReport`, data)
},
// 获取成品检信息
getFinishProductInspectionReport(data) {
  return request.get(`api/web/v1/production/kiki/private/produceWorkOrder/getFinishProductInspectionReport`, data)
},
// 获取放行单信息
getPassReport(data) {
  return request.get(`api/web/v1/production/kiki/private/produceWorkOrder/getPassReport`, data)
},
}
