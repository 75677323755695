import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/production/kiki/private/repairRecord/'

export default {
  // 获取返修记录分页
  getRepairRecord(data) {
    return request.get(baseUrl + 'getRepairRecordListPage', data)
  },
  // 获取返修记录不分页
  getNoRepairRecord(data) {
    return request.get(baseUrl + 'getRepairRecordList', data)
  },
  // 获取返修记录详情
  getRepairRecordDetail(data) {
    return request.get(baseUrl + 'getRepairRecordDetail', data)
  }
}
