import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/authority/private/Organization/'
const repairUrl = 'api/web/v1/authority/private/organizationType/'

export default {
  // 获取组织架构列表（不分页）
  getOrganizationList(data) {
    return request.get(baseUrl + 'getOrganizationList', data)
  },
  // 获取组织架构列表（分页）
  getOrganizationListPage(data) {
    return request.get(baseUrl + 'getOrganizationListPage', data)
  },
  // 新建组织架构
  addOrganization(data) {
    return request.post(baseUrl + 'addOrganization', data)
  },
  // 修改组织架构
  modifyOrganization(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除组织架构
  delOrganization(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 获取组织架构详情
  getOrganizationDetail(data) {
    return request.get(baseUrl + 'getOrganizationDetail', data)
  },

  // 获取组织类型列表（不分页）
  getOrganizationTypeList(data) {
    return request.get(repairUrl + 'getOrganizationTypeList', data)
  },
  // 获取组织类型列表（分页）
  getOrganizationTypeListPage(data) {
    return request.get(repairUrl + 'getOrganizationTypeListPage', data)
  },
  // 新建组织类型
  addOrganizationType(data) {
    return request.post(repairUrl + 'addOrganizationType', data)
  },
  // 修改组织类型
  modifyOrganizationType(data) {
    return request.put(repairUrl + 'updateOne', data)
  },
  // 删除组织类型
  delOrganizationType(data) {
    return request.del(repairUrl + 'deleteOne', data)
  },
  // 获取组织类型详情
  getOrganizationTypeDetail(data) {
    return request.get(repairUrl + 'getOrganizationTypeDetail', data)
  }
}
