import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/basicData/materials/private/'

export default {
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${path}/${status}?id=${id}`)
  },
  // 获取物料单位-不分页
  getMaterialsUnitList(data) {
    return request.get(baseUrl + 'materialsUnit/getMaterialsUnitList', data)
  },
  // 获取物料单位-分页
  getMaterialsUnitListPage(data) {
    return request.get(baseUrl + 'materialsUnit/getMaterialsUnitListPage', data)
  },
  // 新增物料单位
  addMaterialsUnit(data) {
    return request.post(baseUrl + 'materialsUnit/addMaterialsUnit', data)
  },
  copyMaterialsUnit(data) {
    return request.post(baseUrl + 'materialsUnit/addMaterialsUnit', data)
  },
  // 修改物料单位
  modifyMaterialsUnit(data) {
    return request.put(baseUrl + 'materialsUnit/updateOne', data)
  },
  // 删除物料单位
  delMaterialsUnit(data) {
    return request.del(baseUrl + 'materialsUnit/deleteOne', data)
  },
  // 获取物料类型-不分页
  getMaterialsTypeList(data) {
    return request.get(baseUrl + 'materialsType/getMaterialsTypeList', data)
  },
  // 获取物料类型-分页
  getMaterialsTypeListPage(data) {
    return request.get(baseUrl + 'materialsType/getMaterialsTypeListPage', data)
  },
  // 新增物料类型
  addMaterialsType(data) {
    return request.post(baseUrl + 'materialsType/addMaterialsType', data)
  },
  copyMaterialsType(data) {
    return request.post(baseUrl + 'materialsType/addMaterialsType', data)
  },
  // 修改物料类型
  modifyMaterialsType(data) {
    return request.put(baseUrl + 'materialsType/updateOne', data)
  },
  // 删除物料类型
  delMaterialsType(data) {
    return request.del(baseUrl + 'materialsType/deleteOne', data)
  },

  // 获取物料类型-不分页
  getMaterialsQualityList(data) {
    return request.get(baseUrl + 'materialsQuality/getMaterialsQualityList', data)
  },
  // 获取物料类型-分页
  getMaterialsQualityListPage(data) {
    return request.get(baseUrl + 'materialsQuality/getMaterialsQualityListPage', data)
  },
  // 新增物料类型
  addMaterialsQuality(data) {
    return request.post(baseUrl + 'materialsQuality/addMaterialsQuality', data)
  },
  copyMaterialsQuality(data) {
    return request.post(baseUrl + 'materialsQuality/addMaterialsQuality', data)
  },
  // 修改物料类型
  modifyMaterialsQuality(data) {
    return request.put(baseUrl + 'materialsQuality/updateOne', data)
  },
  // 删除物料类型
  delMaterialsQuality(data) {
    return request.del(baseUrl + 'materialsQuality/deleteOne', data)
  },
  // 获取物料属性-分页
  getMaterialsAttributeListPage(data) {
    return request.get(baseUrl + 'materialsAttribute/getMaterialsAttributeListPage', data)
  },
  // 获取物料属性-不分页
  getMaterialsAttributeList(data) {
    return request.get(baseUrl + 'materialsAttribute/getMaterialsAttributeList', data)
  },
  // 修改物料属性
  modifyMaterialsAttribute(data) {
    return request.put(baseUrl + 'materialsAttribute/updateOne', data)
  },
  // 获取物料bom-所有
  getMaterialsBomList(materialsId, requiredQuantity) {
    return request.get(baseUrl + `materialsBom/getMaterialsBomDetail?requiredQuantity=${requiredQuantity}&materialsId=${materialsId}`)
  },
  // 获取物料bom-一层
  getMaterialsBomSingleList(materialsId) {
    return request.get(baseUrl + `materialsBom/getMaterialsBomDetailWithoutNest?materialsId=${materialsId}`)
  },
  // 修改物料bom
  modifyMaterialsBom(data) {
    return request.post(baseUrl + 'materialsBom/updateMaterialsBom', data)
  }
}
