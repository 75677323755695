<template>
  <el-popover
    v-model="inputVisible"
    placement="bottom"
    width="250"
    trigger="click"
    popper-class="type-popover"
  >
    <div v-if="type === 'common'">
      <div v-if="list.length > 0" class="content-container">
        <div
          v-for="item in list"
          :key="item[optionsKey.value]"
          :label="item[optionsKey.label]"
          :value="item[optionsKey.value]"
          class="selectType"
          :style="{
            color: currentIdx === item[optionsKey.label] ? '#607FFF' : '#606266'
          }"
        >
          <span class="name-tit" @click="selectName(item, 'change')">{{ item[optionsKey.label] }}</span>
          <el-button type="text" icon="el-icon-edit" style="font-size: 16px;z-index: 100000" @click="modifyTag(item)" v-if="!item.isSystem"/>
        </div>
      </div>
      <div v-else class="nodata">
        暂无数据，请新增
      </div>
      <div class="select-add" @click="addType">
        <span class="el-icon-circle-plus-outline icons" />
        <span class="tit">新增</span>
      </div>
    </div>
    <div v-if="type === 'add' || type === 'modify' || type === 'del'">
      <div class="addType">
        <div class="left" @click="goBack">
          <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
          <span class="tit">{{ typeTitle.get(type) }}</span>
        </div>
        <el-button type="text" icon="el-icon-error" class="icons" @click="inputVisible = false" />
      </div>
      <div v-if="type === 'del'" class="des-type">是否确认删除？</div>
      <div v-else class="input-wrap">
        <el-input
          v-if="formType === 'text'"
          ref="saveTagInput"
          v-model="inputValue"
          class="input-new-tag"
          :placeholder="placeholder"
          size="small"
          style="width: 100%"
        />
        <el-input-number v-else v-model="inputValue" controls-position="right" :min="10" :step="1" :step-strictly="true" :max="300" style="width: 100%" />
      </div>
      <div v-if="type === 'add'" class="footer flex-cc">
        <el-button type="primary" class="btns" size="small" @click="handleInputConfirm">创建</el-button>
      </div>
      <div v-if="type === 'modify'" class="footer flex-cc">
        <el-button type="danger" class="btns" size="small" plain @click="handleInputDel">删除</el-button>
        <el-button type="primary" class="btns" size="small" @click="handleInputConfirm">完成</el-button>
      </div>
      <div v-if="type === 'del'" class="footer flex-cc">
        <el-button type="danger" class="btns" size="small" @click="delProcedureTypes">删除</el-button>
      </div>
    </div>
    <div slot="reference" class="com-ipts" @focus="goBack">
      {{ name ? name : '请选择' }}
      <span class="el-icon-arrow-down icons" />
    </div>
  </el-popover>
</template>

<script>
import curdApi from '@/api/CURDSelect'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'

export default {
  name: 'CURDSelect',
  props: {
    apiName: {
      type: String,
      default: ''
    },
    defaultValueIndex: {
      type: [Number, Boolean],
      default: false
    },
    defaultValue: {
      type: [Number, Boolean, String],
      default: false
    },
    placeholder: {
      type: String,
      default: '类型名称'
    },
    customList: {
      type: [Boolean, Array],
      default: false
    },
    formType: {
      type: String,
      default: 'text' // number
    },
    optionsKey: {
      type: Object,
      default: () => ({ label: 'name', value: 'id' })
    }
  },
  data() {
    return {
      select: {},
      type: 'common',
      typeTitle: new Map([
        ['add', '新增'],
        ['modify', '编辑'],
        ['del', '删除']
      ]),
      name: '',
      id: '',
      inputValue: '',
      currentIdx: '',
      inputVisible: false,
      list: []
    }
  },
  watch: {
    customList() {
      this.getList()
    },
    defaultValue() {
      if (this.defaultValue !== false) {
        const current = this.list.find(item => item.id === this.defaultValue)
        if (current) this.selectName(current)
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList(inputValue) {
      if (this.customList) {
        this.list = this._.cloneDeep(this.customList)
        if (this.defaultValue !== false) {
          const current = this.list.find(item => item.id === this.defaultValue)
          if (current) this.selectName(current)
        }
        return false
      }
      const res = await curdApi[`get${this.apiName}List`]({
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      })
      if (res) {
        this.list = res
        if (inputValue) {
          this.select = res.find(item => item[this.optionsKey.label] === inputValue)
          this.$emit('change', this.select || {})
          return false
        }
        if (this.defaultValue !== false) {
          const current = res.find(item => item.id === this.defaultValue)
          if (current) this.selectName(current)
        } else if (this.defaultValueIndex !== false) {
          const current = res[this.defaultValueIndex]
          if (current) this.selectName(current)
        }
      }
    },
    selectName(item, type) {
      this.name = item[this.optionsKey.label]
      this.currentIdx = item[this.optionsKey.value]
      this.select = item
      this.inputVisible = false
      if (type === 'change') {
        this.$emit('change', this.select)
      }
    },
    async handleInputDel() {
      this.type = 'del'
    },
    handleInputConfirm() {
      if (this.inputValue === '') {
        this.$message.error('请输入类型名称')
        return false
      }
      if (this.customList) {
        this.$emit('confirm', this.type, this.inputValue, this.select)
        return false
      }
      if (this.type === 'add') {
        this.addProcedureTypes()
      }
      if (this.type === 'modify') {
        this.modifyProcedureTypes()
      }
    },
    goBack() {
      this.type = 'common'
      this.inputValue = ''
    },
    addType() {
      this.type = 'add'
    },
    async modifyTag(item) {
      this.type = 'modify'
      this.inputValue = item[this.optionsKey.label]
      this.select = this._.cloneDeep(item)
    },
    async delProcedureTypes() {
      if (this.customList) {
        this.$emit('del', 'del', this.inputValue, this.select)
        return false
      }
      const res = await curdApi[`del${this.apiName}`](this.select.id)
      if (res) {
        this.name = ''
        this.currentIdx = ''
        this.select = {}
        this.resetSelect()
      }
    },
    async modifyProcedureTypes() {
      const res = await curdApi[`modify${this.apiName}`]({ id: this.select.id, [this.optionsKey.label]: this.inputValue })
      if (res) {
        this.name = this.inputValue
        this.currentIdx = this.inputValue
        this.resetSelect()
      }
    },
    async addProcedureTypes() {
      const res = await curdApi[`add${this.apiName}`]({ [this.optionsKey.label]: this.inputValue })
      if (res) {
        this.name = this.inputValue
        this.currentIdx = this.inputValue
        this.resetSelect()
      }
    },
    resetSelect() {
      this.getList(this.inputValue)
      this.inputVisible = false
      this.inputValue = ''
      this.type = 'common'
    }
  }
}
</script>

<style scoped lang="scss">
.com-ipts {
  width: 100%;
  background-color: #FFFFFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 32px;
  line-height: 32px;
  outline: none;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icons {
    text-align: right;
    display: inline-block;
    color: #C0C4CC;
    font-size: 14px;
  }
}
.content-container {
  max-height: 400px;
  overflow: auto;
}
.selectType {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background-color: #F5F7FA;
  }
  .name-tit {
    width: 190px;
    display: block;
  }
}
.nodata {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  color: #999;
  height: 34px;
  line-height: 34px;
  text-align: center;
}
.select-add {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  background: #EBEEFA;
  margin-bottom: -6px;
  cursor: pointer;
  .icons {
    width: 16px;
    height: 16px;
    color: #607FFF;
  }
  .tit {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393D60;
    margin-left: 5px;
  }
}
.addType {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 36px;
  background: #F5F7FA;
  .left {
    margin-left: 10px;
    cursor: pointer;
    .tit {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4A4F76;
      line-height: 36px;
      margin-left:6px;
    }
  }
  .icons {
    color: #9597AE;
    padding-right: 12px;
    display: block;
  }
}
.input-wrap {
  padding: 20px 15px;
}
.des-type {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #393D60;
  line-height: 20px;
  text-align: center;
  padding: 25px 0 25px 15px;
}
.footer {
  border-top: 1px solid #DDE3EB;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  .btns {
    width: 88px;
    height: 32px;
    border-radius: 4px;
    display: block;
  }
}
</style>
