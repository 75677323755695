import requestForAsync from '@/utils/requestForAsync'
import { getUrl } from '@/utils/index'

const baseUrl = 'api/web/v1/firstPage/private/firstPage/'

export default {
  // 订单统计
  getOrderTotal() {
    return requestForAsync.get(baseUrl + 'getOrderCountForFirstPage')
  },
  // 生产数量
  getProductNum(params) {
    return requestForAsync.get(baseUrl + `getWorkOrderCountForFirstPage${getUrl(params)}`)
  },
  // 工单状态
  getWorkOrderStatus(params) {
    return requestForAsync.get(baseUrl + `getWorkOrderStatusForFirstPage${getUrl(params)}`)
  },
  // 完工数量
  getFinishNum(params) {
    return requestForAsync.get(baseUrl + `getReportCountForFirstPage${getUrl(params)}`)
  },
  // 质检任务数量
  getInspectionNum(params) {
    return requestForAsync.get(baseUrl + `getInspectionStatusForFirstPage${getUrl(params)}`)
  },
  // 首检过程检数量
  getAllInspectNum(params) {
    return requestForAsync.get(baseUrl + `getInspectionCountForFirstPage${getUrl(params)}`)
  },
  // 设置首页产线
  modifyLines(data) {
    return requestForAsync.put(`api/web/v1/authority/private/user/updateProductionLineIdList`, data)
  },
  // 查看首页产线
  getAllLines() {
    return requestForAsync.get(`api/web/v1/authority/private/user/getHomePageLine`)
  },
  // 视频合并
  mergeVideo(postData) {
    return requestForAsync.post(`api/web/v1/basicData/private/video/mergeVideo`, postData)
  },
  // token获取用户信息
  getInfoByToken(postData) {
    return requestForAsync.put(`api/web/v1/authority/public/user/getUserInfo`, postData)
  }
}
