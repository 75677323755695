<template>
  <MDialog v-model="modalShow" width="1000px" title="选择工艺" @onOk="submitForm">
    <div class="top">
      <SearchForm :form-list-extend="searchFormList" :form-data="searchFormData" @search="searchTable" />
    </div>
    <div class="materials-info">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        search-title="请输入工艺编码/名称"
        class="materials-head"
        :columns-setting="false"
        @search="$refs.mTable.setTableData(true)"
      />
      <MTable
        ref="mTable"
        :highlight-current-row="single"
        :columns="columns"
        :height="height"
        :set-data-method="getTableData"
        :columns-setting="false"
        @selection-change="selectionChange"
        @current-change="selectionChange"
      >
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
          <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
          <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
        </div>
      </MTable>
    </div>
  </MDialog>
</template>

<script>

import SearchForm from '@/components/SearchForm/SearchForm'
import FunctionHeader from '@/components/FunctionHeader'
import { columns, sColumns } from './columns'
import { status } from '@/config/options.config'
import { getUUid } from '@/utils'
import api from '@/api/information/production/process'
import { Encrypt } from '@/utils/sercet'

export default {
  name: 'SelectProcessFlowByTable',
  components: { SearchForm, FunctionHeader },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    conditions: {
      type: Array,
      default: () => ([])
    },
    selectProcessFlow: {
      type: Array,
      default: () => ([])
    },
    materialsId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      modalShow: false,
      columns,
      functionHeader: {
        searchVal: '',
        tags: []
      },
      searchFormList: [
        {
          key: 'isEnabled',
          tagName: 'el-select',
          colSpan: 6,
          props: {
            placeholder: '请选择工艺路线状态'
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: status
            }
          ]
        }
      ],
      searchFormData: {},
      searchFormatData: [],
      selectionData: [],
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      }
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 289 - 160 - 57
    }
  },
  watch: {
    visible(val) {
      this.modalShow = val
      if (this.single) this.columns = sColumns
      if (val) {
        this.$nextTick(() => {
          // 数据回显
          this.selectionData = this._.cloneDeep(this.selectProcessFlow)
          if (this.single) {
            this.$refs.mTable.tableRefs().setCurrentRow(this.selectionData[0])
          } else {
            this.selectionData.forEach(item => {
              this.$refs.mTable.tableRefs().toggleRowSelection(item, true)
            })
          }
        })
      }
    },
    modalShow(val) {
      if (!val) {
        this.$emit('update:visible', false)
      }
    }
  },
  mounted() {
    this.modalShow = this.visible
    if (this.single) this.columns = sColumns
  },
  methods: {
    selectionChange(data) {
      if (Array.isArray(data)) {
        this.selectionData = data
      } else {
        this.selectionData = []
        this.selectionData.push(data)
      }
    },
    searchTable(data) {
      this.searchFormatData = data
      this.$refs.mTable.setTableData(true)
    },
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      const res = await api.getProcessListPage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        materialsId: this.materialsId,
        searchKeyword: this.functionHeader.searchVal,
        isGetAreaList: 1,
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchFormatData, ...this.conditions]))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    submitForm(callback) {
      callback()
      if (this.selectionData.length === 0) {
        this.$message.info('请至少选择一条工艺')
        return
      }
      this.$emit('submitForm', this.selectionData)
    }
  }
}
</script>

<style lang="scss" scoped>
.materials-info {
  padding: 20px;
}
.materials-head {
  padding: 0 0 20px 0;
}
</style>
