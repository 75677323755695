import request from '@/utils/requestForAsync'
const baseUrl1 = 'api/web/v1/inspection/private/defectItem/'
const baseUrl2 = 'api/web/v1/inspection/private/defectReason/'

export default {
  // 获取缺陷项不分页
  getDefectsItemList(data) {
    return request.get(baseUrl1 + 'getDefectItemList', data)
  },
  // 获取缺陷项-分页
  getDefectsItemListPage(data) {
    return request.get(baseUrl1 + 'getDefectItemListPage', data)
  },
  // 新增缺陷项
  addDefectsItem(data) {
    return request.post(baseUrl1 + 'addDefectItem', data)
  },
  // 新增缺陷项
  copyDefectsItem(data) {
    return request.post(baseUrl1 + 'addDefectItem', data)
  },
  // 修改缺陷项
  modifyDefectsItem(data) {
    return request.put(baseUrl1 + 'updateOne', data)
  },
  // 删除缺陷项
  delDefectsItem(data) {
    return request.del(baseUrl1 + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get((path === 'defectsItem' ? baseUrl1 : baseUrl2) + `${status}?id=${id}`)
  },

  // 获取缺陷原因不分页
  getDefectsReasonList(data) {
    return request.get(baseUrl2 + 'getDefectReasonList', data)
  },
  // 获取缺陷原因-分页
  getDefectsReasonListPage(data) {
    return request.get(baseUrl2 + 'getDefectReasonListPage', data)
  },
  // 新增缺陷原因
  addDefectsReason(data) {
    return request.post(baseUrl2 + 'addDefectReason', data)
  },
  // 新增缺陷原因
  copyDefectsReason(data) {
    return request.post(baseUrl2 + 'addDefectReason', data)
  },
  // 修改缺陷原因
  modifyDefectsReason(data) {
    return request.put(baseUrl2 + 'updateOne', data)
  },
  // 删除缺陷原因
  delDefectsReason(data) {
    return request.del(baseUrl2 + 'deleteOne', data)
  }
}
