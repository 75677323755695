import request from '@/utils/requestForAsync'
import requestForAsync from '@/utils/requestForAsync'
const authorityUrl = 'api/web/v1/authority/private/'
const settingUrl = 'api/web/v1/basicData/settings/private/'

export default {
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get((path === 'user' ? authorityUrl : settingUrl) + `${path}/${status}?id=${id}`)
  },
  getNewUserName() {
    return request.get(authorityUrl + 'user/getNewUserName')
  },
  // 获取用户-不分页
  getUserList(data) {
    return request.get(authorityUrl + 'user/getUserList', data)
  },
  // 获取用户-分页
  getUserListPage(data) {
    return request.get(authorityUrl + 'user/getUserListPage', data)
  },
  // 新增用户
  addUser(data) {
    return request.post(authorityUrl + 'user/addUser', data)
  },
  copyUser(data) {
    return request.post(authorityUrl + 'user/addUser', data)
  },
  // 修改用户
  modifyUser(data) {
    return request.put(authorityUrl + 'user/updateOne', data)
  },
  // 修改用户-工位
  modifyUserStation(data) {
    return request.put(authorityUrl + 'user/updateOneStation', data)
  },
  // 删除用户
  delUser(data) {
    return request.del(authorityUrl + 'user/deleteOne', data)
  },
  // 重置密码
  resetPassword(id) {
    return request.put(authorityUrl + 'user/resetPassword', { id })
  },
  getUserDetail(id) {
    return requestForAsync.get(authorityUrl + 'user/getUserDetail', { id })
  },

  // 获取用户组-不分页
  getUserGroupList(data) {
    return request.get(settingUrl + 'userGroup/getUserGroupList', data)
  },
  // 获取用户组-分页
  getUserGroupListPage(data) {
    return request.get(settingUrl + 'userGroup/getUserGroupListPage', data)
  },
  // 新增用户组
  addUserGroup(data) {
    return request.post(settingUrl + 'userGroup/addUserGroup', data)
  },
  copyUserGroup(data) {
    return request.post(settingUrl + 'userGroup/addUserGroup', data)
  },
  // 修改用户组
  modifyUserGroup(data) {
    return request.put(settingUrl + 'userGroup/updateOne', data)
  },
  // 删除用户组
  delUserGroup(data) {
    return request.del(settingUrl + 'userGroup/deleteOne', data)
  },
  getUserGroupDetail(id) {
    return request.get(settingUrl + 'userGroup/getUserGroupDetail', { id })
  },
  // 更新用户表格表头
  updateFields(data) {
    return request.put('/api/web/v1/authority/private/user/updateFields', data)
  },
  validatePassword(password) {
    return request.post(authorityUrl + `user/validPassword?password=${password}`)
  },
  // 获取用户组-用户id
  getUserGroupIdList(id) {
    return request.get(authorityUrl + `user/getUserGroupListByUserId?userId=${id}`)
  },
  // 获取组织架构-不分页
  getOrganizationList(data) {
    return request.get(`api/web/v1/authority/private/Organization/getOrganizationList`, data)
  }
}
