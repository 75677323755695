import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/notification/private/messageNotification/'

export default {
  // 获取消息通知-不分页
  getMessageNotificationList(data) {
    return request.get(baseUrl + 'getMessageNotificationList', data)
  },
  // 获取消息通知-分页
  getMessageNotificationListPage(data) {
    return request.get(baseUrl + 'getMessageNotificationListPage', data)
  },
  // 新增消息通知
  addMessageNotification(data) {
    return request.post(baseUrl + 'addMessageNotification', data)
  },
  // 修改消息通知
  modifyMessageNotification(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除消息通知
  delMessageNotification(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 获取消息通知详情
  getMessageNotificationDetail(data) {
    return request.get(baseUrl + 'getMessageNotificationDetail', data)
  },
  //获取时间  触发动作等基础信息 ———新————
  getBaseInfoNew() {
    return request.get('api/web/v1/notification/private/triggerActionConfig/getTriggerActionConfigList')
  },
  //获取消息推送渠道信息 ———新————
  getQuDaosNew() {
    return request.get('api/web/v1/notification/private/messageNotification/getNotificationChannelList')
  },
  //新增保存
  addMessageNotificationNew(data) {
    return request.post('api/web/v1/notification/private/messageNotification/addMessageNotification', data)
  },
  //修改保存
  updateMessageNotificationNew(data) {
    return request.put('api/web/v1/notification/private/messageNotification/updateOne', data)
  },
}
