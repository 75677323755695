import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/systemConfig/private/'

export default {
  // 获取系统配置项
  getSystemParamsList(data) {
    return request.get(baseUrl + 'systemConfig/getAllSystemConfigByTree', data)
  },
  // 启用系统配置项
  enabledSystemParams(data) {
    return request.get(baseUrl + 'systemConfigItem/enable', data)
  }
}
