
export const sColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'code',
    label: '设备编号',
    sortable: false,
    hiddenSearch: true,
    width: 140
  },
  {
    prop: 'name',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'sn',
    label: 'SN',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'model',
    label: '型号',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'equipmentClassName',
    label: '分类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'customerName',
    label: '客户',
    sortable: false,
    hiddenSearch: true
  }
]

export const columns = [
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'code',
    label: '设备编号',
    sortable: false,
    hiddenSearch: true,
    width: 140
  },
  {
    prop: 'name',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'sn',
    label: 'SN',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'model',
    label: '型号',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'equipmentClassName',
    label: '分类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'customerName',
    label: '客户/现场',
    sortable: false,
    hiddenSearch: true
  }
]

