import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/basicData/settings/private/procedureParam/'

export default {
  // 获取仓库建模-不分页
  getProcedureParamList(data) {
    return request.get(baseUrl + 'getProcedureParamList', data)
  },
  // 获取仓库建模-分页
  getProcedureParamListPage(data) {
    return request.get(baseUrl + 'getProcedureParamListPage', data)
  },
  // 新增仓库建模
  addProcedureParam(data) {
    return request.post(baseUrl + 'addProcedureParam', data)
  },
  // 修改仓库建模
  modifyProcedureParam(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 复制仓库建模
  copyProcedureParam(data) {
    return request.post(baseUrl + 'addProcedureParam', data)
  },
  // 删除仓库建模
  delProcedureParam(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  }
}
