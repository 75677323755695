import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/manHour/private/dailyWorkReport/'

export default {
  // 获取工作日报列表（不分页）
  getDailyWorkReportList(data) {
    return request.get(baseUrl + 'getDailyWorkReportList', data)
  },
  // 获取工作日报列表（分页）
  getDailyWorkReportListPage(data) {
    return request.get(baseUrl + 'getDailyWorkReportListPage', data)
  },
  // 新建工作日报
  addDailyWorkReport(data) {
    return request.post(baseUrl + 'addDailyWorkReport', data)
  },
  // 修改工作日报
  modifyDailyWorkReport(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除工作日报
  delDailyWorkReport(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 获取工作日报-详情
  getDailyWorkReportDetail(id) {
    return request.get(baseUrl + 'getDailyWorkReportDetail', { id })
  }
}
