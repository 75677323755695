import router from '@/router'

function checkArray(key) {
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
  const auths = userInfo.authority.split('|||')
  const arr = JSON.parse(auths[0]).checked
  return arr.indexOf(router.name + key) > -1
}

const permission = {
  inserted: function(el, binding) {
    const permission = binding.value // get v-permission value
    if (permission) {
      const hasPermission = checkArray(permission)
      if (!hasPermission) {
        // 没有权限 移除Dom元素
        el.parentNode && el.parentNode.removeChild(el)
      }
    }
  }
}

export default permission
