<template>
  <div class="materials-class">
    <div v-show="isOpen" style="height:100%">
      <div class="top">
        <div>
          BOM类别
        </div>
        <div>
          <span v-if="!view" class="add-materials-class" @click="add()"><i class="el-icon-circle-plus"/></span>
        </div>
      </div>
      <div class="tree">
        <Tree ref="zTrees" :view="view" :nodes="nodes" @command="command" @nodeClick="nodeClick"/>
      </div>
      <MDialog v-model="visible" :title="currentType" @onOk="submitForm">
        <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList"/>
      </MDialog>
      <div class="fixed">
        <span @click="changeWidthStatus(1)" title="点击收起"
              style="cursor: pointer;">
              <i class="el-icon-d-arrow-left"/>
        </span>
      </div>

    </div>
    <div class="fixed" v-show="!isOpen">
      <div @click="changeWidthStatus(0)" title="点击展开" style="cursor: pointer;"><i class="el-icon-d-arrow-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Tree from '@/components/Tree/Tree'
import api from '@/api/information/bom'

export default {
  name: 'MaterialsClass',
  components: { Tree },
  props: {
    view: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      nodes: [{
        id: -1,
        parentId: 0,
        name: '全部',
        expand: true
      }],
      currentClicked: '',
      visible: false,
      currentType: 'add',
      formData: {},
      formList: [
        {
          label: 'BOM类别名称',
          key: 'name',
          tagName: 'el-input',
          required: true
        },
        {
          label: '上级分类',
          key: 'parentId',
          tagName: 'el-select',
          props: {
            clearable: true
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              }
            }
          ]
        }
      ],
      materialsClassList: [{
        id: -1,
        name: ' '
      }],
      isOpen: true
    }
  },
  mounted() {
    this.getMaterialsClassList()
  },
  methods: {
    changeWidthStatus(type) {
      this.isOpen = type ? false : true
      this.$emit('changeWidth', this.isOpen)
    },
    async getMaterialsClassList() {
      const res = await api.getBomClassList()
      if (res) {
        this.nodes = [{
          id: -1,
          parentId: 0,
          name: '全部',
          expand: true
        }]
        this.materialsClassList = [{
          id: -1,
          name: ' '
        }]
        this.nodes = [...this.nodes, ...res]
        this.materialsClassList = [...this.materialsClassList, ...res]
        this.$emit('setMaterialsClass', res)
      }
    },
    command(name, node) {
      this[name](node)
    },
    add() {
      this.formData = { parentId: this.currentClicked }
      this.formList[1].children[0].options = this.materialsClassList
      this.currentType = '新增BOM类别'
      this.visible = true
    },
    modify(node) {
      this.formData = this.createResetData(node)
      this.formList[1].children[0].options = this.materialsClassList
        .filter(item => item.parentId !== node.id && node.id !== item.id)
      this.currentType = '修改BOM类别'
      this.visible = true
    },
    del(node) {
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.deleteBomClass({ id: node.id })
        if (res) {
          this.$message.success('删除成功')
          await this.getMaterialsClassList()
        }
      })
    },
    nodeClick(treeId, node) {
      if (this.currentClicked === node.id || node.id === -1) {
        this.currentClicked = ''
      } else {
        this.currentClicked = node.id
      }
      this.$emit('nodeClick', this.currentClicked)
    },
    submitForm(callback) {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const type = this.currentType === '修改BOM类别' ? 'modify' : 'add'
          const res = await api[`${type}BomClass`](
            this.currentType === '修改BOM类别' ? this.createResetData(form) : this.createSetData(form)
          )
          if (res) {
            this.$message.success(type === 'add' ? '新增成功' : '修改成功')
            this.visible = false
            await this.getMaterialsClassList()
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    createSetData(raw) {
      return {
        name: raw.name,
        parentId: raw.parentId || -1
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.materials-class {
  width: 100%;
  height: 100%;
  background: #F8F8F8;
  overflow: auto;
  position: relative;

  .fixed {
    position: absolute;
    bottom: 0;
    text-align: end;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-right: 10px;
    background: #d3deea;
    box-sizing: border-box;
  }

  .top {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: #DDE3EB;
    }

    .add-materials-class {
      i {
        color: rgb(96, 127, 255);
        cursor: pointer;
        font-size: 20px;
        position: relative;
        top: 1px;
      }
    }
  }

  .tree {
    height: calc(100% - 41px);
    padding: 10px 0 0 8px;
    overflow: auto;
  }
}
</style>
