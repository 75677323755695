<template>
  <div class="kr-designer">
    <viewport class="kr-designer-view" />
    <div class="kr-designer-tool">
      <el-scrollbar class="kr-designer-tool_con">
        <panel class="control-panel" :get-template-lists="getTemplateList" :template-ids="templateId" @selectTitle="selectTitle" />
      </el-scrollbar>
      <div class="kr-designer-tool_bar">
        <el-button size="mini" type="primary" @click="returnTemp">返回</el-button>
        <el-button size="mini" type="success" @click="saveTemp">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Viewport from './components/viewport/index.vue'
import Panel from './components/panel/index.vue'
import cloneDeep from 'lodash/cloneDeep'
import vptd from './mixins/vptd'
import pApi from '@/api/print'

export default {
  name: 'KrPrintDesigner',
  components: { Viewport, Panel },
  mixins: [vptd],
  props: {
    templateId: {
      type: Number,
      default: undefined
    },
    widgetOptions: {
      type: Array,
      default: () => []
    },
    tempValue: {
      type: Object,
      default: () => ({
        title: 'demo',
        width: 300,
        height: 300,
        pageWidth: 300,
        pageHeight: 300,
        tempItems: []
      })
    }
  },
  data() {
    return {
      templateIds: 1,
      getTemplateList: []
    }
  },
  created() {
    this.templateIds = this.templateId
    console.log('传的底》', this.templateId)
    this.initTemp(this.tempValue, this.widgetOptions)
  },
  methods: {
    async getTemplate() {
      const res = await pApi.getPrintTemplateList()
      if (res) {
        this.getTemplateList = res
      }
    },
    // 保存模板
    async saveTemp() {
      const page = this.$vptd.state.page
      console.log('ceshi8888=>', page)
      await this.handleSave(cloneDeep(page), this.templateIds)
    },
    returnTemp() {
      this.$emit('returnTemp', this.templateIds ? this.templateIds : 1)
    },
    // 预览模板
    previewTemp() {
      const page = { ...this.$vptd.state.page }
      console.log('打印模板', this.$lodop.previewTemp(page))
      // this.$lodop.previewTemp(cloneDeep(page));
    },

    // 初始化设计器
    initTemp(tempValue, widgetOptions) {
      this.$vptd.dispatch('designerInit', {
        tempValue: cloneDeep(tempValue),
        options: cloneDeep(widgetOptions)
      })
    },
    selectTitle(id) {
      this.templateIds = id
    },
    async handleSave(item, id) {
      const data = {
        id: id || 1,
        name: item.title,
        type: 1,
        info: JSON.stringify(item)
      }
      const res = await pApi.fixPrintTemplate(data)
      if (res && res == 'ok') {
        this.$message.success('保存成功')
        this.getTemplate()
        this.$emit('save', item, id || 1)
      }
    }
  }
}
</script>

<style lang="scss">
body,
html {
  padding: 0;
  margin: 0;
  height: 100%;
  box-sizing: border-box;
}
.kr-designer {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
  .kr-designer-view {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .kr-designer-tool {
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .el-scrollbar__wrap {
      overflow: auto;
    }
    &_con {
      flex: 1;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
    &_bar {
      padding: 10px 10px 0 10px;
      text-align: center;
    }
  }
}

.kr-form {
  .el-form-item--mini.el-form-item {
    margin-bottom: 10px;
  }
  .min-input {
    width: 100px;
  }
  .unit-text {
    font-size: 12px;
    color: #999999;
    margin-left: 5px;
  }
}

.kr-collapse {
  color: #555555;
  width: 400px;

  .el-collapse-item__header {
    box-sizing: border-box;
    padding-left: 10px;
  }

  .el-collapse-item__content {
    box-sizing: border-box;
    padding: 10px;
  }
}
</style>
