import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/authority/private/frequency/'
const baseUrl1 = 'api/web/v1/authority/private/frequencyDetail/'
export default {
  // 获取班次-不分页
  getShiftList(data) {
    return request.get(baseUrl + 'getFrequencyList', data)
  },
  // 获取班次-分页
  getShiftListPage(data) {
    return request.get(baseUrl + 'getFrequencyListPage', data)
  },
  // 新增班次
  addShift(data) {
    return request.post(baseUrl + 'addFrequency', data)
  },
  // 新增班次
  copyShift(data) {
    return request.post(baseUrl + 'addFrequency', data)
  },
  // 修改班次
  modifyShift(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除班次
  delShift(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  //批量删除
  batchDelete(data){
    return request.post(baseUrl + 'batchDelete', data)
  },
  // 获取班次详情
  getShiftDetail(data){
    return request.get(baseUrl + 'getFrequencyDetail', data)
  },
  //批量启用
  batchEnable(data){
    return request.post(baseUrl + 'batchEnable', data)
  },
  //批量停用
  batchDisable(data){
    return request.post(baseUrl + 'batchDisable', data)
  },
  // 日历班次详情
  getFrequencyDetailMapByCalendarId(data){
    return request.get(baseUrl1 + 'getFrequencyDetailMapByCalendarId', data)
  },
  // 班次日历详情
  getFrequencyMapByFrequencyIds(data){
    return request.post(baseUrl1 + 'getFrequencyMapByFrequencyIds', data)
  }
}
