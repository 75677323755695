<template>
  <div class="searchs">
    <!-- 顶部折叠搜索 -->
    <el-row>
      <el-col :md="18" :gutter="10">
        <el-row :gutter="10" type="flex" justify="start" style="flex-flow:row wrap">
          <el-col :md="4">
            <el-select v-model="searchFormData.status" placeholder="计划状态" filterable multiple clearable collapse-tags @change="searchData">
              <el-option
                v-for="item in planStatuss"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="7">
            <SelectMultipleMaterialsNew ref="multipleMaterial" @typeChange="searchData" />
          </el-col>
          <el-col :md="6">
            <el-date-picker
              v-model="searchFormData.deliveryTime"
              type="daterange"
              range-separator="至"
              start-placeholder="交期开始时间"
              end-placeholder="结束时间"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              clearable
              @change="searchData"
            />
          </el-col>
          <el-col :md="6">
            <el-date-picker
              v-model="searchFormData.planStartTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="计划开始时间"
              end-placeholder=""
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions"
              clearable
              @change="searchData"
            />
          </el-col>
        </el-row>
        <el-row v-if="!show" type="flex" justify="start" style="flex-flow:row wrap" :gutter="10">
          <el-col :md="4">
            <el-select v-model="searchFormData.produceStatus" placeholder="生产状态" clearable @change="searchData">
              <el-option
                v-for="item in productionStatus"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="7">
            <el-select v-model="searchFormData.source" placeholder="计划来源" clearable @change="searchData">
              <el-option
                v-for="item in planSource"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.priority" placeholder="计划优先级" filterable multiple clearable collapse-tags @change="searchData">
              <el-option
                v-for="(item, index) in priorityStatus"
                :key="index"
                :label="item.name"
                :value="index"
              />
            </el-select>
          </el-col>
          <el-col :md="7">
            <el-date-picker
              v-model="searchFormData.planEndTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="计划结束时间"
              end-placeholder=""
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions"
              clearable
              @change="searchData"
            />
          </el-col>
          <el-col :md="4">
            <el-select v-model="searchFormData.type" placeholder="计划类型" clearable @change="searchData">
              <el-option
                v-for="item in productionType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="7">
            <el-select v-model="searchFormData.processFlowId" placeholder="工艺" clearable @change="searchData">
              <el-option
                v-for="item in processFlowList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                @click.native="selectFlower(item)"
              />
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.processFlowVersionId" placeholder="工艺版本" clearable @change="$forceUpdate()">
              <el-option
                v-for="item in versionList"
                :key="item.id"
                :label="item.version"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="7">
            <el-select v-model="searchFormData.areaId" placeholder="产线" clearable @change="searchData">
              <el-option
                v-for="item in areaList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="4">
            <el-select v-model="searchFormData.isRepair" placeholder="计划属性" clearable @change="searchData">
              <el-option
                v-for="item in procedureClass"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col v-if="isShows" :md="7">
            <el-select v-model="searchFormData.delayDate" placeholder="是否延期" clearable @change="searchData">
              <el-option
                v-for="item in isDelay"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col :md="6" class="all-btns">
        <el-button size="small" type="primary" @click="searchData">查询</el-button>
        <el-button size="small" @click="resetData">重置</el-button>
        <div class="showMore" :style="{background: show ? '' : '#F8F8F8'}" @click="changeMore">
          <span>高级筛选</span>
          <i v-if="show" class="el-icon-caret-bottom icons" />
          <i v-else class="el-icon-caret-top" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { planSource, noPlanStatus, havePlanStatus, productionType, productionStatus, procedureClass, isDelay } from '@/config/options.config'
import { getUUid } from '@/utils'
import dayjs from 'dayjs'
import materialsApi from '@/api/information/materials'
import SelectMultipleMaterialsNew from '@/components/SelectMultipleMaterialsNew/SelectMultipleMaterialsNew'

export default {
  components: { SelectMultipleMaterialsNew },
  props: {
    isHave: {
      type: Boolean,
      default: false
    },
    formOption: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isDelay,
      procedureClass,
      planSource,
      noPlanStatus,
      havePlanStatus,
      planStatuss: [],
      productionType,
      productionStatus,
      show: true,
      processFlowList: [],
      areaList: [],
      versionList: [],
      searchFormData: {
        source: '',
        status: [],
        deliveryTime: '',
        priority: [],
        processFlowVersionId: '',
        type: '',
        processFlowId: '',
        areaId: '',
        planStartTime: [],
        produceStatus: ''
      },
      dates: [],
      selectKeyword: '0',
      dates: [],
      searchDatas: [],
      isShows: true,
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = dayjs().format('YYYY-MM-DD 23:59:59')
            const start = dayjs().format('YYYY-MM-DD 00:00:00')
            // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: '昨天',
          onClick(picker) {
            const end = dayjs().subtract(1, 'day').format('YYYY-MM-DD 23:59:59')
            const start = dayjs().subtract(1, 'day').format('YYYY-MM-DD 00:00:00')
            // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }

    }
  },
  computed: {
    priorityStatus() {
      return this.$store.state.user.priorityList
    }
  },
  watch: {
    isHave(val) {
      if (val) {
        this.planStatuss = this.noPlanStatus
        this.isShows = false
      } else {
        this.planStatuss = this.havePlanStatus
        this.isShows = true
      }
      this.versionList = []
    }
  },
  mounted() {
    this.processFlowList = this.formOption.flowerOptions
    this.areaList = this.formOption.areaOptions
    this.planStatuss = this.havePlanStatus
  },
  methods: {
    selectFlower(row) {
      this.versionList = row.versionList
      this.searchFormData.processFlowVersionId = ''
    },
    changeMore() {
      this.show = !this.show
    },
    // 顶部查询
    searchData() {
      const obj = {}
      const searchDatas = []
      Object.keys(this.searchFormData).forEach(item => {
        if (Array.isArray(this.searchFormData[item])) {
          if (this.searchFormData[item].length > 0) {
            if (item === 'priority' || item === 'status') {
              searchDatas.push({
                id: getUUid(),
                fieldName: item,
                fieldType: 'number',
                fieldValue: this.searchFormData[item].join('^'),
                operator: 'in'
              })
            } else if (item === 'deliveryTime') {
              searchDatas.push({
                id: getUUid(),
                fieldName: item,
                fieldType: 'date',
                maxValue: `${dayjs(this.searchFormData[item][1]).format('YYYY-MM-DD')}`,
                minValue: `${dayjs(this.searchFormData[item][0]).format('YYYY-MM-DD')}`,
                operator: 'range'
              })
            } else {
              searchDatas.push({
                id: getUUid(),
                fieldName: item,
                fieldType: 'date',
                maxValue: `${dayjs(this.searchFormData[item][1]).format('YYYY-MM-DD')} 23:59:59`,
                minValue: `${dayjs(this.searchFormData[item][0]).format('YYYY-MM-DD')} 00:00:00`,
                operator: 'range'
              })
            }
          }
        } else if (this.searchFormData[item] || this.searchFormData[item] === 0) {
          if (item === 'delayDate') {
            if (this.searchFormData[item] === 1) {
              searchDatas.push({
                id: getUUid(),
                fieldName: 'planEndTime',
                fieldType: 'date',
                maxValue: `${dayjs().format('YYYY-MM-DD HH:mm:ss')}`,
                minValue: '',
                operator: 'range'
              })
              searchDatas.push({
                id: getUUid(),
                fieldName: 'produceStatus',
                fieldType: 'number',
                fieldValue: '0^1',
                operator: 'in'
              })
            }
          } else {
            searchDatas.push({
              id: getUUid(),
              fieldName: item,
              fieldType: typeof this.searchFormData[item],
              fieldValue: this.searchFormData[item],
              operator: typeof this.searchFormData[item] === 'number' ? 'eq' : 'like'
            })
          }
        }
      })
      const keyword = this.$refs.multipleMaterial.keyword.code
      console.log('123', searchDatas)
      this.$emit('searchData', searchDatas, this.selectKeyword, keyword)
    },
    // 顶部重置
    resetData() {
      this.searchFormData = {
        source: '',
        status: [],
        deliveryTime: '',
        dates: [],
        priority: '',
        processFlowVersionId: '',
        type: '',
        processFlowId: '',
        areaId: ''
      }
      this.$refs.multipleMaterial.keyword = ''
      this.$emit('resetData')
    }
  }
}
</script>

<style scoped lang="scss">
 .searchs{
   padding-top: 10px;
    ::v-deep{
      .el-col-md-6{
        margin-bottom: 10px;
      }
      .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner{
        width: 100%;
      }
      .el-date-editor.el-input, .el-date-editor.el-input__inner{
        width: 100%;
      }
      .el-select{
        width:100%;
      }
   }
  .showMore{
    width: 88px;
    height: 40px;
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #607FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    .icons{
     color: #DDE3EB;
    }
    &:hover{
      background:#F8F8F8;
      i{ color: #607FFF;}
    }
  }
  .all-btns{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
  }
 }
</style>
