<template>
  <div v-if="infoTipImgSrc" id="naBox">
    <div class="top">
      <img src="http://mubyte.cn/wp-content/uploads/2021/11/2021110409511844.png" alt="">
      <img id="cosButton" :src="open ? 'http://mubyte.cn/wp-content/uploads/2021/10/2021102205064353.png' : 'http://mubyte.cn/wp-content/uploads/2021/10/2021102205064484.png'" @click="open = !open">
    </div>
    <div id="infoTip" :class="open ? 'bottom' : 'bottom close'">
      <img id="infoTipImg" :src="infoTipImgSrc" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  data() {
    return {
      open: true,
      infoTipImgSrc: ''
    }
  },
  watch: {
    infoTipImgSrc() {
      this.$nextTick(this.tipBox)
    }
  },
  mounted() {
    this.infoTipImgSrc = sessionStorage.getItem('tipImgSrc')
    this.tipBox()
  },
  methods: {
    tipBox: function() {
      const box = document.getElementById('naBox')
      if (!box) return
      let disX = 0
      let disY = 0
      box.onmousedown = function(e) {
        console.log(e)
        const ea = e || window.event
        disX = ea.clientX - this.offsetLeft
        disY = ea.clientY - this.offsetTop
        document.onmousemove = function(e) {
          const eb = e || window.event
          box.style.left = eb.clientX - disX + 'px'
          box.style.top = eb.clientY - disY + 'px'
        }
        document.onmouseup = function(e) {
          console.log('end')
          this.onmousemove = null
        }
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
#naBox {
  position: absolute;
  cursor: move;
  left: 82%;
  top: 120px;
  z-index: 99999;
}

#naBox .top img:first-child, #naBox .bottom img:first-child {
  width: 228px;
}

#naBox .top img:last-child {
  width: 22px;
  position: absolute;
  top: 20px;
  right: 27px;
  z-index: 101;
  cursor: pointer;
}

#naBox .top {
  position: relative;
  z-index: 99;
  display: inline-flex;
}

#naBox .bottom {
  position: relative;
  top: -65px;
  max-height: 999px;
  overflow: hidden;
  transition: all .5s
}
#naBox .close {
  max-height: 0;
}
</style>
