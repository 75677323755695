var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tag-box" },
    _vm._l(_vm.layers, function(layer, index) {
      return _c(
        "el-tag",
        {
          key: index,
          attrs: {
            closable: layer === _vm.activeElement ? true : false,
            size: "small",
            type: layer === _vm.activeElement ? "" : "info"
          },
          on: {
            click: function(e) {
              _vm.activeLayer(e, layer)
            },
            close: function(e) {
              _vm.dele(e, layer)
            }
          }
        },
        [
          layer.title === "自定义文本"
            ? _c("span", [_vm._v(_vm._s(layer.value))])
            : _c("span", [_vm._v(_vm._s(layer.title))])
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }