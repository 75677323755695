<template>
  <div class="containerBox">
    <div id="container1"></div>
  </div>
</template>
<script>
import { Graph, Shape, Addon } from "@antv/x6";
import { DagreLayout } from "@antv/layout";

import ProcedureContent from "@/components/singleProcessFlow/procedureContent";
// import "@antv/x6-vue-shape"
import { register } from "@antv/x6-vue-shape";
import processApi from "@/api/information/production/process";


let graph = null;
export default {
  name: "ShowProcessFlow",
  props: ["allWorkOrderTaskList"],
  data() {
    return {
      isShowBorder: false,
    };
  },
  watch: {
    async allWorkOrderTaskList(val) {
      this.drawNodeEdge(val);
    },
  },
  mounted() {
    console.log(this.allWorkOrderTaskList);
    if (graph) graph.dispose();
    graph = new Graph({
      container: document.getElementById("container1"),
      // autoResize: true,
      grid: true,
      panning: true,
      interacting: false,
      selecting: {
        enabled: true,
        className: "my-selecting",
      },
      mousewheel: {
        enabled: true,
        modifiers: ["ctrl"],
      },
    });
    graph.on("node:selected", (args) => {
      console.log(args);
      console.log(this.allWorkOrderTaskList);
      const item = this.allWorkOrderTaskList.filter(
        (item) => item.procedureUuid === args.node.id
      );
      this.$emit("selectProducesEvent", item[0]);
    });

    // 方式：注册 vue component
    register({
      shape: "procedure-content-node",
      inherit: "vue-shape",
      width: 235,
      height: 155,
      component: ProcedureContent,
    });
    if (this.allWorkOrderTaskList.length > 0) {
      this.drawNodeEdge(this.allWorkOrderTaskList);
    }
  },
  methods: {
   
    // 美化流程图
    beautifyX6() {
      const cells = graph.toJSON().cells;
      const nodes = cells.filter(
        (item) => item.shape === "procedure-content-node"
      );
      const edges = cells.filter((item) => item.shape === "edge");
      const dagreLayout = new DagreLayout({
        type: "dagre",
        rankdir: "LR",
        align: "UL",
        ranksep: 30,
        nodesep: 15,
        controlPoints: true,
      });
      const model = dagreLayout.layout({
        nodes,
        edges,
      });
      graph.fromJSON(model);
      
      graph.fitToContent({
        padding: 20, // 设置边距
        allowNewOrigin: "any", // 允许调整视口的位置
      });
    
    },
    async drawNodeEdge(val) {
      if (val.length > 0) {
        const res = await processApi.getProcessFlowVersionDetail({
          id: val[0].processFlowVersionId,
        });
        console.log(res);
        let cellsDetails;
        let edgesDetails;
        cellsDetails = JSON.parse(res.details);
        console.log("list", val);
        edgesDetails = cellsDetails.filter((item) => item.shape === "edge");
        val.forEach((item, index) => {
          let node = graph.addNode({
            id: item.procedureUuid,
            shape: "procedure-content-node",
            x: 20,
            y: 20,
            width: 240,
            height: 155,
            data: {
              item,
            },
          });
          console.log("node", node);
        });
        edgesDetails.forEach((item) => {
          graph.addEdge({
            shape: "edge", // 指定使用何种图形，默认值为 'edge'
            source: item.source.cell,
            target: item.target.cell,
          });
        });
        this.beautifyX6();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.containerBox {
  display: flex;
  width: 100%;
  #container1 {
    flex: 1;
    width: 100%;
    min-height: 600px;
  }
}
</style>