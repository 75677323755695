import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/stationPad/private/upDownWork/'

export default {
  // 获取用户、用户组
  getUsers(data) {
    return request.get('api/web/v1/production/private/productionTask/getUserAndUserGroupList', data)
  },
  // 上下工记录列表
  getUpDownWorkRecordListPage(data) {
    return request.post(baseUrl + `getUpDownWorkRecordListPage`, data)
  },
  // 上下工记录编辑
  updateUpDownWorkRecord(data) {
    return request.put(baseUrl + `update`, data)
  },
  // 上下工记录删除
  delUpDownWorkRecord(data) {
    return request.del(baseUrl + `delete?id=${data}`)
  },
  // 工时原因列表
  getWorkHoursReasonList(data) {
    return request.get('api/web/v1/basicData/private/workHoursReason/getWorkHoursReasonList', data)
  },
}
