<template>
  <div v-if="processFlowVersion.procedureExceptionMsg" class="err-container">
    <img class="err-img" src="@/assets/404_images/error.png" alt="404">
    <div class="err-msg">
      <p class="title">当前BOM无法生成，可能存在以下原因： </p>
      <div class="detail">
        1、每道工序至少需要配置一个投入物料<br>
        2、后道工序投入至少需要配置一个前道工序产出<br>
        3、每道工序至少需要配置一个产出物料行<br>
        4、产出物料中必须要有成品物料
      </div>
    </div>
  </div>
  <div v-else class="flow-wrapper">
    <el-button size="small" type="primary" style="margin-top: 15px" @click="onExpand">{{ isExpand ? '折叠全部' : '展开全部' }}</el-button>
    <el-button size="small" type="primary" style="margin-top: 15px" @click="onExport">导出</el-button>
    <el-radio-group v-model="radio" class="m-radio-group" @change="radioChange">
      <!--  暂时不上 -->
      <!-- <el-radio-button label="flow" ref='flow'>
        <img :src="radio === 'flow' ? flowActiveImg : flowImg" style="width: 17px">
      </el-radio-button> -->
      <el-radio-button label="list">
        <img :src="radio === 'list' ? listActiveImg : listImg" style="width: 17px">
      </el-radio-button>
    </el-radio-group>
    <div v-show="radio === 'flow'">
      <div ref="BOMContainer" style="overflow: auto;min-width:100%" />
    </div>
    <MTable
      v-show="radio === 'list'"
      ref="mTable"
      only-key="uuid"
      :is-tree-data="true"
      :tree-props="{children: 'children'}"
      :show-page="false"
      :columns="BOMColumns"
      :data="BOMList"
      :columns-setting="false"
      :default-expand-all="isExpand"
      :set-data-method="getTableData"
    >
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      <div  slot="name" slot-scope="{ row }">
        <div class="cellBox">
          <span class="left" :title="row.name===undefined?'':row.name"> {{ row.name===undefined?'-':row.name }}</span><i  v-if="row.isVirtualMaterials"
            class="el-icon-edit "
            style="color:#607FFF"
            @click="editCountOrName(row,'name')"
          />
        </div>
        </div>
      <div  slot="quantity" slot-scope="{ row }">
        <div class="cellBox">
          <span class="left" > {{ row.quantity===undefined?'-':row.quantity }}</span><i  v-if="row.isVirtualMaterials"
            class="el-icon-edit "
            style="color:#607FFF"
            @click="editCountOrName(row,'count')"
          />
        </div>
      </div>
      <div  slot="mainUnitName" slot-scope="{ row }">
        <div class="cellBox">
          <span class="left" > {{ row.mainUnitName===undefined?'-':row.mainUnitName}}</span><i  v-if="row.isVirtualMaterials"
            class="el-icon-edit "
            style="color:#607FFF"
            @click="editCountOrName(row,'mainUnitName')"
          />
        </div>
          </div>
    </MTable>
    <el-dialog title="编辑" :visible.sync="editDialogVisible" width="500px">
      <div v-if="countOrName==='count'" style="width:350px;margin:auto">
        <div class="topBar"><span>当前数量：</span><span>{{ editRow.quantity }}</span></div>
        <div class="label">数量：
          <el-input-number
            v-model="editQuantity"

            controls-position="right"
            :step="1"
            :min="0"
          />
        </div>
      </div>
      <div v-if="countOrName==='name'" style="width:350px;margin:auto">
        <div class="topBar"><span>当前名称：</span><span>{{ editRow.name }}</span></div>
        <div class="modifyBar"><div class="label">名称：</div>
        <el-input v-model="editName" ></el-input>
        </div>
      </div>
      <div v-if="countOrName==='mainUnitName'" style="width:350px;margin:auto">
        <div class="topBar"><span>当前单位：</span><span>{{ editRow.mainUnitName }}</span></div>
        <div class="modifyBar"> <div class="label">单位：</div>
          <el-select
          v-model="selectUnitValue"
                      placeholder="请选择"
                      style="width: 100%"
                      filterable
                      @change="unitChange"
                    >
                      <el-option
                        v-for="item in materialsUnitList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelEdit">取 消</el-button>
        <el-button type="primary" @click="saveCountAndName">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>
<script>
import { getUUid } from '@/utils'
import { Graph, Shape, Scroller } from '@antv/x6'
import { DagreLayout } from '@antv/layout'
import Api from '@/api/information/production/process'
import dayjs from 'dayjs'
import exportApi from '@/api/exportAndImport/export'
import { rectConfig, edgeConfig, cellConfig, dragRectConfig } from './x6.config'
import listImg from '@/assets/information/process/切换视图-列表视图@2x.png'
import listActiveImg from '@/assets/information/process/切换视图-列表视图备份@2x.png'
import flowActiveImg from '@/assets/information/process/结构@2x.png'
import flowImg from '@/assets/information/process/结构备份@2x.png'
import { BOMColumns } from './columns'
import maApi from '@/api/information/materials-attribute'

export default {
  name: 'BOM',
  props: {
    processFlowVersion: {
      type: Object,
      default: () => ({})
    },
    baseFormData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      listImg,
      listActiveImg,
      flowActiveImg,
      flowImg,
      radio: 'list',
      BOMColumns,
      BOMList: [],
      isExpand: true,
      editRow:{
      },
      editDialogVisible:false,
      countOrName:'',
      materialsUnitList:[],
      selectUnitValue:null,
      selectMainUnitName:null,
      editName:'',
      editQuantity:0
    }
  },
  watch: {
    'processFlowVersion.id': {
      immediate: false,
      handler(val) {
        val && this.resetData && this.init()
      }
    }
  },
  created() {
    this.mergeData()
    this.getUnitList()
  },
  beforeDestroy() {
    this.graphBOM?.dispose()
    this.graphBOM = null
  },
  methods: {
    resetData() {
      this.BOMList = []
    },
    // 递归展开收起
    toggleRowExpansionForAll(data, isExpansion) {
      data.forEach(item => {
        this.$refs.mTable.tableRefs().toggleRowExpansion(item, isExpansion)
        if (item.children) {
          this.toggleRowExpansionForAll(item.children, isExpansion)
        }
      })
    },

    onExpand() {
      this.isExpand = !this.isExpand
      this.toggleRowExpansionForAll(this.BOMList, this.isExpand)
    },

    generateEdges(nodes = []) {
      const edges = []
      for (const node of nodes) {
        if (!node.source?.length) continue
        const edge = node.source.map((item, idx) => {
          return {
            id: `edge-${node.id}-${idx}`,
            target: node.id,
            source: item,
            shape: 'edge'
          }
        })
        edges.push(...edge)
      }
      return edges
    },

    async init() {
      if (!this.processFlowVersion.procedureExceptionMsg) {
        const params = { processFlowVersionId: this.processFlowVersion.id }
        const list = await Api.getBomListView(params)
        this.BOMList = [list]
      }

      if (this.$refs.flow) {
        const graphData = await Api.getBomRelationView(params)

        Graph.registerNode(
          'material',
          {
            inherit: 'rect',
            markup: [
              {
                tagName: 'rect',
                selector: 'body'
              },
              {
                tagName: 'image',
                selector: 'img'
              },
              {
                tagName: 'text',
                selector: 'label'
              }
            ],
            attrs: {
              body: {
                rx: 6,
                ry: 6,
                stroke: '#5F95FF',
                fill: '#EFF4FF',
                strokeWidth: 1
              },
              label: {
                fontSize: 12,
                fill: '#262626'
              }
            }
          },
          true
        )

        Graph.registerEdge(
          'edge',
          {
            // inherit: 'edge',
            attrs: {
              line: {
                // targetMarker: null,
                stroke: '#A2B1C3',
                strokeWidth: 2
              }
            }
          },
          true
        )

        if (this.graphBOM) this.graphBOM.dispose()
        this.graphBOM = new Graph(Object.assign({
          container: this.$refs.BOMContainer,
          interacting: {
            nodeMovable: false
          },
          ...cellConfig,
          width: 1500,
          height: 600
        }))
        // Shape.Rect.config(rectConfig)
        // Shape.Edge.config(edgeConfig)
        const newNodes = this.mergeData(graphData)
        const edges = this.generateEdges(newNodes)
        const data = [...newNodes, ...edges]
        console.log(data)

        this.graphBOM.fromJSON(data)
        this.beautifyX6(this.graphBOM)
      }
    },

    async onExport() {
      const res = await exportApi.exportBOMList({ processFlowVersionId: this.processFlowVersion.id })
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        const fileName = `${this.baseFormData.code} - ${this.baseFormData.name} - ${this.processFlowVersion.version}.xls`
        this.downloadFile(urlP, fileName)
      } else {
        this.$message.error('导出失败，内容为空')
      }
    },

    downloadFile(urlP, name) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = urlP
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    // 美化流程图
    beautifyX6(graph) {
      const cells = graph.toJSON().cells
      const nodes = cells.filter(item => item.shape !== 'edge')
      const edges = cells.filter(item => item.shape === 'edge')
      console.log(cells, '==', nodes, edges)
      const dagreLayout = new DagreLayout({
        type: 'dagreSugiyama',
        rankdir: 'LR',
        // align: 'UL',
        ranksep: 20,
        nodesep: 20,
        controlPoints: true
      })
      const model = dagreLayout.layout({
        nodes,
        edges
      })
      graph.fromJSON(model)
      graph.centerContent()

      // 隐藏连接桩
      const container = this.$refs.BOMContainer
      const ports = container.querySelectorAll(
        '.x6-port-body'
      )
      for (let i = 0, len = ports.length; i < len; i = i + 1) {
        ports[i].style.visibility = 'hidden'
      }
    },

    // 切换按钮
    radioChange(val) {
      this.radio = val
    },

    // 将数据按status&&procedureUuid 分类
    mergeData(origin = []) {
      const merged = origin.reduce((acc, cur) => {
        const { uuid, name, status, relationId, target = [], source = [], procedureUuid, id } = cur
        // 查找是否有已经存在的元素
        // const existingElementIndex = acc.findIndex((el) => el.procedureUuid === procedureUuid && el.status === status);
        const existingElementIndex = acc.findIndex((el) => el.relationId === relationId)
        if (existingElementIndex !== -1) {
          // 如果已经存在，则进行拼接name
          acc[existingElementIndex].name += `<br>${name}`
          acc[existingElementIndex].source && source && (acc[existingElementIndex].source = [...new Set(acc[existingElementIndex].source.concat(source))])
          acc[existingElementIndex].target && target && (acc[existingElementIndex].target = [...new Set(acc[existingElementIndex].target.concat(target))])
        } else {
          // 如果不存在，则添加新元素
          acc.push({
            name, status, procedureUuid, relationId, target: target || [], source: source || [], id: relationId, shape: 'material', label: name
            // newProcedureUuid: ['ONLY_OUTPUT', 'ONLY_INPUT'].includes(status) ? getUUid() : ''
          })
        }
        return acc
      }, [])
      console.log('====merged', merged)
      return merged
    },

    getNodes(nodes = []) {
      const origin = [{
        uuid: 'aa', // 工序ID
        input: [// 输入产物
          {
            name: 'aa-1',
            num: 2
          },
          {
            name: 'aa-2',
            num: 3
          }
        ],
        out: [// 输出产物
          {
            name: 'aa-3',
            num: 1
          },
          {
            name: 'aa-4',
            num: 2
          }
        ],
        target: 'cc', // 下一道工序uuid
        source: [], // 上一道工序uuid
        isFirst: true// 是否首道工序
      },
      // {
      //   uuid: 'bb',// 工序ID
      //   input: [// 输入产物
      //     {
      //       name: 'bb-1',
      //       num: 1,
      //     },
      //     {
      //       name: 'bb-2',
      //       num: 2,
      //     }
      //   ],
      //   out: [// 输出产物
      //     {
      //       name: 'bb-3',
      //       num: 1,
      //     }
      //   ],
      //   target: 'cc',// 下一道工序uuid
      //   source: [],// 上一道工序uuid
      //   isFirst: true,// 是否首道工序
      // },
      {
        uuid: 'cc', // 工序ID
        input: [// 输入产物
          {
            name: 'aa-4',
            num: 2
          },
          {
            name: 'bb-3',
            num: 1
          },
          {
            name: 'dd-5',
            num: 2
          }
        ],
        out: [// 输出产物
          {
            name: 'd',
            num: 1
          }
        ],
        target: '', // 下一道工序uuid
        source: [], // 上一道工序uuid
        isFirst: false// 是否首道工序
      }
      ]
      // 1,根据isFirst找到所有的首道工序，把首道工序的投入物料分别作为一个rootNode,如{id:'aa',data:投入物料},id为原首道工序id，添加进newData数组
      // 2，遍历所有的首道工序，从首道工序开始往最后一道工序，比较当前工序的输出产物和下道工序的投入产物，比如当前工序输出产物为[{id:'a'},{id:'b'}]，下道工序投入产物为[{id:'c'},{id:'d'}],把输出产物中在投入产物中没有的项添加status状态为'output'，把投入产物中有的而输出产物中没有的项添加status状态为'input'，并且合并为一个数组并根据id为条件去重，然后把合并后的物料作为一个节点，并设置节点id，如{id:getUuid(),data:合并数组},继续比较下一道工序直到最后一道工序
      const rootNodes = origin.filter(item => item.isFirst)
      const normalNodes = origin.filter(item => !item.isFirst)
      console.log('rootNodes==', rootNodes, normalNodes)
      const newNodes = []
      // 声明在最外面，因为只有一个成品工序存在
      let lastNodeUUid
      rootNodes.forEach(root => {
        const newNode = {
          id: root.uuid,
          data: this._.clone(root.input),
          target: root.target
        }
        newNodes.push(newNode)

        // 从root一直往下一个工序找，将当前工序的产出物料和下一道工序的投入物料做对比
        const willNext = (curr) => {
          const nextNode = normalNodes.find(next => curr.target === next.uuid)
          // 对比并合并curr的output和next的input,把curr.output中有的而next.input中没有的物料status设置为“onlyOutput”,因为他没有作为下一道工序的投入；把curr.output中没有的而next.input中有的物料status设置为“onlyInput”；把curr.output中有的而next.input中也有的物料status设置为“normal”
          if (nextNode.target) {
            const newNextNode = {
              id: nextNode.uuid,
              data: this.mergeMateria(curr.out || [], nextNode.input || []),
              target: nextNode.target
            }
            newNodes.push(newNextNode)

            willNext(nextNode)
          } else {
            // 并行工序首次遍历到最后一道工序
            if (!lastNodeUUid) {
              lastNodeUUid = getUUid()
              newNodes.push(
                {
                  id: nextNode.uuid,
                  data: this.mergeMateria(curr.out || [], nextNode.input || []),
                  target: lastNodeUUid
                },
                {
                  id: lastNodeUUid,
                  data: nextNode.out,
                  target: null
                }
              )
            } else {
              // 并行工序再次遍历到的时候需要做合并处理
              newNodes[newNodes.length - 2] = {
                id: nextNode.uuid,
                data: this.mergeMateria(curr.out || [], nextNode.input || []),
                target: lastNodeUUid
              }
            }
          }
        }

        willNext(root)
      })
      return newNodes
    },

    // 合并前道工序的输出和后道工序的输入
    mergeMateria(input, output) {
      const inputObj = {}
      const outputObj = {}

      // 将input和output数组转换为对象
      input.forEach((item) => {
        inputObj[item.name] = item
      })

      output.forEach((item) => {
        outputObj[item.name] = item
      })

      // 遍历outputObj对象，将其属性与inputObj进行比较并设置状态
      Object.keys(outputObj).forEach((key) => {
        const outputItem = outputObj[key]
        const inputItem = inputObj[key]
        // 如果output中有而input中没有，并且output中该项没有被比较过即没有status，则说明该项是采购的
        if (!inputItem && !outputItem.status) {
          outputItem.status = 'onlyInput'
        } else { // 此处不精确，因为并行路线可能是某一条路线的产出
          outputItem.status = 'normal'
        }
      })

      // 遍历inputObj对象，将其属性与outputObj进行比较并设置状态
      Object.keys(inputObj).forEach((key) => {
        const inputItem = inputObj[key]
        const outputItem = outputObj[key]
        // 如果input中有而output中没有，并且input中该项没有被比较过即没有status，则说明该项是副产物
        if (!outputItem) {
          inputItem.status = 'onlyOutput'
          outputObj[key] = inputItem // 将input中的元素添加到output中
        }
      })

      // 将outputObj对象转换为数组
      const result = Object.keys(outputObj).map((key) => outputObj[key])

      return result
    },
    editCountOrName(editRow,type) {

      this.editRow = editRow
      this.countOrName = type
      if(type==='name'){
        this.editName =editRow.name
       console.log(this.editRow)
      }else if(type==='count'){
        this.editQuantity =editRow.quantity

      }else if(type==='mainUnitName'){
        this.selectUnitValue= editRow.mainUnitId
      }
      this.editDialogVisible = true
    },

    async saveCountAndName() {
      const id =this.editRow.id;
      const quantity = this.editQuantity?this.editQuantity:this.editRow.quantity;
      const name = this.editName?this.editName:this.editRow.name;
      const mainUnitId = this.selectUnitValue?this.selectUnitValue:this.editRow.mainUnitId;
      const mainUnitName  = this.selectMainUnitName?this.selectMainUnitName:this.editRow.mainUnitName


      const res = await Api.modifyBOMInfo({ id, quantity,name,mainUnitId ,mainUnitName})
      if (res) {
        this.$refs.mTable.setTableData()
        this.$message.success('操作成功')
        this.editDialogVisible = false
        this.selectUnitValue =null
        this.selectMainUnitName = null
      }
    },
    cancelEdit() {
      this.editDialogVisible = false
      this.selectUnitValue =null
        this.selectMainUnitName = null
    },
   async getTableData(param,callback){
      const params = { processFlowVersionId: this.processFlowVersion.id }
        const list = await Api.getBomListView(params)
        this.BOMList = [list]
        callback(null)
    },
 // 获取单位列表
 async getUnitList() {
      const res = await maApi.getMaterialsUnitList(this.defaultSearch)
      if (res) {
        this.materialsUnitList = res
      }
    },
    //单位修改
    unitChange(value){
      console.log(value);
      this.materialsUnitList.forEach((item)=>{
        if(item.id ===value){
          this.selectMainUnitName = item.name
        }
      })
    }
  }
}
</script>
<style lang="scss">
.err-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
  .err-img {
    width: 150px;
    height: auto;
    margin-right: 69px;
  }
  .err-msg {
    margin-top: -30px;
    font-size: 14px;
    line-height: 20px;
    .title {
      font-weight: 600;
      color: #393d60;
    }
    .detail {
      color: #9597ae;
    }
  }
}
.flow-wrapper {
  .m-radio-group {
    width: auto;
    float: right;
    margin: 10px 0;
    .el-radio-button {
      min-width: 60px !important;
      height: 40px;
    }
    .el-radio-button__inner {
      padding: 4px 8px !important;
      min-width: 40px !important;
    }
  }

  .m-table {
    top: 20px;
  }
}
.cellBox{
  display: flex;
  align-items: center;
  // justify-content: space-between;
  .left{
    // max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .el-icon-edit{
    margin-left: 10px;
    cursor: pointer;
  }
}
.topBar{
  display: flex;
  margin-bottom: 8px;
  span:nth-of-type(1){
    width: 70px;
  }
}
.modifyBar{
  display: flex;
  align-items: center;
  .label{
    width: 80px;
  }
  // el-input el-select{
  //   flex: 1;
  // }
}
</style>
