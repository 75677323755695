import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/mold/clazz/private/'
const baseUrl2 = 'api/web/v1/mold/instance/private/'

export default {
  // 选择组件模具列表
  getSelMouldList(data) {
    return request.get('api/web/v1/mold/clazz/private/candidate/list', data)
  },
  // 模具列表
  getMouldList(data) {
    return request.get(baseUrl + 'list', data)
  },
  // 模具新建
  createMould(data) {
    return request.post(baseUrl + 'create', data)
  },
  // 模具编辑
  editMould(data) {
    return request.post(baseUrl + 'edit', data)
  },
  // 模具详情
  viewMould(data) {
    return request.get(baseUrl + 'detail', data)
  },
  // 模具删除
  delMould(data) {
    return request.post(baseUrl + 'delete', data)
  },
  // 模具导出
  exportMouldList(data) {
    return request.exportPost(baseUrl + '', data)
  },
  // 模具库存列表
  getMouldStockList(data) {
    return request.get(baseUrl2 + 'list', data)
  },
  // 批量获取code
  getCode(data) {
    return request.get(baseUrl2 + 'ids', data)
  },
  // 模具入库
  inStock(data) {
    return request.post(baseUrl2 + 'create', data)
  },
  // 模具库存导出
  exportMouldStockList(data) {
    return request.exportPost(baseUrl2 + '', data)
  },
  // 模具库存编辑
  editStock(data) {
    return request.post(baseUrl2 + 'edit', data)
  },
  // 模具库存出库
  outStock(data) {
    return request.post(baseUrl2 + 'outbound', data)
  },
  // 获取模具类型
  getMouldTypeList(data) {
    return request.get('api/web/v1/mold/category/private/page', data)
  }
}
