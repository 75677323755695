import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/basicData/private/workCenter/'			
export default {
  // 获取工作中心-不分页
  getWorkCenterList(data) {
    return request.get(baseUrl + 'getWorkCenterList', data)
  },
  // 获取工作中心-分页
  getWorkCenterListPage(data) {
    return request.get(baseUrl + 'getWorkCenterListPage', data)
  },
  // 新增工作中心
  addWorkCenter(data) {
    return request.post(baseUrl + 'addWorkCenter', data)
  },
  // 修改工作中心
  modifyWorkCenter(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除工作中心
  delWorkCenter(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  //批量删除
  batchDelete(data){
    return request.post(baseUrl + 'batchDelete', data)
  },
  // 获取工作中心详情
  getWorkCenterDetail(data){	
    return request.get(baseUrl + 'getWorkCenterDetail', data)
  },
  //批量启用
  batchEnable(data){
    return request.post(baseUrl + 'batchEnable', data)
  },
  //批量停用
  batchDisable(data){
    return request.post(baseUrl + 'batchDisable', data)
  }
}