<template>
  <el-select
    v-model="currentValue"
    v-load-more="load"
    filterable
    remote
    reserve-keyword
    :clearable="$attrs.clearable && true"
    :placeholder="placeholder"
    :remote-method="remoteMethod"
    :loading="loading"
    v-bind="$attrs"
    v-on="$listeners"
    @change="changeValue"
    @focus="focusEvent"
  >
    <el-option
      v-for="item in list"
      :key="item[valueKey || valueName]"
      :label="item[labelName]"
      :value="item[valueName]"
    />
  </el-select>
</template>

<script>
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'

export default {
  name: 'MRemoteSelect',
  directives: {
    loadMore: {
      inserted: function(el, binding) {
        const selectWrapperDom = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
        selectWrapperDom.addEventListener('scroll', function() {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight
          if (condition) {
            binding.value()
          }
        })
      }
    }
  },
  props: {
    placeholder: {
      type: String,
      default: '请输入关键词'
    },
    value: {
      type: [String, Array, Number],
      default: ''
    },
    labelName: {
      type: String,
      default: 'name'
    },
    valueName: {
      type: String,
      default: 'id'
    },
    valueKey: {
      type: String,
      default: ''
    },
    searchName: {
      type: String,
      default: 'name'
    },
    interfaceFun: {
      required: true,
      type: Function,
      default: () => {}
    },
    condition: {
      type: Array,
      default: () => ([])
    },
    options: {
      type: Array,
      default: () => ([])
    },
    searchType:{
      type: String,
      default: ''
    },
    receiptType:{
      type: Number,
      default: 0
    },
    searchId:{
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      list: [],
      currentValue: '',
      loading: false,
      queryWord: '',
      pageSize: 20,
      currentPage: 1,
      canMore: true,
      projectId:''
    }
  },
  watch: {
    value: {
      handler: function(val) {
        this.currentValue = val
        if (!this.list.find(item => item.id === val)) {
          this.list.push(...this.options)
        }
        let filter
        if(this.searchType){
          filter = this.list.filter(item => item[this.searchType] === val)
        }else{
          filter = this.list.filter(item => item.id === val)
        }
        if(this.searchType !== 'projectStatistic'){
          this.$emit('on-change', val, filter)
        }
         
      },
      immediate: true
    },
    receiptType: {
      handler: function(val) {
           this.getDataList()
      }
    },
    searchId: {
      handler: function(val) {
        this.projectId = val
        if(val){
        this.currentPage = 1
        this.list = []
          this.getDataList()
        }

      },
      immediate: true
    },
  },
  methods: {
    async getList() {
      this.loading = true
      const searchCondition = []
      if (this.queryWord) {
        searchCondition.push({
          id: getUUid(),
          fieldName: this.searchName,
          fieldType: 'string',
          fieldValue: this.queryWord,
          operator: 'like'
        })
      }
      const materialConditon =[]
      if(this.placeholder==='请选择物料'){
        materialConditon.push(
           {
              id: getUUid(),
              fieldName: 'isVirtualMaterials',
              fieldType: 'number',
              fieldValue: 0,
              operator: 'eq',
            }
        )
      }
      const res = await this.interfaceFun({
        page: this.currentPage,
        limit: this.pageSize,
        searchVal: Encrypt(JSON.stringify([...searchCondition, ...this.condition,
            ...materialConditon
          ]))
      })
      if (res && res.records.length > 0) {
        const optionsIds = this.options.map(item => item.id)
        this.list = this.list.concat(res.records.filter(item => optionsIds.indexOf(item.id) === -1))
      }
      if (res && res.records.length < this.pageSize) {
        this.canMore = false
      }
      this.loading = false
    },
    async getDataList() {
      this.loading = true
      let searchCondition = {}
      if (this.queryWord) {
      searchCondition = {
          search: this.queryWord,
          ...this.condition
        }
      }
      if(this.searchType === 'receiptId'){
        searchCondition = {
          receiptType:this.receiptType,
          ...searchCondition
        }
      }
      if(this.searchType === 'projectId'){
        searchCondition = {
          projectId: this.projectId,
          ...searchCondition
        }
      }
      const res = await this.interfaceFun({
        page: this.currentPage,
        size: this.pageSize,
        ...searchCondition
      })
      if (res && res.records.length > 0) {
        const optionsIds = this.options.map(item => item.id)
        this.list = this.list.concat(res.records.filter(item => optionsIds.indexOf(item.id) === -1))
      }
      if (res && res.records.length < this.size) {
        this.canMore = false
      }
      this.loading = false
    },
    focusEvent() {
      this.queryWord = ''
      this.canMore = true
      this.currentPage = 1
      this.list = this.options
      if(this.searchType){
        this.getDataList() 
      }else{
        this.getList() 
      }
    },
    remoteMethod(query) {
      this.queryWord = query
      this.currentPage = 1
      this.list = this.options
      this.$emit('update:options', [])
      if(query !== ''){
        if (this.searchType) {
          this.getDataList()
      }else{
        this.getList() 
      }
      }

    },
    changeValue(val) {
      let filter
        if(this.searchType){
          filter = this.list.filter(item => item[this.searchType] === val)
        if(this.searchType === 'prePlanId'){
          if(filter.length && !filter[0].materialsBomId){
          this.$message.error('当前工单对应BOM不存在')
        }
        }
      }else{
          filter = this.list.filter(item => item.id === val)
        }
      this.$emit('input', val)
      this.$emit('on-change', val, filter)
    },
    load() {
      if (!this.canMore) {
        return
      }
      this.currentPage++
      if (this.searchType) {
          this.getDataList()
      }else{
        this.getList() 
      }
    }
  }
}
</script>