import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/prePlan/private/'

export default {
    // 获取变更管理列表
    getChangeWorkListPage(data) {
        return request.get(baseUrl + 'preChangeRecordList', data)
    },
    // 获取变更详情
    getChangeWorkDetail(data) {
        return request.get(baseUrl + 'preChangeRecordDetail', data)
    },  
    //执行
    executeChangeWork(data) {
        return request.get(baseUrl + `preChangeRecordOperate?id=${data}`)
    },
	//获取物料变更列表
    getMaterialsChangeListPage(data){
        return request.post(baseUrl + 'preChangeRecordDetailMaterials',data)
    },
    // 工单变更所属工单工序
    getProcedureList(data){
        return request.get(baseUrl + 'preChangeRecordProcedureList',data)
    },
    // 工单变更物料清单变更
    updateMaterialsChange(data){
        return request.post(baseUrl + 'preChangeRecordDetailMaterialsChange',data)
    },
    //获取工单变更列表
    getInvolveWorkListPage(data){
        return request.get(baseUrl + 'preChangeRecord/matchOrder',data)
    },
    //获取批量变更列表
    getPlanNodeListPage(data){
        return request.get(baseUrl + 'preChangeRecord/match',data)
    },
    //执行工单变更
    executeOrderWork(data) {
        return request.post(baseUrl + 'preChangeRecord/operateBatchOrder', data)
    },

    //执行工单变更
    executeBatch(data) {
        return request.post(baseUrl + 'preChangeRecord/operateBatch', data)
    },
    //上阶物料下拉列表
    getUpMaterialsList(data) {
        return request.get(baseUrl + 'preChangeRecordUpMaterialsList', data)
    },
    // 批量修改物料
    updateMaterialsChangeBatch(data){
        return request.post(baseUrl + 'preChangeRecordDetailMaterialsChangeBacth',data)
    },
    // 获取变更单号
    getCode(data){
        return request.get(baseUrl + 'preChangeRecord/getPrePlanChangeNumber', data)
    },
    // 获取工单列表
    getPrePlanListPage(data){
        return request.get(baseUrl + 'preChangeRecord/getPrePlanNumberListPage', data)
    },
    // 获取上阶物料
    getAddUpMaterialsList(data){
        return request.get(baseUrl + 'preChangeRecord/getCreatePreChangeUpMaterialsList', data)
    },
    // 新增变更单
    addChangeOrder(data){
        return request.post(baseUrl + 'preChangeRecord/create', data)
    },
    // 删除变更单物料
    delMaterialsChangeItem(data){
        return request.del(baseUrl + 'preChangeRecord/deleteMaterialsChangeItem', data)
    },
    // 编辑变更单类型
    updateChangeWork(data){
        return request.put(baseUrl + 'preChangeRecord/update', data)
    },
    // 删除变更单
    delChangeWork(data){
        return request.del(baseUrl + 'preChangeRecord/delete', data)
    }
}