var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-collapse" },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames"
          }
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { title: "", name: "1" } },
            [
              _c("template", { slot: "title" }, [
                _c("span", [_vm._v("页面参数")])
              ]),
              _c("page", {
                attrs: { "get-template-list": _vm.getTemplateList },
                on: {
                  selectTitle: _vm.selectTitle,
                  addTemplate: _vm.addTemplate,
                  delTemplate: _vm.delTemplate
                }
              })
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { name: "2" } },
            [
              _c("template", { slot: "title" }, [_c("span", [_vm._v("样式")])]),
              _c("appearance", { staticClass: "pd-l-10" })
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { name: "3" } },
            [
              _c("template", { slot: "title" }, [_c("span", [_vm._v("字段")])]),
              _c("options-single")
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { name: "4" } },
            [
              _c("template", { slot: "title" }, [
                _c("span", [_vm._v("自定义")])
              ]),
              _c("options")
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { name: "5" } },
            [
              _c("template", { slot: "title" }, [
                _c("span", [_vm._v("已加字段")])
              ]),
              _c("layers", { ref: "activeLayers" })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { "append-to-body": true, title: "新建打印模板" },
          on: { onOk: _vm.addparmases },
          model: {
            value: _vm.addModel,
            callback: function($$v) {
              _vm.addModel = $$v
            },
            expression: "addModel"
          }
        },
        [
          _c(
            "div",
            { staticClass: "model-tit" },
            [
              _c("span", { staticClass: "tit" }, [_vm._v("模板名称：")]),
              _c("el-input", {
                staticClass: "ipt",
                model: {
                  value: _vm.templateName,
                  callback: function($$v) {
                    _vm.templateName = $$v
                  },
                  expression: "templateName"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }