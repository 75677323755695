import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/production/kiki/private/produceOrder/'

export default {
  // 获取生产订单表分页
  getOrderpage(data) {
    return request.get(baseUrl + 'getProduceOrderListPage', data)
  },
  // 增加生产订单
  addOrder(data) {
    return request.post(baseUrl + 'addProduceOrder', data)
  },
  // 删除生产订单
  deleteOrder(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 批量删除生产订单
  deleteOrders(data) {
    return request.post(baseUrl + 'deleteBatch', data)
  },
  // 获取生产订单详情
  getOrderDetail(id) {
    return request.get(baseUrl + `getProduceOrderDetail?id=${id}`)
  },
  // 修改生产订单
  modifyOrder(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 创建计划
  addPlan(data) {
    return request.post(baseUrl + 'createProcedurePlan', data)
  },
  // 确认图纸
  getPage(data) {
    return request.get(baseUrl + 'confirmDrawing', data)
  },
  // 确认bom
  getBom(data) {
    return request.get(baseUrl + 'confirmBOM', data)
  },
  // 添加计划
  addPlanOrder(data) {
    return request.put(baseUrl + 'addPlanToOrder', data)
  },
  // 取消计划
  cancelPlanOrder(data) {
    return request.put(baseUrl + 'removePlanFromOrder', data)
  },
  // 获取物料需求
  getMaterialsRequirementList(sourceId,sourceType) {
    return request.get(`api/web/v1/production/kiki/private/materialsRequirement/getMaterialsRequirementList?sourceId=${sourceId}&sourceType=${sourceType}`)
  }
}
