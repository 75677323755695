var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add-process-flow-container" }, [
    _c("div", { staticClass: "detail-card" }, [
      _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
      _c(
        "div",
        { staticClass: "card-bot" },
        [
          _vm.saveButton && ["add", "copy"].indexOf(_vm.params.type) !== -1
            ? _c(
                "MFormBuilder",
                {
                  ref: "baseForm",
                  attrs: {
                    "form-list": _vm.baseFormList,
                    "form-data": _vm.baseFormData
                  }
                },
                [
                  _c(
                    "div",
                    {
                      attrs: { slot: "processFlowClassName" },
                      slot: "processFlowClassName"
                    },
                    [
                      _c("CURDSelect", {
                        attrs: {
                          "default-value": _vm.baseFormData.processFlowClassId,
                          "default-value-index": 0,
                          "api-name": "ProcessType"
                        },
                        on: { change: _vm.selectName }
                      })
                    ],
                    1
                  )
                ]
              )
            : [
                _c("MFormBuilder", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false"
                    }
                  ],
                  ref: "baseForm",
                  attrs: {
                    "form-list": _vm.baseFormList,
                    "form-data": _vm.baseFormData
                  }
                }),
                _c(
                  "el-row",
                  { staticClass: "view-list", attrs: { gutter: 24 } },
                  _vm._l(_vm.columns.base, function(item, index) {
                    return _c(
                      "el-col",
                      {
                        key: item.prop,
                        style: {
                          marginBottom: index !== 6 ? "20px" : 0,
                          display: "inline-flex"
                        },
                        attrs: { title: _vm.getValue(item), span: 8 }
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { "word-break": "keep-all" } },
                          [_vm._v(_vm._s(item.label) + "：")]
                        ),
                        _c(
                          "div",
                          { staticStyle: { "word-break": "break-all" } },
                          [_vm._v(_vm._s(_vm.getValue(item)))]
                        )
                      ]
                    )
                  }),
                  1
                )
              ]
        ],
        2
      )
    ]),
    _c("div", { staticClass: "detail-card" }, [
      _c("div", { staticClass: "card-tit" }, [_vm._v("工艺路线图")]),
      _c(
        "div",
        { staticClass: "card-bot process-card" },
        [
          _c("process-flow", {
            ref: "processFlow",
            attrs: {
              "read-only": !_vm.saveButton,
              "process-flow-detail": _vm.processFlowVersion
            },
            on: { addDetail: _vm.addDetails }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }