import request from '@/utils/request'
const baseUrl = 'api/web/v1/inspection/private/defectProductJudgment/'
const baseUrl1 = 'api/web/v1/flowable/private/flowable/'
export default {
  // 获取不良品判定列表
  getDefectProductJudgmentListPage(data) {
    return request.get(baseUrl + 'getDefectProductJudgmentListPage', data)
  },
  // 获取不良品判定子项列表
 getDefectProductJudgmentItemListPage(data) {
    return request.get(baseUrl + 'getDefectProductJudgmentItemListPage', data)
  },
  //   获取不良品判定详情
  getDefectProductJudgmentDetail(data) {
    return request.get(baseUrl + 'getDefectProductJudgmentDetail', data)
  },
  // 获取历史流程信息
  getHistoryTask(data){
    return request.get(baseUrl1 + 'getHistoryTask', data)
  },
  // 新增任务
  addDefectProductJudgment(data){
    return request.post(baseUrl + 'addDefectProductJudgment', data)
  },
  // 新增不良品判定子项
  addDefectProductJudgmentItem(data){
    return request.post(baseUrl + 'addDefectProductJudgmentItem', data)
  },
  // 修改不良品判定子项
  updateDefectProductJudgmentItem(data){
    return request.put(baseUrl + 'updateDefectProductJudgmentItem', data)
  }
}