import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/warehouse/record/private/warehouseOperationSheet/'

export default {
  // 获取库存记录-不分页
  getWarehouseOperationSheetList(data) {
    return request.get(baseUrl + 'getWarehouseOperationSheetList', data)
  },
  // 获取库存记录-分页
  getWarehouseOperationSheetListPage(data) {
    return request.get(baseUrl + 'getWarehouseOperationSheetListPage', data)
  },
  // 新增库存记录
  addWarehouseOperationSheet(data) {
    return request.post(baseUrl + 'addWarehouseOperationSheet', data)
  },
  // 修改库存记录
  modifyWarehouseOperationSheet(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除库存记录
  delWarehouseOperationSheet(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 获取列表详情-id
  getWarehouseOperationSheetDetail(data) {
    return request.get(baseUrl + 'getWarehouseOperationSheetDetail', data)
  },
  // 通过编号，获取库存操作单据详情
  getWarehouseOperationSheetDetailByNumber(data) {
    return request.get(baseUrl + 'getWarehouseOperationSheetDetailByNumber', data)
  }
}
