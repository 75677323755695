import request from '@/utils/requestForAsync'
const baseUrl = '/api/web/v1/equipment/sparePart/warehouse/private/'

export default {
  // 获取备件库存-不分页
  getSparePartNumsList(data) {
    return request.get(baseUrl + 'sparePartWarehouseStock/getSparePartWarehouseStockList', data)
  },
  // 获取备件库存-分页
  getSparePartNumsListPage(data) {
    return request.get(baseUrl + 'sparePartWarehouseStock/getSparePartWarehouseStockListPage', data)
  },
  // 获取备件库存流水-不分页
  getPartBillsList(data) {
    return request.get(baseUrl + 'sparePartWarehouseOutInRecord/getSparePartWarehouseOutInRecordList', data)
  },
  // 获取备件库存流水-分页
  getPartBillsListPage(data) {
    return request.get(baseUrl + 'sparePartWarehouseOutInRecord/getSparePartWarehouseOutInRecordListPage', data)
  }
}
