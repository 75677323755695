var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.processFlowVersion.procedureExceptionMsg
    ? _c("div", { staticClass: "err-container" }, [
        _c("img", {
          staticClass: "err-img",
          attrs: { src: require("@/assets/404_images/error.png"), alt: "404" }
        }),
        _vm._m(0)
      ])
    : _c(
        "div",
        { staticClass: "flow-wrapper" },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "15px" },
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.onExpand }
            },
            [_vm._v(_vm._s(_vm.isExpand ? "折叠全部" : "展开全部"))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "15px" },
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.onExport }
            },
            [_vm._v("导出")]
          ),
          _c(
            "el-radio-group",
            {
              staticClass: "m-radio-group",
              on: { change: _vm.radioChange },
              model: {
                value: _vm.radio,
                callback: function($$v) {
                  _vm.radio = $$v
                },
                expression: "radio"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "list" } }, [
                _c("img", {
                  staticStyle: { width: "17px" },
                  attrs: {
                    src: _vm.radio === "list" ? _vm.listActiveImg : _vm.listImg
                  }
                })
              ])
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.radio === "flow",
                  expression: "radio === 'flow'"
                }
              ]
            },
            [
              _c("div", {
                ref: "BOMContainer",
                staticStyle: { overflow: "auto", "min-width": "100%" }
              })
            ]
          ),
          _c("MTable", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.radio === "list",
                expression: "radio === 'list'"
              }
            ],
            ref: "mTable",
            attrs: {
              "only-key": "uuid",
              "is-tree-data": true,
              "tree-props": { children: "children" },
              "show-page": false,
              columns: _vm.BOMColumns,
              data: _vm.BOMList,
              "columns-setting": false,
              "default-expand-all": _vm.isExpand,
              "set-data-method": _vm.getTableData
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "name",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("div", { staticClass: "cellBox" }, [
                      _c(
                        "span",
                        {
                          staticClass: "left",
                          attrs: {
                            title: row.name === undefined ? "" : row.name
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(row.name === undefined ? "-" : row.name)
                          )
                        ]
                      ),
                      row.isVirtualMaterials
                        ? _c("i", {
                            staticClass: "el-icon-edit ",
                            staticStyle: { color: "#607FFF" },
                            on: {
                              click: function($event) {
                                return _vm.editCountOrName(row, "name")
                              }
                            }
                          })
                        : _vm._e()
                    ])
                  ])
                }
              },
              {
                key: "quantity",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("div", { staticClass: "cellBox" }, [
                      _c("span", { staticClass: "left" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.quantity === undefined ? "-" : row.quantity
                            )
                        )
                      ]),
                      row.isVirtualMaterials
                        ? _c("i", {
                            staticClass: "el-icon-edit ",
                            staticStyle: { color: "#607FFF" },
                            on: {
                              click: function($event) {
                                return _vm.editCountOrName(row, "count")
                              }
                            }
                          })
                        : _vm._e()
                    ])
                  ])
                }
              },
              {
                key: "mainUnitName",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("div", { staticClass: "cellBox" }, [
                      _c("span", { staticClass: "left" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.mainUnitName === undefined
                                ? "-"
                                : row.mainUnitName
                            )
                        )
                      ]),
                      row.isVirtualMaterials
                        ? _c("i", {
                            staticClass: "el-icon-edit ",
                            staticStyle: { color: "#607FFF" },
                            on: {
                              click: function($event) {
                                return _vm.editCountOrName(row, "mainUnitName")
                              }
                            }
                          })
                        : _vm._e()
                    ])
                  ])
                }
              }
            ])
          }),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "编辑",
                visible: _vm.editDialogVisible,
                width: "500px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.editDialogVisible = $event
                }
              }
            },
            [
              _vm.countOrName === "count"
                ? _c(
                    "div",
                    { staticStyle: { width: "350px", margin: "auto" } },
                    [
                      _c("div", { staticClass: "topBar" }, [
                        _c("span", [_vm._v("当前数量：")]),
                        _c("span", [_vm._v(_vm._s(_vm.editRow.quantity))])
                      ]),
                      _c(
                        "div",
                        { staticClass: "label" },
                        [
                          _vm._v("数量： "),
                          _c("el-input-number", {
                            attrs: {
                              "controls-position": "right",
                              step: 1,
                              min: 0
                            },
                            model: {
                              value: _vm.editQuantity,
                              callback: function($$v) {
                                _vm.editQuantity = $$v
                              },
                              expression: "editQuantity"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm.countOrName === "name"
                ? _c(
                    "div",
                    { staticStyle: { width: "350px", margin: "auto" } },
                    [
                      _c("div", { staticClass: "topBar" }, [
                        _c("span", [_vm._v("当前名称：")]),
                        _c("span", [_vm._v(_vm._s(_vm.editRow.name))])
                      ]),
                      _c(
                        "div",
                        { staticClass: "modifyBar" },
                        [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("名称：")
                          ]),
                          _c("el-input", {
                            model: {
                              value: _vm.editName,
                              callback: function($$v) {
                                _vm.editName = $$v
                              },
                              expression: "editName"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm.countOrName === "mainUnitName"
                ? _c(
                    "div",
                    { staticStyle: { width: "350px", margin: "auto" } },
                    [
                      _c("div", { staticClass: "topBar" }, [
                        _c("span", [_vm._v("当前单位：")]),
                        _c("span", [_vm._v(_vm._s(_vm.editRow.mainUnitName))])
                      ]),
                      _c(
                        "div",
                        { staticClass: "modifyBar" },
                        [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("单位：")
                          ]),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择", filterable: "" },
                              on: { change: _vm.unitChange },
                              model: {
                                value: _vm.selectUnitValue,
                                callback: function($$v) {
                                  _vm.selectUnitValue = $$v
                                },
                                expression: "selectUnitValue"
                              }
                            },
                            _vm._l(_vm.materialsUnitList, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c("el-button", { on: { click: _vm.cancelEdit } }, [
                    _vm._v("取 消")
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveCountAndName }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "err-msg" }, [
      _c("p", { staticClass: "title" }, [
        _vm._v("当前BOM无法生成，可能存在以下原因： ")
      ]),
      _c("div", { staticClass: "detail" }, [
        _vm._v(" 1、每道工序至少需要配置一个投入物料"),
        _c("br"),
        _vm._v(" 2、后道工序投入至少需要配置一个前道工序产出"),
        _c("br"),
        _vm._v(" 3、每道工序至少需要配置一个产出物料行"),
        _c("br"),
        _vm._v(" 4、产出物料中必须要有成品物料 ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }