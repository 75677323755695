<template>
  <mark-line
    :mark-line-time="currentTime"
    :get-positon-offset="getPositonOffset"
    color="rgba(255,0,0,.4)"
  />
</template>

<script>
import dayjs from 'dayjs'
import MarkLine from './index.vue'
export default {
  name: 'CurrentTime',
  components: { MarkLine },
  props: {
    getPositonOffset: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      currentTime: dayjs().toString()
    }
  },
  created() {
    const timeNow = setInterval(() => {
      this.currentTime = dayjs().toString()
    }, 1000)
    this.$once('hook:beforeDestroy', () => {
      clearInterval(timeNow)
    })
  }
}
</script>
