import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/materials/private/materialsRequisition/'
export default {
// 获取领料单号
getMaterialsApplyCode(data){
    return request.get(baseUrl + 'getCode', data)
},
// 新增领料单
addMaterialsApply(data){
    return request.post(baseUrl + 'create', data)
},
// 新增领料单工序物料弹框选择
// addMaterialsFromProcess(data){
//     return request.post(baseUrl + 'getRequirementOrder', data)
// },
// 获取领料单列表
getMaterialsApplyListPage(data){
    return request.get(baseUrl + 'listPage', data)
},
// 领料单详情-基本详情
getMaterialsApplyBaseDetail(data){
    return request.get(baseUrl + 'getMaterialsRequisition', data)
},
// 领料单详情-物料清单列表
getMaterialsDetailListPage(data){
    return request.get(baseUrl + 'itemListPage', data)
},
// 导出工序物料
// 物料锁库
lockedQuantity(data){
    return request.post(baseUrl + 'lockedQuantity', data)
},
// 取消物料锁库
cancelLockedQuantity(data){
    return request.post(baseUrl + 'cancelLockedQuantity', data)
},
// 导出需求单物料详情-导出工序物料
exportProcedureMaterialsList(data){
  return request.exportGet(baseUrl + 'exportProcedureMaterialsList', data)
},
// 获取单据列表(领料)
getReceiptListPage(data){
    return request.get(baseUrl + 'getReceiptListPage', data)
},
// 获取单据列表(退料)
getReceiptReturnListPage(data){
  return request.get(baseUrl + 'getReceiptReturnListPage', data)
},
// 导出领料单详情-领料单物料清单导出
exportApplyMaterialsList(data){
  return request.exportGet(baseUrl + 'exportItemList', data)
},
// 导出退料单详情-退料单物料清单导出
exportReturnMaterialsList(data){
    return request.exportGet(baseUrl + 'exportItemList', data)
},
// 导出领退料单列表
exportMaterialsRequisitionList(data){
  return request.exportPost(baseUrl + 'export', data)
},
// 领退料单撤销
backOutMaterialsRequisition(id){
  return request.put(baseUrl + `backOut?id=${id}`)
},
// 同步领退料单
synchronizedMaterialsRequisition(data){
  return request.get(baseUrl + 'synchronizedMaterialsRequisition', data)
},
// 同步领料单申请数量
synchronizedApplyQuantity(data){
  return request.post('api/web/v1/materials/private/requirementOrder/materialsRequisitionComputing', data)
},
// 获取批量领料单列表
getRequirementOrderBatchList(data){
  return request.post('api/web/v1/materials/private/requirementOrder/batchList', data)
},
// 批量领料
batchCreateApply(data){
  return request.post('api/web/v1/materials/private/materialsRequisition/batchCreate', data)
}
}
