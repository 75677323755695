<template>
  <div class="options-box">
    <template v-for="(item,index) in optionItems">
      <el-popover
        v-if="item.type == 'braid-table'"
        :key="index"
        v-model="tablePopover"
        placement="top"
        width="200"
      >
        <div>
          <el-checkbox-group v-model="item.selectCol">
            <el-row>
              <el-col v-for="col in item.columnsAttr" :key="col.name" :span="12">
                <el-checkbox :label="col.name">{{ col.title }}</el-checkbox>
              </el-col>
            </el-row>
          </el-checkbox-group>
          <el-button size="mini" type="primary" @click="(e) => {addTempItem(e,item)}">确定</el-button>
        </div>
        <el-button slot="reference" size="mini">{{ item.title }}</el-button>
      </el-popover>

      <el-button
        v-else
        :key="index"
        :disabled="fixData(item)"
        size="mini"
        @click="(e) => {addTempItem(e,item)}"
      >{{ item.title }}</el-button>
    </template>
  </div>
</template>

<script>
import vptd from '../../../mixins/vptd'
import { getToken } from '@/utils/auth'
export default {
  mixins: [vptd],
  data() {
    return {
      tablePopover: false,
      uploadProps: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: false,
        limit: 1,
        onSuccess: this.fileUploadChange,
        onRemove: this.fileRemove,
        onChange: this.fileUploadChange
      },
      fileList: [],
      info: {}
    }
  },
  computed: {
    optionItems() {
      const items = this.$vptd.state.optionItems.filter(item => item.title !== '自定义文本' && item.title !== '自定义图片')
      return items
    }
  },
  methods: {
    fixData(item) {
      const ites = this.$vptd.state.page.tempItems.find(items => items.title === item.title)
      if (ites) {
        return true
      } else {
        return false
      }
    },
    getImg(e, item) {
      this.fileList = []
      this.info = {
        e,
        item
      }
    },
    // 添加组件
    addTempItem(e, item) {
      const ites = this.$vptd.state.page.tempItems.find(items => items.title === item.title)
      if (ites) {
        return false
      }
      switch (item.type) {
        case 'braid-table': {
          const selectCol = []
          item.selectCol.forEach((itemName) => {
            const colInfo = item.columnsAttr.find((col) => col.name === itemName)
            if (colInfo) {
              selectCol.push(colInfo)
            }
          })
          item.columns = selectCol // 表格显示的字段
          this.$vptd.dispatch('addTempItem', item)
          this.tablePopover = false
          break
        }
        default:
          this.$vptd.dispatch('addTempItem', item)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.options-box {
  .el-button {
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 0;
  }
}
</style>
