<template>
  <MDialog v-model="modalShow" width="1000px" :append-to-body="true" :title="title" @onOk="submitForm" @fullScreen="fullScreenChange">
    <Split :offset.sync="split.offset" :height="splitHeight">
      <template slot="left">
        <EquipmentClass ref="equipmentClass" :view="true" @nodeClick="setEquipmentClassId" />
      </template>
      <template slot="right">
        <div class="bottom">
          <FunctionHeader
            ref="functionHeader"
            v-model="functionHeader.searchVal"
            search-title="请输入设备编号/名称/SN"
            :tags="functionHeader.tags"
            @search="$refs.mTable.setTableData(true)"
          />
          <MTable
            ref="mTable"
            :highlight-current-row="single"
            :columns="columns"
            :height="height"
            :set-data-method="getTableData"
            :columns-setting="false"
            @selection-change="selectionChange"
            @current-change="selectionChange"
            @row-click="handleRowClick"
          >
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <!-- <el-button slot="code" slot-scope="{ row }" style="white-space: pre-wrap;" type="text" @click="viewDetail(row)">{{ row.code }}</el-button> -->
            <div slot="equipmentClassType" slot-scope="{ row }" type="text">{{ row.equipmentClassType | equipmentClassTypeName }}</div>
          </MTable>

        </div>
      </template>
    </Split>
  </MDialog>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/xiewei/SearchForm/SearchForm'
import { columns, getFunctionList } from './columns'
import { getUUid } from '@/utils'
import equipmentClassApi from '@/api/information/equipment/equipment-class'
import api from '@/api/xiewei/facility/facility'
import apis from '@/api/information/equipment/equipment-class'
import { Encrypt } from '@/utils/sercet'
import dayjs from 'dayjs'
import { equipmentType, status } from '@/config/options.config'
import Split from '@/components/Split/Split'
import EquipmentClass from '@/components/xiewei/EquipmentClass/EquipmentClass'
import { getToken } from '@/utils/auth'

export default {
  name: 'SelectFacility',
  components: { EquipmentClass, Split, SearchForm, FunctionHeader },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    selectFacilitys: {
      type: Array,
      default: () => ([])
    },
    isAdd: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '添加设备'
    },
    customerId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      modalShow: false,
      split: {
        offset: 20
      },
      columns,
      functionHeader: {
        searchVal: '',
        tags: []
      },
      searchFormList: [],
      searchFormData: {},
      searchFormatData: [],
      selectionData: [],
      fullScreen: false,
      equipmentClassId: undefined
    }
  },
  computed: {
    height() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 83 : this.$store.state.app.contentHeight - 289 - 110
    },
    splitHeight() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 10 : this.$store.state.app.contentHeight - 289 - 45
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  watch: {
    visible(val) {
      this.modalShow = val
      if (this.single) this.columns = sColumns
      if (val) {
        this.echoData()
      }
    },
    modalShow(val) {
      if (!val) {
        this.searchFormData = {}
        this.searchFormatData = []
        this.functionHeader.searchVal = ''
        this.$emit('update:visible', false)
      }
    }
  },
  mounted() {
    this.modalShow = this.visible
    if (this.single) this.columns = sColumns
    this.init()
    if (this.modalShow) {
      this.echoData()
    }
  },
  methods: {
    resetData() {
      this.selectionData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionData = this.selectionData.filter((item) => item.selectable)
    },
    echoData() {
      this.$nextTick(() => {
        // 数据回显
        this.equipmentClassId = ''
        this.selectionData = this._.cloneDeep(this.selectFacilitys)
        if (this.single) {
          this.$refs.mTable.tableRefs().setCurrentRow(this.selectionData[0])
        } else {
          this.selectionData.forEach(item => {
            this.$refs.mTable.tableRefs().toggleRowSelection(item, true)
          })
        }
      })
    },
    updateTable() {
      this.$refs.mTable.setTableData()
    },
    // 新增
    add() {
      this.$emit('addMaterial')
    },
    // 选中行复选框也会选中
    handleRowClick(row) {
      if (!row.selectable) {
        this.$refs.mTable.tableRefs().toggleRowSelection(row)
      }
    },
    fullScreenChange(val) {
      this.fullScreen = val
    },
    init() {
      this.searchFormatData = []
      this.searchFormData = {}
      this.columns = columns
    },
    selectionChange(data) {
      if (this.single) {
        this.selectionData = []
        this.selectionData.push(data[data.length - 1])
        return false
      }
      if (Array.isArray(data)) {
        this.selectionData = data
      } else if (!data.selectable) {
        this.selectionData = []
        this.selectionData.push(data)
      }
    },
    setEquipmentClassId(val) {
      this.equipmentClassId = val
      this.$refs.mTable.setTableData(true)
    },
    // 查询物料
    searchTable(data) {
      this.searchFormatData = data
      this.$refs.mTable.setTableData()
    },
    // 获取数据
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      search.push(
        {
          id: getUUid(),
          fieldName: 'isDeleted',
          fieldType: 'number',
          fieldValue: 0,
          operator: 'eq'
        }
      )
      const searchKey = []
      if (this.functionHeader.searchVal) {
        searchKey.push({
          fieldKey: 'code^name^sn',
          fieldType: 0,
          fieldValue: this.functionHeader.searchVal
        })
      }
      const extra = []
      if (this.equipmentClassId) {
        extra.push({
          fieldKey: 'equipment_class_id',
          fieldType: 1,
          fieldValue: this.equipmentClassId
        })
      }
      if (this.customerId) {
        extra.push({
          fieldKey: 'customer_id',
          fieldType: 2,
          fieldValue: this.customerId
        })
      }
      const res = await api.getFacilityListPage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchVal: Encrypt(JSON.stringify([...search])),
        fieldParamBean: Encrypt(JSON.stringify([...extra, ...searchKey]))
      })
      if (this.selectFacilitys) {
        this.selectFacilitys.forEach((item) => {
          res.records.forEach(t => {
            if (item.selectable && t.id === item.id) {
              t.selectable = true
            }
          })
        })
      }
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    submitForm(callback) {
      if (this.selectionData.length === 0) {
        this.$message.info('请至少选择一条数据')
        callback()
        return
      }
      this.$emit('submitForm', this.selectionData)
    }
  }
}
</script>

<style lang="scss" scoped>
.materials-info {
  padding: 10px;
}
.materials-head{
  padding:0 0 10px 0;
}
</style>
