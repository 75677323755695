import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/invoices/private/facilityFactoryParameterTemplate/'
const baseUrl1 = 'api/web/v1/field/facility/private/facilityFactoryParameterMapping/'
export default {
  // 获取工厂参数列表（不分页）
  getFactoryParameterList(data) {
    return request.get(baseUrl + 'getFacilityFactoryParameterTemplateList', data)
  },
  // 获取工厂参数列表（分页）
  getFactoryParameterListPage(data) {
    return request.get(baseUrl + 'getFacilityFactoryParameterTemplateListPage', data)
  },
  // 新建工厂参数
  addFactoryParameter(data) {
    return request.post(baseUrl + 'addFacilityFactoryParameterTemplate', data)
  },
  // 修改工厂参数
  modifyFactoryParameter(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 复制工厂参数
  copyFactoryParameter(data) {
    return request.post(baseUrl + 'addFacilityFactoryParameterTemplate', data)
  },
  // 删除工厂参数
  delFactoryParameter(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 获取工厂参数详情
  getFactoryParameterDetail(data) {
    return request.get(baseUrl + 'getFacilityFactoryParameterTemplateDetail', data)
  },
  // 修改工厂参数-设备
  modifyFacilityFactoryParameter(data) {
    return request.put(baseUrl1 + 'updateOne', data)
  },
  // 删除工厂参数-设备
  delFacilityFactoryParameter(data) {
    return request.del(baseUrl1 + 'deleteOne', data)
  }
}
