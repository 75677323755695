import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/production/kiki/private/quotaSheet/'

export default {
 
  //新增定额
  addQuotaSheet(data){
    return request.post(baseUrl + 'addQuotaSheet', data)
  },
  //列表查询
  getQuotaSheetPage(data){
    return request.get(baseUrl + 'getQuotaSheetPage', data)
  },
  //获取定额单详情
  getQuotaSheetDetail(id){
    return request.get(baseUrl + `getQuotaSheetDetail?id=${id}`)

  },
  //批量删除
  allDel(data){
    return request.post(baseUrl + 'batchDeleteQuotaSheet', data)

  },
  //单个删除
  deleteQuotaSheet(data){
    return request.get(baseUrl + `deleteQuotaSheet?id=${data}` )
  },
}