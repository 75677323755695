import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/equipment/parameters/private/deviceParametersMapping/'
const baseUrl2 = 'api/web/v1/equipment/parameters/private/deviceParametersRecord/'

export default {
  // 获取设备-不分页
  getEquipmentParamsList(data) {
    return request.get(baseUrl + 'getDeviceParametersMappingList', data)
  },
  // 获取设备-分页
  getEquipmentParamsListPage(data) {
    return request.get(baseUrl + 'getDeviceParametersMappingListPage', data)
  },
  // 新增设备
  addEquipmentParams(data) {
    return request.post(baseUrl + 'addDeviceParametersMapping', data)
  },
  // 新增设备
  copyEquipmentParams(data) {
    return request.post(baseUrl + 'addDeviceParametersMapping', data)
  },
  // 修改设备
  modifyEquipmentParams(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除设备
  delEquipmentParams(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  getDeviceParametersRecord(params) {
    return request.get(baseUrl2 + 'getDeviceParametersRecord', params)
  },
  getDeviceParametersRecordTrend(params) {
    return request.post(baseUrl2 + 'getDeviceParametersRecordTrend', params)
  }
}
