import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/materials/private/requirementOrder/'
export default {
// 获取需求单列表
getMaterialsRequireListPage(data){
    return request.get(baseUrl + 'listPage', data)
},
// 获取需求单基本信息
getMaterialsRequireDetail(data){
    return request.get(baseUrl + 'getRequirementOrder', data)
},
// 获取需求单物料详情-工单物料列表
getWorkOrderListPage(data){
    return request.get(baseUrl + 'workOrderMaterialsListPage', data)
},
// 需求单物料详情-工序物料列表
getProcedureMaterialsListPage(data){
    return request.get(baseUrl + 'procedureMaterialsListPage', data)
},
// 导出工序物料
// 物料锁库
lockedQuantity(data){
    return request.post(baseUrl + 'lockedQuantity', data)
},
// 取消物料锁库
cancelLockedQuantity(data){
    return request.post(baseUrl + 'cancelLockedQuantity', data)
},
// 导出需求单物料详情-导出工序物料
exportProcedureMaterialsList(data){
  return request.exportGet(baseUrl + 'exportProcedureMaterialsList', data)
},
// 导出需求单物料详情-工单物料列表
exportWorkOrderMaterialsList(data){
  return request.exportGet(baseUrl + 'exportWorkOrderMaterialsList', data)
},
// 获取工序下拉列表
getProcessList(data){
    return request.get(baseUrl + 'procedureGroupList', data)
},
// 获取模组下拉列表
getModuleList(data){
    return request.get(baseUrl + 'moduleGroupList', data)
},
// 同步领料单申请数量
synchronizedApplyQuantity(data){
    return request.post(baseUrl + 'materialsRequisitionComputing', data)
  }
}
