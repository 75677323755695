import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/invoices/private/facilityPlanMaintenanceTemplate/'
const baseUrl1 = 'api/web/v1/field/facility/invoices/private/facilityMaintenancePlan/'

export default {
  // 获取保养计划-不分页
  getFacilityMaintenancePlanList(data) {
    return request.get(baseUrl1 + 'getFacilityMaintenancePlanList', data)
  },
  // 获取保养计划-分页
  getFacilityMaintenancePlanListPage(data) {
    return request.get(baseUrl1 + 'getFacilityMaintenancePlanListPage', data)
  },
  // 新增保养计划
  addFacilityMaintenancePlan(data) {
    return request.post(baseUrl1 + 'addFacilityMaintenancePlan', data)
  },
  // 修改保养计划
  modifyFacilityMaintenancePlan(data) {
    return request.put(baseUrl1 + 'updateOne', data)
  },
  // 复制保养计划
  copyFacilityMaintenancePlan(data) {
    return request.post(baseUrl1 + 'addFacilityMaintenancePlan', data)
  },
  // 删除保养计划
  delFacilityMaintenancePlan(data) {
    return request.del(baseUrl1 + 'deleteOne', data)
  },
  // 批量删除保养计划
  delBatchFacilityMaintenancePlan(data) {
    return request.del(baseUrl1 + 'deleteBatch', data)
  },
  // 获取保养计划-详情
  getFacilityMaintenancePlanDetail(data) {
    return request.get(baseUrl1 + 'getFacilityMaintenancePlanDetail', data)
  },
  // 获取保养计划模板-不分页
  getPlanMaintenanceTemplateList(data) {
    return request.get(baseUrl + 'getFacilityPlanMaintenanceTemplateList', data)
  },
  // 获取保养计划模板-分页
  getPlanMaintenanceTemplateListPage(data) {
    return request.get(baseUrl + 'getFacilityPlanMaintenanceTemplateListPage', data)
  },
  // 新增保养计划模板
  addPlanMaintenanceTemplate(data) {
    return request.post(baseUrl + 'addFacilityPlanMaintenanceTemplate', data)
  },
  // 修改保养计划模板
  modifyPlanMaintenanceTemplate(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除保养计划模板
  delPlanMaintenanceTemplate(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 获取保养计划模板模板-详情
  getPlanMaintenanceTemplateDetail(data) {
    return request.get(baseUrl + 'getFacilityPlanMaintenanceTemplateDetail', data)
  }
}
