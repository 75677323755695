var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showDatas && _vm.showDatas.length > 0
    ? _c(
        "div",
        {
          ref: "elements",
          staticClass: "gantt-blocks",
          style: { height: _vm.blockHeight + "px" }
        },
        [
          _c("div", {
            staticClass: "gantt-block gantt-block-top-space",
            style: { height: _vm.topSpace + "px" }
          }),
          _vm._l(_vm.showDatas, function(data, index) {
            return _c(
              "div",
              {
                key: _vm.dataKey ? data[_vm.dataKey] : index,
                staticClass: "gantt-block",
                class: [index === _vm.rowIndex ? "rowColor" : ""],
                style: {
                  backgroundSize:
                    _vm.cellWidth +
                    "px " +
                    (_vm.currentGanttBlocksHeights[data.id] ||
                      _vm.cellHeight + "px"),
                  height:
                    "" +
                    (_vm.currentGanttBlocksHeights[data.id] ||
                      _vm.cellHeight + "px"),
                  backgroundColor:
                    _vm.selectId === data.id ? "rgba(230, 232, 239, 0.3)" : ""
                },
                on: {
                  click: function($event) {
                    return _vm.clickRow(index)
                  }
                }
              },
              [
                !_vm.customGenerateBlocks
                  ? _vm._l(_vm.concatArray(data), function(item, idx) {
                      return _vm.fixData(item)
                        ? _c(
                            "div",
                            {
                              key: _vm.itemKey ? item[_vm.itemKey] : idx,
                              staticClass: "gantt-block-item block",
                              class: [
                                _vm.blockState &&
                                item === data.gtArray[_vm.operationIndex]
                                  ? _vm.groundFloor
                                  : _vm.superstratum
                              ],
                              style: {
                                left:
                                  item.actualStartTime &&
                                  _vm.compareDate(
                                    item.planStartTime,
                                    item.actualStartTime
                                  )
                                    ? _vm.getPosition(item.actualStartTime) +
                                      "px"
                                    : _vm.getPosition(item.start) + "px",
                                width: item.actualStartTime
                                  ? ""
                                  : _vm.getWidth(item) + "px",
                                top:
                                  _vm.getTop(item, data.gtArray, data, idx) +
                                  "px"
                              }
                            },
                            [
                              _vm._t(
                                "default",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "gantt-block-defaultBlock",
                                      staticStyle: { width: "100%" }
                                    },
                                    [_vm._v("need slot")]
                                  )
                                ],
                                {
                                  data: data,
                                  item: item,
                                  getPositonOffset: _vm.getPositonOffset,
                                  getWidthAbout2Times: _vm.getWidthAbout2Times
                                }
                              )
                            ],
                            2
                          )
                        : _vm._e()
                    })
                  : [
                      _vm._t("default", [_vm._v("need slot")], {
                        data: data,
                        getPositonOffset: _vm.getPositonOffset,
                        getWidthAbout2Times: _vm.getWidthAbout2Times,
                        isInRenderingTimeRange: _vm.isInRenderingTimeRange
                      })
                    ]
              ],
              2
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }