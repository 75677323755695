import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/warehouse/kiki/private/warehouseMaterialsBatKiki/'
const historyUrl = 'api/web/v1/warehouse/kiki/private/outInWarehouseBillRecord/'

export default {
  // 获取库存总表-不分页
  getInventoryList(data) {
    return request.get(baseUrl + '', data)
  },
  // 获取库存总表-分页
  getInventoryListPage(data) {
    return request.get(baseUrl + 'getWarehouseMaterialsTotalListPage', data)
  },
  // 获取库存总表-不分页
  getInventoryDetailList(data) {
    return request.get(baseUrl + 'getWarehouseMaterialsDetailsList', data)
  },
  // 获取库存总表-分页
  getInventoryDetailListPage(data) {
    return request.get(baseUrl + 'getWarehouseMaterialsDetailsListPage', data)
  },
  // 获取箱数明细
  getPackageDetail(data) {
    return request.get(baseUrl + 'getContainerDetailsList', data)
  },
  // 获取散数明细
  getScatterDetail(data) {
    return request.get(baseUrl + 'getScatterDetailsList', data)
  },
  // 拆包
  unPackageContainer(data) {
    return request.put(baseUrl + 'unpackageContainer', data)
  },
  // 更换包装
  changeClassForContainer(data) {
    return request.put(baseUrl + 'changeClassForContainer', data)
  },
  // 转移库位
  changeWarehouseLocation(data) {
    return request.put(baseUrl + 'changeWarehouseLocationBatch', data)
  },
  // 查看出入库记录
  viewInventoryHistory(data) {
    return request.get(historyUrl + 'getOutInWarehouseBillRecordList', data)
  },
  // 查看出入库记录
  viewInventoryHistoryPage(data) {
    return request.get(historyUrl + 'getOutInWarehouseBillRecordListPage', data)
  },
  // 批量出库
  deliveryInventory(data) {
    return request.put(baseUrl + 'warehouseBatchOutbound', data)
  },
  // 获取物料库存汇总列表
  getMaterialsListPage(data) {
    return request.get(baseUrl + 'getMaterialsInventoryPage', data)
  },
  // 获取批次号汇总列表
  getBatchListPage(data) {
    return request.get(baseUrl + 'getBatchSummaryPage', data)
  },
  // 查看明细
  getWarehouseMaterialsDetails(data) {
    return request.post(baseUrl + 'getWarehouseMaterialsDetails', data)
  },
  // 库存明细-新的-分页
  getWarehouseMaterialsDetailsIsContainerListPage(data) {
    return request.get(baseUrl + 'getWarehouseMaterialsDetailsIsContainerListPage', data)
  },
  // 库存明细-新的-不分页
  getWarehouseMaterialsDetailsIsContainerList(data) {
    return request.get(baseUrl + 'getWarehouseMaterialsDetailsIsContainerList', data)
  }
}
