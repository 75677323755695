var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "none" }, attrs: { id: _vm.commonIds } },
    _vm._l(_vm.codes, function(item, index) {
      return _c(
        "div",
        {
          key: index,
          staticStyle: {
            "padding-top": "10px",
            display: "inline-flex",
            "justify-content": "flex-start"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { width: "100px" } },
            [
              _c("VueQr", {
                attrs: {
                  "correct-level": 3,
                  "auto-color": false,
                  "color-dark": "#000000",
                  text: item.batchNumber ? item.batchNumber : item.code,
                  size: 120,
                  margin: 0
                }
              }),
              _c("div", { staticStyle: { "padding-top": "14px" } }, [
                _vm._v(_vm._s(item.batchNumber || item.code))
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-left": "26px", "text-align": "left" } },
            [
              _c("div", { staticStyle: { "font-size": "18px" } }, [
                _vm._v("物料名称：" + _vm._s(item.materialsName || item.name))
              ]),
              _c("div", { staticStyle: { "font-size": "18px" } }, [
                _vm._v("物料编码：" + _vm._s(item.materialsCode || item.code))
              ]),
              _c("div", { staticStyle: { "font-size": "18px" } }, [
                _vm._v(
                  "物料规格：" +
                    _vm._s(item.materialsSpecifications || item.specifications)
                )
              ]),
              item.materialsCode !== item.batchNumber && !item.code
                ? _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v("生产日期：" + _vm._s(_vm.times))
                  ])
                : _vm._e(),
              item.memberCode === "bbelc"
                ? _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v("工厂名称：上海工厂")
                  ])
                : _vm._e(),
              item.memberCode === "bbwz"
                ? _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v("工厂名称：温州工厂")
                  ])
                : _vm._e()
            ]
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }