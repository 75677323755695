import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/siteWarehouse/kiki/private/sitMaterialsBatKiki/'

export default {
  // 物料分页
  getPage(data) {
    return request.get(baseUrl + 'getSitStockMaterialsPage', data)
  },
  // 包装分页
  getPackPage(data) {
    return request.get(baseUrl + 'getSitStockContainerPage', data)
  },
  // 托盘分页
  getTrayPage(data) {
    return request.get(baseUrl + 'getSitStockPalletPage', data)
  },
  batchEnterWarehouse(ids) {
    return request.post('api/web/v1/siteWarehouse/kiki/private/produceOrder/batchOutToERPForBbelc', ids)
  }
}
