var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Popover",
    {
      staticStyle: { "min-width": "100px" },
      attrs: {
        "popper-class": "custom-popover",
        placement: "bottom",
        width: "100",
        trigger: "contextmenu"
      }
    },
    [
      _c(
        "a",
        {
          staticStyle: {
            "text-align": "center",
            width: "100%",
            color: "#607fff"
          },
          on: { click: _vm.jumpDetailNewTab }
        },
        [_vm._v("在新的窗口打开")]
      ),
      _c(
        "el-button",
        {
          attrs: { slot: "reference", type: "text" },
          on: {
            click: function($event) {
              return _vm.jumpDetail()
            }
          },
          slot: "reference"
        },
        [_vm._v(_vm._s(_vm.text))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }