import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/basicData/private/workHoursReason/'
export default {
  // 获取工时原因-分页
  getWorkhoursReasonListPage(data) {
    return request.get(baseUrl + 'getWorkHoursReasonListPage', data)
  },
  // 新增工时原因
  addWorkhoursReason(data){
    return request.post(baseUrl + 'addWorkHoursReason', data)
  },
  // 修改工时原因
  modifyWorkhoursReason(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
 // 删除工时原因
 delWorkhoursReason(data) {
   return request.del(baseUrl + 'deleteOne', data)
  }
}
