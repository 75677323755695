<template>
  <div :id="commonIds" style="display: none">
    <div v-for="(item, index) in codes" :key="index" style="padding-top: 10px">
      <VueQr :correct-level="3" :auto-color="false" color-dark="#000000" :text="item" :size="95" :margin="0" />
      <div style="padding-top: 10px">{{ item }}</div>
    </div>
  </div>
</template>

<script>
// import JsBarcode from 'jsbarcode'
import VueQr from 'vue-qr'
export default {
  name: 'PrintCode',
  components: { VueQr },
  props: {
    commonId: {
      type: String,
      default: 'print'
    }
  },
  data() {
    return {
      codes: [],
      commonIds: 'print'
    }
  },
  watch: {
    commonId(val) {
      this.commonIds = val
    }
  },
  methods: {
    print(code) {
      this.codes = [code]
      this.printIframe()
    },
    batchPrint(codes) {
      this.codes = codes
      this.printIframe()
    },
    printIframe() {
      setTimeout(() => {
        const printHtml = document.getElementById(`${this.commonIds}`).innerHTML
        const new_iframe = document.createElement('IFRAME')
        let doc = null
        new_iframe.setAttribute('style', 'width:0px;height:0px;position:absolute;left:-2000px;top:-2000px;')
        new_iframe.setAttribute('align', 'center')
        document.body.appendChild(new_iframe)
        doc = new_iframe.contentWindow.document
        doc.write('<div style="width:100%;height:auto;margin:0 auto;"align="center">' + printHtml + '</div>')
        // doc.body.style.zoom = '0.8'
        doc.close()
        new_iframe.contentWindow.focus()
        new_iframe.contentWindow.print()
      }, 0)
    }
  }
}
</script>

<style scoped></style>
