import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/equipmentBoard/private/equipmentBoard/'

export default {
  // 获取设备看板状态
  getEquipmentStatusList(data) {
    return request.get(baseUrl + 'getEquipmentStatusList', data)
  },
  // 获取设备看板详情
  getEquipmentParametersList(data) {
    return request.get(baseUrl + 'getEquipmentParametersList', data)
  },
  // 获取设备参数
  getEquipmentAllParametersList(data) {
    return request.get(baseUrl + 'getEquipmentAllParametersList', data)
  },
  // 新增显示设备
  addShowEquip(data) {
    return request.post(baseUrl + 'updateEquipmentAllParametersList', data)
  },
  // 批量修改设备显示状态
  updateShowEquip(data) {
    return request.post(baseUrl + 'updateEquipmentShowList', data)
  }
}
