<template>
  <div id="splitWrapper" class="split-wrapper" :style="{height: height + 'px'}">
    <div class="horizontal">
      <div class="left" :style="{right: anotherOffset + '%'}">
        <slot name="left" />
      </div>
      <div v-if="move" class="trigger-con" :style="{left: offset + '%'}">
        <div class="split-line">
          <div class="icon-wrapper" @mousedown="handleMouseDown($event)">
            <img src="../../assets/split/split.png" style="width: 12px" alt="">
          </div>
        </div>
      </div>
      <div class="right" :style="{left: offset + '%'}">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<script>
/** write by luomingzhi */
export default {
  name: 'Split',
  props: {
    offset: {
      type: Number,
      default: 50
    },
    height: {
      type: Number,
      default: 500
    },
    move: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      anotherOffset: 50,
      maxOffset: 80,
      minOffset: 15,
      oldOffset: 50,
      initOffset: 50
    }
  },
  watch: {
    offset() {
      this.anotherOffset = 100 - this.offset
    }
  },
  mounted() {
    this.anotherOffset = 100 - this.offset
  },
  methods: {
    px2percent(numerator, denominator) {
      return parseFloat(numerator) / parseFloat(denominator)
    },
    handleMouseDown(e) {
      e.preventDefault()
      this.initOffset = e.pageX
      this.oldOffset = this.offset
      document.addEventListener('mousemove', this.handleMove, false)
      document.addEventListener('mouseup', this.handleMouseUp, false)
    },
    handleMouseUp() {
      document.removeEventListener('mousemove', this.handleMove, false)
      document.removeEventListener('mouseup', () => false, false)
    },
    handleMove(e) {
      const pageOffset = e.pageX
      const offset = pageOffset - this.initOffset
      const outerWidth = document.querySelector('#splitWrapper').clientWidth
      let value = this.px2percent(outerWidth * (this.oldOffset / 100) + offset, outerWidth) * 100
      if (value > this.maxOffset) {
        value = this.maxOffset
      } else if (value < this.minOffset) {
        value = this.minOffset
      } else {
        value = Number(value.toFixed(2))
      }
      this.$emit('update:offset', value)
      this.$emit('offsetChange', value)
    }
  }
}
</script>

<style scoped lang="scss">
.split-wrapper {
  width: 100%;
  border: 1px solid #D4D4DC;
  .horizontal {
    height: 100%;
    position: relative;
    .left {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      background: #F8F8F8;
    }
    .trigger-con {
      height: 100%;
      position: absolute;
      z-index: 99;
      .split-line {
        width: 1px;
        height: 100%;
        background: #D4D4DC;
        position: relative;
        z-index: 1;
        .icon-wrapper {
          position: absolute;
          top: 50%;
          transform: translate(-50%);
          cursor: col-resize;
        }
      }
    }
    .right {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      overflow: auto;
    }
  }
}
</style>
