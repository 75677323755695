import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/invoices/private/facilityPlanMaintenanceTemplateMaterialsMapping/'
const baseUrl1 = 'api/web/v1/field/facility/invoices/private/facilityMaintenancePlanMaterialsMapping/'

export default {
  // 新增保养模板更换备件
  addPlanMaintenanceTemplateMaterials(data) {
    return request.post(baseUrl + 'addFacilityPlanMaintenanceTemplateMaterialsMapping', data)
  },
  // 批量新增保养模板更换备件
  addBatchPlanMaintenanceTemplateMaterials(data) {
    return request.post(baseUrl + 'addFacilityPlanMaintenanceTemplateMaterialsMapping', data)
  },
  // 修改保养模板更换备件
  modifyPlanMaintenanceTemplateMaterials(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除保养模板更换备件
  delPlanMaintenanceTemplateMaterials(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 批量新增保养计划更换备件
  addBatchPlanMaintenanceMaterials(data) {
    return request.post(baseUrl1 + 'addFacilityMaintenancePlanMaterialsMapping', data)
  },
  // 修改保养计划更换备件
  modifyPlanMaintenanceMaterials(data) {
    return request.put(baseUrl1 + 'updateOne', data)
  },
  // 删除保养计划更换备件
  delPlanMaintenanceMaterials(data) {
    return request.del(baseUrl1 + 'deleteOne', data)
  }
}
