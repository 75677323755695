import { procedureType } from '@/config/options.config'

export const base = [
  {
    prop: 'code',
    label: '工序编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '工序名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'procedureClassName',
    label: '工序分类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'type',
    label: '工序类别',
    sortable: false,
    hiddenSearch: true,
    form: { options: procedureType }
  }
]

export const inProduct = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'procedureName',
    label: '前道工序名称',
    form: { tagName: 'el-input', props: { disabled: true }},
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '在制品输入数量',
    form: { tagName: 'el-input-number', props: { controlsPosition: 'right', min: 1 }},
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false
  }
]

export const materialsBom = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '物料编码',
    form: { tagName: 'el-input', props: { disabled: true }},
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '物料名称',
    form: { tagName: 'el-input', props: { disabled: true }},
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'spec',
    label: '规格',
    form: { tagName: 'el-input', props: { disabled: true }},
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '物料属性',
    form: { tagName: 'el-input', props: { disabled: true }},
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '需求数量',
    form: { tagName: 'el-input-number', props: { controlsPosition: 'right', min: 1 }},
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '单位',
    form: { tagName: 'el-select', key: 'unit', options: [] },
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false
  }
]

export const fileAndDrawings = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '名称',
    slotName: 'fileName',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    slotName: 'remark',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false
  }
]

export const procedure = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'procedureIndex',
    label: '工序执行顺序',
    sortable: false,
    slotName: 'procedureIndex',
    hiddenSearch: true
  },
  {
    prop: 'procedureCode',
    label: '编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'procedureName',
    label: '名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'procedureType',
    label: '类型',
    slotName: 'procedureType',
    sortable: false,
    hiddenSearch: true
  }
]
export const plans = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planQuantity',
    label: '数量',
    slotName: 'planQuantity',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  }
  // {
  //   prop: 'isMaterial',
  //   label: '关键物料',
  //   sortable: false,
  //   hiddenSearch: true
  // },
]
