import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/warehouse/kiki/private/containerCode/'

export default {
  // 获取批次码-不分页
  getPackageCodeList(data) {
    return request.get(baseUrl + 'getContainerCodeList', data)
  },
  // 获取批次码-分页
  getPackageCodeListPage(data) {
    return request.get(baseUrl + 'getContainerCodeListPage', data)
  },
  // 新增批次码
  addPackageCode(data) {
    return request.post(baseUrl + 'addContainerCode', data)
  },
  // 修改批次码
  modifyPackageCode(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 复制批次码
  copyPackageCode(data) {
    return request.post(baseUrl + 'addContainerCode', data)
  },
  // 删除批次码
  delPackageCode(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  batchAddPackageCode(data) {
    return request.post(baseUrl + 'addContainerCodeBatch', data)
  }
}
